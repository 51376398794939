import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'inline-alert',
  templateUrl: './inline-alert.component.html'
})

export class InlineAlertComponent implements OnInit {

  @Input() level: string; // success | info | warning | error, currently only warning available
  @Input() small: boolean;
  @Input() noIcon: boolean;
  @Input() iconId: string = 'exclamation';
  @Input() collapsible: boolean = false;
  @Input() expanded: boolean = true;
  @Input() dismissLabel: string;
  @Input() actionLabel: string;
  @Output() dismiss: EventEmitter<null> = new EventEmitter();
  @Output() action: EventEmitter<null> = new EventEmitter();


  constructor() {}

  ngOnInit() {
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

  closeAlert() {
    this.dismiss.emit();
  }

  doAction() {
    this.action.emit();
  }
}
