import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, State } from '@app-ngrx-domains';
import { ROUTER_LINKS } from '../../consts';


@Component({
  selector: 'app-page-access-denied',
  templateUrl: './page-access-denied.component.html'
})
export class PageAccessDeniedComponent {

  pagePath: string;

  constructor(
    route: ActivatedRoute,
    private store: Store<State>) {

    // page we've tried to get to, but failed due to lack of access.
    this.pagePath = `/${route.snapshot.url.slice(0).join('/')}`;
    if (this.pagePath === ROUTER_LINKS.PAGE_ACCESS_DENIED) {
      this.pagePath = route.snapshot.queryParams['badUrl'];
    }

    // update header.
    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: `Access Denied`,
    }));
  }
}
