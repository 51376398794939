import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { ApiService } from '@app-services';
import { Actions as DomainActions, State, Queries } from '@app-ngrx-domains';
import { Observable, of, EMPTY, forkJoin } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ActionWithPayload, toPayload } from '@app-libs';
import { PROGRAM_ALLOCATIONS_ACTION_TYPES } from './program-allocations.action';

@Injectable()
export class ProgramAllocationsEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {}

  @Effect() fetchAllocations$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_ALLOCATIONS),
    map(toPayload),
    mergeMap(({ filter, key }) => {
      return this.apiService.getAllocations(filter).pipe(
        mergeMap(results => {
          return of(DomainActions.ProgramAllocations.fetchSuccess('allocations', key, results));
        }),
        catchError(err => {
          return of(DomainActions.ProgramAllocations.serviceFail(err));
        })
      )
    })
  );

  @Effect() fetchCarryovers$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_CARRYOVERS),
    map(toPayload),
    mergeMap(({ filter, key }) => {
      return this.apiService.getAllocationCarryovers(filter.fund_id).pipe(
        mergeMap(results => {
          return of(DomainActions.ProgramAllocations.fetchSuccess('carryovers', key, results));
        }),
        catchError(err => {
          return of(DomainActions.ProgramAllocations.serviceFail(err));
        })
      )
    })
  )

  @Effect() fetchTasks$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_TASKS),
    map(toPayload),
    mergeMap(({ filter, key }) => {
      return this.apiService.listTasks(filter, true).pipe(
        mergeMap(results => {
          return of(DomainActions.ProgramAllocations.fetchSuccess('tasks', key, results));
        }),
        catchError(err => {
          return of(DomainActions.ProgramAllocations.serviceFail(err));
        })
      )
    })
  );

  @Effect() fetchProposals$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_PROPOSALS),
    map(toPayload),
    mergeMap(({ filter, key }) => {
      return this.apiService.getProposals({ deleted: false, ...filter }).pipe(
        mergeMap(results => {
          return of(DomainActions.ProgramAllocations.fetchSuccess('proposals', key, results));
        }),
        catchError(err => {
          return of(DomainActions.ProgramAllocations.serviceFail(err));
        })
      )
    })
  );

  @Effect() refreshAllocations$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_ALLOCATIONS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.ProgramAllocations.getFilterStash)),
    mergeMap(([{ key }, filterStash]) => {
      if (key) {
        const filter = filterStash.allocations[key];
        return filter ? of(DomainActions.ProgramAllocations.fetchAllocations(filter, key)) : EMPTY;
      } else {
        const filters = Object.entries(filterStash.allocations).map(([key, filter]) => DomainActions.ProgramAllocations.fetchAllocations(filter, key));
        return this.dispatchActions(filters);
      }
    })
  );

  @Effect() refreshCarryovers$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_CARRYOVERS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.ProgramAllocations.getFilterStash)),
    mergeMap(([{ key }, filterStash]) => {
      if (key) {
        const filter = filterStash.carryovers[key];
        return filter ? of(DomainActions.ProgramAllocations.fetchCarryovers(filter, key)) : EMPTY;
      } else {
        const filters = Object.entries(filterStash.carryovers).map(([key, filter]) => DomainActions.ProgramAllocations.fetchCarryovers(filter, key));
        return this.dispatchActions(filters);
      }
    })
  );

  @Effect() refreshTasks$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_TASKS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.ProgramAllocations.getFilterStash)),
    mergeMap(([{ key }, filterStash]) => {
      if (key) {
        const filter = filterStash.tasks[key];
        return filter ? of(DomainActions.ProgramAllocations.fetchTasks(filter, key)) : EMPTY;
      } else {
        const filters = Object.entries(filterStash.tasks).map(([key, filter]) => DomainActions.ProgramAllocations.fetchTasks(filter, key));
        return this.dispatchActions(filters);
      }
    })
  );

  @Effect() refreshProposals$: Observable<Action> = this.actions$.pipe(
    ofType(PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_PROPOSALS),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.ProgramAllocations.getFilterStash)),
    mergeMap(([{ key }, filterStash]) => {
      if (key) {
        const filter = filterStash.proposals[key];
        return filter ? of(DomainActions.ProgramAllocations.fetchProposals(filter, key)) : EMPTY;
      } else {
        const filters = Object.entries(filterStash.proposals).map(([key, filter]) => DomainActions.ProgramAllocations.fetchProposals(filter, key));
        return this.dispatchActions(filters);
      }
    })
  );

  dispatchActions(actions: Array<Action | ActionWithPayload<any>> = []) {
    actions.forEach(action => {
      this.store.dispatch(action);
    });

    return EMPTY;
  }

}
