import { Model } from '@app-ngrx-domains';
import { ModelBase } from './model.base';
import { startCase as _startCase} from 'lodash';
import { Task } from './task';
import { STATE_TYPES, TASK_TYPES } from '../consts';
import { UserRoleScope } from './index';

export interface ICfadAmendment {
  cfad_id: number;
  duration_id: number;
  institution_id: number;
  state_id: number;
  tasks: Array<Model.Task>;
  client_state: {
    changes: ICfadAmendmentChanges
    task_id?: number;
  };
}

export interface ICfadAmendmentChanges {
  placeholder_index: number,
  members: Array<{ institution_id: number, institution: Model.Institution, member_reps?: Array<UserRoleScope>, voting_only: boolean }>,
  allocations: Array<Model.Allocation>
}

export class CfadAmendment extends ModelBase implements ICfadAmendment {
  public cfad_id: number;
  public duration_id: number;
  public institution_id: number;
  public state_id: number;
  public tasks: Array<Model.Task>;
  public client_state: {
    changes: {
      placeholder_index: number,
      members: Array<{ institution_id: number, institution: Model.Institution, member_reps?: Array<UserRoleScope>, voting_only: boolean }>,
      allocations: Array<Model.Allocation>
    },
    task_id?: number;
  };

  constructor(raw: any) {
    super();
    if (raw) {
      this.cfad_id = raw.id;
      this.duration_id = raw.duration_id;
      this.institution_id = raw.institution_id;
      // just use tasks relevant to amendments.
      const submit_task = raw.tasks.find(t => t.task_type === TASK_TYPES.CAEP_CFAD_AMENDMENT_SUBMIT);
      if (submit_task) {
        this.tasks = [submit_task];
        const approval_tasks = raw.tasks.filter(t => {
          if (t.task_type === TASK_TYPES.CAEP_CFAD_AMENDMENT_APPROVE) {
            return submit_task.completed && t.store['isSecondRound']
              ? t.id > submit_task.id // Only include second-round approvals that were created after the current submit task
              : true;
          }
        });
        this.tasks.push(...approval_tasks);
      } else {
        this.tasks = [];
      }

      // determine state id from state of submit & approval tasks.
      this.state_id = CfadAmendment.getStateFromTasks(this.tasks);

      // initialize client state
      this.client_state = {
        changes: {
          placeholder_index: -1,
          members: [],
          allocations: []
        },
      };

      // read client state from the submit task.
      const submitTask: any = CfadAmendment.getSubmitTask(this.tasks);
      if (submitTask) {
        this.client_state = {
          ...this.client_state,
          ...submitTask.client_state,
          ['task_id']: submitTask.id,
        };
      }
    }
  }

  /**
   * Determines cfad amendment's state by looking at the state of the tasks.
   * @param tasks
   */
  static getStateFromTasks(tasks) {
    // get submit & approver tasks - there should be single submit task & one or more approval member tasks.
    const submitTask = tasks.find(t => t.task_type === TASK_TYPES.CAEP_CFAD_AMENDMENT_SUBMIT && !t.store.rejected);
    const approveMemberTasks = tasks.filter(t => t.task_type === TASK_TYPES.CAEP_CFAD_AMENDMENT_APPROVE);
    return submitTask ? Task.getStateFromTasksWMultipleApprovers(submitTask, approveMemberTasks, true) : undefined;
  }

 /**
   * Returns true if amendment has been certified.
   * @static
   * @param {number} state_id
   * @returns {boolean}
   */
  static isCertified(state_id: number): boolean {
    return state_id && state_id === STATE_TYPES.CERTIFIED;
  }

  /**
   * Returns true if amendment has been submitted.
   * @static
   * @param {number} state_id
   * @returns {boolean}
   */
  static isSubmitted(state_id: number): boolean {
    return state_id && state_id === STATE_TYPES.SUBMITTED;
  }

  /**
   * Returns true if amendment is in draft mode.
   * @static
   * @param {number} state_id
   * @returns {boolean}
   */
  static isDraft(state_id: number): boolean {
    return !state_id || state_id <= STATE_TYPES.DRAFT;
  }

  /**
   * Returns status/state as a name to be shown on header bar.
   * @param {number} state_id
   * @returns {string}
   */
  static formatStateHeader(state_id: number): string {
    switch (state_id) {
      case STATE_TYPES.CERTIFIED:
        return 'CERTIFIED';

      case STATE_TYPES.SUBMITTED:
        return 'SUBMITTED';

      case STATE_TYPES.REJECTED:
        return 'REJECTED';

      case STATE_TYPES.DRAFT:
      default:
        return 'DRAFT';
    }
  }

  /**
   * Formats the state name.
   * @param {number} state_id
   */
  static stateName(state_id: number): string {
    const result = _startCase(this.formatStateHeader(state_id).toLowerCase());
    return result;
  }

  /**
   * Finds submit task.
   * @param tasks
   */
  static getSubmitTask(tasks: Array<Model.Task>): Model.Task {
    const result = tasks.find(t => t.task_type === TASK_TYPES.CAEP_CFAD_AMENDMENT_SUBMIT);
    return result;
  }

  /**
   * Returns amendment amount assigned to given member.
   * @param institution_id member
   * @param allocations pending changes
   */
  static getAdjustmentAmount(institution_id: number, allocations: Array<Model.Allocation>) {
    const match = allocations.find(alloc => alloc.to_institution_id === institution_id);
    if (match) {
      return match.amount;
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type CfadAmendment = ICfadAmendment;
  }
}
