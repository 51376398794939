import * as _ from 'lodash';
import { Model } from '@app-ngrx-domains'
import { FUND_TYPES, WORKPLAN_TYPES, WORKPLAN_REQUIRED_NUMBER_GOALS, CHAR_LIMITS } from '@app-consts';
import { WorkplanItem } from '@app-models';

/** Form Validator for Workplan tab
 */

/** Validate that Workplan form fields meet requirements. If so, return TRUE, else return FALSE.
 * @param w IWorkplanState object, extended with `risks` and `recommendations`
 * @returns {boolean}
 */
export function validateStepData(w: any): boolean {

  const validateAEBG = () => {
    if (!(w.items && w.items.length > 0)) { return false; }

    // there should be just one workplan item for the entity.
    const item = w.items[0];
    // for now we'll just look for at least one response.
    if (!(item.strategies && item.strategies.length > 0)) { return false; }

    return true;
  }

  const validateIPlan = () => {

    const isActivityValid = (item: Model.WorkplanItem, name: string): boolean => {
      return (!_.isNil(item[name]) && item[name].length >= CHAR_LIMITS.NARRATIVE_MIN);
    }

    let count = 0;

    for (let i = 0; i < w.items.length; i++) {
      const item = w.items[i];

      if (item.workplan_type === WORKPLAN_TYPES.GOAL) {
        // keep count of goals.
        count++;

        if (!(item.major_activities && item.major_activities.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
          return false;
        }

        if (!(item.goal_area && item.goal_area.length > 0)) {
          return false;
        }

        let activityCount = 0;
        if (isActivityValid(item, 'activity_1')) {
          activityCount++;
        }
        if (isActivityValid(item, 'activity_2')) {
          activityCount++;
        }
        if (isActivityValid(item, 'activity_3')) {
          activityCount++;
        }

        if (count === 1) {
          // all three activities must be there.
          if (activityCount < 3) {
            return false;
          }
        } else if (count > 1) {
          // 2 of three must be there.
          if (activityCount < 2) {
            return false;
          }
        }
      }
    }

    // there should be set number of goals.
    if (count !== WORKPLAN_REQUIRED_NUMBER_GOALS) {
      return false;
    }

    return true;
  }

  const validateGP = () => {
    for (let i = 0; i < w.items.length; i++) {
      const item = w.items[i];

      if (item.workplan_type.startsWith(WORKPLAN_TYPES.GP_INQUIRY) && !item.workplan_type.endsWith(WORKPLAN_TYPES.GP_ASSESSMENT)) {
        if (!WorkplanItem.isValidGPItem(item)) {
          return false;
        }
      }
    }

    return true;
  }

  switch (w.project_type) {
    case FUND_TYPES.AEBG:
      return validateAEBG();

    case FUND_TYPES.IPLAN:
      return validateIPlan();

    case FUND_TYPES.GP:
      return validateGP();

    default:
      return false;
  }
}
