export interface IEffortArea {
  id: number;
  effort_area_type: string;
  parent_proposal_id?: number;
  parent_effort_area_id?: number;
  duration_id?: number;
  institution_id?: number;
  fund_id?: number;
  user_id?: number;
  title?: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  temp_id?: number;
}

export class EffortArea {
  static nativeAttributes = [
    'id', 'effort_area_type', 'parent_proposal_id', 'parent_effort_area_id', 'duration_id',
    'institution_id', 'user_id', 'title', 'description'
  ];

  static shadowAttributes = ['temp_id'];

  static basePayload(ea): IEffortArea {
    const base = {
      id:  ea.id,
      effort_area_type: ea.effort_area_type,
      parent_proposal_id: ea.parent_proposal_id,
    };
    EffortArea.nativeAttributes.forEach(attribute => {
      if (ea[attribute]) {
        base[attribute] = ea[attribute];
      }
    });

    return base;
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EffortArea = IEffortArea;
  }
}
