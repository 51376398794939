import { State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.CfadAmendment = {
  amendments: {},
  current_amendment_duration_id: null,
  isLoaded: false
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface ICfadAmendmentState {
    amendments: { [duration_id: number]: Model.CfadAmendment };
    current_amendment_duration_id?: number;
    isLoaded: boolean;
  }

  interface State {
    CfadAmendment: ICfadAmendmentState;
  }
}
