<div *ngIf="editingRequest; then accessRequestModal; else accessRequestTemplate"></div>

<ng-template #accessRequestModal>
  <full-page-modal (action)="emitEditedRequest(currentRequest)"
                   (cancel)="emitEditedRequest(editingRequest)"
                   (dismiss)="emitEditedRequest(editingRequest)"
                   [button-action-disabled]="!isEachFormValid"
                   button-action="Save"
                   button-cancel="Cancel"
                   heading="Edit Request"
                   small="true">
    <ng-container *ngTemplateOutlet="accessRequestTemplate"></ng-container>
  </full-page-modal>
</ng-template>

<ng-template #accessRequestTemplate>
  <div *ngIf="shouldShowEmployer; then employerSection; else newEmployerSection"></div>

  <form (ngSubmit)="submitForm()" [formGroup]="form" autocomplete="off">
    <div class="columns columns--2">
      <input-group formGroupName="programs" headingText="Programs" class="margin-bottom-none">
        <radio-check [labelText]="PROGRAMS_ALL_TEXT">
          <input (change)="checkAllPrograms()"
                 class="checkbox"
                 formControlName="all"
                 id="qa_requestAccess{{formIdSuffix}}_programsAll_checkbox"
                 inputRef
                 type="checkbox">
        </radio-check>
        <radio-check *ngFor="let program of PROGRAMS; let i = index" [labelText]="program">
          <input (change)="checkSingle('programs')"
                 [formControlName]="'p' + i"
                 class="checkbox"
                 id="qa_requestAccess{{formIdSuffix}}_program{{i}}_checkbox"
                 inputRef
                 type="checkbox">
        </radio-check>
        <radio-check [labelText]="DONT_KNOW_TEXT">
          <input (change)="checkNone('programsDontKnow')"
                 class="checkbox"
                 formControlName="dontKnow"
                 id="qa_requestAccess{{formIdSuffix}}_programsDontKnow_checkbox"
                 inputRef
                 type="checkbox">
        </radio-check>
      </input-group>

      <input-group formGroupName="activities" headingText="Activities" class="margin-bottom-none">
        <radio-check [labelText]="ACTIVITIES_READ_ONLY_TEXT">
          <input (change)="checkNone('activitiesReadOnly')"
                 formControlName="readOnly"
                 class="checkbox"
                 id="qa_requestAccess{{formIdSuffix}}_activitiesReadOnly_checkbox"
                 inputRef
                 type="checkbox">
        </radio-check>
        <radio-check *ngFor="let activity of ACTIVITIES; let i = index" [labelText]="activity">
          <input (change)="checkSingle('activities')"
                 [formControlName]="'a' + i"
                 class="checkbox"
                 id="qa_requestAccess{{formIdSuffix}}_activity{{i}}_checkbox"
                 inputRef
                 type="checkbox">
        </radio-check>
        <radio-check [labelText]="DONT_KNOW_TEXT">
          <input (change)="checkNone('activitiesDontKnow')"
                 class="checkbox"
                 formControlName="dontKnow"
                 id="qa_requestAccess{{formIdSuffix}}_activitiesDontKnow_checkbox"
                 inputRef
                 type="checkbox">
        </radio-check>
      </input-group>
    </div>

    <input-field labelText="Additional Information">
      <textarea formControlName="additionalInfo"
                id="qa_requestAccess{{formIdSuffix}}_additionalInfo_textarea"
                inputRef></textarea>
    </input-field>
    <div *ngIf="!editingRequest" class="align-right">
      <button [disabled]="!isEachFormValid"
              class="action-button action-button--secondary"
              type="submit">
        <icon iconId="plus"></icon>
        Add This Request
      </button>
    </div>
  </form>
</ng-template>

<ng-template #employerSection>
  <form [formGroup]="employerForm" autocomplete="off">
    <po-select (search)="updateInstitutionsList($event)"
               id="qa_requestAccess{{formIdSuffix}}_employer_select"
               class="margin-bottom-xs"
               labelText="Employer"
               placeholderText="Start typing to find employer"
               [isSearch]="true"
               [options]="formattedInstitutionsLookup">
      <select class="po-select__select"
              formControlName="employer"
              inputRef></select>
    </po-select>
    <div class="align-right margin-bottom">
      <a (click)="toggleEmployer(false)"
         class="text-link">My employer is not listed</a>
    </div>
  </form>
</ng-template>

<ng-template #newEmployerSection>
  <form [formGroup]="employerForm" autocomplete="off">
    <div formGroupName="newEmployer">
      <input-field class="margin-bottom-sm" labelText="Employer Name">
        <input formControlName="name"
               id="qa_requestAccess{{formIdSuffix}}_name_text"
               inputRef
               type="text">
      </input-field>
      <input-field class="margin-bottom-sm" labelText="Address Line 1">
        <input formControlName="addressLine1"
               id="qa_requestAccess{{formIdSuffix}}_addressLine1_text"
               inputRef
               type="text">
      </input-field>
      <input-field class="margin-bottom-sm" labelText="Address Line 2">
        <input formControlName="addressLine2"
               id="qa_requestAccess{{formIdSuffix}}_addressLine2_text"
               inputRef
               type="text">
      </input-field>
      <div class="columns columns--2 no-margin columns--gap-small">
        <input-field class="margin-bottom-xs" labelText="City">
          <input formControlName="city"
                 id="qa_requestAccess{{formIdSuffix}}_city_text"
                 inputRef
                 type="text">
        </input-field>
        <input-field class="margin-bottom-xs" labelText="Zip">
          <input formControlName="zip"
                 id="qa_requestAccess{{formIdSuffix}}_zip_text"
                 inputRef
                 type="text">
        </input-field>
      </div>
    </div>
    <div class="align-right margin-bottom">
      <a (click)="toggleEmployer(true)"
         class="text-link">Look up my employer</a>
    </div>
  </form>
</ng-template>
