import * as _ from 'lodash';
import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { EnumErrorTypes } from '@app-models';
import { ProgramAreaReport } from '@app-models';

/**
 * Contacts Action types
 */
const ACTION_PREFIX = 'PROPOSAL_REPORTS_';
export const PROPOSAL_REPORTS_ACTION_TYPES = {
  LOAD_PROGRAM_AREA_REPORTS: `${ACTION_PREFIX}LOAD_PROGRAM_AREA_REPORTS`,
  RESET_PROGRAM_AREA_REPORTS: `${ACTION_PREFIX}RESET_PROGRAM_AREA_REPORTS`,
  CREATE_PROGRAM_AREA_REPORT: `${ACTION_PREFIX}CREATE_PROGRAM_AREA_REPORT`,
  UPSERT_PROGRAM_AREA_REPORT_LINE: `${ACTION_PREFIX}UPSERT_PROGRAM_AREA_REPORT_LINE`,
  UPDATE_PROGRAM_AREA_REPORT_STATUS: `${ACTION_PREFIX}UPDATE_PROGRAM_AREA_REPORT_STATUS`,
  LOAD_PROGRAM_AREA_REPORTS_SUCCESS: `${ACTION_PREFIX}LOAD_PROGRAM_AREA_REPORTS_SUCCESS`,
  UPDATE_PROGRAM_AREA_REPORT_SUCCESS: `${ACTION_PREFIX}UPDATE_PROGRAM_AREA_REPORT_SUCCESS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
};

/**
 * Contacts action class
 */
export class ProposalReportsActions {

  loadProgramAreaReports(proposal_id: number): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REPORTS_ACTION_TYPES.LOAD_PROGRAM_AREA_REPORTS,
      payload: proposal_id
    };
  }

  resetProgramAreaReports(): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REPORTS_ACTION_TYPES.LOAD_PROGRAM_AREA_REPORTS_SUCCESS,
      payload: []
    };
  }

  createProgramAreaReport(report: Model.ProgramAreaReport): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REPORTS_ACTION_TYPES.CREATE_PROGRAM_AREA_REPORT,
      payload: report
    };
  }

  upsertProgramAreaReportLine(report_id: number, line: Model.ProgramAreaReportLine): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REPORTS_ACTION_TYPES.UPSERT_PROGRAM_AREA_REPORT_LINE,
      payload: { report_id, line }
    };
  }

  updateProgramAreaReportStatus(report_id: number, state_id: number): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REPORTS_ACTION_TYPES.UPDATE_PROGRAM_AREA_REPORT_STATUS,
      payload: { report_id, state_id }
    };
  }

  loadProgramAreaReportsSuccess(reports: Array<Model.ProgramAreaReport>): ActionWithPayload<any> {
    const pa_reports = reports.map(report => new ProgramAreaReport(report));
    return {
      type: PROPOSAL_REPORTS_ACTION_TYPES.LOAD_PROGRAM_AREA_REPORTS_SUCCESS,
      payload: pa_reports
    };
  }

  updateProgramAreaReportSuccess(report: Model.ProgramAreaReport): ActionWithPayload<any> {
    return {
      type: PROPOSAL_REPORTS_ACTION_TYPES.UPDATE_PROGRAM_AREA_REPORT_SUCCESS,
      payload: report
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: PROPOSAL_REPORTS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.ProposalReports = new ProposalReportsActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    ProposalReports: ProposalReportsActions;
  }
}
