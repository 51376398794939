import { FUND_TYPES, CHAR_LIMITS } from '@app-consts';
import { Model } from '@app-ngrx-domains';
import { Document } from '@app-models';
import { DOCUMENT_TYPES } from '@app-consts';

/** Form Validator for Conclusion tab
 */
export function validateStepData(p: Model.ProposalItem, files: Array<Model.Document>): boolean {

  const validateIPlan = () => {
    if (!(p.description && p.description.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false;
    }

    if (!(p.rationale_10 && p.rationale_10.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false;
    }

    // just make sure that there is one signature file.
    if (!Document.getLatestFile(files, DOCUMENT_TYPES.SIGNATURE_PAGE.id)) {
      return false;
    }

    return true;
  }

  const validateGP = () => {
    if (!(p.rationale_1 && p.rationale_1.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false;
    }

    return true;
  }

  switch (p.project_type) {
    case FUND_TYPES.IPLAN:
      return validateIPlan();

    case FUND_TYPES.GP:
      return validateGP();

    default:
      return false;
  }
}
