import { FormBuilder, Validators } from '@angular/forms';
import { Validate, ValidatorsEx } from '../utilities';
import { ModelBase } from './model.base';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Model } from '@app-ngrx-domains';
import { FUND_TYPES, FUND_SETTINGS, BUDGET_TYPES, OBJECT_CODES, PROGRAM_KEYS } from '../consts';

export interface IBudgetItem {
  id?: number;
  title?: string;
  description?: string;
  object_code_id?: number;
  proposal_id: number;
  contact_user_id?: number;
  amount?: number;
  institution_id?: number;
  institution?: Model.Institution;
  budget_type?: string;
  duration_id: number;
  fund_id: number;
  quarter_1?: number;
  quarter_2?: number;
  quarter_3?: number;
  quarter_4?: number;
}

export interface IBudgetSummaryEntry {
  title: string;
  class: string;
  isNotCurrency?: boolean;
  isSet: () => boolean; // value has been set
  amount: () => number;
  isInvalid: () => boolean; // show alert
  alertText: () => string;
  helpText: () => string;
}

export type BudgetAmountAttribute = 'direct_amount' | 'monetary_match_amount';

export class Budget extends ModelBase {
  private id?: number;
  private title: string;
  private description: string;
  private object_code_id: number;
  private proposal_id: number;
  private contact_user_id?: number;
  private amount: number;
  private institution_id?: number;
  private institution?: Model.Institution;
  private budget_type?: string;
  private duration_id: number;
  private fund_id: number;
  private quarter_1?: number;
  private quarter_2?: number;
  private quarter_3?: number;
  private quarter_4?: number;

  constructor(raw?: Model.BudgetItem) {
    super();
    if (raw) {
      this.id = raw.id;
      this.title = raw.title;
      this.description = raw.description;
      this.object_code_id = raw.object_code_id;
      this.proposal_id = raw.proposal_id;
      this.contact_user_id = raw.contact_user_id;
      this.amount = raw.amount;
      this.institution_id = raw.institution_id;
      this.institution = raw.institution;
      this.budget_type = raw.budget_type;
      this.duration_id = raw.duration_id;
      this.fund_id = raw.fund_id;
      this.quarter_1 = raw.quarter_1;
      this.quarter_2 = raw.quarter_2;
      this.quarter_3 = raw.quarter_3;
      this.quarter_4 = raw.quarter_4;
    }
  }

  public batchUpdate(fields: Model.BudgetItem) {
    Object.keys(fields).map(key => {
      this[key] = fields[key];
    });
  }

  public serverObject(): IBudgetItem {
    return  {
      id: this.id || undefined,
      proposal_id: this.proposal_id || null,
      title: this.title || null,
      description: this.description || '',
      object_code_id: this.getObjectCodeId(),
      institution_id: this.institution_id || null,
      duration_id: this.duration_id || null,
      fund_id: this.getFundId(),
      amount: this.getAmount(),
      budget_type: this.getBudgetType(),
      contact_user_id: this.contact_user_id || null, // This doesnt seem to be being used
      ...this.getQuarters()
    };
  }


  public generateFormGroup(firstTouch: boolean, readOnly: boolean, formBuilder: FormBuilder) {
    const descriptionValidators = (this.isAEBG()) ? [] : [Validators.required, Validators.minLength(5)];
    const nonZeroValidator = [Validators.required, ValidatorsEx.nonZero];
    const positiveValidator = [Validators.required, ValidatorsEx.positiveIfDefined];
    const requiredSelectValidator = [ValidatorsEx.requiredSelection];
    const percentValidator = [Validators.required, Validators.min(0), Validators.max(100)];
    const cumulativeValidator = (group) => {
      const q1 = Number(group.controls['quarter_1'].value);
      const q2 = Number(group.controls['quarter_2'].value);
      const q3 = Number(group.controls['quarter_3'].value);
      const q4 = Number(group.controls['quarter_4'].value);
      if (q1 > q2 || q2 > q3 || q3 > q4) {
        return { validationError: 'Enter forecast amounts as cumulative percentages.' };
      }
      return null;
    };

    const formGroup = {
      title: [this.title || null, descriptionValidators],
      description: [this.description || null, descriptionValidators],
      amount: [this.getAmount(), (this.isAEBG()) ? positiveValidator : nonZeroValidator],
      object_code_id: [this.getObjectCodeId() || 0, requiredSelectValidator]
    };

    if (this.isIPlan()) {
      formGroup['fund_id'] = [this.fund_id, requiredSelectValidator];
      formGroup['description'] = undefined;
    }

    if (this.isSWP() || this.isAEBG()) {
      const q4value = {value: this.getQuarter(4), disabled: !this.isAEBG()};
      const forecastForm = formBuilder.group({
        'quarter_1': [this.getQuarter(1), percentValidator],
        'quarter_2': [this.getQuarter(2), percentValidator],
        'quarter_3': [this.getQuarter(3), percentValidator],
        'quarter_4': [this.getQuarter(4), percentValidator],
      }, { validator: cumulativeValidator.bind(this) });
      formGroup['forecastForm'] = forecastForm;
    }

    if (this.isSWPRegionalShare()) {
      formGroup['institution_id'] = [this.getInstitutionId(), requiredSelectValidator];
    }

    if (this.isAEBG()) {
      formGroup['budget_type'] = [this.getBudgetType() || '', []];
      formGroup['consortium_expense'] = [this.isConsortiumExpense];
    }

    return formGroup;
  }

  // Getters
  public getAmount() {
    return isNaN(this.amount) ? null : this.amount;
  }

  public getObjectCodeId() {
    return this.object_code_id || null;
  }


  public getBudgetType() {
    return this.budget_type || null;
  }

  public getInstitutionId() {
    if (this.institution_id) {
      return this.institution_id;
    } else {
      return null;
    }
  }

  public getFundId() {
    return this.fund_id || null;
  }

  public getId() {
    return this.id || null;
  }

  public getQuartersTotal() {
    return Object.values(this.getQuarters()).reduce((a, b) => a + b);
  }

  private getQuarters() {
    return {
      quarter_1: this.getQuarter(1),
      quarter_2: this.getQuarter(2),
      quarter_3: this.getQuarter(3),
      quarter_4: this.getQuarter(4)
    };
  }

  public getQuarter(quarter: number) {
    let percent;
    switch (quarter) {
      case 1:
        percent = this.quarter_1;
        break;
      case 2:
        percent = this.quarter_2;
        break;
      case 3:
        percent = this.quarter_3;
        break;
      case 4:
        percent = this.quarter_4;
        break;
      default:
        percent = 0;
    }

    if (isNaN(percent)) {
      percent = null;
    }

    return percent;
  }

  public get isConsortiumExpense(): boolean {
    return this.budget_type && this.budget_type === BUDGET_TYPES.AEBG_CONSORTIUM ? true : false;
  }

  // Setters
  public setInstitutionId(id: number) {
    this.institution_id = id;
  }

  public setInstitution(institution: Model.Institution) {
    this.institution = institution;
  }

  public setFundId(id: number) {
    this.fund_id = id;
  }

  public setProposalId(id: number) {
    this.proposal_id = id;
  }

  public setYearDuration(id: number) {
    this.duration_id = id;
  }

  public set isConsortiumExpense(value: boolean) {
    this.budget_type = value ? BUDGET_TYPES.AEBG_CONSORTIUM : null;
  }

  // Flag helpers
  public isSWP() {
    return this.fund_id === FUND_TYPES.SWP_L || this.fund_id === FUND_TYPES.SWP_R;
  }

  public isSWPLocalShare() {
    return this.fund_id === FUND_TYPES.SWP_L;
  }

  public isSWPRegionalShare() {
    return this.fund_id === FUND_TYPES.SWP_R;
  }

  public isGP() {
    return this.fund_id === FUND_TYPES.GP;
  }

  public isAEBG() {
    return this.fund_id === FUND_TYPES.AEBG;
  }

  public isIPlan() {
    const funds = FUND_SETTINGS[FUND_TYPES.IPLAN].collections;
    return this.fund_id === FUND_TYPES.IPLAN || funds.includes(this.fund_id);
  }

  public isForecastValid() {
    return this.getQuartersTotal() === 100;
  }

  public hasBudgetType() {
    return !!this.getBudgetType();
  }

  public hasFundId() {
    return !!this.getFundId();
  }

  public hasInstitutionId() {
    return !!this.getInstitutionId();
  }

  // Statics
  static costsTypeTotal(budgetItems: Array<any>, totalType: string, attributeName = 'amount'): number {
    let total = 0;
    if (totalType === 'direct_costs') {
      budgetItems.forEach(i => {
        if (i.object_code_id !== OBJECT_CODES.INDIRECT_COSTS) {
          total += i[attributeName] || 0;
        }
      });
    } else if (totalType === 'indirect_costs') {
      budgetItems.forEach(i => {
        if (i.object_code_id === OBJECT_CODES.INDIRECT_COSTS) {
          total += i[attributeName] || 0;
        }
      });
    } else if (totalType === 'reporting') {
      budgetItems.forEach(i => {
        total += i[attributeName] || 0;
      });
    }
    return total;
  }

  static validateIndirectCosts(directCostsTotal: number, indirectCostsTotal: number, percentMax: number): boolean {
    return this.indirectPercentage(indirectCostsTotal, directCostsTotal) > percentMax;
  }

  static indirectPercentage(indirectCostsTotal: number, directCostsTotal: number): number {
    let percentage = indirectCostsTotal / directCostsTotal;
    percentage *= 100;
    if (isNaN(percentage)) {
      return 0;
    }
    if (percentage === Infinity) {
      return 100;
    }
    return percentage;
  }

  static costsBudgetTypeTotal(budgetItems: Array<any>, budgetType: string, attributeName = 'amount'): number {
    let total = 0;
    budgetItems.forEach(i => {
      if (i.budget_type && i.budget_type === budgetType) {
        total += i[attributeName] || 0;
      }
    });
    return total;
  }

  static costsFundIdTotal(budgetItems: Array<any>, fund_id: number, attributeName = 'amount') {
    let total = 0;
    budgetItems.forEach(i => {
      if (i.fund_id === fund_id) {
        total += i[attributeName] || 0;
      }
    })
    return total;
  }

  static costsCodeFundIdTotal(budgetItems: Array<any>, code: number, fund_id: number, attributeName = 'amount') {
    let total = 0;
    budgetItems.forEach(i => {
      if (i.object_code_id === code && i.fund_id === fund_id) {
        total += i[attributeName] || 0;
      }
    })
    return total;
  }

  static costsCodeTotal(budgetItems: Array<any>, code: number, attributeName = 'amount') {
    let total = 0;
    budgetItems.forEach(i => {
      if (i.object_code_id === code) {
        total += i[attributeName] || 0;
      }
    })
    return total;
  }

  static fundIdByBudgetType(budgetType: string) {
    if (BUDGET_TYPES.SE === budgetType) {
      return FUND_TYPES.SE;
    } else if (budgetType.startsWith('sssp')) {
      return FUND_TYPES.SSSP;
    } else {
      return FUND_TYPES.SE;
    }
  }

  /**
   * Calculates the percentage of amount against total.
   *
   * @static
   * @param {number} amount
   * @param {number} total
   * @returns {number}
   */
  static percentOf(amount: number, total: number): number {
    let percentage = amount / total;
    percentage *= 100;
    if (isNaN(percentage)) {
      return 0;
    }
    if (percentage === Infinity) {
      return 100;
    }
    return percentage;
  }

  /**
   * Returns list of fiscal year durations that applies to the project.
   * @param proposal
   * @param lookupDurations
   */
  static getProjectYearDurations(proposal: Model.ProposalBudget, lookupDurations: Array<Model.Duration>): Array<Model.Duration> {
    let durations = [];

    if (proposal.funds[0].key === PROGRAM_KEYS.SWP_K12) {
      const minYear = proposal.duration_id;
      const maxYear = proposal.duration_id + proposal.plan_length - 1;
      durations = lookupDurations.filter(d => d.type === 'annual' && d.year >= minYear && d.year <= maxYear);
    } else {
      // determine the min & max fiscal years from the start & end dates of the project.
      if (proposal.start_date && proposal.end_date) {
          durations = this.getFiscalYearDateRange(proposal.start_date, proposal.end_date, lookupDurations);
      }
    }
    return durations;
  }

  static getFiscalYearDateRange(startDateString: string, endDateString: string, lookupDurations: Array<Model.Duration>) {
    let durations = [];

    const startDate = moment(startDateString);
    const endDate = moment(endDateString);
    const startDateQuarter = lookupDurations.find(d => d.type === 'quarter' && startDate.isBetween(d.start_date, d.end_date, 'day', '[]'));
    const endDateQuarter = lookupDurations.find(d => d.type === 'quarter' && endDate.isBetween(d.start_date, d.end_date, 'day', '[]'));
    if (startDateQuarter && endDateQuarter) {
      const minYear = startDateQuarter.year;
      const maxYear = endDateQuarter.year;
      durations = lookupDurations.filter(d => d.type === 'annual' && d.year >= minYear && d.year <= maxYear);
    }
    return durations;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type BudgetItem = IBudgetItem;
    export type BudgetSummaryEntry = IBudgetSummaryEntry;
  }
}
