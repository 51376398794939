export class ModelBase {

  /**
   * Returns model as an object value, consisting only of interface definitions.
   *
   * @template T - interface type
   * @returns {T} value
   */
  iObject<T>(model?): T {
    return <T>JSON.parse(JSON.stringify(model ? model : this));
  };
}
