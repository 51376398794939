<div class="action-list-item">
  <div class="action-list-item__primary-actions">
    <div *ngIf="showIndicator" class="action-list-item__status-indicator" [ngClass]="{'action-list-item__status-indicator--viewed': itemViewed}"></div>
    <ng-content></ng-content>
  </div>

  <div class="action-list-item__content">
    <h4 class="action-list-item__title" id="qa_action_list_item_title">
      <icon [iconId]="isEmail ? 'envelope' : 'bell'" [title]="isEmail ? 'Email' : 'Notification'"></icon>
      <ng-container [ngSwitch]="clickable">
        <a *ngSwitchCase="true" (click)="toggleShowFullMessage()" class="text-link">{{itemTitle}}</a>
        <ng-container *ngSwitchCase="false">{{itemTitle}}</ng-container>
      </ng-container>
    </h4>

    <p class="action-list-item__body-copy"
      [ngClass]="{'action-list-item__body-copy--truncate': truncateBody}">
      {{itemBody_unformatted}}
    </p>
  </div>

  <div class="action-list-item__secondary-actions">
    <p *ngIf="secondaryText" class="action-list-item__secondary-action-text">
      {{secondaryText}}
    </p>

    <div [ngClass]="{'inline-button-group__container': editButtonText && deleteButtonText}">
      <button *ngIf="editButtonText" class="action-button action-button--tertiary inline-button icon--only" [title]="editButtonText"
              (click)="editClicked()">
        <icon iconId="edit"></icon>
      </button>

      <button *ngIf="deleteButtonText" class="action-button action-button--tertiary inline-button icon--only" [title]="deleteButtonText"
              (click)="deleteClicked()">
        <icon iconId="delete"></icon>
      </button>
    </div>
  </div>
</div>

<full-page-modal *ngIf="showFullMessage"
  small="true"
  [heading]="itemTitle"
  button-action="Close"
  (action)="toggleShowFullMessage()"
  (dismiss)="toggleShowFullMessage()">
    <div [innerHtml]="itemBody"></div>
</full-page-modal>