
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from 'ng-logger';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { ApiService } from './api.service';
import { ProgramService } from './program.service';
import { EnumErrorTypes, Fund, PermissionResource } from '../models';
import { ACTIONS, AREAS, WILDCARD } from '../consts';
import { forOwn, get } from 'lodash';

@Injectable()
export class PermissionsService {

  constructor(
    private logger: LoggerService,
    private store: Store<State>,
    private apiService: ApiService,
    private programService: ProgramService,
  ) { }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // can* permissions service
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Returns logged in user's permissions on requested resources.
   * @param {Array<Model.PermResourceItem>} resources
   * @returns {Observable<Array<Model.PermResourceResponseItem>>}
   */
  canResources(resources: Array<Model.PermResourceItem>): Observable<Array<Model.PermResourceResponseItem>> {
    resources = resources.map(resource => PermissionResource.completeObject(resource));
    return new Observable((subscriber) => {
      // get the permissions from the service.
      this.apiService.can(resources).subscribe((res: Array<Model.PermResourceResponseItem>) => {
        const cans = [];
        res.forEach(item => {
          this.logger.debug(`[permService][canResources] response=${JSON.stringify(item)}`);
          cans.push({...item});
        });
        subscriber.next(cans);
        subscriber.complete();
      }, err => {
        this.store.dispatch(Actions.App.setError({
          location: this.constructor.name,
          type: EnumErrorTypes.api,
          raw: err,
          show: true,
          message: `There was an error obtaining permissions.`
        }));
        subscriber.next([]); // Return an empty array since responses are expecting an array
        subscriber.complete();
      });
    });
  }

  /**
   * Returns true if user has permission to go to activated route; if not, and if requested,
   * it will return the rerouting url.
   * @param {{ area: string, action: string, reroutePath: string }} routeCheck
   * @param {ActivatedRouteSnapshot} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<{ allowed: boolean, rerouteUrl?: string }>}
   */
  canGoTo(routeCheck: { area: string, action: string, reroutePath: string, useChildFunds: boolean },
    route: ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<{ allowed: boolean, rerouteUrl?: string }> {

    const toCheck = this.buildResourcesFromRoute([{
      area: routeCheck.area,
      action: routeCheck.action,
    }], route, extraScope, routeCheck.useChildFunds);

    return this.canResources(toCheck.resources).pipe(
      map(cans => {
        // allowed will be OR'd, which means just one has to be true.
        const result = {
          allowed: cans.find(item => { return item.allowed === true; }) ? true : false,
          rerouteUrl: undefined,
        };
        if (!result.allowed && routeCheck.reroutePath) {
          // build reroute url.
          let rerouteUrl = routeCheck.reroutePath;
          if (toCheck.routeParams['proposalId']) {
            rerouteUrl = rerouteUrl.replace(':proposalId', toCheck.routeParams['proposalId'].toString());
          }
          if (toCheck.routeParams['institutionId']) {
            rerouteUrl = rerouteUrl.replace(':institutionId', toCheck.routeParams['institutionId'].toString());
          }
          if (toCheck.routeParams['yearDurationId']) {
            rerouteUrl = rerouteUrl.replace(':yearDurationId', toCheck.routeParams['yearDurationId'].toString());
          }
          if (toCheck.routeParams['userId']) {
            rerouteUrl = rerouteUrl.replace(':userId', toCheck.routeParams['userId'].toString());
          }
          if (toCheck.routeParams['programKey']) {
            rerouteUrl = rerouteUrl.replace(':programKey', toCheck.routeParams['programKey'].toString());
          }
          result.rerouteUrl = rerouteUrl;
        }
        this.logger.debug(`[permService][canGoTo][${toCheck.path}] area=${routeCheck.area} action=${routeCheck.action} allowed=${result.allowed} rerouteUrl=${result.rerouteUrl}`);

        return result;
       }));
  }

  /**
   * Returns permissions on requested area & actions.
   * @param {string} area
   * @param {Array<string>} actions
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<Array<Model.PermResourceResponseItem>>}
   */
  canCan(area: string, actions: Array<string>,
    route: string | ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<Array<Model.PermResourceResponseItem>> {

    const checks: Array<Model.PermResourceItem> = [];
    actions.forEach(action => {
      checks.push({
        area: area,
        action: action,
      });
    });

    const toCheck = this.buildResourcesFromRoute(checks, route, extraScope);

    return this.canResources(toCheck.resources).pipe(
      map(cans => {
        cans.forEach(can => {
          this.logger.debug(`[permService][canCan][${toCheck.path}] area=${area} action=${can.action} allowed=${can.allowed}`);
        });

        return cans;
      }));
  }

  /**
   * Returns true if user can perform an action in the area.
   * @param {string} area
   * @param {string} action
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<boolean>}
   */
  canAction(area: string, action: string,
    route: string | ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<boolean> {

    const toCheck = this.buildResourcesFromRoute([{
      area: area,
      action: action,
    }], route, extraScope);

    return this.canResources(toCheck.resources).pipe(
      map(cans => {
        // allowed will be OR'd, which means just one has to be true.
        const allowed = cans.find(item => { return item.allowed === true; }) ? true : false;
        this.logger.debug(`[permService][canAction][${toCheck.path}] area=${area} action=${action} allowed=${allowed}`);

        return allowed;
      }));
  }

  /**
   * Returns true if user has create access to the area.
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<boolean>}
   */
  canCreate(area: string,
    route: string | ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canAction(area, ACTIONS.CREATE, route, extraScope);
  }

  /**
   * Returns true if user has edit access to the area.
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<boolean>}
   */
  canEdit(area: string,
    route: string | ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canAction(area, ACTIONS.EDIT, route, extraScope);
  }

  /**
   * Returns true if user has submit access to the area.
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<boolean>}
   */
  canSubmit(area: string,
    route: string | ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canAction(area, ACTIONS.SUBMIT, route, extraScope);
  }

  /**
   * Returns true if user has approval/certify access to the area.
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<boolean>}
   */
  canCertify(area: string,
    route: string | ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canAction(area, ACTIONS.CERTIFY, route, extraScope);
  }

  /**
   * Returns true if user can sudo login.
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<boolean>}
   */
  canSudo(area: string,
    route: string | ActivatedRouteSnapshot, extraScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canAction(area, ACTIONS.SUDO, route, extraScope);
  }

/**
   * Returns permissions on requested area & actions per project.
   * @param {Model.ProposalBase} project
   * @param {string} area
   * @param {Array<string>} actions
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {Observable<Array<Model.PermResourceResponseItem>>}
   */
  canCanProject(project: Model.ProposalBase, area: string, actions: Array<string>,
    route: string | ActivatedRouteSnapshot, overrideScope?: Model.PermResourceScope): Observable<Array<Model.PermResourceResponseItem>> {
    const extraScope = this.getProjectScope(project, overrideScope);
    return this.canCan(area, actions, route, extraScope);
  }

  /**
   * Returns true if user can perform an action in given project.
   * @param {Model.ProposalBase} project
   * @param {string} area
   * @param {string} action
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [overrideScope]
   * @returns {Observable<boolean>}
   */
  canActionProject(project: Model.ProposalBase, area: string, action: string,
    route: string | ActivatedRouteSnapshot, overrideScope?: Model.PermResourceScope): Observable<boolean> {
    const extraScope = this.getProjectScope(project, overrideScope);
    return this.canAction(area, action, route, extraScope);
  }

  /**
   * Returns true if user can edit the project.
   * @param {Model.ProposalBase} project
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [overrideScope]
   * @returns {Observable<boolean>}
   */
  canEditProject(project: Model.ProposalBase, area: string,
    route: string | ActivatedRouteSnapshot, overrideScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canActionProject(project, area, ACTIONS.EDIT, route, overrideScope);
  }

  /**
   * Returns true if user can submit the project.
   * @param {Model.ProposalBase} project
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [overrideScope]
   * @returns {Observable<boolean>}
   */
  canSubmitProject(project: Model.ProposalBase, area: string,
    route: string | ActivatedRouteSnapshot, overrideScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canActionProject(project, area, ACTIONS.SUBMIT, route, overrideScope);
  }

  /**
   * Returns true if user can certify the project.
   * @param {Model.ProposalBase} project
   * @param {string} area
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [overrideScope]
   * @returns {Observable<boolean>}
   */
  canCertifyProject(project: Model.ProposalBase, area: string,
    route: string | ActivatedRouteSnapshot, overrideScope?: Model.PermResourceScope): Observable<boolean> {
    return this.canActionProject(project, area, ACTIONS.CERTIFY, route, overrideScope);
  }

  canCertifyHeadcount(route: string | ActivatedRouteSnapshot, overrideScope?: Model.PermResourceScope){
    return this.canAction(AREAS.HEADCOUNT_CERTIFICATION, ACTIONS.APPROVE, route, overrideScope);
  }

  /**
   * Builds out resources from the route provided.
   * @param {Array<Model.PermResourceItem>} checks
   * @param {(string | ActivatedRouteSnapshot)} route
   * @param {(Model.PermResourceScope)} [extraScope]
   * @returns {resources: Array<Model.ResourceItem>, routeConfig: string, path: string }
   */
  buildResourcesFromRoute(
    checks: Array<Model.PermResourceItem>,
    route: string | ActivatedRouteSnapshot,
    extraScope?: Model.PermResourceScope,
    useChildFunds?: boolean,
  ): { resources: Array<Model.PermResourceItem>, routeConfig: string, path: string, routeParams: { [name: string]: number } } {

    // get route config to determine the scoping info from the route.
    const routeParams: { [name: string]: number } = {};
    const routeConfig = (route instanceof ActivatedRouteSnapshot) ? this.getRouteConfig(route, routeParams) : route;
    // this.logger.debug(`[permService][buildResourcesFromRoute] routeConfig=${routeConfig}, routeParams=${JSON.stringify(routeParams)}`);

    // now build back the path from the config & params.
    let path = routeConfig;
    if (routeParams['proposalId']) {
      path = path.replace(':proposalId', routeParams['proposalId'].toString());
    }
    if (routeParams['institutionId']) {
      path = path.replace(':institutionId', routeParams['institutionId'].toString());
    }
    if (routeParams['yearDurationId']) {
      path = path.replace(':yearDurationId', routeParams['yearDurationId'].toString());
    }
    if (routeParams['userId']) {
      path = path.replace(':userId', routeParams['userId'].toString());
    }
    if (routeParams['programKey']) {
      path = path.replace(':programKey', routeParams['programKey'].toString());
    }

    // get scopes from the route config.
    const scopes = this.getScopesFromRouteConfig(routeConfig, routeParams, extraScope, useChildFunds);
    scopes.forEach(scope => {
      this.logger.debug(`[permService][buildResourcesFromRoute][${routeConfig}] scope=fund_id:${scope.fund_id || '-'}, proposal_id:${scope.proposal_id || '-'}, institution_id:${scope.institution_id || '-'}`);
    });

    // build out resource requests
    const resources: Array<Model.PermResourceItem> = [];
    checks.forEach(check => {
      scopes.forEach(scope => {
        resources.push({
          ...check,
          ...scope,
        });
      });
    });

    return {
      resources: resources,
      routeConfig: routeConfig,
      path: path,
      routeParams: routeParams,
    };
  }

  /**
   * Returns if inquired events have expired.
   * @param fundId
   * @param eventTypeIds
   * @param [fund]
   */
  isEventsExpired(fundId: number, eventTypeIds: number[], fund?: Model.Fund): Observable<{ [area: string]: boolean }> {
    return new Observable((subscriber) => {
      if (fund) {
        // first check to see if application window has closed.
        const durationId = this.programService.getProgramDurationId(fund);
        if (Fund.applicationWindowIsClosed(fund, durationId)) {
          this.logger.debug(`[permService][isEventsExpired] ${fundId} Application Window Closed`);
          subscriber.next({ application: true });
          subscriber.complete();
          return;
        }
      }

      // get the event expiracy from the service.
      this.apiService.getEventExpired(fundId, eventTypeIds).subscribe((data: any) => {
        this.logger.debug(`[permService][isEventsExpired] response=${JSON.stringify(data)}`);
        subscriber.next(data);
        subscriber.complete();
      }, err => {
        this.store.dispatch(Actions.App.setError({
          location: this.constructor.name,
          type: EnumErrorTypes.api,
          raw: err,
          show: true,
          message: `There was an error obtaining expired events.`
        }));
        subscriber.next({ application: true, letterOfIntent: true }); // fail-safe?
        subscriber.complete();
      });
    });
  }

  /**
   * Returns fully formatted route config as well as route params.
   * @private
   * @param {ActivatedRouteSnapshot} route
   * @param {{ [name: string]: number }} params - route params found
   * @returns {string}
   */
  private getRouteConfig(route: ActivatedRouteSnapshot, params: { [name: string]: number }): string {
    if (route.parent && route.parent.routeConfig) {
      const parentRouteConfig = this.getRouteConfig(route.parent, params);
      forOwn(route.params, (value, name) => {
        params[name] = Number(value) || value.toString();
      });
      return route.routeConfig.path ? `${parentRouteConfig}/${route.routeConfig.path}` : `${parentRouteConfig}`;
    } else {
      forOwn(route.params, (value, name) => {
        params[name] = Number(value) || value.toString();
      });
      return route.routeConfig && route.routeConfig.path ? `/${route.routeConfig.path}` : '';
    }
  }

  /**
   * Builds out scope information from the route config & route params.
   * @private
   * @param {string} routeConfig
   * @param { [name: string]: number } routeParams
   * @param {(Model.PermResourceScope)} [extraScope]
   * @param {boolean} useChildFunds
   * @returns {Array<Model.PermResourceScope>}
   */
  private getScopesFromRouteConfig(
    routeConfig: string,
    routeParams: { [name: string]: number },
    extraScope?: Model.PermResourceScope,
    useChildFunds?: boolean,
  ): Array<Model.PermResourceScope> {

    let fundIds = [];
    // has explicit fund id in route?
    if (routeConfig.match(':fundId')) {
      fundIds.push(routeParams['fundId']);
    } else {
      // is route fund specific?
      if (routeParams['programKey']) {
        routeConfig = routeConfig.replace(':programKey', routeParams['programKey'].toString());
      }
      if (useChildFunds) {
        fundIds = this.programService.getProgramIdsFromRoute(routeConfig);
      } else {
        const parentProgramId = (this.programService.getParentProgramFromRoute(routeConfig) || {}).id;
        fundIds = parentProgramId ? [parentProgramId] : [];
      }
    }

    // has proposal id?
    let proposalId;
    if (routeConfig.match(':proposalId')) {
      proposalId = routeParams['proposalId'];
    }

    // has institution id?
    let institutionId;
    if (routeConfig.match(':institutionId')) {
      institutionId = routeParams['institutionId'];
    }

    let userId;
    if (routeConfig.match(':userId')) {
      userId = routeParams['userId'];
    }

    // if extra scope's been passed in, then apply them as spread operator.
    if (extraScope) {
      if (extraScope.fund_id) {
        this.logger.debug(`[permService][getScopesFromRouteConfig][${routeConfig}] replacing fundsIds: ${fundIds} to ${extraScope.fund_id}`);
        fundIds = [extraScope.fund_id];
      }
      if (extraScope.proposal_id) {
        this.logger.debug(`[permService][getScopesFromRouteConfig][${routeConfig}] replacing proposalId: ${proposalId} to ${extraScope.proposal_id}`);
        proposalId = extraScope.proposal_id;
      }
      if (extraScope.institution_id) {
        this.logger.debug(`[permService][getScopesFromRouteConfig][${routeConfig}] replacing institutionId: ${institutionId} to ${extraScope.institution_id}`);
        institutionId = extraScope.institution_id;
      }
      if (extraScope.user_id) {
        this.logger.debug(`[permService][getScopesFromRouteConfig][${routeConfig}] replacing userId: ${userId} to ${extraScope.user_id}`);
        userId = extraScope.user_id;
      }
    }

    const base_scope = {
      proposal_id: proposalId,
      institution_id: institutionId,
      user_id: userId
    };

    // build out one or more scopes.
    const scopes = [];
    if (fundIds.length === 0) {
      // not associated with a fund
      scopes.push(base_scope);
    } else {
      // for each fund id, create a scope.
      fundIds.forEach(id => {
        scopes.push({
          ...base_scope,
          fund_id: id
        });
      });
    }

    return scopes;
  }

  /**
   * Builds out extra scoping information from the project itself.
   * @param project
   * @param overrideScope
   */
  private getProjectScope(project: Model.ProposalBase, overrideScope: Model.PermResourceScope): Model.PermResourceScope {
    return {
      fund_id: get(project, 'fund_ids[0]'),
      institution_id: WILDCARD,
      ...overrideScope
    };
  }
}
