import { ModelBase } from './model.base';

export interface IStrategy {
  id: number;
  proposal_id: number;
  description: string;
}

export class Strategy extends ModelBase implements IStrategy {
  public id: number;
  public proposal_id: number;
  public description: string;

  constructor(raw) {
    super();

    if (raw) {
      this.id = raw.id;
      this.proposal_id = raw.proposal_id;
      this.description = raw.description;
    }
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Strategy = IStrategy;
  }
}
