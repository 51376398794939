import { State } from '@app-ngrx-domains';
import { WORKFLOW_TYPES } from '../consts';

/**
 * States managed by reducer; used also as initial states
 */
State.Workflow = {
  showErrorStepper: false,
  current: WORKFLOW_TYPES.CURRENT,
  workflows: {},
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IWorkflowState {
    showErrorStepper: boolean;
    current?: string;
    workflows: {
      [kind: string]: Model.Workflow,
    }
  }

  interface State {
    Workflow: IWorkflowState;
  }
}
