import { Model } from '@app-ngrx-domains';
import { PROGRAM_KEYS, OBJECT_CODES, TASK_TYPES } from '../consts';
import { ALLOCATION_TYPES, Budget, ProposalBase, Utilities } from '../models';
import { AppUtils } from '../utilities';

/***
 * Reduced / common set of budget related interface definitions.
 */
export interface IProposalBudget extends Model.ProposalBase {
  application_region?: number; // SWPK12

  allocations?: Array<Model.Allocation & { indirect_funds: number, direct_funds: number }>;
  application_contributions?: Array<Model.EAApplicationContribution>;

  workplan_activities?: Array<Model.EAWorkplanActivity>; // CAI, EWD

  statement_of_works?: Array<Model.EAStatementOfWork>;   // SWPK12

  plan_budget_items?: Array<Model.EAPlanBudgetItem>;
  plan_expenditure_forecasts?: Array<Model.EAPlanExpenditureForecast>;

  object_code_budgets?: Array<Model.EAObjectCodeBudgets>; // SWPK12 - v2
  institution_match_funds?: Array<Model.EAInstitutionMatchFunds>;

  match_reports?: Array<Model.EAInstitutionMatchReports>;

  budget_items?: Array<Model.BudgetItem>; // SWP L/R
}

export class ProposalBudget {

  /**
   * Returns list of activities as selectable options.
   * @param p
   */
  static getActivityOptions(p: Model.ProposalBudget): Array<Model.SelectOption> {
    // pick the right kind for the effort area.
    const activities: Array<Model.EffortArea> = p.statement_of_works ? p.statement_of_works : p.workplan_activities;
    return activities.filter(act => !!act.title).map(act => ({ value: act.id, label: act.title }));
  }

  /**
   * Returns total contributions.
   * @param p
   */
  static getMatchTotals(p: Model.ProposalBudget): { financial_contribution: number, in_kind_match: number } {
    let financial_contribution = 0;
    let in_kind_match = 0;

    if (p.funds[0].key === PROGRAM_KEYS.SWP_K12_v1 || p.funds[0].parent_key === PROGRAM_KEYS.CAI) {
      p.application_contributions.forEach(contribution => {
        financial_contribution += Number(contribution['financial_contribution']) || 0;
        in_kind_match += Number(contribution['in_kind_match']) || 0;
      });
    } else {
      p.institution_match_funds.forEach(instMatch => {
        financial_contribution += instMatch.financial_match_funds.reduce((acc, match) => acc += match.amount || 0, 0);
        in_kind_match += instMatch.inkind_match_funds.reduce((acc, match) => acc += match.amount || 0, 0);
      });
    }

    return { financial_contribution, in_kind_match };
  }

  /**
   * Returns institution's planned total contributions.
   * @param p
   * @param institution_id
   */
  static getInstitutionPlannedMatchTotals(p: Model.ProposalBudget, institution_id: number): { financial_contribution: number, in_kind_match: number } {
    let financial_contribution = 0;
    let in_kind_match = 0;

    if (p.funds[0].key === PROGRAM_KEYS.SWP_K12_v1 || p.funds[0].parent_key === PROGRAM_KEYS.CAI) {
      p.application_contributions
        .filter(c => c.institution_id === institution_id)
        .forEach(contribution => {
          financial_contribution += Number(contribution['financial_contribution']) || 0;
          in_kind_match += Number(contribution['in_kind_match']) || 0;
        });
    } else {
      p.institution_match_funds
        .filter(match => match.institution_id === institution_id)
        .forEach(instMatch => {
          financial_contribution += instMatch.financial_match_funds.reduce((acc, match) => acc += match.amount || 0, 0);
          in_kind_match += instMatch.inkind_match_funds.reduce((acc, match) => acc += match.amount || 0, 0);
        });
    }

    return { financial_contribution, in_kind_match };
  }

  /**
   * Returns institution's reported total contributions.
   * @param p
   * @param institution_id
   */
  static getInstitutionReportedMatchTotals(p: Model.ProposalBudget, institution_id: number): { financial_contribution: number, in_kind_match: number } {
    let financial_contribution = 0;
    let in_kind_match = 0;

    const submittedDurationIds = ProposalBase.getSubmittedDurationIds(p, TASK_TYPES.FISCAL_REPORT_SUBMIT, institution_id);
    if (submittedDurationIds.length) {
      p.match_reports
        .filter(match => match.institution_id === institution_id && submittedDurationIds.includes(match.duration_id))
        .forEach(instMatch => {
          financial_contribution += instMatch.financial_match_funds.reduce((acc, match) => acc += match.amount || 0, 0);
          in_kind_match += instMatch.inkind_match_funds.reduce((acc, match) => acc += match.amount || 0, 0);
        });
    }

    return { financial_contribution, in_kind_match };
  }

  /**
   * Returns expected grant match based on the match ratio of the lead institution.
   * @param proposalBudget
   * @param programSettings
   */
  static getExpectedGrantMatch(proposalBudget: Model.ProposalBudget, programSettings: Model.EAProgramSettings): number {
    const matchMultiplier = ProposalBudget.getMatchRequirementForInstitution(programSettings, proposalBudget.lead_institution);

    // For applications, compute the expected grant match
    if (proposalBudget.type === 'application') {
      const amounts = ProposalBudget.getBudgetedAmounts(proposalBudget);
      const totalBudget = amounts.direct_costs + amounts.indirect_costs;
      return Math.round((totalBudget / matchMultiplier) * 100);
    } else { // Else, return the actual allocated grant
      const funded = ProposalBudget.getAllocationsAmount(proposalBudget.allocations);
      return Math.round((funded / matchMultiplier) * 100);
    };
  }

  /**
   * Returns sum of allocation amounts
   * @param allocations
   */
  static getAllocationsAmount(allocations: Array<Model.Allocation>): number {
    let total = 0;
    (allocations || []).forEach(alloc => {
      const amount = alloc.amount || 0;
      if (alloc.type === ALLOCATION_TYPES.deferment) {
        total -= amount;
      } else {
        total += amount;
      }
    });
    return total;
  }

  /**
   * Returns deferred allocation amounts
   * @param allocations
   */
  static getDeferredAllocationsTotal(allocations: Array<Model.Allocation>): number {
    let total = 0;
    (allocations || []).filter(alloc => alloc.type === ALLOCATION_TYPES.deferment).forEach(alloc => {
      total += alloc.amount || 0;
    });
    return total;
  }

  /**
   * For CAI applications, returns the ratio of
   * (lead/partner match contributions) to (grantFundsBudgeted), as an integer percentage
   * TODO: We probably should move this someplace CAI-specific? The notion of "match ratio" is fund-specific.
   * @param proposalBudget
   * @param programSettings
   * @param includeInKindForMatch Include in-kind matches in the numerator;
   *   If true, base grant match on monetary + in-kind contributions.
   */
  static getCurrentMatchRatio(proposalBudget: Model.ProposalBudget, programSettings: Model.EAProgramSettings, includeInKindForMatch = false): number {
    const match = ProposalBudget.getMatchTotals(proposalBudget);
    const budget = ProposalBudget.getBudgetedAmounts(proposalBudget);
    const numerator = match.financial_contribution + (includeInKindForMatch ? match.in_kind_match : 0);
    const denominator = budget.direct_costs + budget.indirect_costs;
    return denominator ? (numerator / denominator) * 100 : 0;
  }

  /**
   * Looks up the budget match requirement for a given program and institution type,
   * or returns 0 if either argument is empty or undefined.
   * @param {Model.Fund} program
   * @param {Model.Ins} inst
   * @returns {number} percentage as floating-point, between 0 and 1
   */
  static getMatchRequirementForInstitution(programSettings: Model.EAProgramSettings, inst: Model.Institution): number {
    const instType = inst ? inst.type : undefined;
    if (!instType) { // If we don't have a valid institution type, return no match requirement
      return 0;
    }
    const budgetMatchRequirements = programSettings ? programSettings.budget_match_requirements : [];
    if (!budgetMatchRequirements) {
      return 0;
    }
    let matchRequirement = budgetMatchRequirements.find(item => item.institution_type === instType && !AppUtils.isNil(item.match_percent));
    if (!matchRequirement) {
      matchRequirement = budgetMatchRequirements.find(item => !item.institution_type);
    }

    return matchRequirement && matchRequirement.match_percent ? matchRequirement.match_percent : 0;
  }

  /**
   * Sums up the total budget Grant Funds amount and Monetary Match Funds amount.
   * Grant funds are grouped into 1) direct_costs and 2) indirect_costs
   * Returns budgeted amount as direct & indirect costs.
   * @param budgetItems
   * @returns {Object}
   */
  static getPlanBudgetedAmounts(budgetItems: Array<Model.EAPlanBudgetItem>): { monetary_match: number, direct_costs: number, indirect_costs: number } {
    let monetary_match = 0;
    let direct_costs = 0;
    let indirect_costs = 0;

    (budgetItems || []).forEach(budgetItem => {
      monetary_match += Number(budgetItem.monetary_match_amount) || 0;
      if (budgetItem.object_code_id === OBJECT_CODES.INDIRECT_COSTS) {
        indirect_costs += Number(budgetItem.grant_funds_amount) || 0;
      } else {
        direct_costs += Number(budgetItem.grant_funds_amount) || 0;
      }
    });

    return { monetary_match, direct_costs, indirect_costs };
  }

  static getBudgetedAmounts(p: Model.ProposalBudget): { monetary_match: number, direct_costs: number, indirect_costs: number } {
    if ([PROGRAM_KEYS.SWP_K12_v2, PROGRAM_KEYS.SWP_K12_v4].includes(p.funds[0].key)) {
      const direct_costs = Budget.costsTypeTotal(p.object_code_budgets, 'direct_costs');
      const indirect_costs = Budget.costsTypeTotal(p.object_code_budgets, 'indirect_costs');
      const matches = ProposalBudget.getMatchTotals(p);
      const monetary_match = matches.financial_contribution + matches.in_kind_match;
      return { monetary_match, direct_costs, indirect_costs };
    } else {
      return ProposalBudget.getPlanBudgetedAmounts(p.plan_budget_items)
    }
  }

  /**
   * Returns the amount requested by the given application.
   * @param proposalBudget
   * @param institution_id filter by institution id
   * @returns {number}
   */
  static getBudgetTotal(proposalBudget: Model.ProposalBudget, institution_id?: number): number {
    let amount = 0;

    if (!proposalBudget || !proposalBudget.id) {
      // null proposal
      return amount;
    }

    // get relevant budget items
    const fund = proposalBudget.funds[0];
    let planBudgetItems = [];
    let amountAttribName = 'amount';

    if (fund.is_small_program) {
      planBudgetItems = proposalBudget.plan_budget_items;
      amountAttribName = 'grant_funds_amount';
    } else {
      switch (fund.parent_key) {
        case PROGRAM_KEYS.SWP_K12:
          if (fund.key === PROGRAM_KEYS.SWP_K12_v1) {
            planBudgetItems = proposalBudget.plan_budget_items;
            amountAttribName = 'grant_funds_amount';
          } else {
            planBudgetItems = proposalBudget.object_code_budgets;
          }
          break;

        case PROGRAM_KEYS.RCM:
          planBudgetItems = proposalBudget.plan_budget_items;
          amountAttribName = 'direct_amount';
          break;

        case PROGRAM_KEYS.CAI:
        case PROGRAM_KEYS.EWD:
        case PROGRAM_KEYS.PERKINS:
          planBudgetItems = proposalBudget.plan_budget_items;
          amountAttribName = 'grant_funds_amount';
          break;
      }
    }

    if (planBudgetItems && planBudgetItems.length) {
      // filter by institution if needed
      const items = !institution_id ? planBudgetItems : planBudgetItems.filter(item => item.institution_id === institution_id);
      amount = Budget.costsTypeTotal(items, 'reporting', amountAttribName);
    }

    return amount;
  }

  /**
   * Returns ada total
   * @param proposalBudget
   * @param budgetItems legacy budget items used by SWPK-v1
   * @param institution_id filter by institution id
   * @returns {number}
   */
  static getAdaTotal(p: Model.ProposalBudget): number {
    let adaTotal: number = null;
    p.institutions
      .filter((inst: Model.Institution) => !inst.is_inactive)
      .forEach((inst: Model.Institution) => {
        let inst_ada_number: number = null;
        if (p.funds[0].key === PROGRAM_KEYS.SWP_K12_v1) {
          const effort_area = p.application_contributions ? p.application_contributions.find(ac => ac.institution_id === inst.id) : undefined;
          if (effort_area && effort_area.ada_number && !Utilities.isNil(effort_area.ada_number)) {
            inst_ada_number = parseFloat(effort_area.ada_number);
          } else if (inst.ada_number && !Utilities.isNil(inst.ada_number)) {
            inst_ada_number = inst.ada_number;
          }
        } else {
          const institution_setting = (inst.institution_settings || []).find(setting => setting.duration_id === p.duration_id);
          if (institution_setting && !Utilities.isNil(institution_setting.average_daily_attendance)) {
            inst_ada_number = parseFloat(institution_setting.average_daily_attendance);
          }
        }
        if (!Utilities.isNil(inst_ada_number)) {
          adaTotal = Utilities.isNil(adaTotal) ? inst_ada_number : adaTotal + inst_ada_number;
        }
      });
    return adaTotal;
  }

}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ProposalBudget = IProposalBudget;
 }
}
