import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State, Actions as DomainActions } from '@app-ngrx-domains'
import { toPayload, NewTempId } from '@app-libs';
import { ApiService } from '@app-services';
import { STRATEGIES_ACTION_TYPES } from './strategies.action';

/**
 * Injectable effects class.
 *
 */
@Injectable()
export class StrategiesEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  /**
   * Updates proposal strategy.
   */
  @Effect() updateStrategy$ = this.actions$.pipe(
    ofType(STRATEGIES_ACTION_TYPES.UPDATE_STRATEGY),
    map(toPayload),
    mergeMap(item => {
      if (NewTempId.isTemp(item.id)) {
        // create new strategy.
        const newItem = {
          ...item,
        };
        delete newItem.id; // remove temp id.
        return this.apiService.createProposalStrategy(newItem).pipe(
          map((response) => DomainActions.Strategies.updateStrategySuccess(item, response)),
          catchError((error) => of(DomainActions.Strategies.serviceFail(error)))
        );
      } else {
        // update existing strategy
        return this.apiService.updateProposalStrategy(item).pipe(
          map((response) => DomainActions.Strategies.updateStrategySuccess(item, response)),
          catchError((error) => of(DomainActions.Strategies.serviceFail(error)))
        );
      }
    })
  );

  /**
   * Deletes proposal strategy.
   */
  @Effect() deleteStrategy$ = this.actions$.pipe(
    ofType(STRATEGIES_ACTION_TYPES.DELETE_STRATEGY),
    map(toPayload),
    mergeMap(item => {
      // persist changes.
      return this.apiService.deleteProposalStrategy(item).pipe(
        map((response) => DomainActions.Strategies.deleteStrategySuccess(item)),
        catchError((error) => of(DomainActions.Strategies.serviceFail(error))));
    })
  );
}
