import { ModelBase } from './model.base';
import { Model } from '@app-ngrx-domains';

export interface IMessageTemplate {
  id?: number;
  body: string;
  text?: string;
  subject?: string;
  event_id?: number;
  event?: Model.Event;
  date_offset?: number;
  offset_type?: string;
  recipient_filter?: any;
  repeats?: boolean;
  state_id?: any;
  files?: Array<Model.Document>;
}

export interface IMessageAttachment {
  id: number;
  message_template_id?: number;
  title: string;
}

export class MessageTemplate extends ModelBase implements IMessageTemplate {
  public id: number;
  public body: string;
  public text: string;
  public subject: string;
  public event_id: number;
  public event: Model.Event;
  public date_offset: number;
  public offset_type: string;
  public recipient_filter: any;
  public repeats: boolean;
  public state_id: any;
  public files: Array<Model.Document>;

  constructor(raw?: any) {
    super();

    if (raw) {
      this.id = raw.id;
      this.body = raw.body;
      this.text = raw.text;
      this.subject = raw.subject;
      this.event_id = raw.event_id;
      this.event = raw.event;
      this.date_offset = raw.date_offset;
      this.offset_type = raw.offset_type;
      this.recipient_filter = JSON.parse(raw.recipient_filter);
      this.repeats = raw.repeats;
      this.state_id = raw.state_id;
      this.files = raw.files;
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type MessageTemplate = IMessageTemplate;
    export type MessageAttachment = IMessageAttachment;
  }
}
