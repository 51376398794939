<h1 class="h2 title title--login">
  {{headingText}}
</h1>

<div *ngIf="waitingForInput; else loggingIn" class="card card--login">
  <div class="card__header">
    <div class="card-title">
      <h2 class="h3">
        Log In
      </h2>
    </div>
  </div>
  <div class="card__body">
    <div class="field-group"
      [ngClass]="(errors['message'] ? 'error' : '')">
      <label for="loginEmail">
        Email
      </label>
      <input id="loginEmail" name="email" type="email"
        [(ngModel)]="email">
    </div>
    <div class="field-group"
      [ngClass]="(errors['message'] ? 'error' : '')">
      <label for="loginPassword">
        Password
      </label>
      <input id="loginPassword" name="password" type="password"
        [(ngModel)]="password"
        (keyup.enter)="onSubmit()">
      <span class="c-field__note"
        [hidden]="!errors['message']">
        {{ errors['message'] }}
      </span>
      <li class="login-links">
        <a class="text-link" routerLink="/forgotPassword">
          Forgot your password?
        </a>
      </li>
    </div>
  </div>
  <button type="submit" class="action-button action-button--primary" [disabled]="!validateEmail(email) || !password"
    (click)="onSubmit()">
    Log In
  </button>
  <hr>
  <div class="card__footer">
    <ul class="login-links">
      <li>
        <a class="text-link" routerLink="/request-access">
          Request access to NOVA
        </a>
      </li>
      <li>
        <a class="text-link" routerLink="/help">
          NOVA Help Desk
        </a>
      </li>
    </ul>
  </div>
</div>

<ng-template #loggingIn>
  <div class="card card--login busy-spinner-overlay--alt">
    <app-spinner [isBusy]="true" [short]="true" message="Logging In..."></app-spinner>
  </div>
</ng-template>