import { withLatestFrom, skipWhile, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from 'ng-logger';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Model, Queries, Actions } from '@app-ngrx-domains';
import { PermissionsService, ProgramService, LookupService } from '@app-services';
import { ROUTER_LINKS, PROGRAM_KEYS, WILDCARD, AREAS } from '@app-consts';
import { ProposalBase, Utilities, Institution, Proposal } from '@app-models';
import { LOOKUP_TABLES } from '@app/core/state-management/lookup-tables.action';
import { last } from 'lodash';

@Injectable()
export class ProjectGuard implements CanActivate {

  constructor(
    private router: Router,
    private logger: LoggerService,
    private lookupService: LookupService,
    private permissionsService: PermissionsService,
    private programService: ProgramService,
    private store: Store<State>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {
      this.logger.debug(`[project-guard][${state.url}] checking...`);

      // get proposal id - required
      const proposalId = Number(route.params['proposalId']);
      if (isNaN(proposalId)) {
        // redirect user to page-not-found page.
        this.logger.info(`[project-guard][${state.url}] bad url - redirecting to page-not-found`);
        this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      // read institution id - if exists
      const institutionId = route.params['institutionId'] ? Number(route.params['institutionId']) : undefined;
      if (route.params['institutionId'] && isNaN(institutionId)) {
        // redirect user to page-not-found page - institution id should be a number.
        this.logger.info(`[project-guard][${state.url}] bad url (institution id) - redirecting to page-not-found`);
        this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
        subscriber.next(false);
        subscriber.complete();
        return;
      }

      // start busy spinner...
      this.store.dispatch(Actions.Layout.showBusySpinner(true));

      // load any lookup tables that are required in setting up the proposal first.
      this.logger.debug(`[project-guard][${state.url}] loading any lookup tables...`);

      this.store.dispatch(Actions.LookupTables.doLookup(LOOKUP_TABLES.DURATIONS));
      this.store.dispatch(Actions.LookupTables.doLookup(LOOKUP_TABLES.ROLES));
      const waitFor = [
        LOOKUP_TABLES.DURATIONS,
        LOOKUP_TABLES.ROLES,
      ];

      // Load program-specific institutions, if necessary.
      const routeKey = Utilities.programKeyFromRoute(state.url);
      if (routeKey === PROGRAM_KEYS.CAEP) {
        waitFor.push(LOOKUP_TABLES.CAEP_INSTITUTIONS);
        this.store.dispatch(Actions.LookupTables.lookupInstitutions({ caep: true }))
      } else if (routeKey === PROGRAM_KEYS.SWP_L || routeKey === PROGRAM_KEYS.SWP_R) {
        waitFor.push(LOOKUP_TABLES.CCC_INSTITUTIONS);
        this.store.dispatch(Actions.LookupTables.lookupInstitutions({ ccc: true }))
      }


      // Load proposal while waiting for lookupTables
      this.logger.debug(`[project-guard][${state.url}] loading proposal data...`);
      // add fetched data to proposals redux state as well
      const addToProposals = (state.url.includes('/fiscal-reports/') || state.url.includes('/reporting/'))
        && ![PROGRAM_KEYS.IPLAN].includes(routeKey);
      this.store.dispatch(Actions.CurrentProposal.get(proposalId, institutionId, addToProposals));

      combineLatest([
        this.store.select(Queries.LookupTables.getLoadStatus),
        this.store.select(Queries.CurrentProposal.getLoadStatus)
      ]).pipe(
        skipWhile(([lookupStatus, proposalStatus]) => {
          return proposalStatus.isLoading || !waitFor.every(table => !!lookupStatus[table + 'IsLoaded']);
        }),
        withLatestFrom(this.store.select(Queries.CurrentProposal.getProposal)),
        take(1)
      ).subscribe(([[lookupStatus, proposalStatus], proposal]) => {
        // check if proposal does not exist, has been deleted, or for error
        if (proposalStatus.error) {
          // stop busy spinner...
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          this.logger.error(`[project-guard][${state.url}] failed to load=${JSON.stringify(proposalStatus.error)}`);
          this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
          subscriber.next(false);
          subscriber.complete();
          return;
        }

        const validFundRoute = (): boolean => {
          const rootFromRoute = Utilities.programKeyFromRoute(state.url);
          const programKey = ProposalBase.getProgramKey(proposal);
          const parentProgram = this.programService.getParentProgramByKey(programKey);

          const programIsValid = programKey === PROGRAM_KEYS.RSI
            ? rootFromRoute === PROGRAM_KEYS.CAI
            : rootFromRoute === (parentProgram ? parentProgram.key : programKey);

          // WARNING: Fiscal Agent Proposal's uses a pseudo programKey, so the program may not actually exist
          if (programIsValid) {
            // at least the program key matches
            if (route.data.checkProjectLink) {
              // now check project link to be valid
              const stateUrl = state.url;
              const expectedUrl = Proposal.routerLink(proposal);
              if (!stateUrl.startsWith(expectedUrl)) {
                this.logger.warn(`[project-guard][${state.url}] incorrect route used to access project. Redirecting to ${expectedUrl}`);
                this.router.navigate([expectedUrl]);
              }
            }
            return true;
          } else {
            this.logger.error(`[project-guard][${state.url}] project does not belong to program ${programKey}`);
            return false;
          }
        };

        if (!proposal.id || !!proposal.deleted || !validFundRoute()) {
          // stop busy spinner...
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          this.logger.error('Params: ', proposal.id, proposal.deleted, validFundRoute(), state.url);
          this.logger.error(`[project-guard][${state.url}] either project's been deleted or used incorrect route.`);
          this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
          subscriber.next(false);
          subscriber.complete();
          return;
        }

        if (route.data.routeCheck && route.data.routeCheck.guard === AREAS.PROJECT) {
          this.handleProjectGuard(proposal, route, state, subscriber);
        } else if (route.data.routeCheck && route.data.routeCheck.guard === 'report' ) {
          this.handleReportGuard(proposal, state, subscriber);
        } else {
          // stop busy spinner...
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          this.logger.debug(`[project-guard][${state.url}] is logged in & has no route check or will be checked by another guard`);
          subscriber.next(true);
          subscriber.complete();
        }

      }, (error) => {
        // stop busy spinner... failed to load proposal data.
        this.store.dispatch(Actions.Layout.showBusySpinner(false));
        this.router.navigate([ROUTER_LINKS.HOME]);
        subscriber.error(false);
        subscriber.complete();
      });
    });
  }

  handleProjectGuard(proposal, route, state, subscriber) {
    // check and see if the user has access to the activated route.
    let extraScope: Model.PermResourceScope = { fund_id: proposal.fund_ids[0] };
    if (route.data.extraScope) {
      extraScope = {
        ...route.data.extraScope,
      }
    } else {
      // Wildcard will be expanded to all institutions on the project
      extraScope.institution_id = WILDCARD;
    }

    this.permissionsService.canGoTo(route.data.routeCheck, route, extraScope).subscribe(check => {
      // stop busy spinner...
      this.store.dispatch(Actions.Layout.showBusySpinner(false));
      if (check.allowed) {
        this.logger.debug(`[project-guard][${state.url}] has access to route`);
        subscriber.next(true);
        subscriber.complete();
      } else {
        if (check.rerouteUrl) {
          this.logger.debug(`[project-guard][${state.url}] does not have access - rerouting to ${check.rerouteUrl}`);
          this.router.navigate([check.rerouteUrl]);
        } else {
          this.logger.debug(`[project-guard][${state.url}] does not have access & has no rerouting url.`);
          this.router.navigate([ROUTER_LINKS.PAGE_ACCESS_DENIED], { queryParams: { badUrl: state.url } });
        }
        subscriber.next(false);
        subscriber.complete();
      }
    });
  }

  handleReportGuard(proposal, state, subscriber) {
    // TODO: Handle for any future report cases
    const fundKey = Utilities.programKeyFromRoute(state.url);
    switch (fundKey) {
      case PROGRAM_KEYS.SEP:
        this.handleSEPReport(proposal, state, subscriber);
        return;
      default:
        subscriber.next(false);
        subscriber.complete();
        return;
    }
  }

  handleSEPReport(proposal, state, subscriber) {
    const isDistrict = Institution.isDistrict(proposal.lead_institution);
    const isProjectCertified = ProposalBase.projectIsCertified(proposal);

    const hasMidAllocation = ProposalBase.hasAreaState(proposal, AREAS.MID_ALLOCATION_REPORT);
    const accessingMidAllocation = state.url.includes('mid-allocation');
    const canAccessMidAllocation = isProjectCertified && hasMidAllocation;
    const isMidAllocationCertified = ProposalBase.isAreaCertified(proposal, AREAS.MID_ALLOCATION_REPORT)

    const accessingAllocationEnd = state.url.includes('allocation-end');


    if (!isDistrict && ((accessingMidAllocation && !canAccessMidAllocation) || (accessingAllocationEnd && !isMidAllocationCertified))) {
      this.logger.debug(`[report-guard][${state.url}] Proposal is either not certified or there has not been an allocation`);
      this.router.navigate([ROUTER_LINKS.SEP_REPORTING]);
      this.store.dispatch(Actions.App.showAlert(
        `The plan has not been certified or money has not been allocated to ${proposal.lead_institution.name}. Once both of these steps are completed, you may proceed to edit the report.`)
      );
      subscriber.next(false);
      subscriber.complete();
      return;
    }

    this.logger.debug(`[report-guard][${state.url}]  Proposal is certified and the institution has been given an allocation.`);
    subscriber.next(true);
    subscriber.complete();
  }
}
