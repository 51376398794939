import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, State } from '@app-ngrx-domains';
import { PAGE_TYPES } from '../../consts/index';

@Component({
  selector: 'app-unsupported-browser',
  templateUrl: './unsupported-browser.component.html'
})
export class UnsupportedBrowserComponent implements OnInit, OnDestroy {

  constructor(
    route: ActivatedRoute,
    private store: Store<State>
    ) { }

    ngOnInit() {
      // update header.
      this.store.dispatch(Actions.Layout.setHeader({
        ...State.Layout.header,
        title: 'Unsupported Browser',
      }));
      this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
    }

    ngOnDestroy() {
        this.store.dispatch(Actions.Layout.clearPageType());
    }
}
