import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { AllocationFilter, EnumErrorTypes } from '@app/core/models';

const ACTION_PREFIX = 'PROGRAM_ALLOCATIONS_';
export const PROGRAM_ALLOCATIONS_ACTION_TYPES = {
  CLEAR: `${ACTION_PREFIX}CLEAR`,
  FETCH_ALLOCATIONS: `${ACTION_PREFIX}FETCH_ALLOCATIONS`,
  FETCH_CARRYOVERS: `${ACTION_PREFIX}FETCH_CARRYOVERS`,
  FETCH_TASKS: `${ACTION_PREFIX}FETCH_TASKS`,
  FETCH_PROPOSALS: `${ACTION_PREFIX}FETCH_PROPOSALS`,
  REFRESH_ALLOCATIONS: `${ACTION_PREFIX}REFRESH_ALLOCATIONS`,
  REFRESH_CARRYOVERS: `${ACTION_PREFIX}REFRESH_CARRYOVERS`,
  REFRESH_TASKS: `${ACTION_PREFIX}REFRESH_TASKS`,
  REFRESH_PROPOSALS: `${ACTION_PREFIX}REFRESH_PROPOSALS`,
  FETCH_SUCCESS: `${ACTION_PREFIX}FETCH_SUCCESS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`
};

export class ProgramAllocationsActions {

  clear(): Action {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.CLEAR
    };
  }

  fetchAllocations(filter: AllocationFilter, key: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_ALLOCATIONS,
      payload: { filter, key }
    };
  }

  fetchCarryovers(filter: any, key: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_CARRYOVERS,
      payload: { filter, key }
    };
  }

  fetchTasks(filter: any, key: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_TASKS,
      payload: { filter, key }
    };
  }

  fetchProposals(filter: any, key: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_PROPOSALS,
      payload: { filter, key }
    };
  }

  refreshAllocations(key?: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_ALLOCATIONS,
      payload: { key }
    };
  }

  refreshCarryovers(key?: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_CARRYOVERS,
      payload: { key }
    };
  }

  refreshTasks(key?: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_TASKS,
      payload: { key }
    };
  }
  refreshProposals(key?: string): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.REFRESH_PROPOSALS,
      payload: { key }
    };
  }

  fetchSuccess(resultStore: string, key: string, results: any): ActionWithPayload<any> {
    return {
      type: PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_SUCCESS,
      payload: { resultStore, key, results }
    };
  }

  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: PROGRAM_ALLOCATIONS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error
    });
  }
}

Actions.ProgramAllocations = new ProgramAllocationsActions();

declare module '@app-ngrx-domains' {
  interface Actions {
    ProgramAllocations: ProgramAllocationsActions
  }
}
