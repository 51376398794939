import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Model, State, Queries, Actions as DomainActions } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { BUDGET_ACTION_TYPES } from './budget.action';

/**
 * Injectable budget effects class.
 */
@Injectable()
export class BudgetEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  @Effect() addBudgetItem = this.actions$.pipe(
    ofType(BUDGET_ACTION_TYPES.ADD_ITEM),
    map(toPayload),
    mergeMap((payload: Model.BudgetItem) => {
      return this.apiService.addBudgetItem(payload).pipe(
        mergeMap((response) => of(DomainActions.Budget.addBudgetItemSuccess(response))),
        catchError((error) => of(DomainActions.Budget.serviceFail(error)))
      );
    })
  );

  @Effect() deleteBudgetItem = this.actions$.pipe(
    ofType(BUDGET_ACTION_TYPES.DELETE_ITEM),
    map(toPayload),
    mergeMap((budgetItemId: number) => {
      return this.apiService.deleteBudgetItem(budgetItemId).pipe(
        mergeMap((response) => of(DomainActions.Budget.deleteBudgetItemSuccess(budgetItemId))),
        catchError((error) => of(DomainActions.Budget.serviceFail(error)))
      );
    })
  );

  @Effect() updateBudgetItem = this.actions$.pipe(
    ofType(BUDGET_ACTION_TYPES.UPDATE_ITEM),
    map(toPayload),
    mergeMap((payload: Model.BudgetItem) => {
      return this.apiService.updateBudgetItem(payload).pipe(
        mergeMap((response) => of(DomainActions.Budget.updateBudgetItemSuccess(response))),
        catchError((error) => of(DomainActions.Budget.serviceFail(error)))
      );
    })
  );
}
