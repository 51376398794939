<div class="pagination-arrows__container">
  <div class="pagination-arrows__numbers" aria-live="polite">
    {{startingResult}} to {{endingResult}} of {{totalResults}} {{itemsName}}
  </div>
  <div *ngIf="!onlyOnePage()" class="inline-button-group__container">
    <button id="qa_pagination_previous" title="Previous Page" type="button" class="action-button inline-button action-button--secondary icon--only" [disabled]="disablePrev()" (click)="emitPaginate(-1)">
      <icon iconId="arrow-left"></icon>
    </button>
    <button id="qa_pagination_next" title="Next Page" type="button" class="action-button inline-button action-button--secondary icon--only" [disabled]="disableNext()" (click)="emitPaginate(1)">
      <icon iconId="arrow-right"></icon>
    </button>
  </div>
</div>
