export const DURATION_TYPES = {
  YEAR: 'annual',
  QUARTER: 'quarter',
  PERIOD: 'generic'
};

export const PERIOD_TYPES = {
  p0: 'Period 0',
  p1: 'Period 1',
  p2: 'Period 2',
  p3: 'Period 3',
  p4: 'Period 4',
};

export const PERIODS = {
  [PERIOD_TYPES.p0]: 0,
  [PERIOD_TYPES.p1]: 1,
  [PERIOD_TYPES.p2]: 2,
  [PERIOD_TYPES.p3]: 3,
  [PERIOD_TYPES.p4]: 4,
};

export const YEARS = {
  YEAR_2014_15: 2015,
  YEAR_2015_16: 2016,
  YEAR_2016_17: 2017,
  YEAR_2017_18: 2018,
  YEAR_2018_19: 2019,
  YEAR_2019_20: 2020,
  YEAR_2020_21: 2021,
  YEAR_2021_22: 2022,
  YEAR_2022_23: 2023,
  YEAR_2023_24: 2024,
  YEAR_2024_25: 2025,
  YEAR_2025_26: 2026,
};

export const FINAL_DURATION = 999999999;
