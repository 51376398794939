export interface IAttribute {
  effort_area_id?: number,
  proposal_id?: number,
  attribute_name: string,
  value: any,
}

export interface IAttributeValue {
  id: number,
  value: boolean | string | number,
  [objectName: string]: any,
}

export class Attribute {

  static removeUnusedFields(response: any): any {

    const unUsedFields = [
      'created_at',
      'attribute_name',
      'proposal_id',
      'effort_area_id',
      'bool_val',
      'int_val',
      'string_val',
      'text_val',
    ];

    const cleaned = {};
    Object.keys(response).forEach(key => {
      if (!unUsedFields.includes(key)) {
        cleaned[key] = response[key];
      }
    });

    return cleaned;
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Attribute = IAttribute;
    export type AttributeValue = IAttributeValue;
  }
}
