import { Model } from '@app-ngrx-domains';
import { Proposal } from './proposal';

export interface ISEPProposal extends Model.ProposalBase {
  year_string: string;
  read_legislation: boolean;
  process_schedule: string;
  success_criteria: string;
  executive_summary: string;
  population_groups: Array<Model.EAPopulationGroup>;
  activity_items: Array<Model.EAActivityItem>;
  mid_allocation_report: Model.EAMidAllocationReport;
}

export interface ISEPDashboardRow {
  proposal_id: number;
  title: string;
  duration_id: number;
  year_string: string;
  current_status: string;
  institution: Model.Institution;
}

export class SEPProposal {

  static getDemographicName(population_group: Model.EAPopulationGroup) {
    return population_group.custom_demographic ||
      population_group && population_group.impacted_group
      && population_group.impacted_group.name || 'Unknown Demographic';
  }

  static getGenderName(population_group: Model.EAPopulationGroup) {
    return population_group && population_group.impacted_group_gender
      && population_group.impacted_group_gender.name || 'Unkonwn Gender';
  }

  static getMetricName(population_group: Model.EAPopulationGroup) {
    return population_group && population_group.metric_definition
      && population_group.metric_definition.name || 'Unkown Metric';
  }
}

export interface ISEPDistrictProposal extends Model.ProposalBase {
  allocation_correct: boolean;
  allocation_explanation: string;
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type SEPProposal = ISEPProposal;
    export type SEPDashboardRow = ISEPDashboardRow & ISEPProposal;
    export type SEPDistrictProposal = ISEPDistrictProposal;
  }
}
