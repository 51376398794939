import { combineRootFactory, ILayoutState, Model, Queries, Query, Root } from '@app-ngrx-domains';

// Get the root states.
const fromRoot = combineRootFactory<ILayoutState>('Layout');

// Predefined queries to run against root states.
export interface ILayoutQueries {
  getBookmarkState: Query<Model.Bookmark>;
  getBusySpinner: Query<Model.Spinner>;
  getEmitState: Query<{ name: string, id?: number|string }>;
  getHeader: Query<Model.HeaderLayout>;
  getHeaderMessage: Query<Model.StatusMessage>;
  getNav: Query<Model.NavLayout>;
  getPage: Query<Model.PageLayout>;
  getPageType: Query<string>;
  getLogoState: Query<Model.Logo>;
}

Queries.Layout = {
  getBookmarkState: fromRoot(state => state.bookmark),
  getBusySpinner: fromRoot(state => state.spinner),
  getEmitState: fromRoot(state => state.header.emit),
  getHeader: fromRoot( state => state.header ),
  getHeaderMessage: fromRoot( state => state.headerMessage ),
  getNav: fromRoot( state => state.nav ),
  getPage: fromRoot( state => state.page ),
  getPageType: fromRoot( state => state.page.type ),
  getLogoState: fromRoot( state => state.logo )
};

// Add queries to ngrx domains type declarations.
declare module '@app-ngrx-domains' {

  interface Queries {
    Layout: ILayoutQueries;
  }

  interface Root {
    Layout: Query<ILayoutState>;
  }

}
