<div class="po-select__group{{inline_label}}" [ngClass]="{'disabled' : disabled}">
  <p class="label-text" *ngIf="!canEdit && showLabel()">
    {{labelText}}
  </p>

  <label *ngIf="canEdit && showLabel()" [for]="'searchInput_' + selectInput.element.id" [textContent]="labelText" [class.required]="required"></label>

  <ng-content select=".help-text"></ng-content>

  <div [hidden]="!canEdit" [ngClass]="{ 'columns columns--5 columns--gap-none no-margin': showEmptyButton }">
    <div class="column-start-1 column-end-5">
      <form [formGroup]="searchInputForm" autocomplete="off" class="po-select__search-form">
        <div class="po-select__input-container">
          <input type="text" class="po-select__search-input" inputRef formControlName="searchInput" id="searchInput_{{ selectInput.element.id }}" [placeholder]="placeholderText">

          <button *ngIf="showClearButton() && !disabled" type="button" class="po-select__clear-button" (click)="remove(selectedOption$.getValue())">
            <icon iconId="close" data-qa="remove-selected-item"></icon>
          </button>

          <div class="po-select__arrow-icon-container" *ngIf="!isSearch">
            <icon iconId="arrow-down" class="po-select__arrow-icon"></icon>
          </div>
        </div>
      </form>

      <ng-content select="[inputRef]"></ng-content>

      <div class="po-select__list-wrapper">
        <results-list #resultsList
                      [hostType]="isSearch ? 'search' : 'select'"
                      [multiSelect]="selectMultiple"
                      [selectOnce]="selectOnce"
                      [options]="options"
                      [disabledOptions]="disabledOptions"
                      [selectedOption]="selectedOption$ | async"
                      [selectedOptions]="selectedOptions$ | async"
                      [searchValue]="isSearch ? searchValue : searchInput.control.value"
                      [sortByLabel]="sortByLabel"
                      [input]="searchInput"
                      (optionSelected)="select($event)">
        </results-list>

        <div class="po-select__selection-list" *ngIf="showSelectionList()" data-qa="selection-list">
          <button
            *ngFor="let option of selectedOptions$ | async"
            class="action-button po-select__selection-list-item"
            (click)="remove(option)"
            [disabled]="disabled"
            type="button"
            aria-label="Remove">
            {{option.label}}
            <icon *ngIf="!disabled" iconId="close" class="icon-host" data-qa="remove-selected-item"></icon>
          </button>
        </div>
      </div>
    </div>

    <div>
      <button
        class="margin-left action-button action-button--secondary action-button--small margin-top-xxs"
        *ngIf="showEmptyButton"
        [ngClass]="{'icon--left': !!emptyIcon}"
        (click)="doSelectEmpty()">
        <icon *ngIf="emptyIcon" [iconId]="emptyIcon"></icon>
        {{emptyText}}
      </button>
    </div>
  </div>

  <div *ngIf="!canEdit" class="read-only__container">
    <ng-container [ngSwitch]="selectMultiple">
      <ng-container *ngSwitchCase="true">
        <ul *ngIf="hasSelectedOptions(); else noOptions">
          <li *ngFor="let option of selectedOptions$.getValue()">
            {{option.label}}
          </li>
        </ul>
      </ng-container>

      <ng-template #noOptions>
        <p>{{notEnteredText}}</p>
      </ng-template>

      <ng-container *ngSwitchCase="false">
        <p>
          {{getSelectedText()}}
        </p>
      </ng-container>
    </ng-container>
  </div>

</div>

<show-errors [hidden]="!canEdit" [control]="selectInput.control" class="error-text"></show-errors>

<alert *ngIf="showAlert"
       button-action="Delete"
       button-dismiss="Cancel"
       level="warning"
       (action)="remove(optionToRemove)"
       (dismiss)="dismissAlert()">
  <ng-container [ngTemplateOutlet]="removeAlertTmpl"
    [ngTemplateOutletContext]="{ optionName: optionToRemove.label }">
  </ng-container>
</alert>
