import { parse } from 'url';

export class UrlUtils {

  /**
   * Determines if the provided URI is for an external URL based on its'
   * protocol prefix.
   * @param {string} uri
   * @param {object} options
   * @param {Array<String>} options.protocols
   */
  static isExternalUrl(uri: string, options: any = {}): boolean {
    const acceptedProtocols = options.protocols || ['http:', 'https:'];
    return acceptedProtocols.includes(parse(uri).protocol);
  }
}
