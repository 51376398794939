import { Model } from '@app-ngrx-domains';
import { ActionWithPayload, NewTempId, AttributePayload } from './ngrx-action';
import { EFFORT_AREAS } from '@app-models';

/**
 * Abstract Attribute Actions class.
 */
export abstract class AttributeActions {
  // action prefix used by inherited class.
  private actionPrefix: string;

  constructor(actionPrefix: string) {
    this.actionPrefix = actionPrefix;
  }

  upsertAttribute(req: AttributePayload): ActionWithPayload<any> {
    const actionType = (req.ea && NewTempId.isTemp(req.ea.id))
      ? 'CREATE_ATTRIBUTE_EFFORT_AREA'
      : 'UPSERT_ATTRIBUTE';

    return {
      type: `${this.actionPrefix}${actionType}`,
      payload: req
    }
  }

  upsertAttributes(attributes: Array<{key?: string, value?: any, ea?: any, parentEffortAreas?: Array<Model.EffortArea> }>) {
    return {
      type: `${this.actionPrefix}UPSERT_ATTRIBUTES`,
      payload: { attributes }
    }
  }

  deleteAttributes(attributes: Array<{key?: string, value?: any, ea?: any, parentEffortAreas?: Array<Model.EffortArea> }>) {
    return {
      type: `${this.actionPrefix}DELETE_ATTRIBUTES`,
      payload: { attributes }
    }
  }

  // *** Multi Attribute Actions
  addMultiAttribute(req: AttributePayload): ActionWithPayload<any> {
    const actionType = (req.ea && NewTempId.isTemp(req.ea.id))
      ? 'CREATE_ATTRIBUTE_EFFORT_AREA'
      : 'ADD_MULTI_ATTRIBUTE';

    return {
      type: `${this.actionPrefix}${actionType}`,
      payload: req
    };
  }

  deleteMultiAttribute(req: AttributePayload): ActionWithPayload<any> {
    return {
      type: `${this.actionPrefix}DELETE_MULTI_ATTRIBUTE`,
      payload: req
    };
  }

  // *** EffortArea Actions
  createTempEffortArea(req: {ea: any, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number}): ActionWithPayload<any> {
    // creating temporary effort area.  get unique temp id.
    const temp_id = NewTempId.id();

    const item = {
      ...EFFORT_AREAS[req.ea.effort_area_type],
      ...req.ea,
    }
    return {
      type: `${this.actionPrefix}CREATE_EFFORT_AREA_SUCCESS`,
      payload: {
        ea: {
          ...item,
          id: temp_id,
          temp_id,
        },
        parentEffortAreas: req.parentEffortAreas,
        multi_data_id: req.proposal_id
      }
    };
  }

  // For creating mutliple effort areas at once
  createTempEffortAreas(req: {eas: Array<any>, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number}): ActionWithPayload<any> {
    const temp_eas = req.eas.map(ea => {
      const temp_id = NewTempId.id();
      return {
        id: temp_id,
        temp_id,
        ...EFFORT_AREAS[ea.effort_area_type],
        ...ea
      }
    });

    return {
      type: `${this.actionPrefix}CREATE_MULTI_EFFORT_AREAS_SUCCESS`,
      payload: {
        eas: temp_eas,
        parentEffortAreas: req.parentEffortAreas,
        multi_data_id: req.proposal_id,
      }
    };
  }

  createEffortArea(req: AttributePayload & { hasAttributes?: boolean }): ActionWithPayload<any> {
    const payloadType = req.hasAttributes
      ? `${this.actionPrefix}CREATE_ATTRIBUTE_EFFORT_AREA`
      : `${this.actionPrefix}CREATE_EFFORT_AREA`;

    return {
      type: payloadType,
      payload: req
    };
  }

  createMultiEffortAreas(req: { eas: Array<any>, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number }): ActionWithPayload<any> {
    return {
      type: `${this.actionPrefix}CREATE_MULTI_EFFORT_AREAS`,
      payload: {
        eas: req.eas,
        parentEffortAreas: req.parentEffortAreas,
        multi_data_id: req.proposal_id
      }
    };
  }

  updateEffortArea(req: {ea: any, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number}): ActionWithPayload<any> {
    return {
      type: `${this.actionPrefix}UPDATE_EFFORT_AREA`,
      payload: req
    };
  }

  deleteEffortArea(req: {ea: any, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number}): ActionWithPayload<any> {
    if (NewTempId.isTemp(req.ea.id)) {
      // we're dealing with a temp record, no need to call the service.
      return {
        type: `${this.actionPrefix}DELETE_EFFORT_AREA_SUCCESS`,
        payload: {
          ea: req.ea,
          parentEffortAreas: req.parentEffortAreas,
          multi_data_id: req.proposal_id
        }
      };
    } else {
      return {
        type: `${this.actionPrefix}DELETE_EFFORT_AREA`,
        payload: req
      };
    }
  }

  deleteMultiEffortAreas(req: {eas: Array<any>, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number}): ActionWithPayload<any> {
    return {
      type: `${this.actionPrefix}DELETE_MULTI_EFFORT_AREAS`,
      payload: req
    };
  }

  cloneEffortAreas(operations: Array<{ effortAreaId: number, options: {
    replacers?: Array<{ key: string, value: any }>,
    excludeAttributes?: Array<string>,
    excludeEffortAreas?: Array<string>,
    targetModel: string } }>,
    parentEffortAreas?: Array<Model.EffortArea>): ActionWithPayload<any> {
      return {
        type: `${this.actionPrefix}CLONE_EFFORT_AREAS`,
        payload: { operations, parentEffortAreas }
      }
    }


  upsertAttributeSuccess(req: {key: string, value: Model.Document, ea?: Model.EffortArea, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number}): ActionWithPayload<any> {
    return {
      type: `${this.actionPrefix}UPSERT_ATTRIBUTE_SUCCESS`,
      payload: req
    }
  }

  deleteAttributeSuccess(req: {key: string, value: Model.Document, ea?: Model.EffortArea, parentEffortAreas?: Array<Model.EffortArea>, proposal_id?: number}): ActionWithPayload<any> {
    return {
      type: `${this.actionPrefix}DELETE_ATTRIBUTE_SUCCESS`,
      payload: req
    }
  }
}
