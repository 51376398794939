/** Form Validator for Proposal Description tabs
 */
import { Model } from '@app-ngrx-domains';
import { FUND_TYPES } from '@app-consts';

/** Validate that Budget Item form fields have required values and return an object indicating the conditions that aren't met.
 * Ex: {noinstitution: true, nosource:true}
 * Available flags are: TODO FILL ME IN
 * @param p a proposal object
 * @returns {any}
 */
export function validateStepData(p: Model.ProposalItem): boolean {

  const validateIPlan = () => {
    if (!(p.lead_institution && p.lead_institution.id && p.lead_institution.id > 0)) {
      return false;
    }

    if (!(p.read_swp_legis && p.read_swp_legis)) {
      return false;
    }

    return true;
  };

  const validateGP = () => {
    if (!(p.lead_institution && p.lead_institution.id && p.lead_institution.id > 0)) {
      return false;
    }

    if (!(p.read_swp_legis && p.read_swp_legis)) {
      return false;
    }

    return true;
  };

  switch (p.project_type) {
    case FUND_TYPES.IPLAN:
      return validateIPlan();

    case FUND_TYPES.GP:
      return validateGP();

    default:
      return false;
  }
}
