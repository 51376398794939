import { Model } from '@app-ngrx-domains';
import { ModelBase } from './model.base';
import { Metric } from './metric';

export interface IProgramItem {
  id: number;
  top6_code;
  lmi_id: number;
  demand: number;
  supply: number;
  institution_id: number;
  institution: Model.Institution;
  created_at?: string;
  updated_at?: string;
}

export interface IProgramMetricsItems {
  readonly id: number;
  readonly lmi_id: number;
  readonly institution: any;
  readonly top6_code: any;
  narrative?: string;
  metrics: { [metric_definition_id: number]: Model.MetricItem };
}

export class Program extends ModelBase implements IProgramItem {
  public id: number;
  public top6_code;
  public lmi_id: number;
  public demand: number;
  public supply: number;
  public created_at: string;
  public updated_at: string;
  public metrics: Array<Metric> = [];
  public narrative: string;
  public institution_id: number;
  public institution: Model.Institution;

  constructor(raw?) {
    super();
    if (raw) {
      this.id = raw.id;
      this.top6_code = raw.top6_code;
      this.lmi_id = raw.lmi_id;
      this.supply = raw.supply;
      this.demand = raw.demand;
      this.created_at = raw.created_at;
      this.updated_at = raw.updated_at;
      this.metrics = raw.metrics || [];
      this.narrative = raw.narrative;
      this.institution_id = raw.institution_id;
      this.institution = raw.institution;
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ProgramItem = IProgramItem;
    export type ProgramMetricsItems = IProgramMetricsItems;
  }
}
