import { ModelBase } from './model.base';
import { sortBy, uniq, values } from 'lodash';

export interface IPermisionAction {
  id: number;
  name: string;
}

export interface IPermissionAreaActions {
  id: number;
  name: string;
  description: string;
  actions?: Array<IPermisionAction>;
  actionsJoined?: string;
}

export interface IRole {
  id: number;
  name: string;
  long_name: string;
  description: string;
  type: string | null;
  fund: number | null;
  institution: number | null;
  proposal: number | null;
  sector: number | null;
}

export interface IRolePermissions extends IRole {
  permissions?: Array<IPermissionAreaActions>;
  abilities?: Array<string>;
  aliases?: Array<string>;
}

export class RolePermissions extends ModelBase implements IRolePermissions {
  public id: number;
  public name: string;
  public long_name: string;
  public description: string;
  public type: string | null = null;
  public fund: number | null = null;
  public institution: number | null = null;
  public proposal: number | null = null;
  public sector: number | null = null;
  public permissions: Array<IPermissionAreaActions> = [];
  public abilities: Array<string> = [];
  public aliases: Array<string> = null;

  constructor(raw?: any) {
    super();

    if (raw) {
      this.id = raw.id;
      this.name = raw.name;
      this.long_name = raw.long_name;
      this.description = raw.description;
      this.type = raw.type;
      this.fund = raw.fund;
      this.institution = raw.institution;
      this.proposal = raw.proposal;
      this.sector = raw.sector;
      this.permissions = [];
      this.abilities = raw.abilities || [];
      this.aliases = raw.aliases;
      if (raw.permissions) {
        // transform the permissions to unique areas & actions.
        const areas = {};
        raw.permissions.forEach((p: any) => {
          if (!areas[p.area.name]) {
            areas[p.area.name] = {
              ...p.area,
              actions: [{
                ...p.action,
              }],
            };
          } else {
            areas[p.area.name] = {
              ...areas[p.area.name],
              actions: [
                ...areas[p.area.name].actions,
                {
                  ...p.action,
                },
              ]
            };
          }
        });
        // keep the permissions sorted by name of the areas.
        this.permissions = sortBy(values(areas), 'name');
        // likewise sort the actions.
        this.permissions.forEach(p => {
          p.actions = sortBy(uniq(p.actions), 'name');
          p.actionsJoined = p.actions.map(a => a.name).join(' | ');
        });
      }
    }
  }

  /**
   * This returns true if the rule for the particular value is required.  The rules on the values are:
   * 0 => optional
   * 1 => required
   * null => not allowed
   * @static
   * @param {(number | null)} value
   * @returns {boolean}
   */
  static isRequired(value: number | null): boolean {
    return value === 1;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Role = IRole;
    export type RolePermissions = IRolePermissions;
  }
}
