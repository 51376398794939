import {Directive, ElementRef, HostListener, OnInit} from '@angular/core';
import { NgControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: '[inputRef]'
})
export class InputRefDirective implements OnInit {

  element: HTMLInputElement;
  focus = false;
  focusSubject = new BehaviorSubject<boolean>(null);
  keyUpSubject = new BehaviorSubject<KeyboardEvent>(null);
  pasteSubject = new BehaviorSubject<ClipboardEvent>(null);

  constructor( private el: ElementRef, public control: NgControl ) { }

  ngOnInit() {
    this.element = this.el.nativeElement;
    this.focusSubject.asObservable().subscribe((value) => {
      this.focus = value;
    });
  }

  hasError() {
    return this.control.errors;
  }

  @HostListener('focus')
  handleFocus() {
    this.focusSubject.next(true);
  }

  @HostListener('blur')
  handleBlur() {
    this.focusSubject.next(false);
  }

  @HostListener('keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent) {
    this.keyUpSubject.next(event);
  }

  @HostListener('paste', ['$event'])
  handlePaste(event: ClipboardEvent) {
    this.pasteSubject.next(event);
  }

}
