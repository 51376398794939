import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Model } from '@app-ngrx-domains';
import { LookupService } from '@app-services';

@Component({
  selector: 'app-user-roles-list',
  templateUrl: './user-roles-list.component.html',
})
export class UserRolesListComponent implements OnInit {
  @Input() canEditRole: boolean;
  @Output() addRoleClicked: EventEmitter<any> = new EventEmitter();
  @Output() importRolesClicked: EventEmitter<any> = new EventEmitter();
  @Output() removeRoleScopeClicked: EventEmitter<any> = new EventEmitter();

  @Input() set userRoleScopes(value: Array<Model.UserRoleScope>) {
    this._userRoles = value;
    const grps = this.lookupService.getGroupedUserRoleScopes(this._userRoles);
    this.grpUserSysRoles = grps.sysRoles;
    this.grpUserProjRoles = grps.projRoles;
  }
  get userRoleScopes(): Array<Model.UserRoleScope> {
    return this._userRoles;
  }
  _userRoles: Array<Model.UserRoleScope> = [];
  grpUserSysRoles: Array<Model.GroupedUserRoleScope> = [];
  grpUserProjRoles: Array<Model.GroupedUserRoleScope> = [];

  constructor(
    private lookupService: LookupService,
    private store: Store<State>
  ) {
  }

  ngOnInit() {
  }

  public addRoles() {
    this.addRoleClicked.emit();
  }

  public transferRoles() {
    this.importRolesClicked.emit();
  }

  public removeRoleScope(roleScope: Model.UserRoleScope) {
    this.removeRoleScopeClicked.emit(roleScope);
  }


  /** Returns the ID property of a generic item, used to uniquify ngFor loops in templates. */
  trackById(index: number, item: any): number {
    return item.role_id;
  }
}
