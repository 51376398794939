import { ModelBase } from './model.base';
import * as _ from 'lodash';
import { Model } from '@app-ngrx-domains';
import { WORKPLAN_TYPES, CHAR_LIMITS } from '../consts';

export interface IWorkplanItem {
  id: number;
  proposal_id: number;
  responsible_person_id: number;
  responsible_person: Model.User;
  other_responsible_person: string;
  other_responsible_person_email_address: string;
  other_responsible_person_phone: string;
  other_lead_institution: string;
  lead_institution: Model.Institution;
  institution_ids: Array<number>;
  institutions: Array<Model.Institution>;
  major_activities: string;
  major_outcomes: string;
  year_1: boolean;
  year_2: boolean;
  year_3: boolean;
  year_4: boolean;
  duration_id: number;
  workplan_type: string;
  strategies?: Array<any>;
  goal_area: string;
  activity_1: string;
  activity_2: string;
  activity_3: string;
  adoption_status_1: string;
  adoption_status_2: string;

  // local var.
  state?: string;
}

export class WorkplanItem extends ModelBase implements IWorkplanItem {
  public id: number;
  public proposal_id: number;
  public responsible_person_id: number;
  public responsible_person: Model.User;
  public other_responsible_person: string;
  public other_responsible_person_email_address: string;
  public other_responsible_person_phone: string;
  public other_lead_institution: string;
  public lead_institution: Model.Institution;
  public institution_ids: Array<number> = [];
  public institutions: Array<Model.Institution> = [];
  public major_activities: string;
  public major_outcomes: string;
  public year_1: boolean;
  public year_2: boolean;
  public year_3: boolean;
  public year_4: boolean;
  public duration_id: number;
  public strategies: Array<any> = [];
  public workplan_type: string;
  public goal_area: string;
  public activity_1: string;
  public activity_2: string;
  public activity_3: string;
  public adoption_status_1: string;
  public adoption_status_2: string;

  constructor(raw?) {
    super();
    if (raw) {
      this.id = raw.id;
      this.proposal_id = raw.proposal_id;
      this.responsible_person_id = raw.responsible_person_id;
      this.responsible_person = raw.responsible_person;
      this.other_responsible_person = raw.other_responsible_person;
      this.other_responsible_person_email_address = raw.other_responsible_person_email_address;
      this.other_responsible_person_phone = raw.other_responsible_person_phone;
      this.other_lead_institution = raw.other_lead_institution;
      this.lead_institution = raw.lead_institution;
      this.institution_ids = raw.institutions ? raw.institutions.map( inst => inst.id ) :  [];
      this.institutions = raw.institutions || [];
      this.major_activities = raw.major_activities;
      this.major_outcomes = raw.major_outcomes;
      this.year_1 = raw.year_1 ? true : false;
      this.year_2 = raw.year_2 ? true : false;
      this.year_3 = raw.year_3 ? true : false;
      this.year_4 = raw.year_4 ? true : false;
      this.duration_id = raw.duration_id;
      this.strategies = raw.strategies || [];
      this.workplan_type = raw.workplan_type;
      this.goal_area = raw.goal_area;
      this.activity_1 = raw.activity_1;
      this.activity_2 = raw.activity_2;
      this.activity_3 = raw.activity_3;
      this.adoption_status_1 = raw.adoption_status_1;
      this.adoption_status_2 = raw.adoption_status_2;
    }
  }

  /**
   * Format the other responsible person info.
   * @param proposal
   */
  static formatOtherResponsiblePerson(wp: IWorkplanItem): string {
    if (wp) {
      const email = wp.other_responsible_person_email_address ? ' (' + wp.other_responsible_person_email_address + ')' : '';
      const phone = wp.other_responsible_person_phone ? ' ' + wp.other_responsible_person_phone : '';
      return `${wp.other_responsible_person}${email}${phone}`
    } else {
      return '';
    }
  }

  /**
   * Takes comma separated ids, and return them as array of numbers.
   * @param areas
   */
  static IdsFromString(value: string): Array<number> {
    if (value) {
      const ids = value.split(',').map(id => Number(id));
      return [...ids];
    } else {
      return [];
    }
  }

  /**
   * Orders Guided Pathways' workplan items by self assessment & plan types.
   *
   * @static
   * @param {Array<IWorkplanItem>} wp_items
   * @param {string} matchType
   */
  static orderedGPItems(wp_items: Array<IWorkplanItem>, matchType: string):  Array<any> {
    // categorize items by match type.
    const matchedItems = wp_items.filter((item: Model.WorkplanItem) => {
      if (item.workplan_type.startsWith(matchType)) {
        return item;
      }
    });

    // get plan items.
    let planItems = matchedItems.filter((item: Model.WorkplanItem) => {
      if (!item.workplan_type.endsWith(WORKPLAN_TYPES.GP_ASSESSMENT)) {
        return item;
      }
    });
    // then sort it.
    planItems = _.sortBy(planItems, ['workplan_type']);

    // now build the ordered list.
    const orderedItems = [];
    _.forEach(planItems, item => {
      // build out assessment & plan pairs.
      const pairedItems = {
        workplan_type: item.workplan_type,
        plan: item,
        assessment: null,
      };
      const saType = `${item.workplan_type}${WORKPLAN_TYPES.GP_ASSESSMENT}`;
      const foundDx = _.findIndex(matchedItems, { workplan_type: saType });
      if (foundDx >= 0) {
        // assign assessment.
        pairedItems.assessment = matchedItems[foundDx];
      }
      orderedItems.push(pairedItems);
    });

    return orderedItems;
  }

  /**
   * Returns true if the GP workplan item is in valid state.
   * @static
   * @param {IWorkplanItem} item
   * @returns {boolean}
   */
  static isValidGPItem(item: IWorkplanItem): boolean {
    if (!item) {
      // item should be there.
      return false;
    }

    // at least one of the time period must be selected.
    if (!(item.year_1 || item.year_2 || item.year_3 || item.year_4)) {
      return false;
    }

    // If the current time period (year_1) is selected, the workplan details must be filled in.
    if (item.year_1) {
      if (!(item.major_activities && item.major_activities.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
        return false;
      }

      if (!(item.activity_1 && item.activity_1.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
        return false;
      }

      if (!(item.major_outcomes && item.major_outcomes.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
        return false;
      }

      if (!item.adoption_status_2) {
        return false;
      }
    }

    return true;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type WorkplanItem = IWorkplanItem;
  }
}
