import { ActionWithPayload } from '@app-libs';
import { Actions, Model } from '@app-ngrx-domains';
import { FUND_TYPES, WORKFLOWS_CONFIG, WORKFLOW_TYPES, WORKFLOW_STEPS } from '@app-consts';

/**
 * Current Workflow Action types
 */
const ACTION_PREFIX = 'CURRENT_WORKFLOW_';
export const CURRENT_WORKFLOW_ACTION_TYPES = {
  SET_ERROR: `${ACTION_PREFIX}SET_ERROR`,
  VALIDATE: `${ACTION_PREFIX}VALIDATE`,
  ADD_WORKFLOW_STEP_TO_SECTION: `${ACTION_PREFIX}ADD_WORKFLOW_STEP_TO_SECTION`,
};

/**
 * Current workflow action class.
 */
export class CurrentWorkflowActions {

  /**
   * Sets proposal workflow steps with the settings read in, and
   * makes workflow visible if set.
   *
   * @param {Proposal} p
   * @param {boolean} showWorkflow
   * @returns {Action}
   */
  setProposalSteps(p: any, showWorkflow: boolean): ActionWithPayload<any> {
    // Do sanity check on entry.
    if (!p || !p.project_type) {
      return {
        type: CURRENT_WORKFLOW_ACTION_TYPES.SET_ERROR,
        payload: 'undefined proposal'
      };
    }

    // set type of workflow we're building
    let workflowType = '';
    if (p.project_type === FUND_TYPES.SWP_L) {
      workflowType = WORKFLOW_TYPES.SWP_L;
    } else if (p.project_type === FUND_TYPES.SWP_R) {
      workflowType = WORKFLOW_TYPES.SWP_R;
    } else if (p.project_type === FUND_TYPES.IPLAN) {
      workflowType = WORKFLOW_TYPES.IPLAN;
    } else if (p.project_type === FUND_TYPES.GP) {
      workflowType = WORKFLOW_TYPES.GP;
    } else {
      return {
        type: CURRENT_WORKFLOW_ACTION_TYPES.SET_ERROR,
        payload: `unsupported fund id=${p.project_type}`
      };
    }

    // updates step's initial settings.
    const updateStep = (step: Model.WorkflowStep, extra?: any): Model.WorkflowStep => {
      const stateName = `${step.route}_state`;
      const stepRoute = step.route ? `/${step.route}` : '';
      if (extra) {
        return {
          ...step,
          ...extra,
          firstTouch: (p[stateName] === 'pristine'),
          routerLink: `${p.id}${stepRoute}`
        }
      } else {
        return {
          ...step,
          firstTouch: (p[stateName] === 'pristine'),
          routerLink: `${p.id}${stepRoute}`
        };
      }
    };

    // prepare workflow steps based on the type.
    const config = WORKFLOWS_CONFIG[workflowType];
    let steps: Array<Model.WorkflowStep> = [];

    switch (workflowType) {
      case WORKFLOW_TYPES.SWP_L:
      case WORKFLOW_TYPES.SWP_R: {
        steps = config.steps.map(step => {
          switch (step.route) {
            case WORKFLOW_STEPS.LMI:
              return updateStep(step, {
                hide: (p.all_sectors),
              });

            case WORKFLOW_STEPS.PREVIEW:
              return {
                ...step,
                routerLink: `${p.id}/${step.route}`
              };

            default:
              return updateStep(step);
          }
        });
        break;
      }

      case WORKFLOW_TYPES.GP:
      case WORKFLOW_TYPES.IPLAN: {
        steps = config.steps.map(step => {
          switch (step.route) {
            case WORKFLOW_STEPS.PREVIEW:
              return {
                ...step,
                routerLink: `${p.id}/${step.route}`
              };

            default:
              return updateStep(step);
          }
        });
        break;
      }
    }

    // set up and show fund's proposal workflow as current workflow.
    return Actions.Workflow.update(workflowType, {
      show: showWorkflow,
      steps: steps,
      baseLink: config.baseLink,
      name: workflowType,
      currentStep: undefined,
    });
  }

  /**
  * Sets up entity workflow steps, and displays it if requested.
  * @returns {Action}
  */
  setWorkflowSteps(
    settings: {
      workflowType: string,
      workflowPath: string,
      clientState?: any,
      show: boolean,
      baseLink: string,
      config?: Model.Workflow,
      additionalConfig?: {[step: string]: any},
      components?: Array<Model.WorkflowComponent>,
      isReview?: boolean,
      title?: string,
      parent?: {
        name: string,
        title: string,
        route: string,
      }
    }, isCurrent = true): ActionWithPayload<any> {
    // prepares workflow steps based on the type.
    const config = settings.config ? settings.config : WORKFLOWS_CONFIG[settings.workflowType];

    const steps = config.steps.map(step => {
      switch (step.route) {
        case WORKFLOW_STEPS.PREVIEW:
          return {
            ...step,
            routerLink: `${settings.workflowPath}/${step.route}`
          };

        default: {
          let adjusted: Model.WorkflowStep;

          if (settings.workflowType === WORKFLOW_TYPES.AEBG_MEMBER_PROJECT) {
            const stateName = `${step.route}_state`;
            adjusted = {
              ...step,
              firstTouch: settings.clientState ? (settings.clientState[stateName] === 'pristine') : false,
              routerLink: `${settings.workflowPath}/${step.route}`
            };
          } else {
            const stateName = `${step.route}_dirty`;
            adjusted = {
              ...step,
              firstTouch: settings.clientState ? !settings.clientState[stateName] : false,
              routerLink: `${settings.workflowPath}/${step.route}`
            };
          }

          // apply any additional configurations to given step, if available.
          if (settings.additionalConfig && settings.additionalConfig[step.route]) {
            adjusted = {
              ...adjusted,
              ...settings.additionalConfig[step.route],
            }
          }

          // associate component to the workflow step if found.
          if (settings.components) {
            const match = settings.components.find(c => {
              if (step.custom) {
                return c.path === WORKFLOW_STEPS.CUSTOM;
              }
              const segments = c.path.split('/');
              return segments[segments.length - 1] === step.route;
            });
            if (match) {
              adjusted.component = match.component;
            }
          }

          return adjusted;
        }
      }
    });

    // set up and show given workflow as the current workflow if set.
    const templateName  = !!config.templateName ? config.templateName : settings.workflowType;
    return Actions.Workflow.update(settings.workflowType, {
      show: settings.show,
      steps: steps,
      currentStep: undefined,
      baseLink: settings.baseLink,
      name: settings.workflowType,
      isReview: settings.isReview,
      title: settings.title,
      parent: settings.parent,
      templateName,
    }, isCurrent);
  }

  /** Validate step data for one or all steps
   * @param {string} route or WORKFLOW_STEPS.ALL
   * @returns {Action}
   */
  validate(step: string): ActionWithPayload<any> {
    return {
      type: CURRENT_WORKFLOW_ACTION_TYPES.VALIDATE,
      payload: step,
    };
  }

  /** Based on current step in the workflow, routes user to next step.
   * @param {string} current route
   * @returns {Action}
   */
  gotoNext(currentStep: string): ActionWithPayload<any> {
    return Actions.Workflow.gotoNext(WORKFLOW_TYPES.CURRENT, currentStep);
  }

  /**
   * Sets given workflow type as the current workflow, and makes it visible.
   * @param workflowType
   * @param show
   * @param currentStep
   */
  setCurrentWorkflow(workflowType: string, show: boolean, currentStep?: string): ActionWithPayload<any> {
    return Actions.Workflow.updateCurrent(workflowType, show, currentStep);
  }

  /**
   * Adds workflow step to section steps.
   * @param sectionName
   * @param config
   */
  addWorkflowStepToSection(sectionName: string, config: {
    workflow: Model.Workflow,
    title: string,
    args?: Array<{name: string; value: any}>
  }) {
    return {
      type: CURRENT_WORKFLOW_ACTION_TYPES.ADD_WORKFLOW_STEP_TO_SECTION,
      payload: { sectionName, config },
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.CurrentWorkflow = new CurrentWorkflowActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    CurrentWorkflow: CurrentWorkflowActions;
  }
}
