import {ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ResultsListItemComponent} from '../';
import {InputRefDirective} from '../../directives';
import * as _ from 'lodash';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { Model } from '@app-ngrx-domains';

@Component({
  selector: 'results-list',
  templateUrl: './results-list.component.html'
})
export class ResultsListComponent implements OnInit, OnDestroy {

  @Input() options: Model.SelectOption[] = [];
  @Input() disabledOptions: number[] = [];
  @Input() searchValue = '';
  @Input() sortByLabel: boolean = false;
  @Input() multiSelect = false;
  @Input() selectOnce = false;
  @Input() selectedOption: Model.SelectOption = null;
  @Input() selectedOptions: Model.SelectOption[] = null;
  @Input() input: InputRefDirective;
  @Input() hostType: string;

  @Output() optionSelected = new EventEmitter<Model.SelectOption>();

  @ViewChild('resultsScroller') resultsScroller;

  resultsQueryList: QueryList<ResultsListItemComponent>;
  private destroy$: Subject<boolean> = new Subject();

  @ViewChildren(forwardRef(() => ResultsListItemComponent)) set results(value) {
    this.resultsQueryList = value;
    this.initializeList();
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    // Note there is an assumption that in the case of selects with editable/variable
    // selects will re-sort their options because this sort only happens on init
    if (this.options && this.options.length > 1 && this.sortByLabel) {
      this.options = this.options.slice().sort((a, b) => a.label < b.label ? -1 : 1);
    }

    this.input.keyUpSubject.pipe(
      takeUntil(this.destroy$)
    ).subscribe(value => {
      this.handleKeyUp(value);
    });

    if (this.multiSelect) {
      this.input.control.valueChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe( value => {
        this.resetScroller();
      });
    }
  }

  initializeList() {
    if (this.resultsQueryList && this.resultsQueryList.length > 0) {
      if (this.multiSelect && this.selectedOptions.length > 0) {
        this.selectedOptions.forEach(option => {
          const selectedItem = this.resultsQueryList.find( item => {
            return option.value === item.option.value;
          });

          if (selectedItem) {
            selectedItem.selected = true;
          }
        });
      }

      this.resultsQueryList.forEach( item => {
        item.active = false;
        item.selected = !!this.disabledOptions.find(id => id === item.option.value) ? true : item.selected;
      });

      const firstActiveItem = this.resultsQueryList.find( item => {
        return !item.selected;
      });

      if (firstActiveItem) {
        firstActiveItem.active = true;
        this.scrollToItem(firstActiveItem, true);
      }
    }
    this.cdRef.detectChanges();
  }

  foundInSearch(option) {
    if (this.hostType === 'select') {
      if (this.searchValue.length < 1) {
        return true;
      }

      const initialRegexp = /([a-zA-Z0-9._-]+)/g;
      const wordMatches = this.searchValue.match(initialRegexp);

      if (!!wordMatches) {
        return wordMatches.some(word => {
          return option.label.toLowerCase().search(word.toLowerCase()) >= 0;
        });
      }
      return null;
    } else if (this.hostType === 'search') {
      return true;
    }

    return true;
  }

  showResultsList() {
    if (this.hostType === 'search') {
      return this.searchValue.length >= 2 && !this.selectedOption && this.input.focus;
    } else if (this.hostType === 'select') {
      if (this.selectOnce) {
        return this.input.focus;
      } else {
        return !this.selectedOption && this.input.focus;
      }
    }
  }

  showNoResults() {
    if (this.hostType === 'select') {
      return this.resultsQueryList && this.resultsQueryList.length === 0;
    } else if (this.hostType === 'search') {
      return this.options.length === 0;
    }
  }

  optionSelectedClick(item: ResultsListItemComponent) {
    if (!item.selected) {
      this.optionSelected.emit(item.option);
    }
  }

  handleMouseOver(item: ResultsListItemComponent) {
    if (!item.selected) {
      this.resultsQueryList.forEach(listItem => {
        listItem.active = false;
      });
      item.active = true;
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if (!event) {
      return;
    }

    let shouldNavigate = false;
    if (this.selectOnce) {
      shouldNavigate = this.input.focus && (this.options && this.options.length > 0);
    } else {
      shouldNavigate = !this.selectedOption && this.input.focus && (this.options && this.options.length > 0);
    }

    if (shouldNavigate) {
      switch (event.keyCode) {
        case 40: // down
          this.handleDownKey();
          break;
        case 38: // up
          this.handleUpKey();
          keepCursorAtEnd(this.input.element);
          break;
        case 13: // enter
          this.handleEnterKey();
          break;
        case 27: // esc
          this.reset(false);
          break;
        default:
          break;
      }
    }

    function keepCursorAtEnd(input: HTMLInputElement) {
      input.selectionStart = input.value.length;
      input.selectionEnd = input.value.length;
    }
  }

  findActiveItem(): ResultsListItemComponent {
    return this.resultsQueryList.find((item) => {
      return item.active;
    });
  }

  handleEnterKey() {
    const activeItem = this.findActiveItem();
    if (!activeItem) {
      return;
    }

    activeItem.selected = true;
    this.optionSelected.emit(activeItem.option);
  }

  handleDownKey() {
    const nextItem = this.findNextItem();
    if (nextItem) {
      this.resultsQueryList.forEach(item => {
        item.active = false;
      });
      nextItem.active = true;

      this.scrollToItem(nextItem, true);
    }
  }

  findNextItem(): ResultsListItemComponent {
    const activeItem = this.findActiveItem();
    if (!activeItem) {
      return;
    }

    return this.resultsQueryList.find((item) => {
      return !item.active && item.index > activeItem.index && !item.selected;
    });
  }

  handleUpKey() {
    const previousItem: ResultsListItemComponent = this.findPreviousItem();
    if (previousItem) {
      this.resultsQueryList.forEach(item => {
        item.active = false;
      });
      previousItem.active = true;

      this.scrollToItem(previousItem, false);
    }
  }

  findPreviousItem(): ResultsListItemComponent {
    const activeItem = this.findActiveItem();
    if (!activeItem) {
      return;
    }

    const list = this.resultsQueryList.filter( item => {
      return item.index < activeItem.index;
    });

    return _.findLast(list, item => {
      return !item.selected;
    });
  }

  scrollToItem(activeItem: ResultsListItemComponent, navigatingDown: boolean) {
    if (!this.resultsQueryList)  {
      return;
    }

    setTimeout(() => {
      const activeElement = activeItem.element;

      if (activeElement && !!this.resultsScroller) {
        const activeElementHeight = activeElement.offsetHeight;
        const scrollerHeight = this.resultsScroller.nativeElement.offsetHeight;
        const activeElementTop = activeElement.offsetTop;
        const scrollTop = this.resultsScroller.nativeElement.scrollTop;

        // Element outside scroll window when navigating down?
        if (navigatingDown && activeElementTop >= scrollTop + scrollerHeight - activeElementHeight) {
          this.resultsScroller.nativeElement.scrollTop = activeElementTop - scrollerHeight + activeElementHeight;
        }

        // Element outside scroll window when navigating up?
        if (!navigatingDown && activeElementTop <= scrollTop) {
          this.resultsScroller.nativeElement.scrollTop = activeElementTop;
        }
      }

    }, 0);
  }

  resetScroller() {
    setTimeout(() => {
      if (!!this.resultsScroller) {
        this.resultsScroller.nativeElement.scrollTop = 0;
      }
    }, 0);
  }

  reset(focus: boolean) {
    this.searchValue = '';
    focus ? this.input.element.focus() : this.input.element.blur();
  }

  trackById(index, item) {
    return item.value;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }


}
