import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';
import { Model } from '@app-ngrx-domains';
import { LookupService } from '@app-services';
import { SYSTEM_ROLES } from '@app-consts';
import { find, uniq } from 'lodash';
import { UserRoleScope } from '@app-models';

@Component({
  selector: 'app-user-role-card',
  templateUrl: './user-role-card.component.html',
})
export class UserRoleCardComponent implements OnInit {
  @Input() count: number;
  @Input() canEditRole: boolean;
  @Input() userRole: {
    role_id: number,
    name: string,
    data: Model.RolePermissions,
    roleScopes: Array<Model.UserRoleScope>
  };
  @Output() removeClicked: EventEmitter<any> = new EventEmitter();

  public roleAliases: Array<string>;
  public showSectorColumn: boolean = false;

  constructor(
    private lookupService: LookupService,
  ) { }

  ngOnInit() {
    this.gatherAliases();
    this.showSectorColumn = !!this.userRole.data.sector;
  }

  private gatherAliases() {
    const role_data = find(SYSTEM_ROLES, sys_role => sys_role.ID === this.userRole.role_id);
    if (role_data && role_data.ALIASES) {
      this.roleAliases = uniq(Object.values(role_data.ALIASES));
    }
  }

  public formatDomQaIdFromRoleName(): string {
    return 'qa_' + this.userRole.name.toLowerCase().split(' ').join('_').replace('&', 'and') + '_card_title';
  };


  public expandAliases() {
    return this.roleAliases.join(', ');
  }

  public removeRoleScope(roleScope: Model.UserRoleScope) {
    const removeRoleScopeScope = {
      role_id: roleScope.role_id,
    };
    if (roleScope.fund_id) {
      removeRoleScopeScope['fund_id'] = roleScope.fund_id;
    }
    if (roleScope.institution_id) {
      removeRoleScopeScope['institution_id'] = roleScope.institution_id;
    }
    if (roleScope.sector_id) {
      removeRoleScopeScope['sector_id'] = roleScope.sector_id;
    }

    this.removeClicked.emit(removeRoleScopeScope);
  }

  public fundName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    return this.lookupService.getRoleScopeFundName(roleData, roleScope);
  }

  public institutionName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    return UserRoleScope.institutionName(roleData, roleScope);
  }

  public sectorName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    return UserRoleScope.sectorName(roleData, roleScope);
  }

  public expirationDate(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    return UserRoleScope.expirationDate(roleData, roleScope);
  }


}
