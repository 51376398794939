import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Actions } from '@app-ngrx-domains';
import { PAGE_TYPES } from '../../consts/index';

@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.component.html',
})
export class MaintenanceComponent implements OnInit, OnDestroy {
    constructor(
        private sotre: Store<State>
    ) { }

    ngOnInit() {
        this.sotre.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
    }

    ngOnDestroy() {
        this.sotre.dispatch(Actions.Layout.clearPageType());
    }
}
