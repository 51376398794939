
import {forkJoin as observableForkJoin } from 'rxjs';

import {take, skipWhile} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ValidatorsEx } from '@app-utilities';
import { ApiService, ProgramService } from '@app-services';
import { PAGE_TYPES, ROUTER_LINKS } from '@app/core/consts';
import { EnumErrorTypes } from '@app-models';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html'
})
export class SignupComponent implements OnInit, OnDestroy {
  form: FormGroup;

  isLegacyTask: boolean = true;
  context_path: string; // Encoded signup_context
  signup_context: any; // Contains info based on the context_path parameter (roles to provide, extra instruction, etc.)
  invited_email: string;
  statusMessage: Model.StatusMessage = {
    message: undefined,
    type: undefined
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<State>,
    private _fb: FormBuilder,
    private apiService: ApiService,
    private programService: ProgramService
  ) {
    this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
  }

  ngOnInit() {
    // Verify path is valid for sign-up
    this.context_path = this.route.snapshot.params['contextPath'];
    return this.apiService.getSignupContext(this.context_path).subscribe(
      (res) => {
        this.signup_context = res;
        this.initForm();
      },
      (err) => {
        // Unsupported context path, redirect user to login page
        this.store.dispatch(Actions.App.showAlert(`The sign-up link you used cannot be verified.
          Please try again or visit the NOVA Helpdesk if the problem persists.`));
        this.store.dispatch(Actions.Auth.logout([ROUTER_LINKS.LOGIN]));
      }
    );
  }

  get loginLink() {
    const context_redirect = this.signup_context ? this.signup_context.redirect_url : undefined;
    return context_redirect ? `/${context_redirect}` : ROUTER_LINKS.LOGIN;
  }

  initForm() {
    this.form = this._fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email_address: ['', [ValidatorsEx.email]]
    });
  }

  ngOnDestroy() {
    this.store.dispatch(Actions.Layout.clearPageType());
  }

  sendInvitation() {
    const profile = {
      first_name: this.form.get('first_name').value,
      last_name: this.form.get('last_name').value,
      email_address: this.form.get('email_address').value
    };
    this.statusMessage.message = 'Creating account';
    this.statusMessage.type = 'loading';

    this.apiService.signupUser(profile, this.context_path)
      .subscribe(
        (res) => {
          this.invited_email = profile.email_address;
        },
        (err) => {
          this.statusMessage.type = 'fail';
          if (err.status < 500 && err.error && err.error.message) {
            // Client Error
            this.statusMessage.message = err.error.message;
          } else {
            // Service Error
            this.statusMessage.message = 'There was a problem creating your account. Please try again.';
            this.store.dispatch(Actions.App.setError({
              type: EnumErrorTypes.api,
              location: this.constructor.name,
              show: false,
              raw: err
            }));
          }
        }
      );
  }

  /**
   * If this context token has actions that need to be performed for exisitng users as well,
   * wait for the user to log in, then comlete those actions.
   */
  setPendingActions() {
    if (this.signup_context && this.signup_context.tasks) {
      this.store.select(Queries.Auth.getCurrentUserId).pipe(
        skipWhile(user_id => !user_id),
        take(1)
      ).subscribe(user_id => {
        observableForkJoin(this.signup_context.tasks.map(task => {
          return this.apiService.createTask({ ...task, user_id });
        })).subscribe(() => {
          this.store.dispatch(Actions.Auth.login()); // To refetch the user (and it's tasks);
        });
      });
    }
  }

  shouldDisableSubmit() {
    return !this.form.valid || this.statusMessage.type === 'loading';
  }
}
