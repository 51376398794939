import { AfterContentInit, AfterViewInit, Component, ContentChild, Input, OnDestroy, HostBinding, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { InputRefDirective } from '../../directives';
import { Logger } from '../../../app-logger';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'radio-check',
  templateUrl: './radio-check.component.html'
})
export class RadioCheckComponent implements AfterContentInit, AfterViewInit, OnDestroy {

  @HostBinding('attr.data-invalid') invalidState = 'false';
  @ContentChild(InputRefDirective, {static: true}) input: InputRefDirective;

  @Input() labelText: string;
  @Input() labelFootnote: { symbol: 'string', text: 'string' };
  @Input() labelHidden = false;
  @Input() errorsOnUntouched = false;
  @Input() canEdit = true;
  @Input() readOnlyType: string;

  @ViewChild('checked', { static: true }) checkedTmpl: TemplateRef<any>;
  @ViewChild('unchecked', { static: true }) uncheckedTmpl: TemplateRef<any>;
  @ViewChild('bullet', { static: true }) bulletTmpl: TemplateRef<any>;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  isCheckbox: boolean; // Used to disable show-errors and data-invalid attributes for radio buttons
  private readOnlyTypes = {
    yesNo: 'yesno',
    checkedUnchecked: 'checked-unchecked',
    checked: 'check',
    bullet: 'bullet',
    noText: 'no-text'
  };
  private destroy$: Subject<boolean> = new Subject();

  ngAfterContentInit() {
    if (!this.input) {
      Logger.error('Input required in radio-check component. If input is present, add inputRef directive to element.');
    }

    this.isCheckbox = this.input.element.type === 'checkbox';
  }

  ngAfterViewInit() {
    if (!this.input.element.id) {
      Logger.error('An input with a unique ID is required in radio-check component:');
      Logger.log(this.input);
    }

    if (!this.labelText) {
      Logger.error('labelText input required in radio-check component:');
      Logger.log(this.input);
    }

    if (this.input && this.labelText && this.labelHidden) {
      this.input.element.setAttribute('aria-label', this.labelText);
    }

    this.checkValidation();
    this.input.control.statusChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.checkValidation();
    });
  }

  private checkValidation() {
    const isInvalid = this.input.control.invalid ? 'true' : 'false';
    this.input.element.setAttribute('aria-invalid', isInvalid);

    if (this.isCheckbox) {
      // SetTimeout to avoid expressionChangedAfterChecked error
      setTimeout(() => {
        this.invalidState = isInvalid;
        this.cdr.markForCheck();
      }, 0);
    }
  }

  showReadOnlyText() {
    if (this.canEdit) {
      return false;
    }

    return this.isChecked || [
      this.readOnlyTypes.yesNo,
      this.readOnlyTypes.checkedUnchecked,
      this.readOnlyTypes.noText].includes(this.readOnlyType);
  }

  getReadOnlyText() {
    const checked = this.isChecked;

    if (this.input.element.type === 'checkbox') {
      switch (this.readOnlyType) {
        case this.readOnlyTypes.noText: return null;
        case this.readOnlyTypes.yesNo: {
          let response: string;
          if (checked === undefined || checked === null) {
            response = 'Not Entered';
          } else {
            response = checked ? 'Yes' : 'No';
          }
          return this.labelHidden ? `${response}` : `${this.labelText} <br> ${response}`;
        }
        case this.readOnlyTypes.checkedUnchecked:
        case this.readOnlyTypes.checked:
        case this.readOnlyTypes.bullet:
        default: {
          return this.labelHidden ? '' : this.labelText;
        }
      }
    } else {
      return this.labelText;
    }
  }

  getReadOnlySymbol() {
    if (this.input.element.type === 'checkbox') {
      switch (this.readOnlyType) {
        case this.readOnlyTypes.yesNo: {
          return undefined;
        }
        case this.readOnlyTypes.noText:
        case this.readOnlyTypes.checkedUnchecked: {
          return this.isChecked ? this.checkedTmpl : this.uncheckedTmpl;
        }
        case this.readOnlyTypes.checked: {
          return this.checkedTmpl;
        }
        case this.readOnlyTypes.bullet:
        default: {
          return this.bulletTmpl;
        }
      }
    }
  }

  get isChecked() {
    return this.input.element.checked;
  }

  get required() {
    const value = this.input.element.getAttribute('aria-required');
    return value && value !== 'false';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
