import { IProgramAllocationsState, Queries, Model, combineRootFactory, Query } from '@app-ngrx-domains';

const fromRoot = combineRootFactory<IProgramAllocationsState>('ProgramAllocations');

export interface IProgramAllocationsQueries {
  getAllocations: Query<{ [key: string]: Model.Allocation[] }>;
  getAllocationsByKey: (key: string) => Query<Model.Allocation[]>;
  getCarryovers: Query<{ [key: string]: Model.AllocationCarryover[] }>;
  getCarryoversByKey: (key) => Query<Model.AllocationCarryover[]>;
  getTasks: Query<{ [key: string]: Model.Task[] }>;
  getTasksByKey: (key) => Query<Model.Task[]>;
  getProposals: Query<{ [key: string]: Model.ProposalItem[] }>;
  getPropsalsByKey: (key) => Query<Model.ProposalItem[]>;

  getFilterStash: Query<any>;
}

Queries.ProgramAllocations = {
  getAllocations: fromRoot(state => state.allocations),
  getAllocationsByKey: (key) => fromRoot(state => state.allocations[key] || []),
  getCarryovers: fromRoot(state => state.carryovers),
  getCarryoversByKey: (key) => fromRoot(state => state.carryovers[key] || []),
  getTasks: fromRoot(state => state.tasks),
  getTasksByKey: (key) => fromRoot(state => state.tasks[key] || []),
  getProposals: fromRoot(state => state.proposals),
  getPropsalsByKey: (key) => fromRoot(state => state.proposals[key] || []),

  getFilterStash: fromRoot(state => state.filterStash)
};

declare module '@app-ngrx-domains' {
  interface Root {
    ProgramAllocations: Query<IProgramAllocationsState>;
  }

  interface Queries {
    ProgramAllocations: IProgramAllocationsQueries;
  }
}