import { IMetricsExState, State, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { METRICSEX_ACTION_TYPES } from './metrics-ex.action';
import { OTHER_METRIC_ID } from '@app-consts';

export function MetricsExReducer(state: IMetricsExState = State.MetricsEx, action: ActionWithPayload<any>): IMetricsExState {

  const unique_keys = ['metric_definition_id', 'proposal_id', 'lmi_program_id', 'impacted_group_id'];
  const scrubKeys = (metric: any): any => {
    return unique_keys.reduce((obj, key) => {
      obj[key] = metric[key] || null;
      return obj;
    }, {});
  }
  const isEQ = (src: Model.MetricItem, dest: Model.MetricItem): boolean => {
    const l1 = scrubKeys(src);
    const r1 = scrubKeys(dest)
    return l1.metric_definition_id === r1.metric_definition_id
      && l1.impacted_group_id === r1.impacted_group_id
      && l1.lmi_program_id === r1.lmi_program_id
      && l1.proposal_id === r1.proposal_id;
  }

  switch (action.type) {

    case METRICSEX_ACTION_TYPES.LOAD: {
      return action.payload;
    }

    case METRICSEX_ACTION_TYPES.CREATE_METRIC: {
      const item = action.payload;
      return [...state, item];
    }

    case METRICSEX_ACTION_TYPES.CREATE_METRICS: {
      const items = action.payload;
      return [...state, ...items];
    }

    case METRICSEX_ACTION_TYPES.UPSERT_METRIC_SUCCESS: {
      const item = action.payload.item;
      const dx = (item.metric_definition_id === OTHER_METRIC_ID) ? state.findIndex(m => m.id === item.id) : state.findIndex(m => isEQ(m, item));
      if (dx >= 0) {
        return [
          ...state.slice(0, dx),
          {
            ...state[dx],
            ...item,
          },
          ...state.slice(dx + 1)
        ];
      } else {
        return [
          ...state,
          {
            ...item,
          }
        ];
      }
    }

    case METRICSEX_ACTION_TYPES.DELETE_METRIC_SUCCESS: {
      const item = action.payload;
      const dx = state.findIndex(m => isEQ(m, item));
      if (dx >= 0) {
        return [
          ...state.slice(0, dx),
          ...state.slice(dx + 1)
        ];
      } else {
        return state;
      }
    }

    case METRICSEX_ACTION_TYPES.DELETE_LMI_PROGRAM_METRICS_SUCCESS: {
      const deletedlmiProgramIds = action.payload;
      const reduced = state.filter(m => !deletedlmiProgramIds.includes(m.lmi_program_id));
      return reduced;
    }

    default:
      return state;
  }
}
