import { IMetricsState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { METRICS_ACTION_TYPES } from './metrics.action';

export function MetricsReducer(state: IMetricsState = State.Metrics, action: ActionWithPayload<any>): IMetricsState {
  switch (action.type) {

    case METRICS_ACTION_TYPES.RESET:
    case METRICS_ACTION_TYPES.LOAD_LVG_METRICS:
    case METRICS_ACTION_TYPES.LOAD_GP_METRICS: {
      return State.Metrics;
    }

    case METRICS_ACTION_TYPES.LOAD_METRICS_SUCCESS: {
      const { metricType, metrics } = action.payload;

      return {
        ...state,
        [`${metricType}_metrics`]: metrics
      }
    }

    default:
      return state;
  }
}
