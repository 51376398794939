import { IAppState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { APP_ACTION_TYPES } from './app.action';

export function AppReducer(state: IAppState = State.App, action: ActionWithPayload<any>): IAppState {
  switch (action.type) {

    case APP_ACTION_TYPES.SET_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }

    default:
      return state;
  }
}
