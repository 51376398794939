import { IBudgetState, Actions, State, Model } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { BUDGET_ACTION_TYPES } from './budget.action';

export function BudgetReducer(state: IBudgetState = State.Budget, action: ActionWithPayload<any>): IBudgetState {
  switch (action.type) {
    case BUDGET_ACTION_TYPES.LOAD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case BUDGET_ACTION_TYPES.LOAD_ALLOCATIONS: {
      return {
        ...state,
        allocations: action.payload,
      };
    }

    case BUDGET_ACTION_TYPES.ADD_ITEM_SUCCESS: {
      // append new item at end of array.
      const reducedItems = state.items.slice();
      reducedItems.push(action.payload);

      return {
        ...state,
        items: reducedItems,
      };
    }

    case BUDGET_ACTION_TYPES.DELETE_ITEM_SUCCESS: {
      // remove item from the list
      const reducedItems = state.items
        .filter((item, index) => {
          return item.id !== action.payload;
        });

      return {
        ...state,
        items: reducedItems
      };
    }

    case BUDGET_ACTION_TYPES.UPDATE_ITEM_SUCCESS: {
      // update the item.
      const reducedItems = state.items.map((item, index) => {
        if (item.id === action.payload.id) {
          return action.payload;
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: reducedItems
      };
    }

    default:
      return state;
  }
}
