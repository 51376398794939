import { ISystemState, Model, Query, Queries, combineRootFactory } from '@app-ngrx-domains';

const fromRoot = combineRootFactory<ISystemState>('System');

export interface ISystemQueries {
  get: Query<ISystemState>;
  getMaintenanceStatus: Query<Model.Maintenance>
}

Queries.System = {
  get: fromRoot(state => state),
  getMaintenanceStatus: fromRoot(state => {
    return state.maintenanceStatus
  })
};

declare module '@app-ngrx-domains' {
  interface Root {
    System: Query<ISystemState>;
  }

  interface Queries {
    System: ISystemQueries
  }
}
