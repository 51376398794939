import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.ProposalReports = {
  program_area_reports_loaded: false,
  program_area_reports: [],
  surveys: []
};


/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IProposalReportsState {
    program_area_reports_loaded: boolean;
    program_area_reports: Model.ProgramAreaReport[];
    surveys: Model.LegacyProposalReport[];
  }

  interface State {
    ProposalReports: IProposalReportsState;
  }
}
