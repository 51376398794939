// modules
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule, CurrencyPipe, DecimalPipe, DatePipe, PercentPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgIdleModule } from '@ng-idle/core';
import { get, includes as _includes } from 'lodash';
import * as Sentry from '@sentry/browser';

import { environment } from '../environments/environment';
import { BROWSER_UA_BLACKLIST } from './core/consts/core.const';
import { CLIENT_VERSION } from './core/consts/version.const';

// sentry.io
if (!environment.disableSentry) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.name,
    release: CLIENT_VERSION,
    beforeSend: (event) => {
      // Skip sending event if originates from blacklisted browsers, e.g. IE11
      if (_includes(BROWSER_UA_BLACKLIST, event.request.headers['User-Agent'])) {
        return null;
      }

      const statusCode = get(event, 'extra.Status');
      if ([401, 403, 404].includes(statusCode)) {
        return null;
      }

      return event;
    },
  });

  Sentry.configureScope((scope) => {
    scope.setTag('component', 'client');
  });
}

// our custom error handler
import { AppErrorHandler } from './app-error-handler';

// logger
import { LoggerService } from 'ng-logger';

// shared modules
import { GenericModule } from './shared.generic/generic.module';
import { ProjectModule } from './shared.project/project.module';

// services
import { CoreServices } from './core/services';

// interceptors
import { TokenInterceptor, SentryInterceptor } from './core/interceptors/api.interceptor';

// components
import { AppComponent } from './app.component';
import { CoreComponents } from './core/components';
import { TestComponents } from './core/components/test';

// routing
import { routes } from './app.routes';

// redux state-management
import './core/state-management';
import { CoreEffects } from './core/state-management';
import { CoreGuards } from './core/guards';

import './shared.project/state-management';
import { ProjectEffects } from './shared.project/state-management';
import { ProjectGuards } from './shared.project/guards';

import { SettingsGuards } from './shared.settings/guards';

// redux domain imports
import { DOMAIN_REDUCER_TOKEN, domainReducers } from './app.reducer';
import { PercentTransform, PhoneTransform, PhoneExtensionTransform } from './shared.generic/pipes';

// HelpDesk Widget
import { HelpDeskWidgetModule } from '@productops/helpdesk-widget';

// conditionally add dev tools
const devToolsImportCollection = environment.production ? [] :
  [
    // To use the debugger, install the Redux Devtools extension for either -- Instruments the store retaining past
    // versions of state and recalculating new states.This enables powerful time- travel debugging.
    StoreDevtoolsModule.instrument({
      maxAge: 5
    })
  ];

@NgModule({
  declarations: [
    AppComponent,
    ...CoreComponents,
    ...TestComponents
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', paramsInheritanceStrategy: 'always' }), // Depends on `runGuardsAndResolvers` set on individual routes
    CommonModule,

    NgIdleModule.forRoot(),

    StoreModule.forRoot(DOMAIN_REDUCER_TOKEN, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([
      ...CoreEffects,
      ...ProjectEffects,
    ]),
    ...devToolsImportCollection,

    // shared modules
    GenericModule,
    ProjectModule,
    HelpDeskWidgetModule.forRoot({
      apiUrl: environment.helpdeskApiUrl
    })
  ],
  providers: [
    ...CoreServices,
    ...CoreGuards,
    ...ProjectGuards,
    ...SettingsGuards,

    CurrencyPipe,
    DecimalPipe,
    DatePipe,
    PercentPipe,
    PercentTransform,
    PhoneTransform,
    PhoneExtensionTransform,
    LoggerService,
    Title,

    // interceptors
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SentryInterceptor, multi: true },

    // custom error handler
    { provide: ErrorHandler, useClass: AppErrorHandler },

    // delay binding to reducers
    { provide: DOMAIN_REDUCER_TOKEN, useFactory: domainReducers }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
