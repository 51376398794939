import { State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.FiscalReports = {
  items: [],
  submitStatus: [],
  proposal_report: undefined,

  reportLoaded: undefined,
  reportingInfo: []
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IFiscalReportsState {
    items: Array<Model.FiscalReport>;
    submitStatus: Array<Model.FiscalReportStatus>;
    proposal_report: Model.FiscalReport;

    reportLoaded: boolean;
    reportingInfo: Array<Model.FiscalReportInfo>;
  }

  interface State {
    FiscalReports: IFiscalReportsState;
  }
}
