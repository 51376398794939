import { Model } from '@app-ngrx-domains';

export enum FilterType {
  Single,
  Multi,
  Text,
  TypeAhead,
  Id,
}

export const FILTER_ALL_MATCH_OPTION: Model.SelectOption = {
  label: '-----',
  value: null
}

interface IFilter {
  field: string, // Expect this field on all rows
  name: string, // instead of constant inputs for filter names, it goes here,
  type: FilterType, // of FILTER_TYPES
  /* Optional Fields */
  labelField?: string, // When building options from a joined attribute, use this field as the label (ex: 'name')
  valueField?: string, // When building options from a joined attribute, use this field as the value (ex: 'id')
  defaultOption?: Model.SelectOption; // Default the filter to this option (adds option label + value to select options)
  includeRegions?: boolean; // Include list of regions to select options (uses special logic for handling regions)
  selectOnce?: boolean;
  selectOptions?: Array<Model.SelectOption>;
  placeholder?: string;
  typeAheadFilter?: any; // Additional filtering applied to typeahead searches
}

export class Filter implements IFilter {
  public field: string;
  public name: string;
  public type: FilterType;

  public labelField?: string;
  public valueField?: string;
  public defaultOption?: Model.SelectOption;
  public includeRegions?: boolean;
  public selectOnce?: boolean;
  public selectOptions?: Array<Model.SelectOption>;
  public placeholder?: string;
  public typeAheadFilter?: any;
  public sortDescending?: boolean;

  constructor (raw?: IFilter) {
    this.field = raw.field;
    this.name = raw.name;
    this.type = raw.type;

    this.labelField = raw.labelField;
    this.valueField = raw.valueField;
    this.defaultOption = raw.defaultOption;
    this.includeRegions = raw.includeRegions;
    this.selectOnce = raw.selectOnce;
    this.selectOptions = raw.selectOptions;
    this.placeholder = raw.placeholder;
    this.typeAheadFilter = raw.typeAheadFilter;
  }
}
