import { IEntitiesState, State, Model } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { ENTITIES_ACTION_TYPES } from './entities.action';

/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function EntitiesReducer(state: IEntitiesState = State.Entities, action: ActionWithPayload<any>): IEntitiesState {
  switch (action.type) {
    case ENTITIES_ACTION_TYPES.LOAD:
      return {
        ...action.payload,
      };

    case ENTITIES_ACTION_TYPES.SET_OWNER_LOGO:
      return {
        ...state,
        lead: {
          ...state.lead,
          institution: {
            ...state.lead.institution,
            logo_url: action.payload,
          }
        },
      };

    case ENTITIES_ACTION_TYPES.UPDATE_INFO_SUCCESS: {

      // update lead's info.
      const updateLeadInfo = () => {
        return {
          ...state,
          lead: {
            ...state.lead,
            institution: {
              ...state.lead.institution,
              ...action.payload.info,
            }
          },
        };
      };

      // update member's info accross all years.
      const updateMemberInfo = () => {
        const members = {};
        _.forOwn(state.members, (entities, key_duration_id) => {
          const dx = _.findIndex(entities, { institution_id: action.payload.institution_id });
          if (dx >= 0) {
            members[key_duration_id] = [
              ...entities.slice(0, dx),
              {
                ...entities[dx],
                institution: {
                  ...entities[dx].institution,
                  ...action.payload.info,
                }
              },
              ...entities.slice(dx + 1),
            ];
          } else {
            members[key_duration_id] = entities;
          }
        });

        return {
          ...state,
          members: members
        };
      };

      // update the lead or member's info based on the id.
      return (state.lead.institution_id === action.payload.institution_id) ? updateLeadInfo() : updateMemberInfo();
    }

    case ENTITIES_ACTION_TYPES.ADD_MEMBER_SUCCESS: {
      const duration_id = action.payload.duration_id;
      let members = state.members[duration_id];
      const new_member = action.payload;
      if (members) {
        members = members.filter(member => member.institution_id !== new_member.institution_id);
        members = _.sortBy(members.concat(new_member), ['institution.name']);
      } else {
        members = [new_member];
      }

      return {
        ...state,
        members: {
          ...state.members,
          [duration_id]: members,
        }
      };
    }

    case ENTITIES_ACTION_TYPES.REMOVE_MEMBER_SUCCESS: {
      const duration_id = action.payload.duration_id;
      let entities = [];
      const dx = _.findIndex(state.members[duration_id], { institution_id: action.payload.memberInstitutionId });
      if (dx >= 0) {
        entities = [
          ...state.members[duration_id].slice(0, dx),
          ...state.members[duration_id].slice(dx + 1),
        ];
      } else {
        entities = [...state.members[duration_id]];
      }

      return {
        ...state,
        members: {
          ...state.members,
          [duration_id]: entities,
        }
      };
    }

    case ENTITIES_ACTION_TYPES.UPDATE_MEMBER_CLIENT_STATE_SUCCESS: {
      const task = action.payload;
      const duration_id = task.duration_id;
      let entities = state.members[duration_id] || [];

      const dx = _.findIndex(entities, { institution_id: task.institution_id });
      if (dx >= 0) {

        const taskIndex = _.findIndex(entities[dx].tasks, (t => t.id === task.id));
        let client_state = entities[dx].client_state;
        if (entities[dx].tasks[taskIndex].task_type === 'aebg_memberplan_submit') {
          // rehydrate client states.
          client_state = {
            ...client_state,
            ...task.client_state,
          };
        }

        entities = [
          ...entities.slice(0, dx),
          {
            ...entities[dx],
            tasks: [
              ...entities[dx].tasks.slice(0, taskIndex),
              task,
              ...entities[dx].tasks.slice(taskIndex + 1)
            ],
            client_state: client_state,
          },
          ...entities.slice(dx + 1),
        ];
      } else {
        entities = [...state.members[duration_id]];
      }

      return {
        ...state,
        members: {
          ...state.members,
          [duration_id]: entities,
        }
      };
    }

    case ENTITIES_ACTION_TYPES.SET_CURRENT_duration_id:
      return {
        ...state,
        current_duration_id: action.payload,
      };

    default:
      return state;
  }
}
