import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.ProposalReviews = {
  items: [],
  current_reviewer_id: null,
  task: null,
  is_loading: false,
  error: null,
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IProposalReviewsState {
    items: Array<Model.Resource>;
    task: Model.Task;
    current_reviewer_id: number;
    is_loading: boolean;
    error: any;
  }

  interface State {
    ProposalReviews: IProposalReviewsState;
  }
}
