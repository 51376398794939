import { Component, OnInit, OnDestroy, ViewChild, AfterViewChecked, ChangeDetectorRef, NgZone, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Queries } from '@app-ngrx-domains';
import { takeUntil, filter, debounceTime } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { slideOutAnimation } from '@app-generic/animations';
import { WORKFLOW_STEPS } from '@app/core/consts';

@Component({
  selector: 'error-stepper',
  templateUrl: './error-stepper.component.html',
  animations: [ slideOutAnimation ]
})
export class ErrorStepperComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('stepperComponent') stepperComponent: Element;

  inWorkflow: boolean = false;
  index: number = 0;
  errorList: Array<HTMLElement>;
  stepperAvailable: boolean;
  showStepper: boolean = false;
  numberOfErrors: number;
  titleText: string;
  ariaLiveText: string;
  ariaLiveStatus: string;

  goButtonId = 'error-stepper-go-button';
  private highlightedClass = 'error-highlight';
  private doCheck$: Subject<null> = new Subject();
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<State>,
    private cdr: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.cdr.detach();
  }

  ngOnInit() {
    // On Navigation, reset the stepper & error list.
    combineLatest([
      this.store.select(Queries.Workflow.isVisible),
      this.store.select(Queries.Workflow.showErrorStepper),
      this.store.select(Queries.Workflow.getCurrentStep),
      this.store.select(Queries.Workflow.isReview)
    ]).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([visible, showStepper, currentStep, isReview]) => {
      this.inWorkflow = !!showStepper || (!!visible && !isReview && currentStep !== WORKFLOW_STEPS.PREVIEW);
      this.reinitStepper();
    });


    this.doCheck$.pipe(
      filter(() => this.inWorkflow),
      debounceTime(150),
      takeUntil(this.destroy$)
    ).subscribe(() => {
        this.checkForErrors();

        // Do a secondary check to pick up straggling DOM changes (due to animations)
        setTimeout(() => { this.checkForErrors(); }, 500);
    });
  }

  ngAfterViewChecked() {
    this.zone.runOutsideAngular(() => {
      if (this.inWorkflow) {
        this.doCheck$.next();
      }
    });
  }

  @HostListener('document:keyup.tab')
  handleTabPress() {
    // No logic needed. This just allows the error-stepper to listen on the tab key being pressed
  }

  checkForErrors() {
    const modalActive = document.querySelector('.full-page-modal');
    this.errorList = modalActive ? [] : Array.from(document.querySelectorAll('[data-invalid="true"]'));
    this.updateViewStates();
  }

  // Since this component is detached from changeDetection, it's safer to update all template variables in one place
  updateViewStates(updateAriaLive?: boolean) {
    const wasAvailable = this.stepperAvailable;
    const prevCount = this.numberOfErrors;
    this.numberOfErrors = this.errorList ? this.errorList.length : 0;
    this.stepperAvailable = this.inWorkflow && !!this.numberOfErrors;
    const errorCount = this.numberOfErrors === 1 ? '1 error' : `${this.numberOfErrors} errors`;
    if (this.index > this.numberOfErrors - 1) {
      this.index = this.numberOfErrors ? this.numberOfErrors - 1 : 0;
    }
    this.titleText = this.showStepper && this.numberOfErrors > 1 ? `${this.index + 1} of ${errorCount}` : errorCount;
    this.ariaLiveText = undefined;
    if (updateAriaLive) {
      this.ariaLiveStatus = 'polite';
      this.ariaLiveText = `Viewing ${this.titleText}. Press the "Go" button to view the current error.`;
    } else {
      this.ariaLiveStatus = 'off';
    }

    this.cdr.detectChanges();

    if ((wasAvailable && !this.stepperAvailable) || this.numberOfErrors !== prevCount) {
      this.clearHighlighting();
    }
  }

  reinitStepper() {
    this.showStepper = false;
    this.index = 0;
    this.updateViewStates();
  }

  scrollToError() {
    const element = this.getCurrentError();
    if (element) {
      this.clearHighlighting();

      element.scrollIntoView( { behavior: 'smooth', inline: 'center', block: 'center' });
      element.classList.add(this.highlightedClass);
    }
    this.updateViewStates(true);
  }

  getCurrentError(): HTMLElement {
    if (this.numberOfErrors) {
      if (this.index < 0) {
        this.index = this.numberOfErrors - 1;
      } else if (this.index >= this.numberOfErrors) {
        this.index = 0;
      }

      return this.errorList[this.index];
    }
  }

  focusError() {
    this.focusElement(this.getCurrentError());
  }

  focusElement(element: HTMLElement) {
    if (element) {
      element.focus();

      /* If focusing on the element didn't work, check for a focusable element within the selected element */
      if (document.activeElement !== element) {
        // Order matters here, froala fields require focus on the 'fr-element' rather than the textarea
        const focusableElem = element.querySelector('select, input, .fr-element, textarea');
        if (focusableElem) {
          (focusableElem as HTMLElement).focus();
        } else {
          /* Some elements might be just raw text, so we need to set a tabindex on the element in order to focus it */
          element.setAttribute('tabindex', '0');
          element.focus();
        }
      }
    }

    this.clearHighlighting();
  }

  show() {
    this.showStepper = true;
    this.updateViewStates();

    // Focus the 'Go' button, since the 'Show' button has been removed
    this.focusElement(document.getElementById(this.goButtonId))
    this.scrollToError();
  }

  prev() {
    this.index -= 1;
    this.scrollToError();
  }

  next() {
    this.index += 1;
    this.scrollToError();
  }

  clearHighlighting() {
    const highlightedElems = Array.from(document.querySelectorAll('.' + this.highlightedClass));
    highlightedElems.forEach(e => {
      e.classList.remove(this.highlightedClass);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
