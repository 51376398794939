<div class="container--narrow margin-align-center columns columns--4">
  <div *ngIf="!shouldShowSuccess" class="card card--no-margin column-end-4 column-start-1">
    <h3 class="card-title" id="qa_requestAccess_title">New Access Request</h3>
    <app-access-request-form (requestSubmittedEvent)="addRequest($event)"></app-access-request-form>
  </div>

  <div [ngClass]="shouldShowSuccess ? 'column-start-1' : 'column-start-4'"
       class="align-self-start card card--no-margin column-end-4">
    <h4>{{shouldShowSuccess ? 'Success!' : 'My Requests'}}</h4>
    <p *ngIf="shouldShowSuccess">
      We will review your request for access as soon as possible and send you an invitation when your account is ready.
    </p>
    <div *ngIf="hasRequests; then hasSomeRequests; else hasNoRequests"></div>
</div>

<app-access-request-form (requestEditedEvent)="editRequest($event)"
                         *ngIf="shouldShowEditRequestModal"
                         [editingRequest]="myRequests[editedRequestIndex]"></app-access-request-form>

<alert (action)="removeRequestAlert()"
       (dismiss)="dismissRequestAlert()"
       *ngIf="shouldShowRemoveRequestAlert"
       button-action="Remove"
       button-dismiss="Cancel"
       level="warning">
  <p>Are you sure you want to remove "{{getEmployerName(removedRequestIndex)}}"?</p>
</alert>

<ng-template #hasNoRequests>
  <p class="empty-state margin-bottom-none">No requests added.</p>
</ng-template>

<ng-template #hasSomeRequests>
  <div *ngFor="let request of myRequests; let i = index" class="highlighted-content">
    <h6>{{getEmployerName(i)}}</h6>
    <ul class="list--comma-separated">
      <li *ngIf="request.programsAll">{{PROGRAMS_ALL_TEXT}}</li>
      <div *ngIf="!request.programsAll"><li *ngFor="let program of request.programs">{{program}}</li></div>
      <li *ngIf="request.programsDontKnow">{{DONT_KNOW_TEXT}}</li>
    </ul>
    <ul class="list--circle margin-bottom-none">
      <li *ngIf="request.activitiesReadOnly">{{ACTIVITIES_READ_ONLY_TEXT}}</li>
      <div *ngIf="!request.activitiesReadOnly"><li *ngFor="let activity of request.activities">{{activity}}</li></div>
      <li *ngIf="request.activitiesDontKnow">{{DONT_KNOW_TEXT}}</li>
    </ul>
    <p *ngIf="request.additionalInfo" class="margin-bottom-none margin-top-sm text--small">{{request.additionalInfo}}</p>
    <div *ngIf="!shouldShowSuccess" class="flex flex--justify-content-end margin-top-sm text--small">
      <a (click)="edit(i)" class="margin-right-sm">Edit</a>
      <a (click)="remove(i)">Remove</a>
    </div>
  </div>
  <div *ngIf="shouldShowSuccess; then successFooter; else personalInfoFooter"></div>
</ng-template>

<ng-template #personalInfoButton>
  <button (click)="shouldShowPersonalInfo = true"
          class="action-button action-button--full action-button--primary"
          type="button">Add Personal Info</button>
</ng-template>

<ng-template #personalInfoFooter>
  <hr>
  <div *ngIf="shouldShowPersonalInfo; then personalInfoTemplate; else personalInfoButton"></div>
</ng-template>

<ng-template #personalInfoTemplate>
  <form (ngSubmit)="submitPersonalInfo()" [@collapsible] [formGroup]="personalInfoForm" autocomplete="off">
    <input-field class="margin-bottom-sm" labelText="First Name">
      <input formControlName="firstName"
             id="qa_requestAccess_firstName_text"
             inputRef
             type="text">
    </input-field>
    <input-field class="margin-bottom-sm" labelText="Last Name">
      <input formControlName="lastName"
             id="qa_requestAccess_lastName_text"
             inputRef
             type="text">
    </input-field>
    <input-field class="margin-bottom" labelText="Email">
      <input formControlName="email"
             id="qa_requestAccess_email_text"
             inputRef
             type="text">
    </input-field>
    <button [disabled]="!personalInfoForm.valid"
            [ngClass]="{'loading': isLoading}"
            class="action-button action-button--full action-button--primary"
            type="submit">
      Submit Request
    </button>
  </form>
</ng-template>

<ng-template #successFooter>
  <dl class="dl-horizontal">
    <dt>Full Name</dt>
    <dd>{{fullName}}</dd>
    <dt>Email</dt>
    <dd>{{email}}</dd>
  </dl>
  <button (click)="startNewRequest()"
          class="action-button action-button--full action-button--secondary margin-bottom-sm"
          type="button">New Request</button>
  <button class="action-button action-button--full action-button--primary"
          routerLink="/login"
          type="button">Return to Login</button>
</ng-template>
