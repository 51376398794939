<h1 class="h2 title title--login">
  {{headingText}}
</h1>

<ng-container [ngSwitch]="passwordSet">
  <!-- CREATE PASSWORD -->
  <ng-container *ngSwitchCase="false">
    <div *ngIf="form" class="card">
      <form [formGroup]="form">
        <div class="hard__header">
          <div class="card-title">
            <h2 class="h3">
              Create a Password
            </h2>
          </div>
        </div>
      
        <div class="card__body">
          <input-group formGroupName="passwords" [control]="form.controls['passwords']" [required]="true" [legendHidden]="true" headingText="Create a Password">
            <input-field labelText="Password">
              <input inputRef id="register_password_input" type="password" formControlName="password" required>
            </input-field>
    
            <input-field labelText="Confirm Password">
              <input inputRef id="register_confirm_password_input" type="password" formControlName="confirm_password" required>
            </input-field>
          </input-group>
        </div>
    
        <div class="card-footer card-footer--right" [ngClass]="{ 'margin-bottom': statusMessage.type }">
          <button type="submit" class="action-button action-button--primary"
            [disabled]="shouldDisablePasswordSubmit()"
            (click)="setPassword()">
            Save
          </button>
        </div>

        <po-status-message [statusMessage]="statusMessage"></po-status-message>
      </form>
    </div>
  </ng-container>

  <!-- UPDATE PROFILE -->
  <ng-container *ngSwitchCase="true">
    <app-user-info
      [profile]="user"
      [disableEmail]="true"
      (saveInfoClicked)="profileSaved($event)">
    </app-user-info>
  </ng-container>
</ng-container>