import {Component, ElementRef, Input} from '@angular/core';
import { Model } from '@app-ngrx-domains';

@Component({
  selector: 'results-list-item',
  templateUrl: './results-list-item.component.html'
})
export class ResultsListItemComponent {

  @Input() searchValue;
  @Input() option: Model.SelectOption;
  @Input() index: number;

  selected = false;
  active = false;
  element: HTMLBaseElement;

  constructor(private el: ElementRef) {
    this.element = this.el.nativeElement;
  }
}
