import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ROUTER_LINKS, PAGE_TYPES, WELCOME_MESSAGE } from '../../consts';
import { ApiService } from '../../services';
import { State, Queries, Actions } from '@app-ngrx-domains';
import { Store } from '@ngrx/store';
import { ValidatorsEx } from '@app-utilities';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy {
  waitingForInput: boolean;
  email: string;
  password: string;
  queryParams: any;
  errors: any = {};
  headingText = 'Welcome';

  private queryParamSession: string;
  private storedSessionToken: string;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>,
    private apiService: ApiService
  ) {
    // Clear header
    this.store.dispatch(Actions.Layout.clearHeader());
    // reset the body style for login.
    this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
  }

  ngOnInit() {
    if (environment.name !== 'demo') {
      this.headingText = WELCOME_MESSAGE;
    }

    combineLatest(
      this.store.select(Queries.Auth.getCurrentUser),
      this.route.queryParams
    ).pipe(
      takeUntil(this.destroy$)
    ).subscribe(([user, queryParams]) => {
      this.queryParams = queryParams;
      this.queryParamSession = queryParams.session;
      const existingToken = localStorage.getItem('auth_token');

      // Session query params take priority over current user & existing session token
      if (this.queryParamSession) {
        if (existingToken) {
          // Store the existing token incase the queryParam is expired/invalid
          this.storedSessionToken = existingToken;
        }

        this.store.dispatch(Actions.Auth.login());
      } else if (!user && (this.storedSessionToken || existingToken)) {
        if (this.storedSessionToken) {
          // queryParam was invalid, try using the original token from localStorage
          localStorage.setItem('auth_token', this.storedSessionToken);
          this.storedSessionToken = undefined;
        }

        this.store.dispatch(Actions.Auth.login());
      } else if (user) {
        this.handleLogin(user);
      } else {
        this.waitingForInput = true;
      }
    });

    this.store.select(Queries.Auth.getErrors).pipe(
      takeUntil(this.destroy$)
    ).subscribe(errors => {
      if (this.queryParamSession) {
        // queryParam session was invalid, get rid of it
        const newParams = { ...this.queryParams, session: undefined };
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: newParams });
      } else {
        this.waitingForInput = true;
        this.errors = errors || {};
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.store.dispatch(Actions.Layout.clearPageType());
  }

  onSubmit() {
    this.waitingForInput = false;
    this.errors = {};
    this.store.dispatch(Actions.Auth.login(this.email, this.password));
  }

  handleLogin(user) {
    // User has been logged in at this point and the auth. token should be set
    if (this.queryParams.redirect_uri) {
      this.handleOAuthRedirect(user);
    } else {
      this.handleRouteChange(user);
    }
  }

  handleOAuthRedirect(user) {
    const redirect_uri = decodeURIComponent(this.queryParams.redirect_uri);
    const httpParams = new HttpParams()
        .set('client_id', this.queryParams.client_id)
        .set('redirect_uri', this.queryParams.redirect_uri)
        .set('state', this.queryParams.state)
        .set('response_type', this.queryParams.response_type)
        .set('scope', this.queryParams.scope)
        .set('grant_type', 'authorization_code')
        .set('access_token', localStorage.getItem('auth_token'));

    this.apiService.getAuthorizationCode(httpParams).subscribe((resp: any) => {
      // Redirect externally with the URL from the service.
      // It will include all the required parameters.
      window.location.href = resp.redirectUri;
    });
  }

  handleRouteChange(user) {
    let newQueryParams: any = {};
    let newRoute = ROUTER_LINKS.HOME;

    if (this.queryParams.returnUrl) {
      const returnUrl = decodeURIComponent(this.queryParams.returnUrl || ROUTER_LINKS.HOME);

      const returnUrlComponents = returnUrl.split('?');
      newRoute = returnUrlComponents[0];

      const queryParamString = returnUrlComponents[1];
      if (queryParamString) {
        queryParamString.split('&').forEach(param => {
          const [key, value] = param.split('=');
          newQueryParams[key] = value;
        });
      }

      let additionalParams = this.queryParams.queryParams
      if (additionalParams) {
        try {
          additionalParams = JSON.parse(this.queryParams.queryParams);
          newQueryParams = {
            ...newQueryParams,
            ...additionalParams
          };
        } catch (err) {
          this.errors = { message: 'There was an issue parsing the query parameters.' }
        }
      };
    }
    const fragment = this.queryParams.fragment || undefined;
    this.router.navigate([newRoute], {
      queryParams: newQueryParams,
      fragment
    }).then(event => {
      if (this.queryParams.hardRefresh === 'true') { window.location.reload(); }
    });
  }

  validateEmail(email: string) {
    return ValidatorsEx.emailString(email);
  }
}
