import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { Proposal, WorkplanItem, EnumErrorTypes } from '@app-models';

/**
 * Workplan Action types
 */
const ACTION_PREFIX = 'WORKPLAN_';
export const WORKPLAN_ACTION_TYPES = {
  LOAD_ITEMS: `${ACTION_PREFIX}LOAD_ITEMS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  ADD_ITEM: `${ACTION_PREFIX}ADD_ITEM`,
  ADD_ITEM_SUCCESS: `${ACTION_PREFIX}ADD_ITEM_SUCCESS`,
  ADD_ITEM_FAIL: `${ACTION_PREFIX}ADD_ITEM_FAIL`,
  DELETE_ITEM: `${ACTION_PREFIX}DELETE_ITEM`,
  DELETE_ITEM_SUCCESS: `${ACTION_PREFIX}DELETE_ITEM_SUCCESS`,
  DELETE_ITEM_FAIL: `${ACTION_PREFIX}DELETE_ITEM_FAIL`,
  DUPE_ITEM: `${ACTION_PREFIX}DUPE_ITEM`,
  UPDATE_ITEM: `${ACTION_PREFIX}UPDATE_ITEM_VALUE`,
  UPDATE_ITEM_SUCCESS: `${ACTION_PREFIX}UPDATE_ITEM_SUCCESS`,
  UPDATE_ITEM_FAIL: `${ACTION_PREFIX}UPDATE_ITEM_FAIL`,
  UPDATE_ITEM_NOOP: `${ACTION_PREFIX}UPDATE_ITEM_NOOP`,
  ADD_ITEM_COLLEGE: `${ACTION_PREFIX}ADD_ITEM_COLLEGE`,
  ADD_ITEM_COLLEGE_SUCCESS: `${ACTION_PREFIX}ADD_ITEM_COLLEGE_SUCCESS`,
  ADD_ITEM_COLLEGE_FAIL: `${ACTION_PREFIX}ADD_ITEM_COLLEGE_FAIL`,
  REMOVE_ITEM_COLLEGE: `${ACTION_PREFIX}REMOVE_ITEM_COLLEGE`,
  REMOVE_ITEM_COLLEGE_SUCCESS: `${ACTION_PREFIX}REMOVE_ITEM_COLLEGE_SUCCESS`,
  REMOVE_ITEM_COLLEGE_FAIL: `${ACTION_PREFIX}REMOVE_ITEM_COLLEGE_FAIL`,
  ADD_ITEM_STRATEGY: `${ACTION_PREFIX}ADD_ITEM_STRATEGY`,
  ADD_ITEM_STRATEGY_SUCCESS: `${ACTION_PREFIX}ADD_ITEM_STRATEGY_SUCCESS`,
  REMOVE_ITEM_STRATEGY: `${ACTION_PREFIX}REMOVE_ITEM_STRATEGY`,
  REMOVE_ITEM_STRATEGY_SUCCESS: `${ACTION_PREFIX}REMOVE_ITEM_STRATEGY_SUCCESS`,
};

/**
 * Workplan action class.
 */
export class WorkplanActions {

  /**
   * Loads workplan data from the proposal
   *
   * @param {any} proposal
   * @returns {Action}
   */
  load(proposal: any): ActionWithPayload<any> {
    const workItems: Array<Model.WorkplanItem> = [];
    if (proposal.workplan_items && proposal.workplan_items.length > 0) {
      proposal.workplan_items.forEach(item => {
        workItems.push(new WorkplanItem(item).iObject<Model.WorkplanItem>());
      });
    }

    return {
      type: WORKPLAN_ACTION_TYPES.LOAD_ITEMS,
      payload: {
        proposal_id: proposal.id,
        items: workItems,
      }
    };
  }

  /**
   * Resets workplan.
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.LOAD_ITEMS,
      payload: {
        proposal_id: null,
        items: [],
      }
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: WORKPLAN_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  /** Adds a new workplan item.
   * @param {any} workplan
   * @returns {Action}
   */
  addItem(workplan: any): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.ADD_ITEM,
      payload: {
        workplan: workplan,
      }
    };
  }
  /** Callback after successfull creation of workplan item.
   * Dispatches equivalent message to reducer to add it to its state.
   *
   * @param {any} response from service API
   * @returns {Action}
   */
  addItemSuccess(response: any): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.ADD_ITEM_SUCCESS,
      payload: new WorkplanItem(response).iObject<Model.WorkplanItem>()
    };
  }
  /** Error occurred while persisting the item.
   * @param {any} error
   * @returns {Action}
   */
  addItemFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: WORKPLAN_ACTION_TYPES.ADD_ITEM_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Deletes workplan item.
   * @param {number} itemId
   * @returns {Action}
   */
  deleteItem(itemId: number): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.DELETE_ITEM,
      payload: itemId
    };
  }

  /**
   * Successfully deleted the workplan item.
   * Dispatches equivalent message to reducer to remove it from its state.
   * @param {number} itemId
   * @returns {Action}
   */
  deleteItemSuccess(itemId: number): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.DELETE_ITEM_SUCCESS,
      payload: itemId
    };
  }

  /**
   * Error occurred while deleting the workplan item.
   * Dispatches equivalent error for reducer to consume.
   * @param {any} error
   * @returns {Action}
   */
  deleteItemFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: WORKPLAN_ACTION_TYPES.DELETE_ITEM_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Duplicates given item.
   *
   * @param {Model.WorkplanItem} item to duplicate
   * @returns {Action}
   */
  duplicateItem(item: Model.WorkplanItem): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.DUPE_ITEM,
      payload: {
        item: item,
      }
    };
  }

  /**
   * Updates fields in workplan item.
   *
   * @param {number} itemId - item to update
   * @param {any} fields - one or more fields that need to be updated
   * @returns {Action}
   */
  updateItem(itemId: number, fields: any): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.UPDATE_ITEM,
      payload: {
        itemId: itemId,
        fields: fields,
      }
    };
  }

  /**
   * Successfully updated the workplan item.
   * Dispatches equivalent message to reducer to update its content.
   *
   * @param {any} response
   * @returns {Action}
   */
  updateItemSuccess(response): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.UPDATE_ITEM_SUCCESS,
      payload: new WorkplanItem(response).iObject<Model.WorkplanItem>()
    };
  }

  /**
   * Error occurred while deleting the workplan item.
   * Dispatches equivalent error for reducer to consume.
   *
   * @param {any} error
   * @returns {Action}
   */
  updateItemFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: WORKPLAN_ACTION_TYPES.UPDATE_ITEM_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Noop - update didn't result in a state change.
   *
   * @returns {Action}
   */
  updateItemNoOp(): Action {
    return {
      type: WORKPLAN_ACTION_TYPES.UPDATE_ITEM_NOOP,
    };
  }

  /** Adds a college to a given workplan item.
   * @param {number} itemId
   * @param {number} institution_id
   * @returns {Action}
   */
  addItemCollege(itemId: number, institution_id: number, is_lead: boolean): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.ADD_ITEM_COLLEGE,
      payload: {itemId: itemId, institution_id: institution_id, is_lead: is_lead},
    }
  }

  /** Successfully added college to a given workplan item.
   * @param {number} itemId
   * @param {number} institution_id
   * @param {andy} response - list of fiscal entities
   * @returns {Action}
   */
  addItemCollegeSuccess(itemId: number, institution_id: number, response): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.ADD_ITEM_COLLEGE_SUCCESS,
      payload: {itemId: itemId, institution_id: institution_id, institution: response.institution, is_lead: response.is_lead},
    }
  }

  /** Failed to add college to given workplan item.
   * @param error
   */
  addItemCollegeFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: WORKPLAN_ACTION_TYPES.ADD_ITEM_COLLEGE_FAIL,
      show: true,
      raw: error,
    });
  }

  /** Removes a college from a given workplan item.
   * @param itemId
   * @param institution_id
   * @returns {Action}
   */
  removeItemCollege(itemId: number, institution_id: number): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.REMOVE_ITEM_COLLEGE,
      payload: {itemId: itemId, institution_id: institution_id},
    }
  }

  /** Successfully removed a college from a given workplan item.
   * @param itemId
   * @param institution_id
   * @returns {Action}
   */
  removeItemCollegeSuccess(itemId: number, institution_id: number): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.REMOVE_ITEM_COLLEGE_SUCCESS,
      payload: {itemId: itemId, institution_id: institution_id},
    }
  }

  /** Failed to remove college from a given workplan item.
   * @param error
   */
  removeItemCollegeFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: WORKPLAN_ACTION_TYPES.REMOVE_ITEM_COLLEGE_FAIL,
      show: true,
      raw: error,
    });
  }

  /** Adds a strategy to a given workplan item.
   * @param {number} itemId
   * @param {number} strategy_id
   * @returns {Action}
   */
  addItemStrategy(itemId: number, strategy_id: number): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.ADD_ITEM_STRATEGY,
      payload: { itemId: itemId, strategy_id: strategy_id },
    };
  }

  /** Successfully added strategy to a given workplan item.
   * @param {number} itemId
   * @param {number} strategy_id
   * @param {andy} response - list of fiscal entities
   * @returns {Action}
   */
  addItemStrategySuccess(itemId: number, strategy_id: number, response): ActionWithPayload<any> {
    const strategy = response.filter((s) => { return s.id === strategy_id; })[0];
    return {
      type: WORKPLAN_ACTION_TYPES.ADD_ITEM_STRATEGY_SUCCESS,
      payload: { itemId: itemId, strategy_id: strategy_id, strategy: strategy },
    };
  }

  /** Removes a college from a given workplan item.
   * @param itemId
   * @param strategy_id
   * @returns {Action}
   */
  removeItemStrategy(itemId: number, strategy_id: number): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.REMOVE_ITEM_STRATEGY,
      payload: { itemId: itemId, strategy_id: strategy_id },
    };
  }

  /** Successfully removed a college from a given workplan item.
   * @param itemId
   * @param strategy_id
   * @returns {Action}
   */
  removeItemStrategySuccess(itemId: number, strategy_id: number): ActionWithPayload<any> {
    return {
      type: WORKPLAN_ACTION_TYPES.REMOVE_ITEM_STRATEGY_SUCCESS,
      payload: { itemId: itemId, strategy_id: strategy_id },
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Workplan = new WorkplanActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Workplan: WorkplanActions;
  }
}
