import { concat as concat, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Model, Actions as DomainActions } from '@app-ngrx-domains';
import { errorPayload, toPayload } from '@app-libs';
import { ApiService, AttributesService } from '@app-services';
import { FUND_ACTION_PREFIX, FUND_ACTION_TYPES } from './fund.action';

@Injectable()
export class FundEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private attributesService: AttributesService,
    private store: Store<State>
  ) { }

  /**
   * Fetches fund.
   */
  @Effect() get$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.GET),
    map(toPayload),
    mergeMap(fund_id => {
      return this.apiService.getv2Funds({id: fund_id}).pipe(
        map(response => DomainActions.Fund.getSuccess(response)),
        catchError(error => of(DomainActions.Fund.getFail(error))))
    })
  );

  /**
   * Updates fund attribute.
   */
  @Effect() upsertAttribute$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.UPSERT_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.get)),
    mergeMap(([req, fund]) => {
      return this.attributesService.upsertAttribute$(FUND_ACTION_PREFIX, req, fund, null, fund.id);
    })
  );

  /**
   * Updates multiple attributes at once
   */
  @Effect() upsertAttributes$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.UPSERT_ATTRIBUTES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.get)),
    mergeMap(([req, fund]) => {
      return this.attributesService.upsertAttributes$(FUND_ACTION_PREFIX, req, fund, fund.id);
    })
  )

  /**
   * Updates multiple attributes at once
   */
  @Effect() deleteAttributes$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.DELETE_ATTRIBUTES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.get)),
    mergeMap(([req, fund]) => {
      return this.attributesService.deleteAttributes$(FUND_ACTION_PREFIX, req, fund, null, fund.id);
    })
  )

  /**
   * Adds fund multi attribute.
   */
  @Effect() addMultiAttribute$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.ADD_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.get)),
    mergeMap(([req, fund]) => {
      return this.attributesService.addMultiAttribute$(FUND_ACTION_PREFIX, req, fund, null, fund.id);
    })
  );

  /**
   * Deletes fund multi attribute.
   */
  @Effect() deleteMultiAttribute$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.DELETE_MULTI_ATTRIBUTE),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.get)),
    mergeMap(([req, fund]) => {
      return this.attributesService.deleteMultiAttribute$(FUND_ACTION_PREFIX, req, fund, null, fund.id);
    })
  );

  /**
   * Creates effort area & an attribute.
   */
  @Effect() creatAttributeEffortArea$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.CREATE_ATTRIBUTE_EFFORT_AREA),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.get)),
    mergeMap(([req, fund]) => {
      return this.attributesService.creatAttributeEffortArea$(FUND_ACTION_PREFIX, req, null, fund.id);
    })
  );

  /**
   * Create effort area.
   */
  @Effect() createEffortArea$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.CREATE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.createEffortArea$(FUND_ACTION_PREFIX, req);
    })
  );

  @Effect() createMultiEffortAreas$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.CREATE_MULTI_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.createMultiEffortAreas$(FUND_ACTION_PREFIX, req);
    })
  );

  /**
   * Updates effort area.
   */
  @Effect() updateEffortArea$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.UPDATE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.updateEffortArea$(FUND_ACTION_PREFIX, req);
    })
  );

  /**
   * Deletes effort area.
   */
  @Effect() deleteEffortArea$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.DELETE_EFFORT_AREA),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.deleteEffortArea$(FUND_ACTION_PREFIX, req);
    })
  );

  /**
   * Deletes list of effort areas.
   */
  @Effect() deleteMultiEffortAreas$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.DELETE_MULTI_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.deleteMultiEffortAreas$(FUND_ACTION_PREFIX, req);
    })
  );

  @Effect() cloneEffortAreas$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.CLONE_EFFORT_AREAS),
    map(toPayload),
    mergeMap(req => {
      return this.attributesService.cloneEffortAreas$(FUND_ACTION_PREFIX, req.operations, req.parentEffortAreas);
    })
  );

  @Effect() completeTask$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.COMPLETE_TASK),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.getId)),
    mergeMap(([req, fund_id]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      // complete task.
      return this.apiService.completeTask(req.task).pipe(
        mergeMap(response => {
          // re-fetch fund to read the states.
          return this.apiService.getv2Funds({ id: fund_id }).pipe(
            mergeMap(updatedFund => {
              this.store.dispatch(DomainActions.Layout.showBusySpinner(false));

              const actions: Action[] = [];
              actions.push(DomainActions.Fund.refreshTaskSuccess(updatedFund));
              if (req.goto) {
                // re-route as requested.
                actions.push(DomainActions.App.go([req.goto.url], req.goto.extras));
              }

              return concat([
                ...actions
              ]);
            }));
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(errorPayload(error, FUND_ACTION_TYPES.COMPLETE_TASK));
        }));
    })
  );

  @Effect() undoTask$ = this.actions$.pipe(
    ofType(FUND_ACTION_TYPES.UNDO_TASK),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Fund.getId)),
    mergeMap(([task, fund_id]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      // undo task.
      return this.apiService.undoTask(task).pipe(
        mergeMap(response => {
          // re-fetch fund to read the states.
          return this.apiService.getv2Funds({ id: fund_id }).pipe(
            mergeMap(updatedFund => {
              this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
              return of(DomainActions.Fund.refreshTaskSuccess(updatedFund));
            }));
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(errorPayload(error, FUND_ACTION_TYPES.UNDO_TASK));
        }));
    })
  );

 }
