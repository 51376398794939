import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { State, Actions as DomainActions } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { FILES_ACTION_TYPES } from './files.action';

/**
 * Injectable Files effects class
 */
@Injectable()
export class FilesEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  /**
   * Delete file from service.
   */
  @Effect() deleteFile$ = this.actions$.pipe(
    ofType(FILES_ACTION_TYPES.DELETE),
    map(toPayload),
    mergeMap(fileId => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      return this.apiService.deleteFile(fileId).pipe(
        map(() => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return DomainActions.Files.deleteSuccess(fileId)
        }),
        catchError((error) => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.Files.serviceFail(error));
        }));
    })
  );
}
