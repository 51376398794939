import { take, skipWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from 'ng-logger';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions } from '@app-ngrx-domains';
import { PermissionsService, ProgramService } from '@app-services';
import { ROUTER_LINKS } from '../consts';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private logger: LoggerService,
    private permissionsService: PermissionsService,
    private programService: ProgramService,
    private store: Store<State>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthorized(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthorized(route, state);
  }

  isAuthorized(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {
      // dispatch check auth status... if token was
      // removed from another browsing instance, then it would reset the log in status
      this.store.dispatch(Actions.Auth.loginCheck());

      this.store.select(Queries.Auth.getGuardStates).pipe(
        // wait until login is checked
        skipWhile(authStatus => authStatus.isInProgress),
        // take latest
        take(1)
      ).subscribe(states => {
        if (states.isLoggedIn) {
          this.programService.isLoaded() // make sure settings has been loaded.
            .pipe(
              skipWhile(loaded => !loaded),
              take(1)
            ).subscribe(() => {
              if (!route.data.routeCheck || route.data.routeCheck.guard) {
                this.logger.debug(`[auth-guard][${state.url}] is logged in & has no route check or will be checked by another guard`);
                subscriber.next(true);
                subscriber.complete();
              } else {
                // check and see if the user has access to the activated route.
                this.permissionsService.canGoTo(route.data.routeCheck, route, route.data.extraScope)
                  .subscribe(check => {
                    if (check.allowed) {
                      this.logger.debug(`[auth-guard][${state.url}] has access to route`);
                      subscriber.next(true);
                      subscriber.complete();
                    } else {
                      if (check.rerouteUrl) {
                        this.logger.debug(`[auth-guard][${state.url}] does not have access - rerouting to ${check.rerouteUrl}`);
                        this.router.navigate([check.rerouteUrl]);
                      } else {
                        this.logger.debug(`[auth-guard][${state.url}] does not have access & has no rerouting url.`);
                        this.router.navigate([ROUTER_LINKS.PAGE_ACCESS_DENIED], { queryParams: { badUrl: state.url } });
                      }
                      subscriber.next(false);
                      subscriber.complete();
                    }
                  });
              }
            });
        } else {
          // see if we're in the middle of getting authenticated from the server, and if so wait...
          if (!states.isInProgress) {
            // otherwise route user to login page, if we're not already there.
            if (!states.isWaitingOnUser) {
              // this.logger.debug(`[auth-guard][${state.url}] checked-rerouting user to login page`);
              this.store.dispatch(Actions.Auth.loginWaitingOnUser(true));
              if (state.url) {
                // redirect user to target url once he's logged in.
                this.router.navigate([ROUTER_LINKS.LOGIN], { queryParams: { returnUrl: state.url } });
              } else {
                // target url is default.
                this.router.navigate([ROUTER_LINKS.LOGIN]);
              }
            }
            subscriber.next(false);
            subscriber.complete();
          }
        }
      }, () => {
        this.router.navigate([ROUTER_LINKS.LOGIN]);
        subscriber.error(false);
        subscriber.complete();
      });
    });
  }
}
