import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ApiService } from '../../services';
import { ROUTER_LINKS, PAGE_TYPES } from '../../consts';
import { Store } from '@ngrx/store';
import { State, Actions, Model } from '@app-ngrx-domains';
import { ValidatorsEx } from '../../utilities';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;

  emailAddressFromUrl;
  settingEmailFirstTime;
  loginAvailable;
  show_success = false;
  statusMessage: Model.StatusMessage = {
    message: undefined,
    type: undefined
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private apiService: ApiService,
    private store: Store<State>
  ) {
      // Get user email address from query param, if it's there, which means we're setting the password for the first time.
      if (!_.isEmpty(route.snapshot.queryParams)) {
        this.emailAddressFromUrl = route.snapshot.queryParams['email'];
        this.settingEmailFirstTime = true;
      }
  }

  ngOnInit() {
    // auth token is never used for forgot password, strip it in case user has
    // expired token.
    localStorage.removeItem('auth_token');
    this.loginAvailable = navigator.userAgent.indexOf('Trident') === -1;
    // Clear header
    this.store.dispatch(Actions.Layout.clearHeader());
    // reset the body style for login.
    this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.NAVLESS));
    // build the form
    this.form = this._fb.group({
      email_address: [(this.emailAddressFromUrl ? this.emailAddressFromUrl : ''), [Validators.required, ValidatorsEx.email]],
    });
  }

  ngOnDestroy() {
    this.store.dispatch(Actions.Layout.clearPageType());
  }

  onSubmit() {
    this.statusMessage.message = 'Sending email';
    this.statusMessage.type = 'loading';

    this.apiService.passwordReset(this.email_address).subscribe(() => {
      this.show_success = true;
      this.statusMessage.message = 'Email sent!';
      this.statusMessage.type = 'success';
    },
    (err) => {
      const default_message = 'Error sending password reset email. Please try again.';
      this.statusMessage.message = (err.error && err.error.message) ? err.error.message : default_message;
      this.statusMessage.type = 'fail';
      if (err.status >= 500) {
        console.error('Error resetting password: ', err);
      }
    });
  }

  get email_address() {
    return this.form.get('email_address').value.trim();
  }

  get login_route() {
    return ROUTER_LINKS.LOGIN;
  }
}
