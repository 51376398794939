import * as _ from 'lodash';
import { FUND_TYPES, WORKPLAN_TYPES, CHAR_LIMITS } from '@app-consts';
import { WorkplanItem } from '@app-models';

/** Form Validator for Workplan1 tab
 */

export function validateStepData(w: any): boolean {

  const validateIPlan = () => {
    let effort1 = 0;
    let effort2 = 0;
    let effort3 = 0;
    let effort4 = 0;

    for (let i = 0; i < w.items.length; i++) {
      const item = w.items[i];
      switch (item.workplan_type) {
        // previous effort 1a
        case WORKPLAN_TYPES.EFFORT1: {
          effort1++;
          break;
        }

        // previous effort 1b
        case WORKPLAN_TYPES.EFFORT2: {
          effort2++;
          break;
        }

        // previous effort 1c
        case WORKPLAN_TYPES.EFFORT3: {
          effort3++;
          break;
        }

        // share success
        case WORKPLAN_TYPES.EFFORT4: {
          effort4++;
          break;
        }

        default:
          break;
      }
    }

    // previous effort 1a - one or more
    if (effort1 < 1) {
      return false;
    }
    // previous effort 1b - one
    if (effort2 !== 1) {
      return false;
    }
    // previous effort 1c - one
    if (effort3 !== 1) {
      return false;
    }
    // share success
    if (effort4 !== 1) {
      return false;
    }

    return true;
  }

  const validateGP = () => {
    for (let i = 0; i < w.items.length; i++) {
      const item = w.items[i];

      if (item.workplan_type.startsWith(WORKPLAN_TYPES.GP_DESIGN) && !item.workplan_type.endsWith(WORKPLAN_TYPES.GP_ASSESSMENT)) {
        if (!WorkplanItem.isValidGPItem(item)) {
          return false;
        }
      }
    }

    return true;
  }

  switch (w.proposal.project_type) {
    case FUND_TYPES.IPLAN:
      return validateIPlan();

    case FUND_TYPES.GP:
      return validateGP();

    default:
      return false;
  }
}
