import { IWorkflowState, Query, Queries, Root, Model, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IWorkflowState>('Workflow');

/**
 * Predefined queries to run against root states.
 */
export interface IWorkflowQueries {
  get: Query<{ [route: string]: Model.Workflow }>;
  getCurrent: Query<Model.Workflow>;
  getCurrentStep: Query<string>;
  getCurrentSteps: Query<Array<Model.WorkflowStep>>;
  isVisible: Query<boolean>;
  isReview: Query<boolean>;
  showErrorStepper: Query<boolean>;
}

Queries.Workflow = {
  get: fromRoot(state => state.workflows),
  getCurrent: fromRoot(state => state.workflows[state.current]),
  getCurrentStep: fromRoot(state => state.current ? state.workflows[state.current].currentStep : undefined),
  getCurrentSteps: fromRoot(state => state.current ? state.workflows[state.current].steps : undefined),
  isVisible: fromRoot(state => state.workflows[state.current] ? state.workflows[state.current].show : false),
  isReview: fromRoot(state => state.workflows[state.current] ? state.workflows[state.current].isReview : false),
  showErrorStepper: fromRoot(state => state.showErrorStepper)
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Workflow: Query<IWorkflowState>;
  }

  interface Queries {
    Workflow: IWorkflowQueries;
  }
}
