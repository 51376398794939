import { Action } from '@ngrx/store';
import { IAllocationsState, Actions, State, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { Allocation, EnumErrorTypes } from '@app-models';

/**
 * List of action types
 */
const ACTION_PREFIX = 'ALLOCATIONS_';
export const ALLOCATIONS_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  UPDATE: `${ACTION_PREFIX}UPDATE`,
  NOOP: `${ACTION_PREFIX}NOOP`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  UPDATE_FIELD: `${ACTION_PREFIX}UPDATE_FIELD`,
  UPDATE_FIELD_SUCCESS: `${ACTION_PREFIX}UPDATE_FIELD_SUCCESS`,
  ADD_MEMBER: `${ACTION_PREFIX}ADD_MEMBER`,
  REMOVE_MEMBER: `${ACTION_PREFIX}REMOVE_MEMBER`,
};

/**
 * Action creator class
 */
export class AllocationsActions {

  /**
   * Hydrates from the raw responses
   * @param {any} response
   * @returns {Action}
   */
  load(response: any, budget_allocations: any): ActionWithPayload<any> {
    const state: IAllocationsState = {
      lead: {},
      members: {},
      budget_members: budget_allocations,
    };

    // first sort member allocations by institution id, then by year.
    const members = response.members;
    members.forEach(member => {
      const memberAllocations = response.lead_institution.distributions
        .filter(dist => (
          dist.duration_id === member.duration_id && dist.to_institution_id === member.institution_id
        ));
      if (!state.members[member.institution_id]) {
        state.members[member.institution_id] = {};
      }
      if (!state.members[member.institution_id][member.duration_id]) {
        state.members[member.institution_id][member.duration_id] = [];
      }
      memberAllocations.forEach(allocation => {
        state.members[member.institution_id][member.duration_id].push(new Allocation(allocation).iObject<Model.Allocation>());
      });
    });

    // build out lead/consortium's allocations.
    const consortiumAllocations = {};
    response.lead_institution.allocations.forEach(allocation => {
      if (!consortiumAllocations[allocation.duration_id]) {
        consortiumAllocations[allocation.duration_id] = [];
      }
      consortiumAllocations[allocation.duration_id].push(new Allocation(allocation).iObject<Model.Allocation>());
    });
    state.lead = consortiumAllocations;

    return {
      type: ALLOCATIONS_ACTION_TYPES.LOAD,
      payload: state
    };
  }

  /**
   * Resets/clears data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: ALLOCATIONS_ACTION_TYPES.LOAD,
      payload: {
        ...State.Allocations,
      },
    };
  }

  /**
   * No-op action.
   * @returns {Action}
   */
  noOp(): Action {
    return {
      type: ALLOCATIONS_ACTION_TYPES.NOOP,
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: ALLOCATIONS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Updates a field.
   * @param {array<any>} req
   * @returns {Action}
   */
  update(req: Array<{
    institution_id: number, institution_name?: string, proposal_id?: number, state_id?: number, duration_id: number, id: number,
    name: string, value: any }>): ActionWithPayload<any> {
    return {
      type: ALLOCATIONS_ACTION_TYPES.UPDATE_FIELD,
      payload: req
    };
  }

  /**
   * Handles update success.
   * @param {array<any>} req
   * @returns {Action}
   */
  updateSuccess(req: Array<Model.Allocation>): ActionWithPayload<any> {
    return {
      type: ALLOCATIONS_ACTION_TYPES.UPDATE_FIELD_SUCCESS,
      payload: req
    };
  }

  /**
   * Handles allocations of a new member that was just added.
   * @param {*} allocation - response object
   * @returns {Action}
   */
  addMember(allocation: any): ActionWithPayload<any> {
    return {
      type: ALLOCATIONS_ACTION_TYPES.ADD_MEMBER,
      payload: new Allocation(allocation).iObject<Model.Allocation>(),
    };
  }

  /**
   * Handles member that just got removed.
   * @param {*} req
   * @returns {Action}
   */
  removeMember(req: any): ActionWithPayload<any> {
    return {
      type: ALLOCATIONS_ACTION_TYPES.REMOVE_MEMBER,
      payload: {
        institution_id: req.memberInstitutionId,
        duration_id: req.duration_id,
      }
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Allocations = new AllocationsActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Allocations: AllocationsActions;
  }
}
