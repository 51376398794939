import { IAnnualPlansState, Query, Queries, Root, Model, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IAnnualPlansState>('AnnualPlans');

/**
 * Predefined queries to run against root states.
 */
export interface IAnnualPlansQueries {
  get: Query<IAnnualPlansState>;
}

Queries.AnnualPlans = {
  get: fromRoot(state => state),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    AnnualPlans: Query<IAnnualPlansState>;
  }

  interface Queries {
    AnnualPlans: IAnnualPlansQueries;
  }
}
