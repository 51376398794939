import { forkJoin, of, concat } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { State, Actions as DomainActions, Queries } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { CONTACTS_ACTION_TYPES } from './contacts.action';
import { UserRoleScope } from '@app-models';

/**
 * Injectable Contacts effects class
 */
@Injectable()
export class ContactsEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  /**
   * Adds proposal-contact association.
   */
  @Effect() addProposalContact$ = this.actions$.pipe(
    ofType(CONTACTS_ACTION_TYPES.ADD_CONTACT),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.CurrentProposal.getProposal)),
    mergeMap(([req, proposal]) => {
      const contact = req.contact;
      const user_role = UserRoleScope.stripped(contact);

      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));

      return this.apiService.addUserRoleScope(user_role, req.notify_on_assignment, req.override_subject).pipe(
        mergeMap(response => {
          return this.apiService.getProfileById(contact.user_id).pipe(
            mergeMap(profile => {
              response.user = profile;
              const actions: Action[] = [];
              actions.push(DomainActions.Contacts.addSuccess(response));
              return concat([...actions, DomainActions.Layout.showBusySpinner(false)]);
            })
          );
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          if (error.status === 409) {
            // [Conflict] User already has role
            return of(DomainActions.App.showAlert(
              `<strong>Couldn't Add Duplicate Role</strong>
              This user already has this role.`
            ));
          } else {
            return of(DomainActions.Contacts.addFail(error));
          }
        }));
    })
  );

  /**
   * Updates an existing role entry with the new values for the contact
   */
  @Effect() updateProposalContact$ = this.actions$.pipe(
    ofType(CONTACTS_ACTION_TYPES.UPDATE_CONTACT),
    map(toPayload),
    mergeMap(contact => {
      return this.apiService.updateUserRoleScope(contact.id, UserRoleScope.stripped(contact)).pipe(
        mergeMap(response => {
          return this.apiService.getProfileById(contact.user_id).pipe(mergeMap(profile => {
            response.user = profile;
            return of(DomainActions.Contacts.updateSuccess(response));
          }));
        }),
        catchError(error => {
          if (error.status === 409) {
            // [Conflict] User already has role
            return of(DomainActions.App.showAlert(
              `<strong>Couldn't Add Duplicate Role</strong>
              This user already has this role.`
            ));
          } else {
            return of(DomainActions.Contacts.addFail(error));
          }
      }));
    })
  );

  /**
   * Removes proposal-contact association.
   */
  @Effect() deleteProposalContact$ = this.actions$.pipe(
    ofType(CONTACTS_ACTION_TYPES.DELETE_CONTACT),
    map(toPayload),
    mergeMap(contact => {
      return this.apiService.removeUserRoleScope(UserRoleScope.stripped(contact)).pipe(
        map(() => DomainActions.Contacts.deleteSuccess([contact])),
        catchError((error) => of(DomainActions.Contacts.deleteFail(error)))
      );
    })
  );

  /**
   * Removes a set of proposal-contacts and then dispatches the provided action if there is one
   */
  @Effect() batchDeleteProposalContacts$ = this.actions$.pipe(
    ofType(CONTACTS_ACTION_TYPES.BATCH_DELETE_CONTACTS),
    map(toPayload),
    mergeMap(paylaod => {
      const contacts = paylaod.contacts;
      const observables = contacts.length
        ? contacts.map(contact => this.apiService.removeUserRoleScope(UserRoleScope.stripped(contact)))
        : [];

      const obsRemoveContacts = (observables.length === 0) ? of([]) : forkJoin(observables);
      return obsRemoveContacts.pipe(
        map(() => {
          if (paylaod.action_after) {
            this.store.dispatch(paylaod.action_after);
          }
          return DomainActions.Contacts.deleteSuccess(contacts);
        }),
        catchError((error) => of(DomainActions.Contacts.deleteFail(error)))
      );
    })
  );
}
