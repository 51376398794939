<div [ngSwitch]="showHeaderLink()" class="content-header__branding">
  <a *ngSwitchCase="true" [routerLink]="['/']" aria-label="Go to Login">
    <img src="/assets/images/{{logo.src}}" alt="{{logo.altText}}"/>
  </a>

  <img *ngSwitchCase="false" src="/assets/images/{{logo.src}}" alt="{{logo.altText}}" id="qa_login_nova_logo"/>
</div>

<div class="content-header__title-status-group">
  <div class="content-header__titles" [ngSwitch]="!!headerInfo.titleHref">
    <h1 *ngIf="headerInfo.title" id="qa_headerPane_projectTitle_text" class="content-header__title">
    <span *ngIf="headerInfo.pre_title" id="qa_header_preTitle" class="content-header__pre-title h4">
      {{ headerInfo.pre_title }}
    </span>

    <span *ngSwitchCase="true" id="qa_header_title" class="content-header__title h3 content-header__title--link">
      <a [routerLink]="headerInfo.titleHref.href" [queryParams]="headerInfo.titleHref.queryParams || {}">{{headerInfo.title}}</a>
    </span>

    <span *ngSwitchCase="false" id="qa_header_title" class="content-header__title h3">
      {{ headerInfo.title }}
    </span>
    </h1>

    <ng-container *ngIf="headerInfo.sub_title" [ngSwitch]="!!headerInfo.subTitleHref">
      <p *ngSwitchCase="false" id="qa_header_subtitle" class="content-header__sub-title">
        {{ headerInfo.sub_title }}
      </p>

      <a *ngSwitchCase="true" id="qa_header_subtitle" [routerLink]="headerInfo.subTitleHref.href" [queryParams]="headerInfo.subTitleHref.queryParams || {}" class="content-header__sub-title content-header__title--link">
        {{ headerInfo.sub_title }}
      </a>
    </ng-container>

    <nav *ngIf="!!headerInfo.breadcrumbs" class="breadcrumbs" aria-label="breadcrumb">
      <ol class="breadcrumbs__list">
        <ng-container *ngFor="let crumb of headerInfo.breadcrumbs">
          <li *ngIf="crumb.route" class="breadcrumbs__item"><a [routerLink]="crumb.route">{{crumb.title}}</a></li>
          <li *ngIf="!crumb.route" class="breadcrumbs__item">{{crumb.title}}</li>
        </ng-container>
      </ol>
    </nav>
  </div>

  <div class="content-header__status-actions">
    <p *ngIf="headerInfo.status" id="qa_header_workflow_status" class="content-header__status h4">
      {{ headerInfo.statusType ? headerInfo.statusType : 'Status' }}: {{ headerInfo.status }}
    </p>

    <div *ngIf="!printMode && (headerInfo?.actions?.length || isBookmarkable)" class="content-header__actions">
      <ng-container *ngIf="isBookmarkable" [ngSwitch]="activeBookmark">
        <button *ngSwitchCase="true" title="Remove Bookmark"
                id="qa_header_bookmark_button" class="action-button action-button--secondary action-button--small"
                [attr.aria-label]="'Remove Bookmark'"
                (click)="removeBookmark()">
          <icon iconId="bookmark-fill"></icon>
        </button>

        <button *ngSwitchCase="false" title="Add Bookmark"
                id="qa_header_bookmark_button" class="action-button action-button--secondary action-button--small"
                [attr.area-label]="'Add Bookmark'"
                (click)="addBookmark()">
          <icon iconId="bookmark-empty"></icon>
        </button>
      </ng-container>

      <app-action-bar [actions]="headerInfo.actions"></app-action-bar>
    </div>

    <div class="content-header__messages" *ngIf="showHeaderMessage()">
      <div *ngIf="headerMessage.type === 'loading'" class="tool-tray-status fadeInRight">
        <p data-qa="app_action_loading_text" class="message">
          {{headerMessage.message ? headerMessage.message : 'loading'}}
          <span class="loader-spinner-inline"></span>
        </p>
      </div>

      <div *ngIf="headerMessage.type === 'success'" class="tool-tray-status fadeInRight">
        <icon iconId="checkmark" class="icon--block icon--positive"></icon>
        <p data-qa="app_action_confirmation_text" class="message">{{headerMessage.message ? headerMessage.message : 'Success!'}}</p>
      </div>

      <div *ngIf="headerMessage.type === 'fail'" class="tool-tray-status fadeInRight">
        <icon iconId="alert" class="icon--block icon--negative"></icon>
        <p data-qa="app_action_failure_text" class="message">{{headerMessage.message ? headerMessage.message : 'There was an error, please try again.'}}</p>
      </div>
    </div>
  </div>
</div>
