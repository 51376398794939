import { NgControl } from '@angular/forms';
import { Directive, ElementRef, HostBinding, HostListener, OnInit } from '@angular/core';
import { PhoneTransform } from '../../pipes';

/*
 * This directive is a one-way transform into a formatted phone number.
 * <input phone-transform/> -> on blur, parses strings into numbers, and then formats the string into (999) 999-9999
 * or, given 11 digits, +9 (999) 999-9999.
 */

@Directive({
  selector: '[phone-transform]',
  providers: [PhoneTransform],
})
export class PhoneTransformerDirective implements OnInit {

  constructor(private control: NgControl,
              private el: ElementRef,
              private pipe: PhoneTransform) {}

  value: string;
  formattedAmount: string;

  @HostListener('blur', ['$event']) onBlur(event) {
    this.transformValue(event.target.value);
    this.setFormValues();
  }

  @HostBinding('attr.placeholder') placeholder = '(___) ___-____';

  ngOnInit() {
    const parent = this.el.nativeElement;

    const savedValue = parent.value;
    if (savedValue) {
      this.transformValue(savedValue);
      this.setFormValues();
    }
  }

  transformValue(value) { // happens on blur by default
    const { formatted, raw } = this.pipe.transformForField(value);
    this.value = raw;
    this.formattedAmount = formatted;
  }

  setFormValues() {
    if (this.value === null) {
      this.control.control.setValue(undefined);
      this.control.valueAccessor.writeValue(null);
      return;
    }
    this.control.control.setValue(this.value);
    this.control.valueAccessor.writeValue(this.formattedAmount);
  }
}
