import * as _ from 'lodash';
import { ModelBase } from './model.base';
import { WORKFLOW_STEPS, STATE_TYPES } from '../consts';
import { Model } from '@app-ngrx-domains';
import { Task } from './task';

export interface IAnnualPlanStrategy {
  id: number;
  annual_plan_id: number;
  objective: string;
  description: string;
  adoption_status: string;
  response_1: string;
  response_2: string;
  response_3: string;
  isRevision?: boolean;
}

export interface IAnnualPlan {
  id: number;
  institution_id: number;
  institution?: Model.Institution;
  duration_id: number;
  status_id: number;
  description: string;
  response_1: string;
  response_2: string;
  response_3: string;
  strategies: { [objective: string]: Array<IAnnualPlanStrategy> };
  tasks?: Array<Model.Task>;
  client_state?: { [name: string]: any };
}

export class AnnualPlan extends ModelBase implements IAnnualPlan {
  public id: number;
  public institution_id: number;
  public institution: Model.Institution;
  public duration_id: number;
  public status_id: number;
  public description: string;
  public response_1: string;
  public response_2: string;
  public response_3: string;
  public strategies: { [objective: string]: Array<IAnnualPlanStrategy> };
  public tasks: Array<Model.Task>;
  public client_state?: { [name: string]: any };

  constructor(raw: any) {
    super();
    if (raw) {
      this.id = raw.id;
      this.institution_id = raw.institution_id;
      this.institution = raw.institution;
      this.duration_id = raw.duration_id;
      this.status_id = raw.status_id;
      this.description = raw.description;
      this.response_1 = raw.response_1;
      this.response_2 = raw.response_2;
      this.response_3 = raw.response_3;
      this.strategies = {};
      if (raw.strategies) {
        raw.strategies.forEach(strategy => {
          if (!this.strategies[strategy.objective]) {
            this.strategies[strategy.objective] = [];
          }
          this.strategies[strategy.objective].push({
            id: strategy.id,
            annual_plan_id: strategy.annual_plan_id,
            objective: strategy.objective,
            description: strategy.description,
            adoption_status: strategy.adoption_status,
            response_1: strategy.response_1,
            response_2: strategy.response_2,
            response_3: strategy.response_3,
          });
        });
      }

      this.tasks = raw.tasks || [];
      const task: any = Task.getTask(this.tasks, 'aebg_annual_plan_submit');
      if (task) {
        // initialize client state
        this.client_state = {};
        this.client_state[WORKFLOW_STEPS.DESCRIPTION + '_dirty'] = false;
        this.client_state[WORKFLOW_STEPS.WORKPLAN + '_dirty'] = false;
        this.client_state[WORKFLOW_STEPS.WORKPLAN1 + '_dirty'] = false;
        this.client_state[WORKFLOW_STEPS.WORKPLAN2 + '_dirty'] = false;
        this.client_state[WORKFLOW_STEPS.WORKPLAN3 + '_dirty'] = false;
        this.client_state[WORKFLOW_STEPS.WORKPLAN4 + '_dirty'] = false;
        this.client_state[WORKFLOW_STEPS.WORKPLAN5 + '_dirty'] = false;
        // update client state with values saved.
        const task_client_state = task.client_state ? task.client_state : {};
        this.client_state = {
          ...this.client_state,
          ...task_client_state,
          ['task_id']: task.id,
        };
      }
    }
  }

  /**
   * Returns target year's annual plan.
   * @static
   * @param {number} target_duration_id
   * @param {Array<any>} annualPlans
   * @returns {*} AnnualPlan
   */
  static targetYearAnnualPlan(target_duration_id: number, annualPlans: Array<any>): any {
    const result = annualPlans.find(ap => ap.duration_id === target_duration_id);
    return result;
  }

  /**
   * Returns status/state as a name to be shown on header bar.
   * @param {number} status_id
   * @returns {string}
   */
  static formatStateHeader(status_id: number): string {
    switch (status_id) {
      case STATE_TYPES.OWNER_SUBMITTED:
        return 'SUBMITTED';

      case STATE_TYPES.ALL_MEMBERS_APPROVED:
        return 'CONSORTIUM APPROVED';

      case STATE_TYPES.STATE_APPROVED:
        return 'APPROVED';

      case STATE_TYPES.EDITED:
        return 'AMENDING';

      case STATE_TYPES.EDIT_SUBMITTED:
        return 'AMENDMENTS SUBMITTED';

      case STATE_TYPES.ATTENTION_NEEDED:
        return 'ATTENTION NEEDED';

      case STATE_TYPES.CREATED:
      default:
        return 'DRAFT';
    }
  }

  /**
   * Formats the state name.
   * @param {number} status_id
   */
  static stateName(status_id: number): string {
    const result = _.startCase(this.formatStateHeader(status_id).toLowerCase());
    return result;
  }

  /**
   * Returns true if annual plan has been certified.
   * @static
   * @param {number} status_id
   * @returns {boolean}
   */
  static isCertified(status_id: number): boolean {
    return status_id && status_id === STATE_TYPES.ALL_MEMBERS_APPROVED;
  }

  /**
   * Returns true if annual plan is in draft mode.
   * @static
   * @param {number} status_id
   * @returns {boolean}
   */
  static isDraft(status_id: number): boolean {
    return !status_id || status_id <= STATE_TYPES.CREATED;
  }

  static isSubmitted(status_id: number): boolean {
    return status_id && status_id === STATE_TYPES.OWNER_SUBMITTED;
  }

  static isAmending(status_id: number): boolean {
    return status_id && status_id === STATE_TYPES.EDITED;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type AnnualPlan = IAnnualPlan;
    export type AnnualPlanStrategy = IAnnualPlanStrategy;
  }
}
