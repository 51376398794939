<li class="site-nav__item"
    [ngClass]="{'last' : isLast}"
    [attr.aria-hidden]="!isVisible">
  <div class="site-nav__item-content" [ngSwitch]="isButton">
    <a #treeItem [tabindex]="isTabbable ? 0 : -1"
       *ngSwitchCase="false"
       (keyup)="handleKeyUp($event)"
       (keypress)="handleKeyPress($event)"
       (click)="handleClick($event)"
       [routerLink]="itemUrl"
       [class.site-nav__item-link--has-icon]="!!iconId"
       [class.active]="isActive" class="site-nav__item-link" role="treeitem">

      <icon *ngIf="iconId" [iconId]="iconId" class="site-nav__item-icon"></icon>

      <span [attr.data-qa]="isVisible ? 'nav-item' : ''" class="site-nav__item-link-text">
        {{itemText}}
      </span>

      <div id="qa_{{id}}_badge" *ngIf="itemBadge" class="site-nav__item-notification-badge">
        {{itemBadge}}
      </div>
    </a>

    <button *ngSwitchCase="true"
      #treeItem
      [tabindex]="isTabbable ? 0 : -1"
      class="site-nav__item-button"
      title="{{itemText}}"
      (keyup)="handleKeyUp($event)"
      (keypress)="handleKeyPress($event)"
      (click)="handleClick($event)"
      role="treeitem">
      <icon *ngIf="iconId" [iconId]="iconId" class="site-nav__item-icon"></icon>
      <span class="site-nav__text-overflow">{{itemText}}</span>
    </button>
  </div>
</li>
