// module imports
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// froala modules & plugins
import 'froala-editor/js/plugins.pkgd.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';

// material Modules
import { A11yModule } from '@angular/cdk/a11y';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop';

// components
import { GenericComponents } from './components';

// directives
import { GenericDirectives } from './directives';

// pipes
import { GenericPipes } from './pipes';

@NgModule({
  declarations: [
    ...GenericComponents,
    ...GenericPipes,
    ...GenericDirectives,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    A11yModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatRippleModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
  ],

  providers: [
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {disabled: true}},
  ],
  exports: [
    ...GenericComponents,
    ...GenericPipes,
    ...GenericDirectives,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatIconModule,
    FroalaViewModule,
    FroalaEditorModule,
  ],
})
export class GenericModule { }
