import { Action } from '@ngrx/store';
import { Actions, State, Model } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { Institution, Member, Cfad, Task, EnumErrorTypes } from '@app-models';

/**
 * List of action types
 */
const ACTION_PREFIX = 'ENTITIES_';
export const ENTITIES_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  NOOP: `${ACTION_PREFIX}NOOP`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  SET_OWNER_LOGO: `${ACTION_PREFIX}SET_OWNER_LOGO`,
  UPDATE_INFO: `${ACTION_PREFIX}UPDATE_INFO`,
  UPDATE_INFO_SUCCESS: `${ACTION_PREFIX}UPDATE_INFO_SUCCESS`,
  ADD_MEMBER: `${ACTION_PREFIX}ADD_MEMBER`,
  ADD_MEMBER_SUCCESS: `${ACTION_PREFIX}ADD_MEMBER_SUCCESS`,
  ADD_NEW_MEMBER: `${ACTION_PREFIX}ADD_NEW_MEMBER`,
  REFRESH_MEMBER: `${ACTION_PREFIX}REFRESH_MEMBER`,
  REMOVE_MEMBER: `${ACTION_PREFIX}REMOVE_MEMBER`,
  REMOVE_MEMBER_SUCCESS: `${ACTION_PREFIX}REMOVE_MEMBER_SUCCESS`,
  SET_VOTING_ONLY_STATUS: `${ACTION_PREFIX}SET_VOTING_ONLY_STATUS`,
  SET_CURRENT_duration_id: `${ACTION_PREFIX}SET_CURRENT_duration_id`,
  UPDATE_MEMBER_STATE: `${ACTION_PREFIX}UPDATE_MEMBER_STATE`,
  UPDATE_MEMBER_CLIENT_STATE: `${ACTION_PREFIX}UPDATE_MEMBER_CLIENT_STATE`,
  UPDATE_MEMBER_CLIENT_STATE_SUCCESS: `${ACTION_PREFIX}UPDATE_MEMBER_CLIENT_STATE_SUCCESS`,
  VALIDATE: `${ACTION_PREFIX}VALIDATE`,
};

/**
 * Action creator class
 */
export class EntitiesActions {

  /**
   * Loads proposal as entities, made up of one lead, and members.
   */
  load(proposal: any, current_year: Model.Duration, currentYearClosedOut: boolean, lastYearClosedOut: boolean, current_institution_id?: number): ActionWithPayload<any> {
    // transform response data to redux states.

    // current program year
    const target_duration_id = current_year.id;

    // get cfad to read off cfad related data associated with the target year.
    const cfad = Cfad.targetYearCfad(target_duration_id, proposal.cfads);

    // hydrate owner info.
    const state: any = {
      program_year: current_year,
      lead: {
        duration_id: target_duration_id,
        proposal_id: proposal.id,
        institution_id: proposal.lead_institution.id,
        institution: Institution.iObject(proposal.lead_institution),
      },
      direct_funding: cfad.direct_funding,
      members: {},
      // set institution as current member if it's member institution
      current_member_institution_id: (current_institution_id && proposal.lead_institution.id !== current_institution_id) ?  current_institution_id : null,
      // assume program year
      current_duration_id: target_duration_id,
      current_year_closed_out: currentYearClosedOut,
      last_year_closed_out: lastYearClosedOut,
    };

    // hydrate member data - order them by years.
    proposal.members.forEach(member => {
      const member_duration_id = member.duration_id;

      if (!state.members[member_duration_id]) {
        state.members[member_duration_id] = [];
      }

      state.members[member.duration_id].push(new Member(member).iObject<Model.Member>());
    });

    // now sort the members by institution names.
    _.forOwn(state.members, (entities: Array<Model.Member>, key_duration_id) => {
      state.members[key_duration_id] = _.sortBy(state.members[key_duration_id], ['institution.name']);
    });

    return {
      type: ENTITIES_ACTION_TYPES.LOAD,
      payload: state
    };
  }

  /**
   * Resets/clears data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.LOAD,
      payload: {
        ...State.Entities,
      },
    };
  }

  /**
   * no-op action.
   * @returns {Action}
   */
  noOp(): Action {
    return {
      type: ENTITIES_ACTION_TYPES.NOOP,
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: ENTITIES_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Sets owner's logo that just got uploaded.
   * @param {string} logo_url
   */
  setOwnerLogoUrl(logo_url: string): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.SET_OWNER_LOGO,
      payload: logo_url,
    };
  }

  /**
   * Updates entity's information.
   * @param req - request
   */
  updateInfo(req: { institution_id: number, info: Model.Institution }): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.UPDATE_INFO,
      payload: req,
    };
  }

  /**
   * Successfully updated entity's information.
   * @param result
   */
  updateInfoSuccess(result: { institution_id: number, info: Model.Institution }): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.UPDATE_INFO_SUCCESS,
      payload: result
    };
  }

  /**
   * Associates an institution as a member agency.
   * @param {{ leadProposalId: number, leadInstitutionId: number, memberInstitutionId: number, duration_id: number }} req
   * @returns {Action}
   */
  associateMember(req: { leadProposalId: number, leadInstitutionId: number, memberInstitutionId: number, duration_id: number, voting_only: boolean }): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.ADD_MEMBER,
      payload: req,
    };
  }

  /**
   * Handles new member agency that just got added.
   * @param {any} member
   * @returns {Action}
   */
  addMemberSuccess(member: any): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.ADD_MEMBER_SUCCESS,
      payload: (new Member(member)).iObject<Model.Member>(),
    };
  }

  createMember(req: { leadProposalId: number, leadInstitutionId: number, duration_id: number, memberInstitution: any, voting_only: boolean }): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.ADD_NEW_MEMBER,
      payload: req,
    };
  }

  /**
   * Removes member agency from owner.
   * @param {{ leadInstitutionId: number, leadProposalId: number, memberInstitutionId: number, duration_id: number }} req
   * @returns {Action}
   */
  removeMember(req: { leadProposalId: number, leadInstitutionId: number, memberInstitutionId: number, duration_id: number }): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.REMOVE_MEMBER,
      payload: req,
    };
  }

  /**
   * Handles member agency that just got removed.
   * @param {any} req
   * @returns {Action}
   */
  removeMemberSuccess(req: any): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.REMOVE_MEMBER_SUCCESS,
      payload: req,
    };
  }

  refreshMember(gotoUrl = ''): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.REFRESH_MEMBER,
      payload: { gotoUrl }
    };
  }

  setMemberVotingStatus(member: any): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.SET_VOTING_ONLY_STATUS,
      payload: member
    };
  }

  updateMemberState(changes: any): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.UPDATE_MEMBER_CLIENT_STATE,
      payload: changes,
    };
  }

  updateMemberStateSuccess(res: any): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.UPDATE_MEMBER_CLIENT_STATE_SUCCESS,
      payload: new Task(res).iObject<Model.Task>(),
    };
  }

  setCurrentYearDurationId(yearDurationId: number): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.SET_CURRENT_duration_id,
      payload: yearDurationId
    };
  }

  /*******************************************************
   * Workflow related actions
   *******************************************************/

  /** Validate work flow steps
   * @param {string} route or WORKFLOW_STEPS.ALL
   * @returns {Action}
   */
  validate(step: string): ActionWithPayload<any> {
    return {
      type: ENTITIES_ACTION_TYPES.VALIDATE,
      payload: step,
    };
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Entities = new EntitiesActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Entities: EntitiesActions;
  }
}
