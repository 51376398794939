import { IFilesState, Query, Queries, Root, Model, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IFilesState>('Files');

/**
 * Predefined queries to run against root states.
 */
export interface IFilesQueries {
  getItems: Query<Array<Model.Document>>;
}

Queries.Files = {
  getItems: fromRoot(state => state.items),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Files: Query<IFilesState>;
  }

  interface Queries {
    Files: IFilesQueries;
  }
}
