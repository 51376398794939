import { Actions, Model, State } from '@app-ngrx-domains';
import { ActionWithPayload, AttributeActions } from '@app-libs';

/**
 * Proposals action types
 */
export const PROPOSALS_ACTION_PREFIX = 'PROPOSALS_';
export const PROPOSALS_ACTION_TYPES = {
    // ********** Action Types Managed by Parent/AttributeActions Class ********
    UPSERT_ATTRIBUTE: `${PROPOSALS_ACTION_PREFIX}UPSERT_ATTRIBUTE`,
    ADD_MULTI_ATTRIBUTE: `${PROPOSALS_ACTION_PREFIX}ADD_MULTI_ATTRIBUTE`,
    DELETE_MULTI_ATTRIBUTE: `${PROPOSALS_ACTION_PREFIX}DELETE_MULTI_ATTRIBUTE`,
    CREATE_ATTRIBUTE_SUCCESS: `${PROPOSALS_ACTION_PREFIX}CREATE_ATTRIBUTE_SUCCESS`,
    UPSERT_ATTRIBUTE_SUCCESS: `${PROPOSALS_ACTION_PREFIX}UPSERT_ATTRIBUTE_SUCCESS`,
    DELETE_ATTRIBUTE_SUCCESS: `${PROPOSALS_ACTION_PREFIX}DELETE_ATTRIBUTE_SUCCESS`,
    CREATE_EFFORT_AREA: `${PROPOSALS_ACTION_PREFIX}CREATE_EFFORT_AREA`,
    CREATE_EFFORT_AREA_SUCCESS: `${PROPOSALS_ACTION_PREFIX}CREATE_EFFORT_AREA_SUCCESS`,
    CREATE_MULTI_EFFORT_AREAS: `${PROPOSALS_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS`,
    CREATE_MULTI_EFFORT_AREAS_SUCCESS: `${PROPOSALS_ACTION_PREFIX}CREATE_MULTI_EFFORT_AREAS_SUCCESS`,
    UPDATE_EFFORT_AREA: `${PROPOSALS_ACTION_PREFIX}UPDATE_EFFORT_AREA`,
    UPDATE_EFFORT_AREA_SUCCESS: `${PROPOSALS_ACTION_PREFIX}UPDATE_EFFORT_AREA_SUCCESS`,
    DELETE_EFFORT_AREA: `${PROPOSALS_ACTION_PREFIX}DELETE_EFFORT_AREA`,
    DELETE_EFFORT_AREA_SUCCESS: `${PROPOSALS_ACTION_PREFIX}DELETE_EFFORT_AREA_SUCCESS`,
    CREATE_ATTRIBUTE_EFFORT_AREA: `${PROPOSALS_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA`,
    CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS: `${PROPOSALS_ACTION_PREFIX}CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS`,
    APPEND_ITEM: `${PROPOSALS_ACTION_PREFIX}APPEND_ITEM`,
    DELETE_ITEM_FILE: `${PROPOSALS_ACTION_PREFIX}DELETE_ITEM_FILE`,
    DELETE_ITEM_SUCCESS: `${PROPOSALS_ACTION_PREFIX}DELETE_ITEM_SUCCESS`,
    DELETE_MULTI_EFFORT_AREAS: `${PROPOSALS_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS`,
    DELETE_MULTI_EFFORT_AREAS_SUCCESS: `${PROPOSALS_ACTION_PREFIX}DELETE_MULTI_EFFORT_AREAS_SUCCESS`,
    // ********** Action Types Managed by Parent/AttributeActions Class ********

    LOAD: `${PROPOSALS_ACTION_PREFIX}LOAD`,
    UPDATE: `${PROPOSALS_ACTION_PREFIX}UPDATE`,
};

/**
 * Proposals action class.
 */
export class ProposalsActions extends AttributeActions {

  constructor() {
    super(PROPOSALS_ACTION_PREFIX);
  }

  /**
   * Loads list of proposals and sets one as a current one, if set.
   * @param proposals
   * @param currentProposalId
   */
  load(proposals: Array<Model.ProposalBase>, currentProposalId?: number): ActionWithPayload<any> {
    return {
      type: PROPOSALS_ACTION_TYPES.LOAD,
      payload: {
        current_item_id: currentProposalId ? currentProposalId : null,
        items: proposals,
      }
    };
  }

  /**
   * Resets redux store.
   */
  reset(): ActionWithPayload<any> {
    return {
      type: PROPOSALS_ACTION_TYPES.LOAD,
      payload: {
        ...State.Proposals
      }
    };
  }

  /**
   * Sets up current proposal.
   * @param proposalId
   */
  setCurrent(proposalId: number): ActionWithPayload<any> {
    return {
      type: PROPOSALS_ACTION_TYPES.UPDATE,
      payload: {
        current_item_id: proposalId,
      }
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Proposals = new ProposalsActions();

/**
 * Adds actions definitions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Proposals: ProposalsActions;
  }
}
