import { Model } from '@app-ngrx-domains';
import { IEffortArea } from '../effort-area';
import { STATES, TASK_TYPES } from '@app-consts';
import { ProposalBase, AREA_NAMES } from '..';

export interface IThreeYearPlan extends IEffortArea {
  // Details
  executive_summary: string;
  assurance_ids: Array<Model.AttributeValue>

  // Assessment
  assessment_overview: string;
  regional_alignment: string;
  educational_needs: string;
  entities_contribution: string;
  program_area_participants: Array<Model.EAProgramAreaParticipants>;
  service_providers: Array<Model.AttributeValue>;
  regional_service_evaluation: string;

  // Metrics
  vision_goals: Array<Model.EAVisionGoal>;
  target_goals: Array<Model.EATargetGoal>;
  member_spending_targets: Array<Model.EAMemberSpendingTargets>;

  // Objectives
  workplan_objectives: Array<Model.EAWorkplanObjective>;

  // Activities & Outcomes
  workplan_activities: Array<Model.EAWorkplanActivity>;

  // Funds Evaluation
  funds_evaluation: string;
}

export class ThreeYearPlan {

  /**
   * Returns target year's three year plan.
   * @static
   * @param {number} target_duration_id
   * @param {Array<Model.ThreeYearPlan>} threeYearPlans
   * @returns {*} ThreeYearPlan
   */
  static targetYearThreeYearPlan(target_duration_id: number, threeYearPlans: Array<Model.EAThreeYearPlan>): any {
    const result = threeYearPlans.find(plan => plan.duration_id === target_duration_id);
    return result;
  }

  static isDraft(proposal, durationId): boolean {
    return ProposalBase.isAreaDraft(proposal, AREA_NAMES.THREE_YEAR_PLAN, durationId);
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAThreeYearPlan = IThreeYearPlan;
  }
}
