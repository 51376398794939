import { ErrorHandler, Injector, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@app-ngrx-domains';
import * as Sentry from '@sentry/browser';

const chunkLoadErrorMessage = /Loading chunk [\d]+ failed/;

/**
 * The app's custom error handler that extends Angular's version; it is created
 * to listen for any unhandled exceptions and send them to the server.
 */
 @Injectable()
export class AppErrorHandler extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  /**
   * Our Custom Error Handler.
   */
  handleError(error) {
    // Force page reload if we encounter chunk load error from lazy loading route
    // which files no longer exist
    if (chunkLoadErrorMessage.test(error.message)) {
      window.location.reload();
      return true;
    }

    // Forward error to Sentry.io
    Sentry.captureException(error.originalError || error);

    /**
     * Shows alert message
     */
    const showAlert = (message: string, refresh: boolean = false) => {
      const store = this.injector.get(Store);
      if (store && store.dispatch) {
        store.dispatch(Actions.App.showAlert(message, refresh));
      }
    };

    /**
     * Shows general alert message, then passes the error to parent if requested.
     * @param {boolean} passToParent
     */
    const showGeneralAlert = (passToParent: boolean) => {
      showAlert(`The application encountered an error.<br>You must refresh the browser to continue working.`, true);

      if (passToParent) {
        // let Angular handle the error.
        super.handleError(error);
      }
    };

    // On non-production, just show an alert.
    showGeneralAlert(true);
    return true;
  }
}
