import { IEffortArea } from '../effort-area';
import { Model } from '@app-ngrx-domains';
import { WORKFLOW_TYPES } from '@app-consts';

export const FundingProgramTypes = {
  TOP: 'TOP Code',
  Across: 'Across CTE',
  Admin: 'Administration'
}

export const FundingProgramShortNames = {
  [FundingProgramTypes.TOP]: 'Top',
  [FundingProgramTypes.Across]: 'Across',
  [FundingProgramTypes.Admin]: 'Admin',
}

export const FundingProgramSkipAttributes = {
  [FundingProgramTypes.TOP]: 'no_top_codes',
  [FundingProgramTypes.Across]: 'no_cte_programs',
  [FundingProgramTypes.Admin]: 'no_admin_programs'
};

export const FundingProgramRouteNames = {
  [FundingProgramTypes.TOP]: 'topcodes',
  [FundingProgramTypes.Across]: 'acrosscte',
  [FundingProgramTypes.Admin]: 'admin',
}

export const FundingProgramWorkflowProgramTypes = {
  [WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_TOP_CODES]: FundingProgramTypes.TOP,
  [WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ACROSS_CTE]: FundingProgramTypes.Across,
  [WORKFLOW_TYPES.PERKINS_SUB_APPLICATIONS_ADMIN]: FundingProgramTypes.Admin,
}

export interface IEAFundingProgram extends IEffortArea {
  type: string;
  top_code: number;
  // Core Indicators
  information_available: boolean;
  num_meets_levels: number;
  num_below_levels: number;
  num_not_reported: number;
  reasons_unavailable: Array<Model.AttributeValue>;
  // Program Info
  improvement_issues: string;
  issue_addressment: string;
  activity_types_funded: Array<Model.AttributeValue>;
  program_activities: Array<Model.EAProgramActivities>;
  // Budget
  plan_budget_items: Array<Model.EAPlanBudgetItem>;
  // Reporting
  is_removed: boolean;
  direct_expenditure_amount: number;
  admin_expenditure_amount: number;
}

export class FundingProgram {

  /**
   * Returns program's short name, used mainly in workflow steps.
   * @param program
   * @param index
   * @param topCodes
   */
  static getProgramShortName(program: Model.EAFundingProgram, index: number, topCodes: Array<Model.TopCode> = []) {
    let programName = FundingProgramShortNames[program.type];
    if (program.type === FundingProgramTypes.TOP) {
      const topCode = topCodes.find(code => code.id === program.top_code);
      programName = !!topCode ? topCode.code : 'Unknown';
    }

    const id = (index + 1).toString();
    return `${programName}.${id.padStart(2, '0')}`;
  }
}


declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAFundingProgram = IEAFundingProgram;
  }
}
