import {Component, Input} from '@angular/core';
import {Model} from '@app-ngrx-domains';

@Component({
  selector: '[app-nav-workflow-step]',
  templateUrl: './nav-workflow-step.component.html',
})
export class NavWorkflowStepComponent {
  @Input() step: Model.WorkflowStep;

  constructor() {}

  public isNil(value: any): boolean {
    return value === undefined || value === null;
  }
}
