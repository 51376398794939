import { IContactsState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { CONTACTS_ACTION_TYPES } from './contacts.action';
import { UserRoleScope } from '@app-models';

export function ContactsReducer(state: IContactsState = State.Contacts, action: ActionWithPayload<any>): IContactsState {
  switch (action.type) {
    case CONTACTS_ACTION_TYPES.LOAD: {
      return action.payload;
    }

    case CONTACTS_ACTION_TYPES.ADD_CONTACT_SUCCESS: {
      // Filter out duplicate contacts for proposals that span multiple funds
      const added = action.payload;
      const reducedContacts = state.items.filter(c => {
        return c.user_id !== added.user_id || c.role_id !== added.role_id || c.institution_id !== added.institution_id;
      });
      reducedContacts.push(added);

      return {
        ...state,
        items: reducedContacts
      };
    }

    case CONTACTS_ACTION_TYPES.UPDATE_CONTACT_SUCCESS: {
      // The updated role will have the same ID as before, we need to remove the old one
      const replaced = action.payload;
      const reducedContacts = state.items.filter(c => c.id !== replaced.id);
      reducedContacts.push(replaced);

      return {
        ...state,
        items: reducedContacts
      };
    }

    case CONTACTS_ACTION_TYPES.DELETE_CONTACT_SUCCESS: {
      // Removes a set of contacts from the state contacts object
      const deleted_contacts = action.payload || [];
      let contacts_state = state.items;

      deleted_contacts.forEach(contact => {
        const identifier = UserRoleScope.stripped(contact);
        contacts_state = contacts_state.filter(item => {
          let match = true;
          Object.keys(identifier).forEach(field => {
            match = match && (identifier[field] === item[field]);
          });
          return !match;
        });
      });

      return {
        ...state,
        items: contacts_state
      };
    }

    default:
      return state;
  }
}
