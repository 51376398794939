import { Model } from '@app-ngrx-domains';
import { ProposalBase } from './proposal-base';
import { TASK_TYPES } from '../consts';

export const SWP_K12_v2_2020 = 2020;
export const SWP_K12_BUDGET_NARRATIVE_LIMIT = 2500;
export const SWP_K12_QUALITY_ELEMENTS = [
  { label: 'Curriculum and Instruction', value: 'curriculum_and_instruction' },
  { label: 'College and Career Exploration', value: 'college_and_career' },
  { label: 'Postsecondary Transition and Completion', value: 'postsecondary_transition' },
  { label: 'Work-Based Learning', value: 'work_based_learning'}
];
export const SWP_K12_v2_TASKS = {
  application_submit: TASK_TYPES.RFA_APPLICATION_SUBMIT,
  funding_offer: TASK_TYPES.RFA_FUNDING_OFFER,
  funding_accept: TASK_TYPES.RFA_FUNDING_ACCEPT,
  plan_submit: TASK_TYPES.RFA_PLAN_SUBMIT,
  plan_approve: TASK_TYPES.RFA_PLAN_APPROVE,
};

export interface ISWPKProposal extends Model.ProposalBase {
  ///////////////////////////////////////////////////
  // shared
  allocations: Array<Model.Allocation & { indirect_funds: number, direct_funds: number }>;
  application_contributions: Array<Model.EAApplicationContribution>;
  application_region: number;
  cde_sectors: Array<Model.AttributeValue>;
  match_reports: Array<Model.EAInstitutionMatchReports>;

  ////////////////////////////////////////////////////////
  // v1
  // summary tab
  read_legislation: boolean;
  lcap_alignment: boolean;
  // lead & partner agencies
  is_rural_district: boolean;
  // collaborative partners
  collaborative_partnerships: string;
  resources_and_contributions: string;
  // statement of need
  pathway_sectors: Array<Model.AttributeValue>;
  pathway_selection_narrative: string;
  cte_status_narrative: string;
  applying_to_cte: boolean;
  // target pupil
  pf_sectors: Array<Model.AttributeValue>;
  impacted_groups: Array<Model.AttributeValue>;
  serves_dropouts: number;
  serves_grades: Array<Model.AttributeValue>;
  pupil_considerations: string;
  // pathway
  goals_and_priorities: string;
  pathway_improvements: Array<Model.EAPathwayImprovement>;
  // sow
  statement_of_works: Array<Model.EAStatementOfWork>;
  capability: Model.EACapability;
  // budget
  estimated_budget: number;
  budget_narrative: string;
  budget_items: Array<Model.BudgetItem>;
  plan_budget_items: Array<Model.EAPlanBudgetItem>;
  plan_expenditure_forecasts: Array<Model.EAPlanExpenditureForecast>;

  ////////////////////////////////////////////////////////
  // v2
  // pathway identification
  assurance_ids: Array<Model.AttributeValue>;

  // higher ed & collaborative partners
  collaborative_partners: Array<Model.EACollaborativePartner>;

  // problem statement & project objectives
  problem_statement: string;
  project_objectives: string;
  serves_high_dropout_rate: boolean;
  serves_unduplicated_pupils: boolean;
  serves_special_populations: boolean;

  // sectors & pathways
  design_purposes: Array<Model.EADesignPurposes>;

  // budget
  institution_match_funds: Array<Model.EAInstitutionMatchFunds>;
  institution_budget_narratives: Array<Model.EAInstitutionBudgetNarrative>;
  object_code_budget_narratives: Array<Model.EAObjectCodeBudgetNarrative>;
  object_code_budgets: Array<Model.EAObjectCodeBudgets>;
  total_revised_amount?: number; // v2 & beyond

  // workplan
  workplan_quality_elements: Array<Model.AttributeValue>;
  leveraged_funds: Array<Model.AttributeValue>;
  leveraged_funds_other: string;
  pathway_strategies: Array<Model.EAPathwayStrategy>;

  ////////////////////////////////////////////////////////
  // v4
  calpass_designees: Array<Model.AttributeValue>;
  historical_performance_proposal_ids: Array<Model.AttributeValue>;
  historical_successes: string;
  historical_changes: string;
  pc_pupils: Array<Model.AttributeValue>;
  pc_populations: Array<Model.AttributeValue>;
}

export interface ISWPKHistoricalProposal extends ISWPKProposal {
  amount_funded: number;
}

export class SWPKProposal extends ProposalBase {

  /**
   * Returns list of tasks based on the version type.
   */
  static taskTable: { [version: string]: { [name: string]: string } } = {
    v1: {
      application_submit: TASK_TYPES.SWPK_APPLICATION_SUBMIT,
      funding_offer: TASK_TYPES.SWPK_FUNDING_SUBMIT,
      funding_accept: TASK_TYPES.SWPK_FUNDING_APPROVE,
      plan_submit: TASK_TYPES.SWPK_PLAN_SUBMIT,
      plan_approve: TASK_TYPES.SWPK_PLAN_APPROVE,
    },
    v2: SWP_K12_v2_TASKS,
    v4: SWP_K12_v2_TASKS,
  };

}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type SWPKProposal = ISWPKProposal;
    export type SWPKHistoricalProposal = ISWPKHistoricalProposal;
 }
}
