import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.AnnualPlans = [];

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export type IAnnualPlansState = Array<Model.AnnualPlan>;

  interface State {
    AnnualPlans: IAnnualPlansState;
  }
}
