import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store, Action } from '@ngrx/store';
import { State } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { APP_ACTION_TYPES } from './app.action';

/**
 * Injectable Workflow effects class
 */
@Injectable()
export class AppEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private store: Store<State>
  ) {
  }

  /**
   * Routes to requested path.
   */
  @Effect({dispatch: false})
  public go$: Observable<Action> = this.actions$.pipe(
    ofType(APP_ACTION_TYPES.NAVIGATE_GO),
    map(toPayload),
    tap(payload => {
      if (payload.extras) {
        this.router.navigate(payload.path, payload.extras);
      } else {
        this.router.navigate(payload.path);
      }
    })
  );
}
