<form [formGroup]="form" autocomplete="off">
  <div id="qa_passwordReset_resetPassword_card" class="card card--reset-password">
    <div class="card__header">
      <div class="card-title">
        <h3 class="h3">Reset Password</h3>
      </div>
    </div>

    <div class="card__body">
      <input-group formGroupName="passwords" [control]="form.controls['passwords']" headingText="Reset Password" [legendHidden]="true">
        <input-field labelText="New Password">
          <input inputRef id="qa_new_password_input" formControlName="password" type="password" aria-required="true">
        </input-field>

        <input-field labelText="Confirm Password">
          <input inputRef id="qa_password_confirmation_input" formControlName="password_confirmation" type="password" aria-required="true">
        </input-field>
      </input-group>
    </div>

    <div class="align-right">
      <button type="submit" (click)="onSubmit()" [disabled]="!form.valid" class="action-button action-button--primary">
        Submit
      </button>

      <po-status-message [statusMessage]="statusMessage"></po-status-message>
    </div>
  </div>
</form>
