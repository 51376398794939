import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'full-page-modal',
  templateUrl: './full-page-modal.component.html'
})
export class FullPageModalComponent implements OnInit {

  @Input('small') small = false;
  @Input('heading') heading: string;
  @Input('button-action') buttonAction: string ;
  @Input('button-action-loading') buttonActionLoading: any;
  @Input('button-cancel') buttonCancel: string;
  @Input('button-action-disabled') buttonActionDisabled = false;
  @Input('button-cancel-disabled') buttonCancelDisabled = false;
  @Input('return-value') returnValue: any;

  @Output() action: EventEmitter<string> = new EventEmitter();
  @Output() dismiss: EventEmitter<string> = new EventEmitter();
  @Output() cancel: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  doAction(action: string) {
    this.action.emit(this.returnValue);
  }

  dismissModal(result: string) {
    this.dismiss.emit(this.returnValue);
  }

  cancelModal() {
    this.cancel.emit();
  }

}
