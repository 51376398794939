import { IMetricsState, Model, Query, Queries, Root, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IMetricsState>('Metrics');

/**
 * Predefined queries to run against root states.
 */
export interface IMetricsQueries {
  getGPMetrics: Query<Array<Model.MetricItem>>;
  getLVGMetrics: Query<Array<any>>;
  getSEPMetrics: Query<Array<any>>;
  getSEPDIGs: Query<Array<any>>; // Returns only the DI groups according to the metric data
  getLBMetrics: Query<Array<Model.LBMetric>>;
  getSWPMetrics: Query<Array<Model.LBMetric>>;
  getCAEPMetrics: Query<Array<Model.LBMetric>>;
}

Queries.Metrics = {
  getGPMetrics: fromRoot(state => state.gp_metrics),
  getLVGMetrics: fromRoot(state => state.lvg_metrics),
  getSEPMetrics: fromRoot(state => state.sep_metrics),
  getSEPDIGs: fromRoot(state => state.sep_metrics.filter(metric => !!metric.di_bin)),
  getLBMetrics: fromRoot(state => state.lb_metrics),
  getSWPMetrics: fromRoot(state => state.swp_metrics),
  getCAEPMetrics: fromRoot(state => state.caep_metrics),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Metrics: Query<IMetricsState>;
  }

  interface Queries {
    Metrics: IMetricsQueries;
  }
}
