import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone_extension' })
export class PhoneExtensionTransform implements PipeTransform {
  transform(value, length = 'med') {
    if (value && value.length > 0) {
      switch (length) {
        case 'short':
          return 'ex: ' + value;
        case 'med':
          return 'ext: ' + value;
        case 'long':
          return 'extension: ' + value;
      }
    } else {
      return '';
    }
  }
}
