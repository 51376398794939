import { ModelBase } from './model.base';
import { STATE_TYPES, TASK_TYPES, WORKFLOW_STEPS } from '../consts';
import { startCase as _startCase } from 'lodash';
import { Model } from '@app-ngrx-domains';
import { Task } from './task';

export interface ICfad {
  id: number;
  institution_id?: number;
  proposal_id?: number;
  direct_funding?: boolean;
  last_direct_funding?: boolean;
  rationale?: string;
  fiscal_approach?: string;
  justification?: string;
  duration_id?: number;
  year?: Model.Duration;
  status_id?: number;
  tasks?: Array<Model.Task>;
  client_state?: { [name: string]: any };
  fiscal_agent_id?: number;
}

export class Cfad extends ModelBase implements ICfad {
  public id: number;
  public institution_id: number;
  public proposal_id: number;
  public direct_funding: boolean;
  public last_direct_funding: boolean;
  public rationale: string;
  public fiscal_approach: string;
  public justification: string;
  public status: Object;
  public status_id: number;
  public duration_id: number;
  public year: Model.Duration;
  public tasks: Array<Model.Task>;
  public client_state?: { [name: string]: any };
  public fiscal_agent_id?: number;

  constructor(raw: any) {
    super();
    if (raw) {
      this.id = raw.id;
      this.institution_id = raw.institution_id;
      this.proposal_id = raw.proposal_id;
      this.direct_funding = raw.direct_funding ? true : false;
      this.last_direct_funding = raw.last_direct_funding ? true : false;
      this.rationale = raw.rationale;
      this.fiscal_approach = raw.fiscal_approach;
      this.justification = raw.justification;
      this.status = raw.status;
      this.status_id = raw.status_id;
      this.duration_id = raw.duration_id;
      this.year = raw.year;
      this.tasks = raw.tasks.filter(t =>
        t.task_type === TASK_TYPES.CAEP_CFAD_SUBMIT || t.task_type === TASK_TYPES.CAEP_CFAD_APPROVE) || [];

      // initialize client state
      this.client_state = {
        disbursment_change_answered: false,
      };
      this.client_state[WORKFLOW_STEPS.DECLARATION + '_dirty'] = false;
      this.client_state[WORKFLOW_STEPS.CERTIFIERS + '_dirty'] = true;
      this.client_state[WORKFLOW_STEPS.ALLOCATIONS + '_dirty'] = false;
      const task = Task.getTask(this.tasks, 'aebg_cfad_submit');
      // update client state with values saved.
      if (task) {
        this.client_state = {
          ...this.client_state,
          ...task.client_state,
          ['task_id']: task.id,
        };
      }

      this.fiscal_agent_id = raw.fiscal_agent_id;
    }
  }

  /**
   * Returns funding channel as a long name.
   * @param {boolean} direct_funding
   * @returns {string}
   */
  static getFundingChannelLongName(direct_funding: boolean): string {
    return direct_funding ? 'Direct Funded' : 'Fiscal Agent';
  }

  /**
   * Returns status/state as a name to be shown on header bar.
   * @param {number} status_id
   * @returns {string}
   */
  static formatStateHeader(status_id: number): string {
    switch (status_id) {
      case STATE_TYPES.CREATED:
        return 'DRAFT';

      case STATE_TYPES.OWNER_SUBMITTED:
        return 'SUBMITTED';

      case STATE_TYPES.ALL_MEMBERS_APPROVED:
        return 'CONSORTIUM APPROVED';

      case STATE_TYPES.STATE_APPROVED:
        return 'APPROVED';

      case STATE_TYPES.ATTENTION_NEEDED:
        return 'ATTENTION NEEDED';

      default:
        return status_id ? `${status_id}` : '';
    }
  }

  /**
   * Formats the state name.
   * @param {number} status_id
   */
  static stateName(status_id: number): string {
    const result = _startCase(this.formatStateHeader(status_id).toLowerCase());
    return result;
  }

  /**
   * Returns latest year's cfad from the list.
   * @static
   * @param {Array<any>} cfads
   * @param {Boolean} latestCompleted optional param, returns latest completed cfad if set
   * note that if no completed cfad is found, it defaults to the latest year cfad
   * @returns {*} cfad
   */
  static latestYearCfad(cfads: Array<any>, latestCompleted? : boolean): any {
    cfads.sort((a, b) => { return b.duration_id - a.duration_id });

    if (!!latestCompleted) {
      const completedStates = [STATE_TYPES.ALL_MEMBERS_APPROVED, STATE_TYPES.STATE_APPROVED];

      for (const cfad of cfads) {
        if (completedStates.includes(cfad.status_id)) {
          return cfad;
        }
      }
    }

    return cfads[0];
  }

  /**
   * Returns consortium's latest year's cfad.
   * @param cfads
   * @param proposal_id
   */
  static latestYearCfadByConsortium(cfads: Array<any>, proposal_id: number, latestCompleted?: boolean): any {
    const consortiumCfads = cfads.filter(c => c.proposal_id === proposal_id);
    const result = Cfad.latestYearCfad(consortiumCfads, latestCompleted);
    return result;
  }

  /**
   * Returns target year's cfad.
   * @static
   * @param {number} target_duration_id
   * @param {Array<any>} cfads
   * @returns {*} cfad
   */
  static targetYearCfad(target_duration_id: number, cfads: Array<any>): any {
    target_duration_id = target_duration_id;
    return cfads.find((cfad) => cfad.duration_id === target_duration_id);
  }

  /**
   * Returns true if cfad has been certified.
   * @static
   * @param {number} status_id
   * @returns {boolean}
   */
  static isCertified(status_id: number): boolean {
    return status_id >= STATE_TYPES.ALL_MEMBERS_APPROVED;
  }

  /**
   * Returns true if cfad is in draft mode.
   * @static
   * @param {number} status_id
   * @returns {boolean}
   */
  static isDraft(status_id: number): boolean {
    return status_id <= STATE_TYPES.CREATED;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Cfad = ICfad;
  }
}
