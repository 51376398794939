<div class="margin-bottom">
  <a *ngIf="loginAvailable" [routerLink]="login_route" class="action-button action-button--secondary action-button--small icon--left">
    <icon iconId="arrow-back"></icon>
    Back to Login
  </a>
</div>

<form [formGroup]="form">
    <div id="qa_forgotPassword_card" class="card card--reset-password">
      <div class="card__header">
        <div class="card-title">
          <h3 class="h3">{{ settingEmailFirstTime ? 'Set Password' : 'Forgot Password' }}</h3>
        </div>
      </div>

      <div class="card__body">
        <div class="card-section-wrapper">
          <div class="card-section">
            <p *ngIf="!show_success" class="card-description">
              Please enter the email address registered to your account. We'll send you an email with a link allowing you to set a new password.
            </p>
            <input-field *ngIf="!show_success" labelText="Email">
              <input inputRef id="email_address" formControlName="email_address" type="email" aria-required="true">
            </input-field>

            <ng-container *ngIf="show_success">
              <p>A password reset link has been sent to <strong>{{ email_address }}</strong>.
                Check your inbox and spam folder for a message from NOVA.</p>
              <p>PLEASE NOTE: The password reset link will expire after 24 hours.</p>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="align-right">
        <button [disabled]="!form.valid" (click)="onSubmit()" class="action-button action-button--primary" type="submit">
          {{ show_success ? 'Resend' : 'Send' }} Password Reset Email
        </button>
        <po-status-message [statusMessage]="statusMessage"></po-status-message>
      </div>
    </div>
  </form>
