import { map, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Model, State, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { PROPOSAL_REPORTS_ACTION_TYPES } from './proposal-reports.action';

/**
 * Injectable effects class
 */
@Injectable()
export class ProposalReportsEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  @Effect() loadProgramAreaReports = this.actions$.pipe(
    ofType(PROPOSAL_REPORTS_ACTION_TYPES.LOAD_PROGRAM_AREA_REPORTS),
    map(toPayload),
    mergeMap(proposal_id => {
      return this.apiService.getProgramAreaReports([proposal_id], [], []).pipe(
        map(response => {
          return DomainActions.ProposalReports.loadProgramAreaReportsSuccess(response);
        }, error => DomainActions.ProposalReports.serviceFail(error)));
    })
  );

  @Effect() createProgramAreaReport = this.actions$.pipe(
    ofType(PROPOSAL_REPORTS_ACTION_TYPES.CREATE_PROGRAM_AREA_REPORT),
    map(toPayload),
    mergeMap((report: Model.ProgramAreaReport) => {
      return this.apiService.createProgramAreaReport(report.proposal_id, report.institution_id, report.duration_id, report.lines).pipe(
        map(response => {
          return DomainActions.ProposalReports.updateProgramAreaReportSuccess(response);
        }, error => DomainActions.ProposalReports.serviceFail(error)));
    })
  );

  @Effect() upsertProgramAreaReportLine = this.actions$.pipe(
    ofType(PROPOSAL_REPORTS_ACTION_TYPES.UPSERT_PROGRAM_AREA_REPORT_LINE),
    map(toPayload),
    mergeMap((payload: { report_id: number, line: Model.ProgramAreaReportLine }) => {
      return this.apiService.upsertProgramAreaReportLine(payload.report_id, payload.line).pipe(
        map(response => {
          return DomainActions.ProposalReports.updateProgramAreaReportSuccess(response);
        }, error => DomainActions.ProposalReports.serviceFail(error)));
    })
  );

  @Effect() updateProgramAreaReportStatus = this.actions$.pipe(
    ofType(PROPOSAL_REPORTS_ACTION_TYPES.UPDATE_PROGRAM_AREA_REPORT_STATUS),
    map(toPayload),
    mergeMap(payload => {
      return this.apiService.updateProgramAreaReportState(payload.report_id, payload.state_id).pipe(
        map(response => {
          return DomainActions.ProposalReports.updateProgramAreaReportSuccess(response);
        }, error => DomainActions.ProposalReports.serviceFail(error)));
    })
  );
}
