import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {

  @Input() level: string; // success | info | warning | error
  // tslint:disable-next-line:no-input-rename
  @Input('button-dismiss') buttonDismiss: string;
  // tslint:disable-next-line:no-input-rename
  @Input('button-action') buttonAction: string;
  // tslint:disable-next-line:no-input-rename
  @Input('return-value') returnValue: any;
  @Output() dismiss: EventEmitter<string> = new EventEmitter();
  @Output() action: EventEmitter<string> = new EventEmitter();

  constructor() { }

  closeAlert(result: string) {
    this.dismiss.emit(this.returnValue);
  }

  doAction(action: string) {
    this.action.emit(this.returnValue);
  }
}
