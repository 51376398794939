import { IEffortArea } from '../effort-area';
import { Model } from '@app-ngrx-domains';
import { uniq } from 'lodash';

export const MID_ALLOCATION_REPORT_DI_MINIUM = 5;

export interface IEAMidAllocationReport extends IEffortArea {
  additional_funds: number;
  additional_funds_reason: string;
  category_spendings: Array<Model.EACategorySpending>;
  category_spendings_other: string,
  challenge: string;
  challenge_category_ids: Array<Model.AttributeValue>;
  challenge_responsible_user: number;
  population_group_ids: Array<Model.AttributeValue>;
  population_group_ids_cloned: Array<Model.AttributeValue>;
  reporting_activity_items: Array<Model.EAReportingActivity>;
  reverted_funds: number;
  success_responsible_user: number;
  success_story: string;
  success_story_outcomes: string;
  success_story_title: string;
  year_2_forecast: number;
  removed_population_groups: Array<Model.EARemovedPopulationGroup>;
}

export interface IEAReportingActivity extends IEffortArea {
  population_id: number;
  activity_item_id: number;
  category_ids: Array<Model.AttributeValue>;
  scale_of_adoption: string;
  other_category: string;
}

export interface IEACategorySpending extends IEffortArea {
  category_id: number;
  percentage: number;
}

export class MidAllocationReport {

  /**
   * Returns unique list of activity categories.
   * @param midAllocationReport
   * @param excludeActivityIds
   */
  static getUniqueActivityCategories(midAllocationReport: IEAMidAllocationReport, excludeActivityIds: Array<number>) {
    // Get all uses of the same category across all activity item instances
    const categoryIds = midAllocationReport.reporting_activity_items
      .filter(activityItem => !excludeActivityIds.includes(activityItem.id))
      .reduce((acc, activityItem) => {
        return acc = [...acc, ...activityItem.category_ids.map(c => c.value)];
      }, []);

    return uniq(categoryIds);
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EAMidAllocationReport = IEAMidAllocationReport;
    export type EAReportingActivity = IEAReportingActivity;
    export type EACategorySpending = IEACategorySpending;
  }
}
