import { Model } from '@app-ngrx-domains';

export class ReducerUtils {

  // Get most up-to-date records of each parentEffortArea so nothing is overwritten by the provided records
  static getFreshParents(root, parentEffortAreas: Array<Model.EffortArea> = []) {
    const freshParents = [];
    parentEffortAreas.forEach((parent) => {
      const freshEA = ReducerUtils.findEffortArea(root, parent, freshParents);
      freshParents.push(freshEA);
    });

    return freshParents;
  }

  /* Return the value of 'ea' currently in the root (useful for finding the "current/old" value of an effort area) */
  static findEffortArea(root: any, ea: Model.EffortArea, parentEffortAreas: Array<Model.EffortArea> = []) {
    let currentRoot = root;
    // Traverse the parent hierarchy to get the effortArea's direct parent
    parentEffortAreas.forEach(parent => {
      const thisParent = this.findEffortArea(currentRoot, parent);
      currentRoot = thisParent;
    });

    if (currentRoot[ea.effort_area_type] instanceof Array) {
      return { ...currentRoot[ea.effort_area_type].find(item  => item.id === ea.id) };
    } else {
      return { ...currentRoot[ea.effort_area_type] };
    }
  }

  static getDirectParent(parentEffortAreas: Array<Model.EffortArea>) {
    return parentEffortAreas.length ? parentEffortAreas[parentEffortAreas.length - 1] : undefined;
  }

  /* Stores/replaces effortArea(s) under it's parent object, returns the new root */
  static storeEffortAreas(root: any, items: Array<Model.EffortArea>, parentEffortAreas: Array<Model.EffortArea> = []) {
    const directParent = this.getDirectParent(parentEffortAreas) || root;
    // Insert each item into the parent object
    const newParent = items.reduce((parent, item) => {
      return {
        ...parent,
        [item.effort_area_type]: this.replaceEffortArea(item, parent)
      }
    }, directParent);

    if (newParent.effort_area_type) {
      // Parent is an effortArea, store the result
      return this.storeEffortAreas(root, [newParent], parentEffortAreas.slice(0, parentEffortAreas.length - 1));
    } else {
      // Parent is the root, just return it as it
      return newParent;
    }
  }

  /* Replaces or Adds an effortArea under it's parent object */
  static replaceEffortArea(ea: Model.EffortArea, parent: Model.ProposalItem | Model.EffortArea) {
    const oldId = ea.id;
    if (ea['newId']) { // Replacing a temp EA with a real one requires swapping the id value
      ea.id = ea['newId'];
    }

    if (parent[ea.effort_area_type] instanceof Array) {
      const list = parent[ea.effort_area_type].slice(); // Copy the list so we can work with it
      const dx = list.findIndex(e => e.id === oldId);
      if (dx >= 0) {
        list[dx] = { ...list[dx], ...ea }; // Update the existing entry
      } else {
        list.push(ea); // Push the new entry
      }
      return list;
    } else {
      return ea; // Not an array, return the new value
    }
  }

  /* Removes effortArea(s) from it's parent object, returns the new root */
  static removeEffortAreas(root: any, items: Array<Model.EffortArea>, parentEffortAreas: Array<Model.EffortArea> = []) {
    const directParent = this.getDirectParent(parentEffortAreas) || root;
    // Remove each item from the parent object
    const newParent = items.reduce((parent, item) => {
      return {
        ...parent,
        [item.effort_area_type]: this.removeEffortAreaAttribute(item, parent)
      }
    }, directParent);

    if (newParent.effort_area_type) {
      // Parent is an effortArea, store the new result
      return this.storeEffortAreas(root, [newParent], parentEffortAreas.slice(0, parentEffortAreas.length - 1));
    } else {
      // Parent is the root, just return as is
      return newParent;
    }
  }

  static removeEffortAreaAttribute(item: Model.EffortArea, parent: Model.ProposalItem | Model.EffortArea) {
    if (parent[item.effort_area_type] instanceof Array) {
      return parent[item.effort_area_type].filter(ea => ea.id !== item.id);
    }
    return null; // In the event we're removing a singleton effort area, set it back to null;
  }

  static upsertAttribute(currentValue, value) {
    if (currentValue instanceof Array) {
      return [ ...currentValue, value];
    } else {
      return value.value;
    }
  }

  static upsertUpdatedAttribute(root: Object, attribute_name: string, payload: any, ) {
    if (Object.keys(root).includes(attribute_name + '_updated')) {
      root[attribute_name + '_updated'] = {
          updated_by: payload.updated_by_id,
          updated_at: payload.updated_at
      }
    }

    return root;
  }

  static removeAttribute(currentValue, deletedValue) {
    if (currentValue instanceof Array) {
      if (typeof deletedValue === 'object' && deletedValue !== null) {
        // Check for 'id' property rather than standard value (joined attribute rather than direct attribute, i.e. assurances.files)
        return currentValue.filter(item => item.id !== deletedValue.id);
      } else {
        return currentValue.filter(item => item.value !== deletedValue);
      }
    }
    return null; // Unset attributes values are null, not undefined
  }
}
