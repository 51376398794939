import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LoggerService } from 'ng-logger';
import { ApiService } from '@app-services';
import { ROUTER_LINKS } from '../consts';

@Injectable()
export class HealthCheckGuard implements CanActivate {

  constructor(
    private logger: LoggerService,
    private router: Router,
    private apiService: ApiService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.healthCheck(route, state);
  }

  healthCheck(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const onMaintenance = state.url.includes(ROUTER_LINKS.MAINTENANCE);

    return new Observable((subscriber) => {
      this.apiService.healthCheck().subscribe(
        (response) => {
          if (onMaintenance) {
            this.logger.debug(`[heath-check-guard][${state.url}] on maintenance rerouting to home`);
            subscriber.next(false);
            this.router.navigate([ROUTER_LINKS.HOME]);
            subscriber.complete();
          } else {
            this.logger.debug(`[heath-check-guard][${state.url}] ok`);
            subscriber.next(true);
            subscriber.complete();
          }
        }, (error) => {
          if (onMaintenance) {
            this.logger.debug(`[heath-check-guard][${state.url}] error on maintenance`);
            subscriber.next(true);
            subscriber.complete();
          } else {
            this.logger.debug(`[heath-check-guard][${state.url}] error rerouting to maintenance`);
            this.router.navigate([ROUTER_LINKS.MAINTENANCE]);
            subscriber.next(false);
            subscriber.complete();
          }
      });
    });
  }
}
