import { of, forkJoin } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Model, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { ALLOCATIONS_ACTION_TYPES } from './allocations.action';


/**
 * Container class for allocation effects calls.
 */
@Injectable()
export class AllocationsEffects {

  /**
   * Creates an instance of effects class.
   * @param {Actions} actions$
   * @param {ApiService} service
   * @param {Store} store
   */
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>,
  ) {
  }

  /**
   * Updates allocation field.
   */
  @Effect() updateField$ = this.actions$.pipe(
    ofType(ALLOCATIONS_ACTION_TYPES.UPDATE_FIELD),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Allocations.getMembers)),
    mergeMap(data => {
      const [allocationChanges, currentAllocations] = data;
      const apiUpdates = [];
      allocationChanges.forEach(update => {
        const allocation = {
          id: update.id,
          [update.name]: update.value
        };
        apiUpdates.push(this.apiService.updateAllocation(allocation));
      });

      // execute api calls in parallel.
      return forkJoin(apiUpdates).pipe(
        mergeMap((updates: Array<Model.Allocation>) => {
          return of(DomainActions.Allocations.updateSuccess(updates));
        }),
        catchError((error) => of(DomainActions.Allocations.serviceFail(error))));
    })
  );
}
