// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import base from './base.json';

export const environment = {
  ...base,
  name: 'demo',
  production: true,
  apiHostUrl: 'https://service.demo.nova.cccco.edu',
  logger: {
    threshold: 'WARN',
    colorOutput: true,
  },
  wip_features: {
    rc_management: false,
  },
  disableSentry: false,
  showHelpDeskWidget: false,
  logos: {
    header: { src: 'pO-logo-login.svg', altText: 'Logo' },
    nav: { src: 'pO-logo-nav.svg', altText: 'Logo' },
    footer: { src: 'pO-logo-footer.png', altText: 'Logo' },
  }
};
