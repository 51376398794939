import { State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Allocations = {
  lead: {},
  members: {},
  budget_members: {},
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IAllocationsState {
    lead: Model.Allocations;
    members: { [institution_id: number]: Model.Allocations };
    budget_members: Model.RemainingAllocations;
  }

  interface State {
    Allocations: IAllocationsState;
  }
}
