<div [ngClass]="{'error' : input.hasError(), 'focused' : input.focus}" [ngSwitch]="!!canEdit">
  <label *ngIf="showLabel()" [id]="labelId" [for]="inputId" [textContent]="labelText" [class.required]="required"></label>

  <ng-content select=".help-text"></ng-content>

  <ng-container *ngSwitchCase="true">
    <ng-content></ng-content>

    <div class="input-footer">
      <show-errors [control]="input.control" [ngClass]="{'error-text' : !hideError, 'error-text-none' : hideError}" [showUntouched]="errorsOnUntouched"></show-errors>
      <character-count *ngIf="showCounter" [input]="input"></character-count>
    </div>
  </ng-container>

  <div class="read-only__container" *ngSwitchCase="false">
    <ng-container *ngIf="!readOnlyHtmlOutput">
      <a *ngIf="isLink && !isEmpty" [href]="getReadOnlyText()" target="_blank" class="text-link">{{getReadOnlyText()}}</a>
      <p *ngIf="!isLink || isEmpty">{{getReadOnlyText()}}</p>
    </ng-container>

    <div *ngIf="readOnlyHtmlOutput" [innerHTML]="getReadOnlyText() | safeHtml"></div>
  </div>
</div>
