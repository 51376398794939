import { IInstitutionState, Query, Queries, Model, combineRootFactory } from '@app-ngrx-domains';
import { IUserRoleScope } from '@app-models';

const fromRoot = combineRootFactory<IInstitutionState>('Institution');

export interface IInstitutionQueries {
  getInstitutionId: Query<number>;
  getInstitution: Query<Model.Institution>;
  getInstitutionalContacts: Query<Array<IUserRoleScope>>;
  getContactsByFund: Query<{ [fund_id: number]: Array<IUserRoleScope> }>;
}

Queries.Institution = {
  getInstitutionId: fromRoot(state => state.institution.id),
  getInstitution: fromRoot(state => state.institution),
  getInstitutionalContacts: fromRoot(state => state.contacts.filter(contact => !contact.fund_id)),
  getContactsByFund: fromRoot(state => {
    const response: { [fund_id: number]: Array<IUserRoleScope> } = {};
    state.contacts.reduce((res, contact) => {
      if (contact.fund_id) {
        if (!res[contact.fund_id]) {
          res[contact.fund_id] = [contact];
        } else {
          res[contact.fund_id].push(contact);
        }
      }
      return res;
    }, response);
    return response;
  })
};

declare module '@app-ngrx-domains' {
  interface Root {
    Institution: Query<IInstitutionState>;
  }

  interface Queries {
    Institution: IInstitutionQueries;
  }
}
