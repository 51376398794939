import { State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Strategies = [];

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export type IStrategiesState = Array<Model.Strategy>;

  interface State {
    Strategies: IStrategiesState;
  }
}
