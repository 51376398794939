import { State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Proposals = {
  items: [],
  current_item_id: null,
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IProposalsState {
    items: Array<any>;
    current_item_id: number;
  }

  interface State {
    Proposals: IProposalsState;
  }
}
