<ng-container [ngSwitch]="filteredProposals && filteredProposals.length ? true : false">
  <!-- table -->
  <ng-container *ngSwitchCase="true">
    <app-simple-pagination
      *ngIf="paginateTop"
      [endingResult]="displayList.length + ((currentPage - 1) * pageLimit)"
      [startingResult]="((currentPage - 1) * pageLimit) + 1"
      [totalResults]="filteredProposals.length"
      [resetScroll]="resetScroll"
      (paginate)="updatePage($event)"
      [itemsName]="projectShortName">
    </app-simple-pagination>
    <div class="table-overflow" id="qa_project_table">
      <div [ngClass]="{ 'table-overflow__inside--60-rem': tableOverflow }">
        <table class="po-table po-table--fixed po-table--sticky-header datatable" [ngClass]="{'po-table--captioned': showTableCaption}">
          <caption *ngIf="showTableCaption">{{ projectLongName }} List</caption>
          <thead>
          <tr>
            <th scope="col" *ngFor="let column of columns"
                [class]="column.headerCssClass ? column.headerCssClass : ''"
                [class.po-table__sortable-header]="column.sortable"
                [attr.aria-sort]="column.sortable ? ariaState[column.prop] : 'none'">
              <ng-container [ngTemplateOutlet]="columnHeaderTmpl"
                            [ngTemplateOutletContext]="{ column: column.name, sortable: column.sortable, sortBy: column.prop }">
              </ng-container>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let row of displayList; trackBy: trackByFn" [attr.data-qa]="'table-row-data'">
            <ng-container *ngFor="let column of columns">
              <th *ngIf="column.isHeader"
                  [attr.data-qa]="column.cellDataQa"
                  [class]="column.cellCssClass">
                <ng-container [ngTemplateOutlet]="column.cellTemplate"
                              [ngTemplateOutletContext]="{ row: row }">
                </ng-container>
              </th>
              <td *ngIf="!column.isHeader"
                  [attr.data-qa]="column.cellDataQa"
                  [class]="column.cellCssClass ? column.cellCssClass : ''">
                <ng-container [ngTemplateOutlet]="column.cellTemplate"
                              [ngTemplateOutletContext]="{ row: row, prop: column.prop }">
                </ng-container>
              </td>
            </ng-container>
          </tr>
          </tbody>
          <tfoot>
            <tr id="qa_project_table_total_row">
              <td [attr.colspan]="columns.length">
                <ng-container [ngTemplateOutlet]="footer">
                </ng-container>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <app-simple-pagination
      [endingResult]="displayList.length + ((currentPage - 1) * pageLimit)"
      [startingResult]="((currentPage - 1) * pageLimit) + 1"
      [totalResults]="filteredProposals.length"
      [resetScroll]="resetScroll"
      (paginate)="updatePage($event)"
      [itemsName]="projectShortName">
    </app-simple-pagination>
  </ng-container>
  <!-- empty or is loading -->
  <ng-container *ngSwitchCase="false" [ngTemplateOutlet]="empty">
  </ng-container>
</ng-container>

<ng-template #emptyTmpl>
  <ng-container [ngSwitch]="!!filteredProposals">
    <p *ngSwitchCase="false" class="message">Loading {{projectLongName}}... <span class="loader-spinner-inline"></span></p>
    <p *ngSwitchCase="true" class="empty-state" id="qa_project_table_nomatch">There are no {{projectLongName}}, or none that matches the filter criteria.</p>
  </ng-container>
</ng-template>

<ng-template #footerTmpl>
  {{ filteredProposals.length }} Total
</ng-template>

<ng-template #columnHeaderTmpl hidden let-column="column" let-sortBy="sortBy" let-sortable="sortable">
  <ng-container *ngIf="column">
    <ng-container *ngIf="!sortable">
      {{ column }}
    </ng-container>
    <button *ngIf="sortable" type="button" class="po-table__sort-control"
            [attr.aria-label]="'Sort by ' + column"
            (click)="sortStateStep(sortBy)">
      {{ column }}
      <icon *ngIf="sortState.column === sortBy && sortState.descending < 0" iconId="arrow-down"></icon>
      <icon *ngIf="sortState.column === sortBy && sortState.descending > 0" iconId="arrow-up"></icon>
      <icon *ngIf="sortState.column !== sortBy" iconId="arrow-bidirectional"></icon>
    </button>
  </ng-container>
</ng-template>

<ng-template #columnHiddenHeaderTmpl let-column="column">
  <span class="screen-reader-text">
    {{ column }}
  </span>
</ng-template>

<ng-template #actionsTmpl let-row="row">
  <ng-container *ngIf="canEdit(row) else none">
    <div class="inline-button-group__container">
      <a *ngIf="actions.edit" [routerLink]="proposalLink(row)" (click)="logEdit()" title="Edit" class="action-button action-button--tertiary action-button--small inline-button" data-qa="qa_proposals_edit-proposal_button">
        <icon iconId="edit"></icon>
      </a>

      <a *ngIf="actions.del" (click)="deleteProposalAlert(row?.id); logDelete();" title="Delete" class="action-button action-button--tertiary action-button--small inline-button" data-qa="qa_proposals_delete-proposal_button">
        <icon iconId="delete" data-qa="delete-icon"></icon>
      </a>
    </div>
  </ng-container>
</ng-template>

<ng-template #none>
  None
</ng-template>

<ng-template #editTmpl let-row="row">
  <a *ngIf="canEdit(row)" [routerLink]="proposalLink(row)" (click)="logEdit()" title="Edit" class="action-button action-button--tertiary action-button--small">
    <icon iconId="edit"></icon>
  </a>
</ng-template>

<ng-template #delTmpl let-row="row">
  <a *ngIf="canEdit(row)" (click)="deleteProposalAlert(row?.id); logDelete();" title="Delete" class="action-button action-button--tertiary action-button--small">
    <icon iconId="delete" data-qa="delete-icon"></icon>
  </a>
</ng-template>

<alert *ngIf="showDeleteAlert"
       button-action="Delete"
       button-dismiss="Cancel"
       level="warning"
       (action)="confirmDelete()"
       (dismiss)="dismissDeleteAlert()">
  <p>Deletion is permanent. Are you sure?</p>
</alert>
