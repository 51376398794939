import { IProposalsState, State } from '@app-ngrx-domains';
import { ActionWithPayload, AttributeReducer } from '@app-libs';
import { PROPOSALS_ACTION_PREFIX, PROPOSALS_ACTION_TYPES } from './proposals.action';

export function ProposalsReducer(state: IProposalsState = State.Proposals, action: ActionWithPayload<any>): IProposalsState {
  switch (action.type) {
    ///////////////////////////////////////////////////////////////////////
    // Attribute Reducers
    ///////////////////////////////////////////////////////////////////////
    case PROPOSALS_ACTION_TYPES.UPSERT_ATTRIBUTE_SUCCESS:
    case PROPOSALS_ACTION_TYPES.DELETE_ATTRIBUTE_SUCCESS:
    case PROPOSALS_ACTION_TYPES.CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS:
    case PROPOSALS_ACTION_TYPES.CREATE_EFFORT_AREA_SUCCESS:
    case PROPOSALS_ACTION_TYPES.CREATE_MULTI_EFFORT_AREAS_SUCCESS:
    case PROPOSALS_ACTION_TYPES.UPDATE_EFFORT_AREA_SUCCESS:
    case PROPOSALS_ACTION_TYPES.DELETE_EFFORT_AREA_SUCCESS:
    case PROPOSALS_ACTION_TYPES.APPEND_ITEM:
    case PROPOSALS_ACTION_TYPES.DELETE_ITEM_SUCCESS:
    case PROPOSALS_ACTION_TYPES.DELETE_MULTI_EFFORT_AREAS_SUCCESS:  {
      const actionType = action.type.replace(PROPOSALS_ACTION_PREFIX, '');
      return AttributeReducer<IProposalsState>(actionType, state, action);
    }

    ///////////////////////////////////////////////////////////////////////
    // Local Reducers
    ///////////////////////////////////////////////////////////////////////
    case PROPOSALS_ACTION_TYPES.LOAD: {
      return action.payload;
    }

    case PROPOSALS_ACTION_TYPES.UPDATE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    default:
      return state;
  }
}
