import { AfterContentInit, AfterViewInit, Component, ContentChild, Input, OnDestroy, HostBinding, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { InputRefDirective, InputTransformerDirective, IntegerCurrencyTransformerDirective } from '../../directives/';
import { Logger } from '../../../app-logger';
import { NOT_ENTERED } from '@app-consts';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'input-field',
  templateUrl: './input-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputFieldComponent implements AfterViewInit, AfterContentInit, OnDestroy {
  @Input() hideError = false;
  @Input() labelText: string;
  @Input() labelHidden = false;
  @Input() showCounter = false;
  @Input() errorsOnUntouched = false;
  @Input() canEdit = true;
  @Input() isLink = false;
  @Input() disableStepper = false;

  @HostBinding('attr.data-invalid') invalidState = 'false';
  @ContentChild(InputRefDirective, {static: true}) input: InputRefDirective;
  @ContentChild(InputTransformerDirective, {static: true}) transform: InputTransformerDirective;
  @ContentChild(IntegerCurrencyTransformerDirective, { static: true }) currencyTransform: IntegerCurrencyTransformerDirective;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterContentInit() {
    if (!this.input) {
      Logger.error('Input required in input-field component. If input is present, add inputRef directive to element.');
    }
  }

  ngAfterViewInit() {
    if (!this.input.element.id) {
      Logger.error('An input with a unique ID is required in input-field component:');
      Logger.log(this.input);
    }

    if (!this.labelText) {
      Logger.error('labelText input required in input-field component:');
      Logger.log(this.input);
    }

    if (this.input && this.labelText && this.labelHidden) {
      this.input.element.setAttribute('aria-label', this.labelText);
    }

    this.checkValidation();
    this.cdr.detectChanges();

    this.input.control.statusChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.checkValidation();
      this.cdr.detectChanges();
    });
  }

  getReadOnlyText() {
    const inputValue = this.input.control.value;

    if (this.transform) {
      const amount = this.transform.formattedAmount;
      return (amount === null || amount === undefined) ? NOT_ENTERED : amount;
    } else if (this.currencyTransform) {
      const amount = this.currencyTransform.formatted;
      return (amount === null || amount === undefined) ? NOT_ENTERED : amount;
    } else if (this.isEmptyValue(inputValue)) {
      return NOT_ENTERED;
    } else {
      return inputValue;
    }
  }

  get isEmpty() {
    return this.getReadOnlyText() === NOT_ENTERED;
  }

  showLabel() {
    return this.labelText && !this.labelHidden;
  }

  private checkValidation() {
    const isInvalid = this.input.control.invalid ? 'true' : 'false';
    this.input.element.setAttribute('aria-invalid', isInvalid);

    // SetTimeout to avoid expressionChangedAfterChecked error
    if (!this.disableStepper) {
      setTimeout(() => {
        this.invalidState = isInvalid;
        this.cdr.markForCheck();
    }, 0);
    }
  }

  private isEmptyValue(value: any): boolean {
    return value === 'default' || value === null || value === undefined || value === '';
  }

  get required() {
    // getAttribute is returning the string value 'false'
    const value = this.input.element.getAttribute('aria-required');
    return value && value !== 'false';
  }

  get readOnlyHtmlOutput(): boolean {
    return this.input.element.localName && this.input.element.localName === 'po-html-textarea';
  }

  get inputId() {
    return this.input.element.id;
  }

  get labelId() {
    return this.inputId + '_label';
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
