import { Model, State } from '@app-ngrx-domains';
import { sortBy } from 'lodash';
import { ProposalBase } from './proposal-base';
import { Sector } from './sector';

export interface IProposalItem extends Model.ProposalBase {
  outcomes: string;
  start_date: string;
  end_date: string;
  ongoing: boolean;
  state_id: number;
  region_institution_id: number;
  region: any;
  subregions: Array<any>;
  counties: Array<any>;
  estimated_cost: number;
  rationale_1: string;
  rationale_2: string;
  rationale_3: string;
  rationale_4: string;
  rationale_5?: string;
  rationale_6?: string;
  rationale_7?: string;
  rationale_8?: string;
  rationale_9?: string;
  rationale_10?: string;
  description_state: string;
  contacts_state: string;
  lmi_state: string;
  metrics_state: string;
  workplan_state: string;
  workplan1_state: string;
  workplan2_state: string;
  workplan3_state: string;
  budget_state: string;
  conclusion_state: string;
  preview_state: string;
  read_swp_legis: boolean;
  meets_swp_legis: boolean;
  investments: string;
  all_sectors: number;
  sectors: Array<any>;
  recommendations: Array<any>;
  workplan_risks: string;
  approver_institution_id?: number;
  allocations?: Array<Model.Allocation>;
  uses_expired_funds?: boolean; // for swp-v1
  survey_responses: Array<Model.EASurveyResponse>;
}

export class Proposal extends ProposalBase implements IProposalItem {
  // IProposalItem
  public outcomes: string;
  public start_date: string;
  public end_date: string;
  public ongoing: boolean;
  public state_id: number;
  public state: State;
  public workplan_risks: string;
  public estimated_cost: number;
  public rationale_1: string;
  public rationale_2: string;
  public rationale_3: string;
  public rationale_4: string;
  public rationale_5: string;
  public rationale_6: string;
  public rationale_7: string;
  public rationale_8: string;
  public rationale_9: string;
  public rationale_10: string;
  public read_swp_legis: boolean;
  public meets_swp_legis: boolean;
  public all_sectors: number;
  public region_institution_id: number;
  public region: any;
  public description_state: string;
  public contacts_state: string;
  public lmi_state: string;
  public metrics_state: string;
  public workplan_state: string;
  public workplan1_state: string;
  public workplan2_state: string;
  public workplan3_state: string;
  public budget_state: string;
  public conclusion_state: string;
  public preview_state: string;
  public sectors: Array<Sector>;
  public subregions: Array<Sector>;
  public counties: Array<Sector>;
  public investments: string;
  public recommendations: Array<any>;
  public approver_institution_id: number;
  public allocations: Array<Model.Allocation>;
  public uses_expired_funds: boolean;
  public occupational_clusters: Array<Model.EAOccupationalCluster>;
  public plan_expenditure_forecasts: Array<Model.EAPlanExpenditureForecast>;
  public three_year_plans: Array<Model.EAThreeYearPlan>;
  public caep_closeout_responses: Array<Model.EACAEPCloseoutResponses>;
  public survey_responses: Array<Model.EASurveyResponse>;

  constructor(raw?: any) {
    super(raw);

    if (!raw) {
      // nothing to hydrate
      return;
    }

    this.outcomes = raw.outcomes;
    this.start_date = raw.start_date;
    this.end_date = raw.end_date;
    this.ongoing = raw.ongoing;
    this.state_id = raw.state_id;
    this.state = raw.state;
    this.workplan_risks = raw.workplan_risks;
    this.lead_institution = raw.lead_institution;
    this.institutions = raw.institutions;
    this.estimated_cost = raw.estimated_cost;
    this.rationale_1 = raw.rationale_1;
    this.rationale_2 = raw.rationale_2;
    this.rationale_3 = raw.rationale_3;
    this.rationale_4 = raw.rationale_4;
    this.rationale_5 = raw.rationale_5;
    this.rationale_6 = raw.rationale_6;
    this.rationale_7 = raw.rationale_7;
    this.rationale_8 = raw.rationale_8;
    this.rationale_9 = raw.rationale_9;
    this.rationale_10 = raw.rationale_10;
    this.read_swp_legis = raw.read_swp_legis;
    this.meets_swp_legis = raw.meets_swp_legis;
    this.all_sectors = raw.all_sectors;
    this.region_institution_id = raw.region_institution_id;
    this.region = raw.region;
    this.description_state = raw.description_state;
    this.contacts_state = raw.contacts_state;
    this.lmi_state = raw.lmi_state;
    this.metrics_state = raw.metrics_state;
    this.workplan_state = raw.workplan_state;
    this.workplan1_state = raw.workplan1_state;
    this.workplan2_state = raw.workplan2_state;
    this.workplan3_state = raw.workplan3_state;
    this.budget_state = raw.budget_state;
    this.conclusion_state = raw.conclusion_state;
    this.preview_state = raw.preview_state;
    this.sectors = raw.sectors || [];
    if (this.sectors.length > 0) {
      this.sectors = raw.sectors.map((item: any) => ({
        name: item.name,
        value: item.id
      }));
    }
    this.subregions = raw.subregions || [];
    if (this.subregions.length > 0) {
      this.subregions = raw.subregions.map((item: any) => ({
        name: item.name,
        value: item.id
      }));
    }
    this.counties = raw.counties || [];
    if (this.counties.length > 0) {
      this.counties = raw.counties.map((item: any) => ({
        name: item.name,
        value: item.id
      }));
    }
    this.investments = raw.investments;
    this.recommendations = raw.recommendations || [];
    if (this.recommendations.length > 0) {
      this.recommendations = raw.recommendations.map((item: any) => ({
        value: item.code
      }));
      this.recommendations = sortBy(this.recommendations);
    }
    this.deleted = raw.deleted;
    this.approver_institution_id = raw.approver_institution_id;
    this.allocations = raw.allocations || [];
    this.uses_expired_funds = raw.uses_expired_funds;
    this.occupational_clusters = raw.occupational_clusters;
    this.plan_expenditure_forecasts = raw.plan_expenditure_forecasts || [];
    this.three_year_plans = raw.three_year_plans || [];
    this.caep_closeout_responses = raw.caep_closeout_responses || [];
    this.survey_responses = raw.survey_responses || [];
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ProposalItem = IProposalItem;
  }
}
