export const CDE_DWM_SECTOR_CROSSWALK = {
  17: [4],
  18: [10],
  19: [7],
  20: [14, 8],
  21: [6],
  22: [7],
  23: [7],
  24: [13],
  25: [9, 11],
  26: [13],
  27: [10],
  28: [2],
  29: [14, 8],
  30: [12],
  31: [3],
};
