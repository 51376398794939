import { Actions, Model } from '@app-ngrx-domains';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = 'SYSTEM_';
export const SYSTEM_ACTION_TYPES = {
  REFRESH_MAINTENANCE: `${ACTION_PREFIX}REFRESH_MAINTENANCE`,
  SET_MAINTENANCE: `${ACTION_PREFIX}SET_MAINTENANCE`
}

export class SystemActions {

  refreshMaintenance(): Action {
    return {
      type: SYSTEM_ACTION_TYPES.REFRESH_MAINTENANCE
    };
  }

  setMaintenance(maintenanceStatus: Model.Maintenance) {
    return {
      type: SYSTEM_ACTION_TYPES.SET_MAINTENANCE,
      payload: {
        maintenanceStatus
      }
    };
  }
}

Actions.System = new SystemActions();

declare module '@app-ngrx-domains' {
  interface Actions {
    System: SystemActions
  }
}
