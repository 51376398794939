import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Workplan = {
  items: [],
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IWorkplanState {
    proposal_id?: number;
    items: Array<Model.WorkplanItem>;
  }

  interface State {
    Workplan: IWorkplanState;
  }
}
