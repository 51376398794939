import * as _ from 'lodash';
import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { Proposal, UserRoleScope, EnumErrorTypes } from '@app-models';

/**
 * Contacts Action types
 */
const ACTION_PREFIX = 'CONTACTS_';
export const CONTACTS_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  ADD_CONTACT: `${ACTION_PREFIX}ADD_CONTACT`,
  ADD_CONTACT_SUCCESS: `${ACTION_PREFIX}ADD_CONTACT_SUCCESS`,
  ADD_CONTACT_FAIL: `${ACTION_PREFIX}ADD_CONTACT_FAIL`,
  UPDATE_CONTACT: `${ACTION_PREFIX}UPDATE_CONTACT`,
  UPDATE_CONTACT_SUCCESS: `${ACTION_PREFIX}UPDATE_CONTACT_SUCCESS`,
  DELETE_CONTACT: `${ACTION_PREFIX}DELETE_CONTACT`,
  BATCH_DELETE_CONTACTS: `${ACTION_PREFIX}BATCH_DELETE_CONTACTS`,
  DELETE_CONTACT_SUCCESS: `${ACTION_PREFIX}DELETE_CONTACT_SUCCESS`,
  DELETE_CONTACT_FAIL: `${ACTION_PREFIX}DELETE_CONTACT_FAIL`,
};

/**
 * Contacts action class
 */
export class ContactsActions {

  /**
   * Loads contacts and contact types from the proposal
   *
   * @param {any} proposal
   * @returns {Action}
   */
  load(proposal: any): ActionWithPayload<any> {
    const items: Array<Model.UserRoleScope> = [];
    if (proposal.contacts && proposal.contacts.length > 0) {
      // Filter out duplicate contacts for proposals that span multiple funds
      const reducedContacts = _.uniqBy(proposal.contacts, c => [c.user_id, c.role_id, c.institution_id].join());
      reducedContacts.forEach((item) => {
        items.push(new UserRoleScope(item).iObject<Model.UserRoleScope>());
      });
    }

    return {
      type: CONTACTS_ACTION_TYPES.LOAD,
      payload: {
        proposal_id: proposal.id,
        items: items,
      }
    };
  }

  /**
   * Resets contacts
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.LOAD,
      payload: {
        proposal_id: null,
        items: [],
      }
    };
  }

  /**
   * Adds a contact and type to the current proposal.
   */
  add(contact: Model.UserRoleScope, notify_on_assignment?: boolean, override_subject?: string): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.ADD_CONTACT,
      payload: { contact, notify_on_assignment, override_subject }
    };
  }

  /**
   * Updates an existing contact by modifying fields in the user_role_scope
   */
  update(contact: Model.UserRoleScope): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.UPDATE_CONTACT,
      payload: contact
    };
  }

  /**
   * Successfully updated a contact.
   * Dispatches equivalent message to reducer to replace existing contact.
   *
   * @returns {Action}
   */
  updateSuccess(contact: Model.UserRoleScope): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.UPDATE_CONTACT_SUCCESS,
      payload: contact
    };
  }

  /**
   * Successfully add a contact.
   * Dispatches equivalent message to reducer to add it to its state.
   *
   * @returns {Action}
   */
  addSuccess(contact: Model.UserRoleScope): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.ADD_CONTACT_SUCCESS,
      payload: contact
    };
  }

  /**
   * Error occurred while persisting the contact association.
   * Dispatches equivalent error for reducer to consume.
   *
   * @param {any} error
   * @returns {Action}
   */
  addFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: CONTACTS_ACTION_TYPES.ADD_CONTACT_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Deletes a contact from a proposal.
   */
  delete(contact: Model.UserRoleScope): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.DELETE_CONTACT,
      payload: contact
    };
  }

  /**
   * Batch delete contacts and then perform the provided action.
   */
  batchDelete(contacts: Array<Model.UserRoleScope>, action_after?: any): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.BATCH_DELETE_CONTACTS,
      payload: { contacts, action_after }
    };
  }

  /**
   * Successfully deleted contacts.
   * Dispatches equivalent message to reducer to update state.
   *
   * @returns {Action}
   */
  deleteSuccess(contacts: Array<Model.UserRoleScope>): ActionWithPayload<any> {
    return {
      type: CONTACTS_ACTION_TYPES.DELETE_CONTACT_SUCCESS,
      payload: contacts
    };
  }

  /**
   * Error occurred while persisting the contact association.
   * Dispatches equivalent error for reducer to consume.
   *
   * @param {any} error
   * @returns {Action}
   */
  deleteFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: CONTACTS_ACTION_TYPES.DELETE_CONTACT_FAIL,
      show: true,
      raw: error,
    });
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Contacts = new ContactsActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Contacts: ContactsActions;
  }
}
