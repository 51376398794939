import { IFilesState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { FILES_ACTION_TYPES } from './files.action';

/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function FilesReducer(state: IFilesState = State.Files, action: ActionWithPayload<any>): IFilesState {
  switch (action.type) {
    case FILES_ACTION_TYPES.LOAD: {
      return {
        ...state,
        items: action.payload.items,
      };
    }

    case FILES_ACTION_TYPES.APPEND_FILE: {
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    }

    case FILES_ACTION_TYPES.DELETE_SUCCESS: {
      const newState = state.items.filter(item => item.id !== action.payload);
      return {
        ...state,
        items: newState
      };
    }

    default:
      return state;
  }
}
