import { ModelBase } from './model.base';

export interface IGoalItem {
  id: number;
  all_disg_required: boolean;
  type: string; // or formVersion or task index
  title: string;
  increasing: boolean;
  metric: number;
  metric_type_uniq: string;
  required: boolean;
  year: number; // this should be a duration id -- it will go on everything
  duration: number; // I think duration id rolls up into this
  base: number;
  target: number;
  text: string; // Can also be used for tooltip in surveys.
  institution_id: number;
  dis_id: number;
  disg: boolean; // This determines DISG subquestion
  parent_goal_id: number;
  context_goal_id: number; // Used for question text
  is_default: boolean;
  other: boolean;
  header?: string;
  name: string;
  description: string;
  duration_id: number;
}

export class Goal extends ModelBase implements IGoalItem {
  private static _id = 0;
  public id: number;
  public all_disg_required: boolean;
  public type: string;
  public title: string;
  public increasing: boolean;
  public metric: number;
  public metric_type_uniq: string;
  public required: boolean;
  public year: number;
  public duration: number;
  public base: number;
  public target: number;
  public text: string;
  public institution_id: number;
  public dis_id: number;
  public disg: boolean;
  public parent_goal_id: number; // handles lineage
  public context_goal_id: number; // handles question text
  public is_default: boolean;
  public other: boolean;
  public answers: any;
  public context: any;
  public header: string;
  public name: string;
  public description: string;
  public duration_id: number;

  constructor( raw? ) {
    super();

    if (!raw) {
      return;
    }

    // console.log(`Current Goal id: ${Goal._id}`);
    if (raw.id > Goal._id) {
      Goal._id = raw.id;
      // console.log(`Goal static id now ${Goal._id}.`);
    }
    this.id = raw.id && raw.id < Goal._id ? raw.id : Goal._id;
    this.all_disg_required = raw.all_disg_required;
    this.type = raw.type;
    this.title = raw.title;
    this.increasing = raw.increasing;
    this.metric = raw.metric;
    this.metric_type_uniq = raw.metric_type_uniq;
    this.required = raw.boolean;
    this.year = raw.year;
    this.duration = raw.duration;
    this.base = raw.base;
    this.target = raw.target;
    this.text = raw.text;
    this.institution_id = raw.institution_id;
    this.dis_id = raw.dis_id;
    this.disg = raw.disg;
    this.parent_goal_id = raw.parent_goal_id;
    this.context_goal_id = raw.context_goal_id;
    this.is_default = raw.is_default;
    this.other = raw.other;
    this.header = raw.header;
    this.name = raw.name;
    this.description = raw.description;
    this.duration_id = raw.duration_id;
    Goal._id++; // DB- temporary number should not collide with real ids.
    // console.log(this);
  }

  directionString(lower: boolean = true): string {
    const result = this.increasing ? 'Increase' : 'Decrease';
    if (lower) {
      return result.toLowerCase();
    } else {
      return result;
    }
  }

  calculatePercent() {
    if (this.base && this.target) {
      return Math.round((!this.increasing ? this.base - this.target : this.target - this.base) / this.base * 100);
    } else {
      return null;
    }
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type GoalItem = IGoalItem;
  }
}
