import {AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {NavTreeService} from '../nav-tree/nav-tree.service';

@Component({
  selector: 'po-nav-item',
  templateUrl: './nav-item.component.html',
})
export class NavItemComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() id;
  @Input() itemText;
  @Input() itemUrl;
  @Input() isButton = false;
  @Input() exactUrl = false;
  @Input() alternateUrls = [];
  @Input() iconId;
  @Input() isLast = false;
  @Input() itemBadge;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  @ViewChild('treeItem') treeItem: ElementRef;

  isActive = false;
  isVisible = true;
  isTabbable = false;
  destroy$: Subject<boolean> = new Subject();

  constructor(private router: Router, private navTreeService: NavTreeService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.setInitialState();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.setActiveStatus();
    });
  }

  private setInitialState() {
    this.setActiveStatus();
  }

  private setActiveStatus() {
    if (this.itemUrl) {
      this.isActive = this.router.isActive(this.itemUrl, this.exactUrl) || this.alternateUrls.some(url => this.router.isActive(url, false));
      this.isTabbable = this.isActive;
    }
  }

  handleClick(event) {
    if (this.isButton) {
      this.buttonClick.emit();
    }

    this.navTreeService.handleClick({
      keyboardEvent: event,
      component: this
    });
  }

  handleKeyPress(event) {
    this.navTreeService.handleKeyPress({
      keyboardEvent: event,
      component: this
    });
  }

  handleKeyUp(event) {
    this.navTreeService.handleKeyUp({
      keyboardEvent: event,
      component: this
    });
  }

  setFocus() {
    this.isTabbable = true;
    this.treeItem.nativeElement.focus();
  }

  clearFocus() {
    this.isTabbable = false;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
