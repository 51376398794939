import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload, NewTempId } from '@app-libs';
import { EnumErrorTypes, Strategy } from '@app-models';

/**
 * Proposal Strategies Action types
 */
const ACTION_PREFIX = 'PROPOSAL_STRATEGIES_';
export const STRATEGIES_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  CREATE_STRATEGY: `${ACTION_PREFIX}CREATE_STRATEGY`,
  UPDATE_STRATEGY: `${ACTION_PREFIX}UPDATE_STRATEGY`,
  UPDATE_STRATEGY_SUCCESS: `${ACTION_PREFIX}UPDATE_STRATEGY_SUCCESS`,
  DELETE_STRATEGY: `${ACTION_PREFIX}DELETE_STRATEGY`,
  DELETE_STRATEGY_SUCCESS: `${ACTION_PREFIX}DELETE_STRATEGY_SUCCESS`,
};

/**
 * Proposal Strategies action class.
 */
export class StrategiesActions {

  /**
   * Fetched data - send it along to reducer
   * @param strategies
   */
  load(strategies: any): ActionWithPayload<any> {
    const state = [];

    strategies.forEach(s => {
      state.push(new Strategy(s).iObject<Model.Strategy>());
    });

    return {
      type: STRATEGIES_ACTION_TYPES.LOAD,
      payload: state
    };
  }

  /**
   * Resets data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: STRATEGIES_ACTION_TYPES.LOAD,
      payload: [],
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: STRATEGIES_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  createStrategy(item: any): ActionWithPayload<any> {
    return {
      type: STRATEGIES_ACTION_TYPES.CREATE_STRATEGY,
      payload: {
        ...item,
        id: NewTempId.id(), // assign auto generated id.
      }
    };
  }

  updateStrategy(item: any): ActionWithPayload<any> {
    return {
      type: STRATEGIES_ACTION_TYPES.UPDATE_STRATEGY,
      payload: item
    };
  }

  updateStrategySuccess(item: any, res: any): ActionWithPayload<any> {
    return {
      type: STRATEGIES_ACTION_TYPES.UPDATE_STRATEGY_SUCCESS,
      payload: {
        item: item,
        newId: res.id,
      }
    };
  }

  deleteStrategy(item: any): ActionWithPayload<any> {
    if (NewTempId.isTemp(item.id)) {
      // we're dealing with a temp record, no need to call the service.
      return {
        type: STRATEGIES_ACTION_TYPES.DELETE_STRATEGY_SUCCESS,
        payload: item
      };
    } else {
      return {
        type: STRATEGIES_ACTION_TYPES.DELETE_STRATEGY,
        payload: item
      };
    }
  }

  deleteStrategySuccess(item: any): ActionWithPayload<any> {
    return {
      type: STRATEGIES_ACTION_TYPES.DELETE_STRATEGY_SUCCESS,
      payload: item
    };
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Strategies = new StrategiesActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Strategies: StrategiesActions;
  }
}
