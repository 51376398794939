import { Model } from '@app-ngrx-domains';
import { ProposalBase } from './proposal-base';
import { FUND_TYPES } from '../consts';

export interface IIPlanProposal extends Model.ProposalBase {
  submit_task: Model.Task;
  close_task: Model.Task;
  statuses: Array<string>;
  current_status: string;
  rationale_1: string;
  rationale_2: string;
  rationale_3: string;
  rationale_4: string;
  rationale_5: string;
  rationale_6: string;
  rationale_7: string;
  rationale_8: string;
  rationale_9: string;
  rationale_10: string;
  read_swp_legis: boolean;
  budget_items?: Array<Model.BudgetItem>;
}

export class IPlanProposal extends ProposalBase implements IIPlanProposal {
  public stateId;
  public submit_task: Model.Task;
  public close_task: Model.Task;
  public statuses: Array<string>;
  public current_status: string;
  public rationale_1: string;
  public rationale_2: string;
  public rationale_3: string;
  public rationale_4: string;
  public rationale_5: string;
  public rationale_6: string;
  public rationale_7: string;
  public rationale_8: string;
  public rationale_9: string;
  public rationale_10: string;
  public read_swp_legis: boolean;
  public budget_items?: Array<Model.BudgetItem>;

  constructor(raw?: any) {
    super(raw);
    this.project_type = FUND_TYPES.IPLAN;
    if (raw) {
      this.submit_task = raw.submit_task;
      this.close_task = raw.close_task;
      this.statuses = raw.statuses
      if (raw.statuses.length) {
        this.current_status = raw.statuses[raw.statuses.length - 1];
      }
      this.rationale_1 = raw.rationale_1;
      this.rationale_2 = raw.rationale_2;
      this.rationale_3 = raw.rationale_3;
      this.rationale_4 = raw.rationale_4;
      this.rationale_5 = raw.rationale_5;
      this.rationale_6 = raw.rationale_6;
      this.rationale_7 = raw.rationale_7;
      this.rationale_8 = raw.rationale_8;
      this.rationale_9 = raw.rationale_9;
      this.rationale_10 = raw.rationale_10;
      this.read_swp_legis = raw.read_swp_legis;
      this.budget_items = raw.budget_items;
    }
  }

  static getCurrentState(proposal: Model.ProposalBase): string {
    if (proposal.statuses && proposal.statuses.length) {
      return proposal.statuses[proposal.statuses.length - 1];
    } else {
      return 'Draft';
    }
  }

  static isDraft(proposal) { return proposal.statuses[proposal.statuses.length - 1] === this.draftState }

  static getFiscalReportState(proposal: Model.ProposalBase): string {
    if (proposal.statuses && proposal.statuses.length) {
      return proposal.statuses.includes(this.closedState) ? this.fiscalComplete : this.fiscalIncomplete;
    }
  }

  static fiscalComplete: string = 'Completed';
  static fiscalIncomplete: string = 'Incomplete';
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type IPlanProposal = IIPlanProposal;
  }
}
