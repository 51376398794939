import { concat, of, EMPTY } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '../services/api.service';
import { LOOKUP_TABLES, LOOKUP_TABLES_ACTION_TYPES } from './lookup-tables.action';
import { groupBy } from 'lodash';

@Injectable()
export class LookupTablesEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>,
  ) {
  }

  private lookupConfigs = {
    [LOOKUP_TABLES.CATEGORIES]: { api: this.apiService.getCodes(LOOKUP_TABLES.CATEGORIES) },
    [LOOKUP_TABLES.COUNTIES]: { api: this.apiService.getCodes(LOOKUP_TABLES.COUNTIES) },
    [LOOKUP_TABLES.COUNTIES_CITIES]: { api: this.apiService.getCountiesAndCitites() },
    [LOOKUP_TABLES.DOCUMENT_TYPES]: { api: this.apiService.getCodes(LOOKUP_TABLES.DOCUMENT_TYPES) },
    [LOOKUP_TABLES.DURATIONS]: { api: this.apiService.getCodes(LOOKUP_TABLES.DURATIONS) },
    [LOOKUP_TABLES.FUNDS]: { api: this.apiService.getFunds() },
    [LOOKUP_TABLES.IMPACTED_GROUPS]: { api: this.apiService.getCodes(LOOKUP_TABLES.IMPACTED_GROUPS) },
    [LOOKUP_TABLES.JOB_CATEGORIES]: { api: this.apiService.getCodes(LOOKUP_TABLES.JOB_CATEGORIES) },
    [LOOKUP_TABLES.METRICS_DEFINITIONS]: { api: this.apiService.getCodes(LOOKUP_TABLES.METRICS_DEFINITIONS) },
    [LOOKUP_TABLES.OBJECT_CODES]: { api: this.apiService.getCodes(LOOKUP_TABLES.OBJECT_CODES), override: this.mapObjectCodes },
    [LOOKUP_TABLES.LEGISLATION]: { api: this.apiService.getCodes(LOOKUP_TABLES.LEGISLATION) },
    [LOOKUP_TABLES.PERKINS_CODES]: { api: this.apiService.getCodes(LOOKUP_TABLES.PERKINS_CODES) },
    [LOOKUP_TABLES.PROGRAM_AREAS]: { api: this.apiService.getCodes(LOOKUP_TABLES.PROGRAM_AREAS) },
    [LOOKUP_TABLES.PROGRAM_DIVISIONS]: { api: this.apiService.getCodes(LOOKUP_TABLES.PROGRAM_DIVISIONS) },
    [LOOKUP_TABLES.ROLES]: { api: this.apiService.getRolesAndPermissions() },
    [LOOKUP_TABLES.SECTORS]: { api: this.apiService.getCodes(LOOKUP_TABLES.SECTORS) },
    [LOOKUP_TABLES.SOC_CODES]: { api: this.apiService.getCodes(LOOKUP_TABLES.SOC_CODES) },
    [LOOKUP_TABLES.SUBREGIONS]: { api: this.apiService.getCodes(LOOKUP_TABLES.SUBREGIONS) },
    [LOOKUP_TABLES.SUCCESS_GOALS]: { api: this.apiService.getCodes(LOOKUP_TABLES.SUCCESS_GOALS) },
    [LOOKUP_TABLES.TASKFORCE_RECOMMENDATIONS]: { api: this.apiService.getCodes(LOOKUP_TABLES.TASKFORCE_RECOMMENDATIONS) },
    [LOOKUP_TABLES.TOP6_CODES]: { api: this.apiService.getCodes(LOOKUP_TABLES.TOP6_CODES) }
  };

  @Effect() doLookup$ = this.actions$.pipe(
    ofType(LOOKUP_TABLES_ACTION_TYPES.DO_LOOKUP),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.LookupTables.getLoadStatus)),
    mergeMap(([payload, loadStatus]) => {
      if (loadStatus[payload.name + 'IsLoaded']) {
        return EMPTY;
      }

      const lookupConfig = this.lookupConfigs[payload.name];
      if (lookupConfig.override) {
        return lookupConfig.api.pipe(
          mergeMap(lookupConfig.override),
          catchError(error => of(DomainActions.LookupTables.lookupFail(payload.name, error)))
        );
      } else {
        return lookupConfig.api.pipe(
          map(res => DomainActions.LookupTables.lookupSuccess(res, payload.name)),
          catchError(error => of(DomainActions.LookupTables.lookupFail(payload.name, error)))
        );
      }
    })
  );

  @Effect() lookupInstitutions$ = this.actions$.pipe(
    ofType(LOOKUP_TABLES_ACTION_TYPES.LOOKUP_INSTITUTIONS_LIST),
    map(toPayload),
    // check the lookup table
    withLatestFrom(this.store.select(Queries.LookupTables.getLoadStatus)),
    mergeMap(([payload, loadStatus]) => {
      // Set load flags. The permutations are kinda complex.
      // `all` is true if the payload asks for it and it's not already loaded.
      const loadAll: boolean = !!(payload.all && (!loadStatus[LOOKUP_TABLES.ALL_INSTITUTIONS + 'IsLoaded'] || payload.force));
      // `caep` is true if the payload asks for it, AND it's not already loaded, AND It's not covered by the "all" case.
      const caep: boolean = !!(payload.caep && (!loadStatus[LOOKUP_TABLES.CAEP_INSTITUTIONS + 'IsLoaded'] || payload.force) && !loadAll);
      // `ccc` is true if the payload asks for it, AND it's not already loaded, AND It's not covered by the "all" case...
      //    OR `caep` is true AND CCC institutions are not already loaded.
      const ccc: boolean = !!(payload.ccc && (!loadStatus[LOOKUP_TABLES.CCC_INSTITUTIONS + 'IsLoaded'] || payload.force) && !loadAll)
        || !!(caep && (!loadStatus[LOOKUP_TABLES.CCC_INSTITUTIONS + 'IsLoaded'] || payload.force));

      const include_settings: boolean = !!(payload.include_settings && (!loadStatus[LOOKUP_TABLES.SETTINGS_INSTITUTIONS + 'IsLoaded'] || payload.force));

      // Do lookup from Service if we don't already have the bits of data we need, or if given the `force` option.
      if (payload.force || ccc || caep || loadAll || include_settings) {
        return this.apiService.listInstitutions({ ccc, caep, include_settings }).pipe( // No filter = Load all
          map((res: any) =>
            DomainActions.LookupTables.lookupInstitutionsSuccess(res, payload)
          ),
          catchError((error) => of(DomainActions.LookupTables.lookupFail(LOOKUP_TABLES_ACTION_TYPES.LOOKUP_INSTITUTIONS_LIST, error)))
        );
      } else {
        // The needed institutions are already loaded..don't lookup again again.
        return of(DomainActions.LookupTables.lookupHasData());
      }
    })
  );

  mapObjectCodes(objectCodes: Array<any>) {
    // Filter out the "Unspecified Expenditure" object code
    const filteredCodes = objectCodes.filter(objectCode => objectCode.code !== '0000');
    const defaultCodes = filteredCodes.filter(objectCode => objectCode.type === 'default');
    const fundsObjectCodes = groupBy(filteredCodes, objectCode => objectCode['type']);

    return concat([
      DomainActions.LookupTables.lookupSuccess(fundsObjectCodes, LOOKUP_TABLES.FUNDS_OBJECT_CODES),
      DomainActions.LookupTables.lookupSuccess(defaultCodes, LOOKUP_TABLES.OBJECT_CODES)
    ]);
  }
}
