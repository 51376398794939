import { IFiscalReportsState, Model, Query, Queries, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IFiscalReportsState>('FiscalReports');

/**
 * Predefined queries to run against root states.
 */
export interface IFiscalReportsQueries {
  getReports: Query<Array<Model.FiscalReport>>;
  getStatus: Query<Array<Model.FiscalReportStatus>>;
  isStatusComplete: Query<boolean>;
  getProposalReport: Query<Model.FiscalReport>;

  isReportLoaded: Query<boolean>;
  getTasks: Query<Array<Model.FiscalReportInfo>>;
}

Queries.FiscalReports = {
  getReports: fromRoot(state => state.items),
  getStatus: fromRoot(state => state.submitStatus),
  isStatusComplete: fromRoot(state => {
    let isComplete = true && !!state.submitStatus.length;
    state.submitStatus.forEach(statusObj => {
      if (Object.values(statusObj.status).some(value => !value)) {
        isComplete = false;
        return false;
      }
    });
    return isComplete;
  }),
  getProposalReport: fromRoot(state => state.proposal_report),

  isReportLoaded: fromRoot(state => state.reportLoaded),
  getTasks: fromRoot(state => state.reportingInfo),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    FiscalReports: Query<IFiscalReportsState>;
  }

  interface Queries {
    FiscalReports: IFiscalReportsQueries;
  }
}
