import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Budget = {
  items: [],
  raw_items: [],
  lastUsedNewId: 0,
  allocations: {},
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IBudgetState {
    proposal_id?: number;
    lastUsedNewId: number;
    items: Array<Model.BudgetItem>;
    raw_items: Array<Model.BudgetItem>;
    allocations: Model.Allocations;
  }

  interface State {
    Budget: IBudgetState;
  }
}
