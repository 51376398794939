import { ICfadAmendmentState, Query, Queries, Model, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<ICfadAmendmentState>('CfadAmendment');

/**
 * Predefined queries to run against root states.
 */
export interface ICfadAmendmentQueries {
  getAmendments: Query<{ [duration_id: number]: Model.CfadAmendment }>;
  getCurrentAmendment: Query<Model.CfadAmendment>;
  isLoaded: Query<boolean>;
  get: Query<ICfadAmendmentState>;
}

Queries.CfadAmendment = {
  getAmendments: fromRoot(state => state.amendments),
  getCurrentAmendment: fromRoot(state => {
    if (state.amendments && state.current_amendment_duration_id) {
      return state.amendments[state.current_amendment_duration_id];
    } else {
      return null;
    }
  }),
  isLoaded: fromRoot(state => state.isLoaded),
  get: fromRoot(state => state)
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    CfadAmendment: Query<ICfadAmendmentState>;
  }

  interface Queries {
    CfadAmendment: ICfadAmendmentQueries;
  }
}
