import { from as observableFrom } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Model } from '@app-ngrx-domains';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

export class AppUtils {

  /**
   * Returns true if value is undefined or is null.
   * @param value
   */
  static isNil(value: any): boolean {
    return value === undefined || value === null;
  }

  /**
   * Formats a user object for use in po-select menu
   * @param contact Contact item, must use any to deal with AEBG contact
   * @returns {Model.SelectOption} Formatted option for look-ahead
   */
  static formatContact(contact: Model.User|any): Model.SelectOption {
    if (contact.first_name && contact.last_name) {
      return {
        label: `${contact.last_name || contact}, ${contact.first_name} (${contact.email_address})`,
        value: contact.id
      }
    } else if (contact.name && contact.name.length > 0) {
      const name = contact.name.split(' ');
      return {
        label: `${name[name.length - 1]}, ${name.slice(0, name.length - 1).join (' ')} (${contact.email_address})`,
        value: contact.user_id
      }
    }
  }

  /**
   * Formats a institution object for use in po-select menu
   * @param institution
   * @returns {Model.SelectOption} Formatted option for look-ahead
   */
  static formatInstitution(institution: Model.Institution, useName?: boolean): Model.SelectOption {
    if (!institution) {
      return null;
    }
    let label: string;
    useName ? label = institution.name : label = institution.long_name || institution.name || 'Undefined';
    return {
      label: `${label} - ${institution.type}`,
      value: institution.id
    };
  }

  static showWIPFeature(featureName: string): boolean {
    // abscence of check is to show the feature.
    return Object.keys(environment.wip_features).includes(featureName) ? environment.wip_features[featureName] : true;
  }

  static openBrowserPrintModal(): void {
    observableFrom([true]).pipe(delay(300)).subscribe(() => { // Wait a half second
      window.print();
    });
  }

  /**
   * Decodes jwt token using JwtHelperService.
   * @param token
   */
  static jwtDecodeToken(token: string): any {
    const jwtHelper = new JwtHelperService();
    return jwtHelper.decodeToken(token);
  }
}
