import { Action } from '@ngrx/store';
import { ICfadAmendmentState, Actions, Model, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { Cfad, CfadAmendment, Task, EnumErrorTypes, ICfadAmendmentChanges } from '@app-models';

/**
 * List of action types
 */
const ACTION_PREFIX = 'CFAD_AMENDMENT_';
export const CFAD_AMENDMENT_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  UPDATE: `${ACTION_PREFIX}UPDATE`,
  REFRESH: `${ACTION_PREFIX}REFRESH`,
  REFRESH_RESET: `${ACTION_PREFIX}REFRESH_RESET`,
  REFRESH_SUCCESS: `${ACTION_PREFIX}REFRESH_SUCCESS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  UPDATE_AMENDMENT_CHANGES: `${ACTION_PREFIX}UPDATE_AMENDMENT_CHANGES`,
  UPDATE_ALLOCATIONS: `${ACTION_PREFIX}UPDATE_ALLOCATIONS`,
  UPDATE_STATE: `${ACTION_PREFIX}UPDATE_STATE`,
  UPDATE_TASK_SUCCESS: `${ACTION_PREFIX}UPDATE_TASK_SUCCESS`,
};

/**
 * Action creator class
 */
export class CfadAmendmentActions {

  load(response: any): ActionWithPayload<any> {
    const state: ICfadAmendmentState = {
      amendments: {},
      isLoaded: true,
    };

    // get amendment tasks for all cfads that's been certified.
    response.cfads.forEach(cfad => {
      if (Cfad.isCertified(cfad.status_id)) {
        state.amendments[cfad.duration_id] = new CfadAmendment(cfad).iObject<Model.CfadAmendment>();
      }
    });

    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.LOAD,
      payload: state
    };
  }

  /**
   * Resets/clears data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.LOAD,
      payload: {
        ...State.CfadAmendment,
      },
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: CFAD_AMENDMENT_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * Sets current amendment year id.
   * @param yearDurationId
   */
  setAmendmentYear(yearDurationId: number): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.UPDATE,
      payload: {
        current_amendment_duration_id: yearDurationId,
      }
    };
  }

  updateAmendmentChanges(changes: ICfadAmendmentChanges): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.UPDATE_AMENDMENT_CHANGES,
      payload: changes
    };
  }

  updateAllocations(allocations: Array<Model.Allocation>, markWorkflowStep?: any): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.UPDATE_ALLOCATIONS,
      payload: { allocations, markWorkflowStep }
    };
  }

  updateState(changes: any): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.UPDATE_STATE,
      payload: changes
    };
  }

  updateTaskSuccess(duration_id: number, res: any): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.UPDATE_TASK_SUCCESS,
      payload: {
        duration_id: duration_id,
        task: new Task(res).iObject<Model.Task>()
      }
    };
  }

  refresh(gotoUrl = ''): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.REFRESH,
      payload: { gotoUrl: gotoUrl }
    };
  }

  refreshReset(cfad_id: number, gotoUrl = ''): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.REFRESH_RESET,
      payload: { cfad_id: cfad_id, gotoUrl: gotoUrl }
    };
  }

  refreshSuccess(cfad): ActionWithPayload<any> {
    return {
      type: CFAD_AMENDMENT_ACTION_TYPES.REFRESH_SUCCESS,
      payload: new CfadAmendment(cfad).iObject<Model.CfadAmendment>(),
    };
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.CfadAmendment = new CfadAmendmentActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    CfadAmendment: CfadAmendmentActions;
  }
}
