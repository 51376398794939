import {AfterViewInit, Component, Input, OnDestroy, ChangeDetectionStrategy} from '@angular/core';
import {InputRefDirective} from '../../directives';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'character-count',
  templateUrl: './character-count.component.html'
})
export class CharacterCountComponent implements AfterViewInit, OnDestroy {

  @Input() input: InputRefDirective;
  destroy$: Subject<boolean> = new Subject();
  ariaLive = 'off';
  ariaText = '';
  atMaxLength = false;

  constructor() {}

  ngAfterViewInit() {
    this.setAriaLabelText();
    this.setMaxLength();

    this.input.focusSubject.pipe(
      takeUntil(this.destroy$)
    ).subscribe(focused => {
      this.setAriaLive(focused);
    });

    this.input.keyUpSubject.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.setAriaLabelText();
      this.setMaxLength()
    });
  }

  private setAriaLive(focused: boolean) {
    this.ariaLive = focused ? 'polite' : 'off';
  }

  private setAriaLabelText() {
    let str = `${this.input.element.maxLength} Characters Maximum`;
    if (this.input.element.value.length > 0) {
      if (this.input.element.value.length <= this.input.element.maxLength) {
        str = `${Math.abs(this.input.element.maxLength - this.input.element.value.length)} Characters Remaining`;
      }
    }
    if (this.input.element.value.length > this.input.element.maxLength) {
      str = `0 Characters Remaining`;
    }
    this.ariaText = str;
  }

  private setMaxLength() {
    this.atMaxLength = this.input.element.value.length === this.input.element.maxLength;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
