import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil, take, skipWhile, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';
import { PermissionsService, ProgramService, ApiService } from '@app/core/services';
import { Fund, PROPOSAL_TYPES } from '@app/core/models';
import { AREAS, ACTIONS, WILDCARD, PROGRAM_KEYS, PAGE_TYPES } from '@app/core/consts';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-left-nav',
  templateUrl: './left-nav.component.html',
})
export class LeftNavComponent implements OnInit, OnDestroy {
  public profileName$: Observable<string>;
  public loggedin$: Observable<boolean>;
  public canAdmin$: Observable<boolean>;
  public sudoLoggedIn$: Observable<boolean>;
  public unreadMessages$: Observable<number>;
  public routePermissions: { [key: string]: boolean } = {};
  public loggedIn: boolean;
  public logo = environment.logos.nav;

  public url = '';
  public hideNav = false;
  public expandNav = true;
  public selectedProgram = null;
  @ViewChild('treePrograms', {static: false}) treePrograms;

  public programs: Array<Model.Fund & { isActive?: boolean, isCompetitive?: boolean }> = [];
  public settingsRoutes: { [key: string]: string };

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private store: Store<State>,
    private apiService: ApiService,
    private permissionsService: PermissionsService,
    private programService: ProgramService
  ) {
    this.profileName$ = this.store.select(Queries.Auth.getFullName);
    this.loggedin$ = this.store.select(Queries.Auth.isLoggedIn);
    this.sudoLoggedIn$ = this.store.select(Queries.Auth.isSudoLoggedIn);
    this.unreadMessages$ = this.store.select(Queries.Auth.getCurrentUserUnreadMessages);
  }

  ngOnInit() {
    this.programService.isLoaded()
      .pipe(
        skipWhile(loaded => !loaded),
        take(1)
      )
      .subscribe(loaded => {
        this.initPrograms();

        this.router.events.subscribe((event: RouterEvent) => {
          this.url = event.url || '';
          if (event instanceof NavigationEnd) {
            this.setInitialSelectedProgram();
          }
        });

        this.store.select(Queries.Layout.getNav)
          .pipe(takeUntil(this.destroy$))
          .subscribe((nav: Model.NavLayout) => {
            const hide = !nav.show;
            if (hide !== this.hideNav) {
              // show or hide the nav bar.
              this.hideNav = hide;
            }
          });

        this.fillDynamicRoutes();

        this.loggedin$
          .pipe(takeUntil(this.destroy$))
          .subscribe(loggedIn => {
            this.loggedIn = loggedIn;
            this.routePermissions = {};

            if (loggedIn) {
              this.programService.refreshSmallPrograms();

              // list Fiscal Agent Proposals to see if user has access to Fiscal Agent Hub
              this.apiService.getProposals({ deleted: false, type: PROPOSAL_TYPES.FISCAL_AGENT }, false).subscribe(proposals => {
                this.routePermissions['fiscal-agent-hub'] = !!proposals.length;
              });
            }
        });
      });
  }

  private initPrograms() {
    this.programService.smallPrograms
      .pipe(
        takeUntil(this.destroy$),
        filter(smallPrograms => !!smallPrograms)
      )
      .subscribe((smallPrograms) => {
        let parentPrograms = this.programService.parentPrograms;
        if (environment.name === 'demo') {
          parentPrograms = parentPrograms.filter(p => [PROGRAM_KEYS.SWP_R, PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWP_K12].includes(p.key));
        } 

        this.programs = [...parentPrograms, ...smallPrograms].sort((a, b) => a.short_name > b.short_name ? 1 : -1);

        if (this.loggedIn) {
          this.fetchPermissions();
        }
      });
  }

  private fillDynamicRoutes() {

    // fill in settings routes that depend on program ids
    this.settingsRoutes = {};

    let childProgram = this.programService.getChildProgramsByParentKey(PROGRAM_KEYS.CAI, PROGRAM_KEYS.RSI);
    this.settingsRoutes['cai-rsi'] = !!childProgram ? `/${childProgram[0].parent_key}/settings/rsi/${childProgram[0].id}` : '';

    childProgram = this.programService.getChildProgramsByParentKey(PROGRAM_KEYS.SWP_L, PROGRAM_KEYS.SWPL_v2);
    if (!!childProgram) {
      this.settingsRoutes['swpl-assurances'] = childProgram ? `/${childProgram[0].parent_key}/settings/assurances/${childProgram[0].id}` : '';
    }

    childProgram = this.programService.getChildProgramsByParentKey(PROGRAM_KEYS.SWP_R, PROGRAM_KEYS.SWPR_v2);
    if (!!childProgram) {
      this.settingsRoutes['swpr-assurances'] = childProgram ? `/${childProgram[0].parent_key}/settings/assurances/${childProgram[0].id}` : '';
    }
  }

  private fetchPermissions() {
    // build the resources out once program service becomes available.
    const resources: Array<Model.PermResourceItem> = [
      // system admin access
      { area: AREAS.PROGRAM_ADMIN, action: ACTIONS.EDIT },
      // admin access
      { area: AREAS.USER_ADMIN, action: ACTIONS.EDIT },
    ];

    const fundOptions = [];

    this.programs.forEach(program => {
      const programId = program.id;
      const programKey = program.key;

      fundOptions.push({ value: programId, label: Fund.getShortestName(program) });

      // settings access
      resources.push({ area: AREAS.FUND_SETTINGS, action: ACTIONS.VIEW, fund_id: programId, institution_id: WILDCARD });

      if (programKey === PROGRAM_KEYS.SWP_L || programKey === PROGRAM_KEYS.SWP_R) {
        resources.push({ area: AREAS.ANALYTICS, action: ACTIONS.VIEW, fund_id: programId, institution_id: WILDCARD });
      }

      // review & offer access
      if (program.is_small_program) {
        const rfas = this.programService.getChildProgramsByParentKey(program.key, PROGRAM_KEYS.RFA);
        rfas.forEach(rfa => {
          resources.push({ area: AREAS.REVIEW, action: ACTIONS.VIEW, fund_id: rfa.id, institution_id: WILDCARD });
          resources.push({ area: AREAS.OFFER, action: ACTIONS.EDIT, fund_id: rfa.id, institution_id: WILDCARD });
        });
      } else if (programKey === PROGRAM_KEYS.CAI) {
        // need to check for each rfas
        const caiV1Settings = this.programService.getChildProgramsByParentKey(PROGRAM_KEYS.CAI, PROGRAM_KEYS.RFA);
        const caiV2Settings = this.programService.getChildProgramsByParentKey(PROGRAM_KEYS.CAI, PROGRAM_KEYS.RFA_v2);
        [...caiV1Settings, ...caiV2Settings].forEach(rfa => {
          resources.push({ area: AREAS.REVIEW, action: ACTIONS.VIEW, fund_id: rfa.id, institution_id: WILDCARD });
          resources.push({ area: AREAS.OFFER, action: ACTIONS.EDIT, fund_id: rfa.id, institution_id: WILDCARD });
        });
      } else {
        // rest can be checked at parent level
        const isCompetitive = this.programService.getChildProgramsByParentKey(programKey).some(fund => !!fund.program_settings.is_rfa);
        if (isCompetitive) {
          resources.push({ area: AREAS.REVIEW, action: ACTIONS.VIEW, fund_id: programId, institution_id: WILDCARD });
          resources.push({
            area: AREAS.OFFER,
            action: [PROGRAM_KEYS.SWP_K12, PROGRAM_KEYS.RCM].includes(programKey) ? ACTIONS.VIEW : ACTIONS.EDIT,
            fund_id: programId,
            institution_id: WILDCARD
          });
        }
      }

      // renewal access
      // TODO: add PROGRAM_KEYS.RCM once it's ready to support renewal
      if ([PROGRAM_KEYS.EWD].includes(programKey)) {
        resources.push({ area: AREAS.RENEWAL, action: ACTIONS.EDIT, fund_id: programId, institution_id: WILDCARD });
      }

      // deferral access
      if ([PROGRAM_KEYS.SWP_K12].includes(programKey)) {
        resources.push({ area: AREAS.DEFERRAL, action: ACTIONS.VIEW, fund_id: programId, institution_id: WILDCARD });
      }
    });

    // re-get user's permissions to guard the menu items.
    this.permissionsService.canResources(resources)
      .pipe(take(1))
      .subscribe(permissions => {

        const addRoutePermission = (key: string, allowed: boolean) => {
          if (this.routePermissions[key]) {
            this.routePermissions[key] = this.routePermissions[key] || allowed;
          } else {
            this.routePermissions[key] = allowed;
          }
        };

        permissions.forEach(permission => {
          if (permission.fund_id) {
            // search from root funds.
            let match = fundOptions.find(fund => fund.value === permission.fund_id);
            if (!match) {
              // get child's parent/root fund.
              const parent = this.programService.getParentProgramById(permission.fund_id);
              match = fundOptions.find(fund => fund.value === parent.id);
            }
            if (match) {
              const key = `${match.label}:${permission.area}:${permission.action}`;
              addRoutePermission(key, permission.allowed);
            }
          } else {
            addRoutePermission(permission.area, permission.allowed);
          }
        });
      });
  }

  private setInitialSelectedProgram() {
    const urlSplit = this.url.split('/');
    const firstPath = urlSplit.length > 1
      ? (urlSplit[1] === PROGRAM_KEYS.SMALL_PROGRAMS ? urlSplit[2] : urlSplit[1])
      : null;
    if (firstPath) {
      let found = this.programs.find(program => program.key === firstPath);
      this.selectedProgram = found ? found : null;
    }
  }

  toggleExpand() {
    this.expandNav = !this.expandNav;
    if (!this.expandNav) {
      this.store.dispatch(Actions.Layout.setPageType(PAGE_TYPES.SITE_NAV_COLLAPSED));
    } else {
      this.store.dispatch(Actions.Layout.clearPageType());
    }
  }

  routeAccess(key: string): boolean {
    return this.routePermissions[key] ? this.routePermissions[key] : false;
  }

  selectProgram(program: Model.Fund | null) {
    this.selectedProgram = program;
    if (!program) {
      setTimeout(() => {
        this.treePrograms.treeItem.nativeElement.click();
      }, 100);
    }
  }

  logout() {
    this.store.dispatch(Actions.Auth.logout());
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
