import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { State, Actions } from '@app-ngrx-domains';
import { Observable } from 'rxjs';
import { LoggerService } from 'ng-logger';
import { ProgramService } from '@app-services';
import { skipWhile, take } from 'rxjs/operators';

@Injectable()
export class ProgramsResolver implements Resolve<boolean> {
  constructor(
    private store: Store<State>,
    private logger: LoggerService,
    private programService: ProgramService
  ) { }

  resolve(): Observable<boolean> {
    return new Observable(subscriber => {
      this.logger.debug(`[programs-resolver] loading`);
      this.store.dispatch(Actions.Layout.showBusySpinner(true));

      this.programService.isLoaded().pipe(
        skipWhile(loaded => !loaded),
        take(1)
      ).subscribe(() => {
        this.logger.debug(`[programs-resolver] loaded`);
        this.store.dispatch(Actions.Layout.showBusySpinner(false));
        subscriber.next(true);
        subscriber.complete();
      }, error => {
        this.logger.error(`[programs-resolver] error=${JSON.stringify(error)}`);
        this.store.dispatch(Actions.Layout.showBusySpinner(false));
        subscriber.error(false);
        subscriber.complete();
      });
    });
  }
}
