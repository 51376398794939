import * as _ from 'lodash';
import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { Proposal, EnumErrorTypes } from '@app-models';

/**
 * List of action types
 */
const ACTION_PREFIX = 'FILES_';
export const FILES_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  APPEND_FILE: `${ACTION_PREFIX}APPEND_FILE`,
  DELETE: `${ACTION_PREFIX}DELETE`,
  DELETE_SUCCESS: `${ACTION_PREFIX}DELETE_SUCCESS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`
};

/**
 * Action creator class
 */
export class FilesActions {

  /**
   * Loads documents from the project.
   *
   * @param {any} project
   * @returns {Action}
   */
  load(project: any): ActionWithPayload<any> {
    let items = (project.files && project.files.length > 0) ? project.files : [];
    items = items.filter(item => !item['deleted']);

    return {
      type: FILES_ACTION_TYPES.LOAD,
      payload: {
        items: items,
      }
    };
  }

  /**
   * Resets documents
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: FILES_ACTION_TYPES.LOAD,
      payload: {
        items: [],
      }
    };
  }

  /**
   * Appends a file to the list.
   *
   * @param {Model.Document} file
   * @returns {Action}
   */
  append(file: Model.Document): ActionWithPayload<any> {
    return {
      type: FILES_ACTION_TYPES.APPEND_FILE,
      payload: file
    };
  };

  /**
   * Deletes a file from the list.
   *
   * @param {number} id
   * @returns {Action}
   */
  delete(id: number): ActionWithPayload<any> {
    return {
      type: FILES_ACTION_TYPES.DELETE,
      payload: id
    }
  };

  /**
   * Removes deleted file from redux store.
   *
   * @param {number} id
   * @returns {Action}
   */
  deleteSuccess(id: number): ActionWithPayload<any> {
    return {
      type: FILES_ACTION_TYPES.DELETE_SUCCESS,
      payload: id
    }
  };

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): Action {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: FILES_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Files = new FilesActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Files: FilesActions;
  }
}
