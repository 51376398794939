import * as _ from 'lodash';
import { Model } from '@app-ngrx-domains';
import { STATE_TYPES, WORKFLOW_STEPS } from '../consts';

export interface IProgramAreaReport {
  id?: number;
  proposal_id: number;
  institution_id: number;
  institution?: Model.Institution;
  duration_id: number;
  state_id: number;
  lines?: IProgramAreaReportLine[];
  client_state?: { [name: string]: any };
}

export class ProgramAreaReport implements IProgramAreaReport {
  public id?: number;
  public proposal_id: number;
  public institution_id: number;
  public institution?: Model.Institution;
  public duration_id: number;
  public state_id: number;
  public lines?: IProgramAreaReportLine[];
  public client_state?: { [name: string]: any };

  constructor(raw?) {
    if (raw) {
      this.id = raw.id;
      this.proposal_id = raw.proposal_id;
      this.institution_id = raw.institution_id;
      this.institution = raw.institution;
      this.duration_id = raw.duration_id;
      this.state_id = raw.state_id;
      this.lines = _.map(raw.lines, (line) => { return new ProgramAreaReportLine(line); });
      this.client_state = {};
      if (this.lines && this.lines.length) {
        const leveraged_funds = this.lines.find(line => line.type === 'leveraged_funds');
        const hours_of_instruction = this.lines.find(line => line.type === 'hours_of_instruction');
        this.client_state[WORKFLOW_STEPS.LEVERAGED_FUNDS + '_dirty'] = !!leveraged_funds;
        this.client_state[WORKFLOW_STEPS.HOURS_OF_INSTRUCTION + '_dirty'] = !!hours_of_instruction;
      }
    }
  }

  get iObject(): IProgramAreaReport {
    return {
      id: this.id,
      proposal_id: this.proposal_id,
      institution_id: this.institution_id,
      duration_id: this.duration_id,
      state_id: this.state_id,
      lines: this.lines
    };
  }

  static getStatusLabel(state_id: number) {
    if (!state_id || state_id === STATE_TYPES.DRAFT) {
      return 'Draft';
    } else if (state_id === STATE_TYPES.SAVED) {
      return 'Estimates Saved';
    } else if (state_id === STATE_TYPES.SUBMITTED) {
      return 'Submitted';
    } else if (state_id === STATE_TYPES.CERTIFIED) {
      return 'Certified';
    }
  }

}

export interface IProgramAreaReportLine {
  id?: number;
  program_area_report_id?: number;
  program_area_id: number;
  fund_id?: number;
  amount: number;
  type: string;
}

export class ProgramAreaReportLine implements IProgramAreaReportLine {
  public id?: number;
  public program_area_report_id?: number;
  public program_area_id: number;
  public fund_id?: number;
  public amount: number;
  public type: string;

  constructor(raw?) {
    if (raw) {
      this.id = raw.id;
      this.program_area_report_id = raw.program_area_report_id;
      this.program_area_id = raw.program_area_id;
      this.fund_id = raw.fund_id;
      this.amount = raw.amount;
      this.type = raw.type;
    }
  }

  get iObject(): IProgramAreaReportLine {
    const iObj = {
      id: this.id,
      program_area_report_id: this.program_area_report_id,
      program_area_id: this.program_area_id,
      fund_id: this.fund_id,
      amount: this.amount,
      type: this.type,
    };

    return iObj;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ProgramAreaReport = IProgramAreaReport;
  }
  export namespace Model {
    export type ProgramAreaReportLine = IProgramAreaReportLine;
  }
}
