<div class="segmented-control segmented-control--stand-alone mb-l">
  <ul class="segmented-control__list">
    <li class="segmented-control__item">
      <input type="radio" name="page" value="1" class="segmented-control__input" id="profile_info_tab_radio"
        [checked]="activeTab==='profile_info'"
        (click)="activeTab='profile_info'">
      <label for="profile_info_tab_radio" class="segmented-control__label" id="qa_profile_personal_tab">
        Profile
      </label>
    </li>
    <li class="segmented-control__item">
      <input type="radio" name="page" value="2" class="segmented-control__input" id="profile_roles_tab_radio"
        [checked]="activeTab==='profile_roles'"
        (click)="activeTab='profile_roles'">
      <label for="profile_roles_tab_radio" class="segmented-control__label" id="qa_profile_roles_tab">
        Roles
      </label>
    </li>
    <li class="segmented-control__item">
      <input type="radio" name="page" value="3" class="segmented-control__input" id="profile_settings_tab_radio"
        [checked]="activeTab==='profile_settings'"
        (click)="activeTab='profile_settings'">
      <label for="profile_settings_tab_radio" class="segmented-control__label" id="qa_profile_settings_tab">
        Account Settings
      </label>
    </li>
  </ul>
</div>

<app-user-info [hidden]="activeTab !=='profile_info'"
  [profile]="profile"
  (saveInfoClicked)="saveInfo($event)">
</app-user-info>

<app-user-roles-list [hidden]="activeTab !=='profile_roles'"
  [userRoleScopes]="userRoleScopes"
  [canEditRole]="canEditRole">
</app-user-roles-list>

<app-profile-settings [hidden]="activeTab !=='profile_settings'"
  [profile]="profile">
</app-profile-settings>
