import { State, Model } from '@app-ngrx-domains';
import { EnumErrorTypes } from '../models';

/**
 * States managed by reducer; used also as initial states
 */
State.App = {
  error: {
    type: EnumErrorTypes.none,
  }
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IAppState {
    error: Model.ErrorHandler;
  }

  interface State {
    App: IAppState;
  }
}
