
import {take, skipWhile} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services';
import { ROUTER_LINKS } from '../../consts';
import { Profile } from '../../models';
import { cloneDeep } from 'lodash';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit, OnDestroy {

  activeTab = 'profile_info';

  profile: Profile = new Profile({});
  showFeedback = false;

  canEditRole = false;
  userRoleScopes: Array<Model.UserRoleScope> = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private apiService: ApiService,
    private store: Store<State>,
  ) {
    // update header
    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: 'My Profile',
      actions: []
    }));
  }

  ngOnInit() {
    // set up subscriptions as needed.
    let subscription;

    // listen for current user.
    subscription = this.store.select(Queries.Auth.getCurrentUser).subscribe((profile: Profile) => {
      if (!profile) {
        // in the process of logging out... ignore this change.
        return;
      }

      if (this.showFeedback) {
        this.showFeedback = false;
        this.store.dispatch(Actions.Layout.updateHeaderMessage('success', 'Successfully saved profile.'));
      }

      // make a copy since what's read in is readonly from the store.
      this.profile = cloneDeep(profile);

      // read user's roles.
      this.refreshUserRoleScopes();
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  saveInfo(updated: Model.User) {
    // dispatch update user info to persistent in storage
    this.store.dispatch(Actions.Auth.updateUser(new Profile(updated)));
    this.showFeedback = true;
    this.inProgress(() => {
    });
  }

  private inProgress(cb?) {// wait for it to be done.
    this.store.select(Queries.Auth.isInProgress).pipe(
      skipWhile((inProgress: boolean) => inProgress),
      take(1))
      .subscribe(cb || (() => {
        // route user to the landing page.
        this.router.navigate([ROUTER_LINKS.HOME]);
      }));
  }

  /**
   * Gets list of roles assigned to the user.
   * @private
   */
  private refreshUserRoleScopes() {
    this.apiService.getProfileById(this.profile.id).subscribe(
      (profile: Profile) => {
        this.userRoleScopes = (new Profile(profile)).scopes;
      }
    );
  }
}
