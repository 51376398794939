import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Metrics = {
  gp_metrics: [],
  lvg_metrics: [],
  sep_metrics: [],
  swp_metrics: [],
  lb_metrics: [],
  caep_metrics: [],
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface IMetricsState {
    gp_metrics: Array<Model.MetricItem>;
    lvg_metrics: Array<any>;
    sep_metrics: Array<any>;
    swp_metrics: Array<Model.LBMetric>;
    lb_metrics: Array<Model.LBMetric>;
    caep_metrics: Array<Model.LBMetric>;
  }

  interface State {
    Metrics: IMetricsState;
  }
}
