import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload, NewTempId } from '@app-libs';
import { EnumErrorTypes, Metric } from '@app-models';

/**
 * Proposal Metrics Action types
 */
const ACTION_PREFIX = 'PROPOSAL_METRICSEX_';
export const METRICSEX_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  CREATE_METRIC: `${ACTION_PREFIX}CREATE_METRIC`,
  CREATE_METRICS: `${ACTION_PREFIX}CREATE_METRICS`,
  UPSERT_METRIC: `${ACTION_PREFIX}UPSERT_METRIC`,
  UPSERT_METRIC_SUCCESS: `${ACTION_PREFIX}UPDATE_METRIC_SUCCESS`,
  DELETE_METRIC: `${ACTION_PREFIX}DELETE_METRIC`,
  DELETE_METRIC_SUCCESS: `${ACTION_PREFIX}DELETE_METRIC_SUCCESS`,
  DELETE_LMI_PROGRAM_METRICS: `${ACTION_PREFIX}DELETE_LMI_PROGRAM_METRICS`,
  DELETE_LMI_PROGRAM_METRICS_SUCCESS: `${ACTION_PREFIX}DELETE_LMI_PROGRAM_METRICS_SUCCESS`,
};

/**
 * Proposal MetricsEx action class.
 */
export class MetricsExActions {

  /**
   * Fetched data - send it along to reducer
   * @param proposal
   */
  load(proposal: any): ActionWithPayload<any> {
    const state = [];

    (proposal.metrics || []).forEach(item => {
      state.push(new Metric(item).iObject<Model.MetricItem>());
    });

    return {
      type: METRICSEX_ACTION_TYPES.LOAD,
      payload: state
    };
  }

  /**
   * Resets data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.LOAD,
      payload: [],
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: METRICSEX_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  createMetric(item: any): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.CREATE_METRIC,
      payload: {
        ...item,
        id: NewTempId.id(), // assign auto generated id.
      }
    };
  }

  createMetrics(items: any[]): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.CREATE_METRICS,
      payload: items.map(item => ({ ...item, id: NewTempId.id() }))
    }
  }

  upsertMetric(item: any): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.UPSERT_METRIC,
      payload: item,
    }
  }

  upsertMetricSuccess(res: any): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.UPSERT_METRIC_SUCCESS,
      payload: {
        item: res,
      }
    };
  }

  deleteMetric(item: any): ActionWithPayload<any> {
    if (NewTempId.isTemp(item.id)) {
      // we're dealing with a temp record, no need to call the service.
      return {
        type: METRICSEX_ACTION_TYPES.DELETE_METRIC_SUCCESS,
        payload: item
      };
    } else {
      return {
        type: METRICSEX_ACTION_TYPES.DELETE_METRIC,
        payload: item
      };
    }
  }

  deleteMetricSuccess(item: any): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.DELETE_METRIC_SUCCESS,
      payload: item
    };
  }

  deleteLmiProgramMetrics(lmiProgramIds: Array<number>): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.DELETE_LMI_PROGRAM_METRICS,
      payload: lmiProgramIds
    };
  }

  deleteLmiProgramMetricsSuccess(lmiProgramIds: Array<number>): ActionWithPayload<any> {
    return {
      type: METRICSEX_ACTION_TYPES.DELETE_LMI_PROGRAM_METRICS_SUCCESS,
      payload: lmiProgramIds
    };
  }

}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.MetricsEx = new MetricsExActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    MetricsEx: MetricsExActions;
  }
}
