import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State, Model } from '@app-ngrx-domains';
import { ApiService } from '../../services';
import { Profile } from '../../models';
import { ValidatorsEx } from '../../utilities';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html'
})
export class ProfileSettingsComponent implements OnInit {
  @Input() profile: Profile = new Profile({});

  formPassword: FormGroup;
  formNotifications: FormGroup;
  changePasswordStatus: Model.StatusMessage = {
    message: undefined,
    type: undefined
  };

  pc: AbstractControl;

  constructor(
    private apiService: ApiService,
    private _fb: FormBuilder,
    private store: Store<State>
  ) {
  }

  ngOnInit() {
    this.formPassword = this._fb.group({
      current: ['', [Validators.required, Validators.minLength(1)]],
      new: this._fb.group({
        update: ['', [ValidatorsEx.password] ],
        confirmation: ['', [ValidatorsEx.password]],
      }, {
        validator: ValidatorsEx.passwords('update', 'confirmation')
      }),
    });

    this.pc = this.formPassword.controls['confirmation'];
    this.formNotifications = this._fb.group({});
  }

  changePassword() {
    const formValue = this.formPassword.value;
    const password = {
      current: formValue.current,
      update: formValue.new.update,
      confirmation: formValue.new.confirmation,
    };

    this.changePasswordStatus.message = 'Changing password';
    this.changePasswordStatus.type = 'loading';
    this.apiService.updatePassword({ user_id: this.profile.id, password }).subscribe(
      (res) => {
        // successfully updated the password.
        this.changePasswordStatus.message = 'Successfully changed password';
        this.changePasswordStatus.type = 'success';
        // clear out fields.
        this.formPassword.reset();
      },
      (err) => {
        const error_message = (err.error && err.error.message) ? err.error.message : 'Please try again';
        this.changePasswordStatus.message = `Failed to change password. ${error_message}`;
        this.changePasswordStatus.type = 'fail';
      });
  }
}
