import { IProposalReportsState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { PROPOSAL_REPORTS_ACTION_TYPES } from './proposal-reports.action';

export function ProposalReportsReducer(state: IProposalReportsState = State.ProposalReports, action: ActionWithPayload<any>): IProposalReportsState {
  switch (action.type) {

    case PROPOSAL_REPORTS_ACTION_TYPES.LOAD_PROGRAM_AREA_REPORTS: {
      return {
        ...state,
        program_area_reports_loaded: false
      };
    }

    case PROPOSAL_REPORTS_ACTION_TYPES.LOAD_PROGRAM_AREA_REPORTS_SUCCESS: {
      return {
        ...state,
        program_area_reports_loaded: true,
        program_area_reports: action.payload
      };
    }

    case PROPOSAL_REPORTS_ACTION_TYPES.UPDATE_PROGRAM_AREA_REPORT_SUCCESS: {
      const updated_report = action.payload;
      const existing_reports = state.program_area_reports.filter(report => report.id !== updated_report.id);
      existing_reports.push(updated_report);

      return {
        ...state,
        program_area_reports: existing_reports
      };
    }

    default:
      return state;
  }
}
