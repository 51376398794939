import { IStrategiesState, Model, Query, Queries, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IStrategiesState>('Strategies');

/**
 * Predefined queries to run against root states.
 */
export interface IStrategiesQueries {
  get: Query<Array<Model.Strategy>>;
}

Queries.Strategies = {
  get: fromRoot(state => state),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Strategies: Query<IStrategiesState>;
  }

  interface Queries {
    Strategies: IStrategiesQueries;
  }
}
