<div class="card">
  <div class="card-title">
    <h3 class="h3">{{release.date}}</h3>
  </div>

  <h4 class="h4">Version: {{release.version}}</h4>

  <div class="columns columns--2">
    <div *ngIf="release.features.length">
      <h6 class="h6">Features</h6>
      <ul class="unordered-list no-margin">
        <li *ngFor="let feature of release.features">
          {{feature}}
        </li>
      </ul>
    </div>

    <div *ngIf="release.bugs.length">
      <h6 class="h6">Fixes & Improvements</h6>
      <ul class="unordered-list no-margin">
        <li *ngFor="let bug of release.bugs">
          {{bug}}
        </li>
      </ul>
    </div>
  </div>
</div>
