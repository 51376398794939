import { createDomain } from '@app-ngrx-domains';

// set up App ngrx domain
import './app.state';
import './app.action';
import './app.query';
import { AppReducer } from './app.reducer';
import { AppEffects } from './app.effect';
createDomain('App', AppReducer);

// set up Auth ngrx domain
import './auth.state';
import './auth.action';
import './auth.query';
import { AuthReducer } from './auth.reducer';
import { AuthEffects } from './auth.effect';
createDomain('Auth', AuthReducer);

// set up Fund ngrx domain
import './fund.state';
import './fund.action';
import './fund.query';
import { FundReducer } from './fund.reducer';
import { FundEffects } from './fund.effect';
createDomain('Fund', FundReducer);

// set up Layout domain
import './layout.state';
import './layout.action';
import './layout.query';
import { LayoutReducer } from './layout.reducer';
createDomain('Layout', LayoutReducer);

import './institution.state';
import './institution.action';
import './institution.query';
import { InstitutionEffects } from './institution.effect';
import { InstitutionReducer } from './institution.reducer';
createDomain('Institution', InstitutionReducer);

// set up LookupTables ngrx domain
import './lookup-tables.state';
import './lookup-tables.action';
import './lookup-tables.query';
import { LookupTablesReducer } from './lookup-tables.reducer';
import { LookupTablesEffects } from './lookup-tables.effect';
createDomain('LookupTables', LookupTablesReducer);

import './system.state';
import './system.actions';
import './system.query';
import { SystemReducer } from './system.reducer';
import { SystemEffects } from './system.effect';
createDomain('System', SystemReducer);

// set up Workflow ngrx domain
import './workflow.state';
import './workflow.action';
import './workflow.query';
import { WorkflowReducer } from './workflow.reducer';
import { WorkflowEffects } from './workflow.effect';
createDomain('Workflow', WorkflowReducer);

/*
 * Exports core effects.
 */
export const CoreEffects = [
  AppEffects,
  AuthEffects,
  FundEffects,
  InstitutionEffects,
  LookupTablesEffects,
  SystemEffects,
  WorkflowEffects,
];
