import { Actions, Model, State } from '@app-ngrx-domains';
import { NavigationExtras } from '@angular/router';
import { ActionWithPayload } from '@app-libs';
import { EnumErrorTypes } from '../models';

/**
 * App Action types
 */
const ACTION_PREFIX = 'APP_';
export const APP_ACTION_TYPES = {
  NAVIGATE_GO: `${ACTION_PREFIX}NAVIGATE_GO`,
  SET_ERROR: `${ACTION_PREFIX}SET_ERROR`,
};

/**
 * App action class.
 */
export class AppActions {

  /**
   * Sets global error
   *
   * @param {Model.ErrorHandler} error
   * @returns {Action}
   */
  setError(error: Model.ErrorHandler): ActionWithPayload<any> {
    return {
      type: APP_ACTION_TYPES.SET_ERROR,
      payload: error
    };
  }

  /**
   * Puts up the toast box with a message of given severity, with the option to force refresh.
   * @param {string} message
   * @param {string} success | info | warning | error
   * @param {boolean} refresh Forces refresh
   * @returns {Action}
   */
  showToast(message: string, level = 'info', forceRefresh = false): ActionWithPayload<any> {
    return {
      type: APP_ACTION_TYPES.SET_ERROR,
      payload: {
        type: EnumErrorTypes.user,
        show: true,
        message: message,
        messageLevel: level,
        refresh: forceRefresh
      }
    };
  }

  /**
   * Shows global alert message, with option to force refresh.
   * The 'set refresh to specify error' behavior is for legacy support. Use showError() instead.
   *
   * @param {string} message
   * @param {boolean} refresh - asks user to refresh the page.
   * @returns {Action}
   */
  showAlert(message: string, refresh = false): ActionWithPayload<any> {
    return this.showToast(message, refresh ? 'error' : 'warning', refresh);
  }

  showError(message: string): ActionWithPayload<any> {
    return this.showToast(message, 'error', true);
  }

  /**
   * Shows global Info message
   */
  showInfo(message: string): ActionWithPayload<any> {
    return this.showToast(message, 'info');
  }

  /**
   * Shows global Success message
   */
  showSuccess(message: string): ActionWithPayload<any> {
    return this.showToast(message, 'success');
  }

  /**
   * Navigates to set path.
   *
   * @param {any[]} path
   * @param {NavigationExtras} [extras]
   * @returns {Action}
   */
  go(path: any[], extras?: NavigationExtras): ActionWithPayload<any> {
    return {
      type: APP_ACTION_TYPES.NAVIGATE_GO,
      payload: {path: path, extras: extras}
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.App = new AppActions();

/**
 * Adds actions definitions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    App: AppActions;
  }
}
