<div class="card" id="qa_usersettings_password_card">
  <form [formGroup]="formPassword">

    <div class="card-header">
      <div class="card-title">
        <h3 class="h3">Change Password</h3>
      </div>
    </div>

    <input-field labelText="Current Password">
      <input type="password" inputRef id="qa_usersettings_password_current_text" formControlName="current" aria-required="true">
    </input-field>

    <input-group formGroupName="new" [control]="formPassword.controls['new']" headingText="New Password">
      <input-field labelText="Password">
        <input type="password" inputRef id="qa_usersettings_password_update_text" formControlName="update" aria-required="true">
      </input-field>

      <input-field labelText="Confirm Password">
        <input type="password" inputRef id="qa_usersettings_password_confirmation_text" formControlName="confirmation" aria-required="true">
      </input-field>
    </input-group>

    <div class="card-footer card-footer--right" [ngClass]="{ 'margin-bottom': changePasswordStatus.type }">
      <button type="submit" class="action-button action-button--primary"
        [disabled]="!formPassword.valid"
        (click)="changePassword()">
        Change Password
      </button>
    </div>

    <po-status-message [statusMessage]="changePasswordStatus"></po-status-message>
  </form>
</div>
