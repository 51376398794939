import { ICurrentProposalState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload, AttributeReducer } from '@app-libs';
import { CURRENT_PROPOSAL_ACTION_PREFIX, CURRENT_PROPOSAL_ACTION_TYPES } from './current-proposal.action';
import { STATE_TYPES, TASK_TYPES, FUND_TYPES } from '@app-consts';


/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function CurrentProposalReducer(state: ICurrentProposalState = State.CurrentProposal, action: ActionWithPayload<any>): ICurrentProposalState {

  switch (action.type) {
    ///////////////////////////////////////////////////////////////////////
    // Attribute Reducers
    ///////////////////////////////////////////////////////////////////////
    case CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_ATTRIBUTE_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_ATTRIBUTES_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.DELETE_ATTRIBUTE_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.DELETE_ATTRIBUTES_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.CREATE_ATTRIBUTE_EFFORT_AREA_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.CREATE_EFFORT_AREA_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.CREATE_MULTI_EFFORT_AREAS_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.UPDATE_EFFORT_AREA_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.DELETE_EFFORT_AREA_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.APPEND_ITEM:
    case CURRENT_PROPOSAL_ACTION_TYPES.DELETE_ITEM_SUCCESS:
    case CURRENT_PROPOSAL_ACTION_TYPES.DELETE_MULTI_EFFORT_AREAS_SUCCESS:
    {
      const actionType = action.type.replace(CURRENT_PROPOSAL_ACTION_PREFIX, '');
      return AttributeReducer<ICurrentProposalState>(actionType, state, action);
    }

    ///////////////////////////////////////////////////////////////////////
    // Local Reducers
    ///////////////////////////////////////////////////////////////////////
    case CURRENT_PROPOSAL_ACTION_TYPES.GET:
      return {
        ...State.CurrentProposal,
        is_loaded: false,
        is_loading: true,
      };

    case CURRENT_PROPOSAL_ACTION_TYPES.GET_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case CURRENT_PROPOSAL_ACTION_TYPES.SET_ISLOADED:
      return {
        ...state,
        is_loaded: true,
        is_loading: false,
      };

    case CURRENT_PROPOSAL_ACTION_TYPES.GET_FAIL:
      // If failed to get or create a proposal, reset the state store to initial conditions,
      // but set the is_loaded to true so we no longer wait on it.
      return {
        ...State.CurrentProposal,
        is_loaded: true,
        is_loading: false,
        error: action.payload
      };

    case CURRENT_PROPOSAL_ACTION_TYPES.TASK_UPDATE_SUCCESS:
      let reducedTasks = [ ...state.data.tasks, action.payload];

      reducedTasks = _.uniqBy(reducedTasks.reverse(), task => [task.task_type, task.proposal_id,
        task.institution_id, task.duration_id].join());

      return {
        ...state,
        data: {
          ...state.data,
          tasks: reducedTasks
        }
      };

    case CURRENT_PROPOSAL_ACTION_TYPES.REFRESH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };

    case CURRENT_PROPOSAL_ACTION_TYPES.UPSERT_INSTITUTION_SUCCESS:
      const new_institution = { ...action.payload.institution, ...action.payload.attributes };
      // Swap or Append new_institution into the institutions list
      let newInstitutionsList;
      if (!_.find(state.data.institutions, i => i.id === new_institution.id)) { // If the item ID is new, stick it at the end.
        newInstitutionsList = [...state.data.institutions, new_institution];
      } else { // Replace the existing institution by the same ID with new_institution
        newInstitutionsList = state.data.institutions.map(i => (i.id === new_institution.id) ? new_institution : i);
      }
      return {
        ...state,
        data: {
          ...state.data,
          lead_institution: new_institution.is_lead ? new_institution : state.data.lead_institution,
          institutions: newInstitutionsList
        }
      };

    case CURRENT_PROPOSAL_ACTION_TYPES.REMOVE_INSTITUTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          lead_institution: state.data.lead_institution ?
            action.payload.institution_id === state.data.lead_institution.id ? null : state.data.lead_institution
            : null,
          institutions: _.filter(state.data.institutions, (inst => inst.id !== action.payload.institution_id))
        }
      };

    ///////////////////////////////////////////////////////////////////////
    // no-op
    ///////////////////////////////////////////////////////////////////////
    default:
      return state;
  }
}
