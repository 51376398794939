import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class NavTreeService {

  private keypressSource$: BehaviorSubject<NavTreeEvent> = new BehaviorSubject(null);
  public keypress$: Observable<NavTreeEvent> = this.keypressSource$.asObservable();

  private keyUpSource$: BehaviorSubject<NavTreeEvent> = new BehaviorSubject(null);
  public keyUp$: Observable<NavTreeEvent> = this.keyUpSource$.asObservable();

  private clickSource$: BehaviorSubject<NavTreeEvent> = new BehaviorSubject(null);
  public click$: Observable<NavTreeEvent> = this.clickSource$.asObservable();

  constructor() { }

  public handleKeyPress(navTreeEvent: NavTreeEvent) {
    if (navTreeEvent) {
      this.keypressSource$.next(navTreeEvent);
    }
  }

  public handleKeyUp(navTreeEvent: NavTreeEvent) {
    if (navTreeEvent) {
      this.keyUpSource$.next(navTreeEvent);
    }
  }

  public handleClick(navTreeEvent: NavTreeEvent) {
    if (navTreeEvent) {
      this.clickSource$.next(navTreeEvent);
    }
  }

}

export interface NavTreeEvent {
  keyboardEvent: KeyboardEvent;
  component;
}
