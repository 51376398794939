import { Model, State } from '@app-ngrx-domains';

State.ProgramAllocations = {
  allocations: {},
  carryovers: {},
  tasks: {},
  proposals: {},

  filterStash: {
    allocations: {},
    carryovers: {},
    tasks: {},
    proposals: {}
  }
};

declare module '@app-ngrx-domains' {
  export interface IProgramAllocationsState {
    allocations: { [key: string]: Array<Model.Allocation> };
    carryovers: { [key: string]: Array<Model.AllocationCarryover> };
    tasks: { [key: string]: Array<Model.Task> };
    proposals: { [key: string]: Array<Model.ProposalItem> };
    filterStash: {
      allocations: { [key: string]: any },
      carryovers: { [key: string]: any }
      tasks: { [key: string]: any }
      proposals: { [key: string]: any }
    }
  }

  interface State {
    ProgramAllocations: IProgramAllocationsState; 
  }
}

