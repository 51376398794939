import { interval } from 'rxjs';
import { withLatestFrom, skip, map } from 'rxjs/operators';
import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, ViewChild, ElementRef} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';
import { Actions, State, Queries, Model } from '@app-ngrx-domains';
import { LoggerService } from 'ng-logger';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { EnumErrorTypes } from '@app-models';
import { ROUTER_LINKS, SYSTEM_ROLES, TIMEOUT_DELAYS } from '@app-consts';
import { CLIENT_VERSION } from './core/consts/version.const';
import { omit } from 'lodash';
import * as svg4everybody from 'svg4everybody';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { timer } from 'rxjs/internal/observable/timer';
import moment from 'moment';
import { slideOutAnimation } from './shared.generic/animations';

declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [slideOutAnimation]
})
export class AppComponent implements OnInit, AfterViewChecked {
  @ViewChild('mainContent', { static: true }) mainContent: ElementRef;

  showBusySpinner = false;
  pageClass = '';
  printMode = false;
  isProduction = false;
  isSysAdmin = false;
  environment = environment;
  disableAnimations = environment.disableAnimations; // Enable disabling animations for our test environments
  showFooterLogo = true;
  // For keeping track of idle state
  idling = false;
  timedOut = false;
  countdown = 0;
  showHelpDeskWidget = environment.showHelpDeskWidget;
  logo = environment.logos.footer;

  // For application error messages
  appErrorMessage = '';
  appErrorLevel = 'warning';
  errDetailsMessage = '';
  hasDetails = false;
  showDetails = false;
  showHelpdeskLink = true;
  refreshOnError = false;
  isWorkflowVisible = false;
  footerActions: Array<Model.HeaderAction>;
  helpdeskUser: {
    firstName: string;
    lastName: string;
    email: string;
  };

  showMaintenanceWarning: boolean = false;
  inMaintenanceWindow: boolean = false;
  pageWrapperClass: {
    'page-wrapper--pushed'?: boolean;
    'page-wrapper--pushed-double'?: boolean;
  };
  systemNotificationClass: {
    'system-notification--pushed'?: boolean,
    'system-notification--expanded'?: boolean
  };
  systemNotificationExpanded: boolean = false;


  public maintenanceStatus: Model.Maintenance;


  // For Copyright year in footer
  year = new Date().getFullYear();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private store: Store<State>,
    private idle: Idle,
    private ref: ChangeDetectorRef,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {

    this.isProduction = environment.name === 'prod';

    // set logging settings
    if (environment.logger) {
      this.logger.logLevel = environment.logger.threshold;
      this.logger.colorOutput = Boolean(environment.logger.colorOutput);
    }

    // register our material icons
    this.matIconRegistry.addSvgIcon(
      'icon-calendar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/calendar.svg')
    );

    // scroll to top of container when we get to a new page.
    this.router.events.pipe(
      withLatestFrom(this.route.queryParams))
      .subscribe(([event, params]) => {
      if (event instanceof NavigationEnd) {
        this.printMode = !!params['forPrint'];
        const skipScrollReset = this.router.getCurrentNavigation()?.extras?.state?.skipScrollReset;
        if (!/#\w+$/.test(event.url) && !skipScrollReset) {
          // give page time to build, before scrolling to top.
          setTimeout(() => {
            const scrollAnchor = document.getElementById('scroll-anchor');
            if (scrollAnchor && scrollAnchor.scrollIntoView) {
              scrollAnchor.scrollIntoView({ behavior: 'smooth' });
            } else {
              const container = document.querySelector('.content-body__container');
              container.scrollTop = 0;
            }
          }, 250);
        }
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
      if (event instanceof NavigationStart) {
        if (event.url.indexOf('#') === 1) {
          const url = event.url.replace('/#', '');
          this.router.navigateByUrl(url);
        }
      }
    });

    // try logging in with token in case user is already logged in.
    if (localStorage.getItem('auth_token')) {
      this.store.dispatch(Actions.Auth.login());
    }

    this.initializeSessionManager();
  }

  ngOnInit() {
    svg4everybody();

    if (navigator.userAgent.indexOf('Trident') !== -1) {
      this.router.navigate([ROUTER_LINKS.UNSUPPORTED_BROWSER]);
    }

    // Listen for spinner.
    this.store.select(Queries.Layout.getBusySpinner).subscribe((spinner: Model.Spinner) => {
      if (spinner.show !== this.showBusySpinner) {
        // show or hide busy spinner.
        this.showBusySpinner = spinner.show;
      }
    });

    // Listen for footer logo state.
    this.store.select(Queries.Layout.getLogoState).subscribe((footer: Model.Logo) => {
      if (footer.show !== this.showFooterLogo) {
        // show or hide busy spinner.
        this.showFooterLogo = footer.show;
      }
    });

    // Listen for page type changes.
    this.store.select(Queries.Layout.getPageType).pipe(skip(1)).subscribe((type: string) => {
      const newClass = type || '';
      if (newClass !== this.pageClass) {
        // update page class.
        this.pageClass = newClass;
      }
    });

    // Listen for app error.
    this.store.select(Queries.App.getError).pipe(skip(1)).subscribe((error: Model.ErrorHandler) => {
      if (error.type === EnumErrorTypes.user) {
        this.displayAlert(error);
      } else {
        this.displayError(error);
      }
    });

    // Listen for nav workflow visibility
    this.store.select(Queries.Workflow.isVisible).subscribe( visible => {
      this.isWorkflowVisible = visible;
    });

    // Initialize the idle timer
    // Clear any app error messages
    // Restart the timer if a user logs back in after the idle timeout
    this.store.select(Queries.Auth.getCurrentUser)
      .subscribe((user) => {
        if (!!user) {
          this.idleReset();
          this.appErrorMessage = '';
          this.initializedIdle(`${user.id}`);
          this.helpdeskUser = {
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email_address
          };
          this.isSysAdmin = user.scopes.some(role => role.role_id === SYSTEM_ROLES.SYSTEM_ADMIN.ID);
          this.updatePushDownClass();
          this.updateNotificationClass();
        } else {
          this.helpdeskUser = null;
          this.idle.stop();
        }
      });

    this.store.select(Queries.Layout.getHeader)
      .pipe(map(header => header.actions))
      .subscribe(headerActions => {
        this.footerActions = headerActions.filter(action => action.footer);
      });

    this.intitializeStatusManager();

  }

  ngAfterViewChecked(): void {
    // suppress ExpressionChangedAfterItHasBeenCheckedError warnings.
    this.ref.detectChanges();
  }

  focusOnMainContent() {
    const focusable = this.mainContent.nativeElement.querySelectorAll('button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    focusable[0].focus();
  }

  getClientVersion() {
    return CLIENT_VERSION;
  }

  initializedIdle(idleName: string) {

    // Set the idle and timeout values
    this.idle.setIdle(TIMEOUT_DELAYS.IDLE_ALERT);
    this.idle.setTimeout(TIMEOUT_DELAYS.IDLE_TIMEOUT);
    this.idle.setIdleName(idleName);

    // Set events that cancel the idle state
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.idleReset();
    });

    // When the timeout event fires, pull the latest isLoggedIn state
    // If true, logout the user
    this.idle.onTimeout.pipe(
      withLatestFrom(
        this.store.select(Queries.Auth.isLoggedIn)
      )).subscribe((res) => {
        const [_, loggedIn] = res;

        if (loggedIn) {
          this.logoutUser(true);
          this.timedOut = true;
        }
      });

    // When entering idle state, set flag to true
    this.idle.onIdleStart.subscribe(() => {
      this.idling = true;
    });

    this.idle.onTimeoutWarning.subscribe((countdown) => this.countdown = countdown);

    this.idle.watch();
  }

  intitializeMaintenance() {
    return
  }

  idleReset() {
    this.idling = false;
    this.timedOut = false;
    this.countdown = 0;
  }

  initializeSessionManager() {
    interval(TIMEOUT_DELAYS.AUTH_REFRESH).pipe(
      withLatestFrom(this.store.select(Queries.Auth.isLoggedIn)))
      .subscribe((res) => {
        const [_, loggedIn] = res;

        if (!this.idling && loggedIn) {
          this.store.dispatch(Actions.Auth.refresh());
        }
      });
  }

  intitializeStatusManager() {
    timer(0, TIMEOUT_DELAYS.MAINTENANCE_CHECK)
      .subscribe(() => {
        this.store.dispatch(Actions.System.refreshMaintenance())
      });

    this.store.select(Queries.System.getMaintenanceStatus)
      .pipe(
        withLatestFrom(this.store.select(Queries.Auth.isLoggedIn))
      )
      .subscribe(([maintenanceStatus, isLoggedIn]) => {
        this.maintenanceStatus = maintenanceStatus;
        this.updateShowMaintenanceWarning();
        this.updateMaintenanceWindow()
        this.updateNotificationClass();
        this.updatePushDownClass()


        if (this.inMaintenanceWindow) {
          this.logoutUser(false, true);
        }
      })
  }

  updateShowMaintenanceWarning() {
    if (!this.maintenanceStatus || !this.maintenanceStatus.show_maintenance) {
      this.showMaintenanceWarning = false;
      return;
    }

    const now = moment();
    const warning_start = moment(this.maintenanceStatus.warning_start);
    const warning_end = moment(this.maintenanceStatus.maintenance_start);

    this.showMaintenanceWarning = now.isBetween(warning_start, warning_end, 'second', '[]');
  }

  updateMaintenanceWindow() {
    if (!this.maintenanceStatus || !this.maintenanceStatus.show_maintenance) {
      this.inMaintenanceWindow = false;
      return;
    }

    const now = moment();
    const maintenance_start = moment(this.maintenanceStatus.maintenance_start);
    const maintenance_end = moment(this.maintenanceStatus.maintenance_end);

    this.inMaintenanceWindow = now.isBetween(maintenance_start, maintenance_end, 'second', '[]')
  }

  get showSystemNotificationExpander() {
    return this.maintenanceStatus && (this.maintenanceStatus.warning_message || "").length > 150;
  }

  get showEnvWarning() {
    return this.isProduction ? this.isSysAdmin : true;
  }

  updatePushDownClass() {
    if (this.showEnvWarning || this.showMaintenanceWarning) {
      if (this.showEnvWarning && this.showMaintenanceWarning) {
        this.pageWrapperClass = { 'page-wrapper--pushed-double': true };
      } else {
        this.pageWrapperClass = { 'page-wrapper--pushed': true };
      }
    }
  }

  updateNotificationClass() {
    this.systemNotificationClass = {
      'system-notification--pushed': this.showMaintenanceWarning && this.showEnvWarning,
      'system-notification--expanded': this.systemNotificationExpanded
    };
  }

  get pushDownPageWrapper(): boolean {
    return (this.showEnvWarning || this.showMaintenanceWarning) && !this.inMaintenanceWindow;
  }

  formatCountdown() {
    const minutes = Math.floor(this.countdown / 60),
      seconds = Math.floor(this.countdown % 60);

    let formattedCountdown = '';
    if (minutes) { formattedCountdown = `${minutes} minute(s) and `; }
    formattedCountdown += `${seconds} seconds`;

    return formattedCountdown;
  }

  /**
   * Displays global alert.
   * @param {Model.ErrorHandler} error
   */
  displayAlert(error: Model.ErrorHandler) {
    this.appErrorMessage = error.message;
    this.hasDetails = false;
    this.refreshOnError = error.refresh || false;
    this.showHelpdeskLink = this.refreshOnError;
    this.appErrorLevel = error.messageLevel || 'warning';

    this.errDetailsMessage = '';
    this.showDetails = false;
  }

  /**
   * Take an ErrorHandler object and set the appropriate instance variables
   * to display the alert box appropriately.
   */
  displayError(error: Model.ErrorHandler) {
    const response: any = error.raw; // raw as response object
    let status = -1; // http status code if available, which determines the body of alert message

    this.hasDetails = false;
    this.errDetailsMessage = '';
    this.refreshOnError = false;
    this.showDetails = false;
    this.showHelpdeskLink = true;
    this.appErrorLevel = 'warning';

    // Capture http response details for official HTTP errors
    if (response instanceof HttpErrorResponse) {
      status = response.status;
      this.errDetailsMessage = response.error ? JSON.stringify(omit(response.error, ['stack', 'url'])) : response.message;
    } else {
      this.errDetailsMessage = (response && response.message) ? response.message : JSON.stringify(response);
    }

    // log error first
    this.logger.error(`[${error.location || ''}] ${this.errDetailsMessage}`);
    if (error.details) { // dump out the details if supplied.
      this.logger.error(JSON.stringify(error.details));
    }

    // turn off busy spinner, in case it's running...
    this.store.dispatch(Actions.Layout.showBusySpinner(false));

    // handle the error based on status code if available.
    switch (status) {
      case 0: { // service is down... redirect the user to login page
        if (error.show) {
          this.appErrorMessage = 'Unable to connect to the NOVA server. Please try again.';
          this.hasDetails = false;
        }
        this.logoutUser(true);
        break;
      }

      case 401: { // unauthorized...
        if (error.show) {
          this.appErrorMessage = 'The application attempted an unauthorized access to the NOVA server.';
          this.hasDetails = true;
        }
        this.logoutUser();
        break;
      }

      case 403: { // forbidden...
        if (error.show) {
          this.appErrorMessage = response.error.message;
          this.appErrorLevel = 'error';
          this.hasDetails = false;
        }

        // reroute to bookmarks
        this.router.navigate(['/']);
        break;
      }

      default: { // app error or service 500 error
        if (error.show) {
          this.appErrorMessage = 'The NOVA server encountered an error.';
          this.hasDetails = true;
        }
        // TODO: if set, log the error to server.
        if (error.logToServer) {
        }
      }
    }
  }

  clearAppError(helpClicked?: boolean) {
    this.appErrorMessage = '';

    if (this.refreshOnError && !helpClicked) {
      // need to refresh current url.
      const route = this.router.url.split('?')[0];
      const queryParams = this.route.snapshot.queryParams;

      // because reload on same page doesn't work... we'll cheat by going to the help page, then redirecting to target.
      this.router.navigateByUrl('/error', { skipLocationChange: true }).then(() => {
        this.router.navigate([route], { queryParams });
      });
    }
  }

  logoutUser(hardRefresh: boolean = false, sendToMaintenance: boolean = false) {
    const currentQP = this.route.snapshot.queryParams;
    const queryParams = {
      returnUrl: this.router.url,
      hardRefresh: hardRefresh && environment.name !== 'dev',
      ...currentQP
    };

    const routerLink = sendToMaintenance
      ? ROUTER_LINKS.MAINTENANCE
      : ROUTER_LINKS.LOGIN;


    this.store.dispatch(Actions.Auth.logout([routerLink], { queryParams: queryParams }));
  }

}
