import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { IUserRoleScope } from '@app-models';

const ACTION_PREFIX = 'INSTITUTION_';
export const INSTITUTION_ACTION_TYPES = {
  GET: `${ACTION_PREFIX}GET`,
  LOAD: `${ACTION_PREFIX}LOAD`,
  UPDATE: `${ACTION_PREFIX}UPDATE`,
  ADD_ROLE_SCOPES: `${ACTION_PREFIX}ADD_ROLE_SCOPES`,
  ADD_ROLE_SCOPES_SUCCESS: `${ACTION_PREFIX}ADD_ROLE_SCOPES_SUCCESS`,
  REMOVE_ROLE_SCOPE: `${ACTION_PREFIX}REMOVE_ROLE_SCOPE`,
  REMOVE_ROLE_SCOPE_SUCCESS: `${ACTION_PREFIX}REMOVE_ROLE_SCOPE_SUCCESS`,
  SET_LOGO_URL: `${ACTION_PREFIX}SET_LOGO_URL`
};

export class InstitutionActions {
  get(institution_id: number): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.GET,
      payload: { institution_id }
    }
  }

  load(institution: Model.Institution): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.LOAD,
      payload: { institution }
    }
  }

  update(updates: Model.Institution): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.UPDATE,
      payload: { updates }
    }
  }

  addRoleScopes(role_scopes: Array<IUserRoleScope>, notify_on_assignment?: boolean): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES,
      payload: { role_scopes, notify_on_assignment }
    };
  }

  addRoleScopesSuccess(role_scopes: Array<IUserRoleScope>): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES_SUCCESS,
      payload: { role_scopes }
    }
  }

  removeRoleScope(role_scope: IUserRoleScope): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.REMOVE_ROLE_SCOPE,
      payload: { role_scope }
    };
  }

  removeRoleScopeSuccess(role_scope: IUserRoleScope): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.REMOVE_ROLE_SCOPE_SUCCESS,
      payload: { role_scope }
    };
  }

  setLogoUrl(logo_url: string): ActionWithPayload<any> {
    return {
      type: INSTITUTION_ACTION_TYPES.SET_LOGO_URL,
      payload: { logo_url }
    };
  }
}

Actions.Institution = new InstitutionActions();

declare module '@app-ngrx-domains' {
  interface Actions {
    Institution: InstitutionActions
  }
}
