import { IProgramAllocationsState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { PROGRAM_ALLOCATIONS_ACTION_TYPES } from './program-allocations.action';

export function ProgramAllocationsReducer(state: IProgramAllocationsState = State.ProgramAllocations, action: ActionWithPayload<any>): IProgramAllocationsState {
  switch(action.type) {

    case PROGRAM_ALLOCATIONS_ACTION_TYPES.CLEAR: {
      return State.ProgramAllocations;
    }

    case PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_SUCCESS: {
      const { resultStore, key, results } = action.payload;
      return {
        ...state,
        [resultStore]: {
          ...state[resultStore],
          [key]: results
        }
      };
    }

    case PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_ALLOCATIONS: {
      return reduceFilter(state, 'allocations', action.payload);
    }

    case PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_CARRYOVERS: {
      return reduceFilter(state, 'carryovers', action.payload);
    }

    case PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_TASKS: {
      return reduceFilter(state, 'tasks', action.payload);
    }

    case PROGRAM_ALLOCATIONS_ACTION_TYPES.FETCH_PROPOSALS: {
      return reduceFilter(state, 'proposals', action.payload);
    }

    default:
      return state;
  }
}

function reduceFilter(state: IProgramAllocationsState, filterType: string, { key, filter }) {
  return {
    ...state,
    filterStash: {
      ...state.filterStash,
      [filterType]: {
        ...state.filterStash[filterType],
        [key]: filter
      }
    }
  }
}
