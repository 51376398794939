import { Model } from '@app-ngrx-domains';
import { ProposalBase } from './proposal-base';
import { INSTITUTION_TYPES } from '@app-consts';
import { uniq } from 'lodash';

export const PERIOD_OPTIONS = [
  { value: 1, label: 'P1' },
  { value: 2, label: 'P2' },
  { value: 3, label: 'P3' },
  { value: 4, label: 'ReCal' }
];

export interface IRSIProposal extends Model.ProposalBase {
  rsi_reports: Array<Model.EARSIReport>
}

export class RSIProposal extends ProposalBase {

  /**
   * Returns reporting institutions based on the lead type.
   * @param p
   */
  static getReportingInstitutions(p: IRSIProposal, yearDurationId?: number): Array<Model.Institution> {
    let reportingInstitutions = [];
    if (p.lead_institution.type === INSTITUTION_TYPES.CCD) {
      if (!yearDurationId) {
        // get all institutions regardless of the year.
        const collegeIds: Array<number> = uniq(p.institutions.filter(inst => inst.type !== INSTITUTION_TYPES.CCD).map(i => i.id));
        collegeIds.forEach(instId => {
          reportingInstitutions.push(p.institutions.find(inst => inst.id === instId));
        })
      } else {
        reportingInstitutions = p.institutions.filter(inst => (inst.type !== INSTITUTION_TYPES.CCD && inst.duration_id === yearDurationId));
      }
    } else {
      reportingInstitutions = [p.lead_institution];
    }

    return reportingInstitutions.sort((a, b) => a.name > b.name ? 1 : -1);
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type RSIProposal = IRSIProposal;
 }
}
