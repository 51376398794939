import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Model, Actions } from '@app-ngrx-domains';
import { Event } from '../../models';
import { orderBy } from 'lodash';
import * as moment from 'moment';

interface ISelectMessageList extends Model.UserMessage {
  selectName: string,
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
})
export class NotificationsComponent implements OnInit, OnDestroy {
  form: FormGroup;

  selectMsgList: Array<ISelectMessageList>;

  @ViewChild('selectAllCheckbox') selectAllCheckbox;
  private subscriptions: Subscription[] = [];

  constructor(
    private _fb: FormBuilder,
    private store: Store<State>,
  ) {
    // get latest messages.
    this.store.dispatch(Actions.Auth.refreshUserMessages());

    // update header.
    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: 'Notifications'
    }));

    this.form = this._fb.group({
      select_all: [false],
    });
  }

  ngOnInit() {
    const subscription = this.store.select(Queries.Auth.getCurrentUserMessages)
      .subscribe((messages: Array<Model.UserMessage>) => {
        // build the list & preserve the selection state if possible.
        const selectList = [];
        const selectCtrls = [];
        if (messages.length > 0) {
          messages.forEach(msg => {
            const selectName = `msg_${msg.id}`;
            selectList.push({
              selectName: selectName,
              ...msg,
            });
            selectCtrls[selectName] = false;
          });
        }

        // update new lists.
        this.selectMsgList = orderBy(selectList, ['created_at'], ['desc']);
        // update the selection form.
        this.form = this._fb.group({
          select_all: [false],
          ...selectCtrls,
        });
      });
    this.subscriptions.push(subscription);
  }

  public enableDeleteAllButton() {
    return this.selectMsgList.some(message => this.isChecked(message.selectName));
  }

  ngOnDestroy() {
    if (this.selectMsgList && this.selectMsgList.length > 0) {
      // mark all the unread msgs as read.
      const ids = this.selectMsgList.filter(msg => !msg.read).map(msg => msg.id);
      if (ids.length > 0) {
        this.store.dispatch(Actions.Auth.markUserMessages({
          all: false,
          markType: 'read',
          ids: ids,
        }));
      }
    }

    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  public checkAll() {
    const checked = this.form.get('select_all').value;
    this.selectMsgList.forEach(msg => {
      this.form.get(msg.selectName).setValue(checked);
    });

    this.setSelectAllCheckboxState();
  }

  public checkMessage(checked: boolean, msg: ISelectMessageList) {
    this.setSelectAllCheckboxState();
  }

  private isChecked(selectName: string) {
    return this.form && this.form.get(selectName) ? this.form.get(selectName).value : false;
  }

  private setSelectAllCheckboxState() {
    const allSelected = this.selectMsgList.every(message => this.isChecked(message.selectName));
    const someSelected = this.selectMsgList.some(message => this.isChecked(message.selectName));

    this.selectAllCheckbox.nativeElement.indeterminate = someSelected && !allSelected;
    this.selectAllCheckbox.nativeElement.checked = allSelected;
  }

  public isMessageEmail(msg: ISelectMessageList): boolean {
    return !!msg.email_response;
  }

  public formatContentTitle(msg: ISelectMessageList): string {
    if (msg.message) {
      const msg_subject = msg.message.subject;
      if (msg_subject) {
        return msg_subject;
      } else if (msg.message.event_id && msg.message.event) {
        return Event.formatName(msg.message.event);
      }
    }
    return '[No Title]';
  }

  public formatContentBody(msg: ISelectMessageList): string {
    if (msg.message) {
      return msg.message.body || '';
    }
    return '';
  }

  public formatDate(msg: ISelectMessageList): string {
    if (msg) {
      return moment(msg.created_at).format('MMM Do, YYYY');
    } else {
      return '';
    }
  }

  public dismissMessage(msg: ISelectMessageList) {
    // dismiss selected message.
    this.store.dispatch(Actions.Auth.markUserMessages({
      all: false,
      markType: 'dismissed',
      ids: [msg.id],
    }));
  }

  public dismissCheckedMessages() {
    // dismiss all selected messages.
    const selectedIds = this.selectMsgList.filter(msg => this.isChecked(msg.selectName)).map(msg => msg.id);
    if (selectedIds.length > 0) {
      this.store.dispatch(Actions.Auth.markUserMessages({
        all: false,
        markType: 'dismissed',
        ids: selectedIds,
      }));
    }
  }
}
