import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Actions } from '@app-ngrx-domains';

/* Resolver to load Program Area report data for a proposal (consortium) */
@Injectable()
export class ProgramAreasResolver implements Resolve<boolean> {

  constructor(
    private store: Store<State>,
    private router: Router,
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {

      const proposal_id = Number(route.params['proposalId']);
      this.store.dispatch(Actions.ProposalReports.loadProgramAreaReports(proposal_id));
      subscriber.next(true);
      subscriber.complete();
    });
  }
}
