<ng-container *ngIf="canEdit">
  <div [ngClass]="{'radio-check__check-label-container': !labelHidden}">
    <ng-content></ng-content>
    <label *ngIf="labelText && !labelHidden" [for]="input.element.id" [innerHTML]="labelText" [class.required]="required"></label>
    <span *ngIf="labelFootnote" [attr.aria-label]="labelFootnote.text">{{labelFootnote.symbol}}</span>
  </div>
  <show-errors *ngIf="isCheckbox" [control]="input.control" class="error-text" [showUntouched]="errorsOnUntouched"></show-errors>
</ng-container>


<div *ngIf="showReadOnlyText()" [ngSwitch]="!!getReadOnlySymbol()" class="read-only__container">
  <ng-container *ngSwitchCase="true"
    [ngTemplateOutlet]="getReadOnlySymbol()"
    [ngTemplateOutletContext]="{ text: getReadOnlyText() }">
  </ng-container>

  <p *ngSwitchCase="false" [innerHTML]="getReadOnlyText() | safeHtml" class="no-margin"></p>
</div>

<ng-template #checked let-text="text">
  <p [attr.aria-label]="'Checked checkbox ' + text" class="no-margin">
    <span aria-hidden="true" class="margin-right-xs">&#10003;</span>
    <span aria-hidden="true" [innerHTML]="text | safeHtml"></span>
  </p>
</ng-template>

<ng-template #unchecked let-text="text">
  <p [attr.aria-label]="'Unchecked checkbox ' + text" class="no-margin">
    <span aria-hidden="true" class="margin-right-xs">&#10005;</span>
    <span aria-hidden="true" [innerHTML]="text | safeHtml"></span>
  </p>
</ng-template>

<ng-template #bullet let-text="text">
  <p [attr.aria-label]="'Checked checkbox ' + text" class="no-margin">
    <span aria-hidden="true" class="margin-right-xs">&#8226;</span>
    <span aria-hidden="true" [innerHTML]="text | safeHtml"></span>
  </p>
</ng-template>
