<div class="card">
  <inline-alert level="error">
    <p id="qa_unsupported-text">
      Internet Explorer is not a NOVA supported browser.
      <a class="text-link" href="https://www.google.com/chrome">Chrome</a>,
      <a class="text-link" href="https://www.mozilla.org/firefox">Firefox</a>,
      <a class="text-link" href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>, or
      <a class="text-link" href="https://support.apple.com/downloads/safari">Safari</a>
      is required to use NOVA. Download and install one of these four and change your default browser to support email
      links from NOVA. If Internet Explorer remains your default browser, you will need to copy and paste emailed links
      to a supported browser. Internet Explorer is no longer supported by Microsoft.
    </p>
  </inline-alert>
  <div class="card__footer align-center">
    <ul class="login-links">
      <li>
        <a class="text-link" routerLink="/forgotPassword">
          Forgot your password?
        </a>
      </li>
      <li>
        <a class="text-link" routerLink="/help">
          Help
        </a>
      </li>
      <li>
        <a class="text-link" routerLink="/help" [queryParams]="{requestAccess: true}">
          Request access to NOVA
        </a>
      </li>
    </ul>
  </div>
</div>
