import { ILookupTablesState, Query, Queries, combineRootFactory, Model } from '@app-ngrx-domains';
import { sortBy as _sortBy } from 'lodash';
import { FUND_TYPES, INSTITUTION_TYPES, CHANCELLORS_OFFICE, PROGRAM_KEYS, METRIC_GROUPS } from '../consts';
import { Duration, IDocumentType, Institution } from '../models';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<ILookupTablesState>('LookupTables');

/**
 * Predefined queries to run against root states.
 */
export interface ILookupTablesQueries {
  getInstitutions: Query<Array<Model.SelectOption>>;
  getInstitutionsRaw: Query<Array<Model.Institution>>;
  getCategories: Query<Array<Model.Category>>;
  getCategoriesSEP: Query<Array<Model.Category>>;
  getCategoriesSEPOptions: Query<Array<Model.SelectOption>>;
  getColleges: Query<Array<any>>;
  getCollegesRaw: Query<Array<Model.Institution>>;
  getDistricts: Query<Array<any>>;
  getCollegesAndDistricts: Query<Array<any>>;
  getRegions: Query<Array<Model.SelectOption>>;
  getRegionalInstitutions: Query<Array<any>>;
  getConsortia: Query<Array<any>>;
  getCounties: Query<Array<any>>;
  getCountiesCities: Query<Array<any>>;
  getCountiesMenuOptions: Query<Array<Model.SelectOption>>;
  getChancellorsOfficeInstitution: Query<Model.Institution>;
  getStateInstitution: Query<Model.Institution>;
  getDocumentTypes: Query<Array<IDocumentType>>;
  getDocumentTypesMap: Query<Map<number, IDocumentType>>;
  getDurations: Query<Array<Model.Duration>>;
  getFunds: Query<Array<any>>;
  getFundsObjectCodes: Query<Array<any>>;
  getImpactedGroups: Query<Array<any>>;
  getImpactedGroupsSEP: Query<Array<any>>;
  getImpactedGroupsSWP: Query<Array<any>>;
  getJobCategories: Query<Array<Model.SelectOption>>;
  getMetricsDefinitions: Query<Array<any>>;
  getMetricsDefinitionsByGroup: Query<(group: string) => Array<any>>;
  getMetricsDefinitionsAEBG: Query<Array<any>>;
  getMetricsDefinitionsSWP: Query<Array<any>>;
  getMetricsDefinitionsGP: Query<Array<any>>;
  getMetricsDefinitionsSEP: Query<Array<any>>;
  getObjectCodes: Query<Array<Model.ObjectCode>>;
  getObjectCodesMS: Query<Array<Model.SelectOption>>; // used for multiselect
  getLegislation: Query<Array<Model.Legislation>>;
  getPerkinsCodes: Query<Array<Model.TopCode>>;
  getProgramAreas: Query<Array<any>>;
  getProgramDivisions: Query<Array<{ id: number, name: string }>>;
  getRoles: Query<Array<Model.RolePermissions>>;
  getSectors: Query<Array<any>>;
  getSectorsCCCCO: Query<Array<Model.SelectOption>>;
  getMonitoredSectors: Query<Array<Model.SelectOption>>;
  getSocCodes: Query<Array<any>>;
  getSubregions: Query<Array<any>>;
  getSubregionsMenuOptions: Query<Array<Model.SelectOption>>;
  getSuccessGoals: Query<Array<Model.SuccessGoal>>;
  getTaskforceRecommendations: Query<Array<any>>;
  getTop6Codes: Query<Array<any>>;
  getYears: Query<Model.LookupYears>;
  getYearOptions: Query<Array<Model.SelectOption>>;

  getLoadStatus: any; // Add flags here, contactsIsLoaded contactsError, institutionsIsLoaded, institutionsError, etc.
}

Queries.LookupTables = {
  getInstitutions: fromRoot(state =>
    state.institutions.filter((inst: any) => ![INSTITUTION_TYPES.CONSORTIUM, INSTITUTION_TYPES.EMPLOYER].includes(inst.type))
      .map( inst => ({ value: inst.id, label: inst.name }) )
  ),
  getInstitutionsRaw: fromRoot(state => state.institutions),
  getCategories: fromRoot(state => state.categories),
  getCategoriesSEP: fromRoot(state => {
    const categories =  state.categories
      .filter(category => category.fund_id === FUND_TYPES.SEP && !category.deleted);
    return _sortBy(categories, ['name']);
  }),
  getCategoriesSEPOptions: fromRoot(state => {
    const categories = state.categories
      .filter(category => category.fund_id === FUND_TYPES.SEP && !category.deleted)
      .map( college => ({ value: college.id, label: college.name }) );
    return _sortBy(categories, ['label']);
  }),
  getColleges: fromRoot(state =>
    state.institutions.filter((inst: any) => inst.type === INSTITUTION_TYPES.COLLEGE)
      .map( college => ({ value: college.id, label: college.name }) )
  ),
  getCollegesRaw: fromRoot(state =>
    state.institutions.filter((inst: any) => inst.type === INSTITUTION_TYPES.COLLEGE)
  ),
  getDistricts: fromRoot(state => {
    const districts = state.institutions.filter((inst: any) => inst.type === INSTITUTION_TYPES.CCD)
      .map(district => ({ value: district.id, label: district.name }));
    return _sortBy(districts, ['label']);
  }),
  getCollegesAndDistricts: fromRoot(state => {
    const insts = state.institutions.filter((inst: any) => (inst.type === INSTITUTION_TYPES.COLLEGE || inst.type === INSTITUTION_TYPES.CCD))
      .map(college => ({ value: college.id, label: college.name }));
    return _sortBy(insts, ['label']);
  }),
  getRegions: fromRoot(state => {
    const regions = state.institutions.filter((inst: any) => inst.type === INSTITUTION_TYPES.RC)
      .map( region => ({ value: region.id, label: region.name }));
    return _sortBy(regions, ['label']);
  }),
  getRegionalInstitutions: fromRoot(state => {
    const insts = state.institutions.filter((inst: any) => [INSTITUTION_TYPES.COLLEGE, INSTITUTION_TYPES.CCD, INSTITUTION_TYPES.RC].includes(inst.type))
      .map(college => ({ value: college.id, label: college.name }));
    return _sortBy(insts, ['label']);
  }),
  getChancellorsOfficeInstitution: fromRoot(state => {
    return state.institutions.find(inst => inst.type === INSTITUTION_TYPES.EMPLOYER && inst.id === CHANCELLORS_OFFICE.ID)
  }),
  getStateInstitution: fromRoot(state => {
    return state.institutions.find((inst: any) => inst.type === INSTITUTION_TYPES.STATE);
  }),
  getConsortia: fromRoot(state => {
    const consortia = state.institutions.filter((inst: any) => inst.type === INSTITUTION_TYPES.CONSORTIUM)
      .map(consortium => ({ value: consortium.id, label: Institution.getLongNameWithId(consortium) }));
    return _sortBy(consortia, ['label']);
  }),
  getImpactedGroups: fromRoot(state => state.impacted_groups),
  getImpactedGroupsSEP: fromRoot(state => state.impacted_groups.filter(group => group.group === METRIC_GROUPS.SEP)),
  getImpactedGroupsSWP: fromRoot(state => state.impacted_groups.filter(group => group.group === METRIC_GROUPS.SWP)),
  getCounties: fromRoot(state => state.counties),
  getCountiesCities: fromRoot(state => state.countiesCities),
  getCountiesMenuOptions: fromRoot(state => {
    return state.counties.map(i => ({ value: i.id, label: i.name }));
  }),
  getDocumentTypes: fromRoot(state => state.document_types),
  getDocumentTypesMap: fromRoot(state => new Map(<Iterable<[number, IDocumentType]>>state.document_types.map(type => <[number, IDocumentType]>[type.id, type]))),
  getDurations: fromRoot(state => state.durations),
  getFunds: fromRoot(state => state.funds),
  getFundsObjectCodes: fromRoot(state => state.funds_object_codes),
  getJobCategories: fromRoot(state => state.job_categories),
  getMetricsDefinitions: fromRoot(state => state.metric_definitions),
  getMetricsDefinitionsByGroup: fromRoot(state => {
    return (group: string) => (state.metric_definitions || []).filter(metric_def => metric_def.group === group);
  }),
  getMetricsDefinitionsSWP: fromRoot(state =>
    state.metric_definitions.filter((metric: any) => metric.group === METRIC_GROUPS.SWP)
  ),
  getMetricsDefinitionsAEBG: fromRoot(state =>
    state.metric_definitions.filter((metric: any) => metric.group === METRIC_GROUPS.CAEP)
  ),
  getMetricsDefinitionsGP: fromRoot(state =>
    state.metric_definitions.filter((metric: any) => metric.group === METRIC_GROUPS.GP)
  ),
  getMetricsDefinitionsSEP: fromRoot(state => state.metric_definitions.filter(metric_def => metric_def.group === METRIC_GROUPS.SEP)),
  getObjectCodes: fromRoot(state => state.object_codes),
  getObjectCodesMS: fromRoot(state => state.object_codes.map( code => ({value: code.id, label: code.name })) ),
  getLegislation: fromRoot(state => state.legislation),
  getPerkinsCodes: fromRoot(state => state.perkins_codes),
  getProgramAreas: fromRoot(state => state.program_areas),
  getProgramDivisions: fromRoot(state => state.program_divisions),
  getRoles: fromRoot(state => state.roles),
  getSectors: fromRoot(state => state.sectors),
  // CCCCO Sectors
  getSectorsCCCCO: fromRoot(state => {
    let filtered = state.sectors.filter(s => s.type === 'CCCCO');
    filtered = _sortBy(filtered, ['display_order']);
    return filtered.map(i => ({ value: i.id, label: i.name }));
  }),
  getMonitoredSectors: fromRoot(state => {
    const sectors = state.sectors
      .filter(s => s.type === 'CCCCO' && !!s.monitored)
      .map(i => ({ value: i.id, label: i.name, extras: i.display_id }));
    return _sortBy(sectors, ['label']);
  }),
  getSocCodes: fromRoot(state => state.soc_codes),
  getSubregions: fromRoot(state => state.subregions),
  getSubregionsMenuOptions: fromRoot(state => {
    return state.subregions.map(i => ({ value: i.id, label: i.name }));
  }),
  getSuccessGoals: fromRoot(state => [...state.success_goals].sort((a, b) => a.sequence < b.sequence ? -1 : 1)),
  getTaskforceRecommendations: fromRoot(state => state.recommendations),
  getTop6Codes: fromRoot(state => state.top6_codes),
  getYears: fromRoot(state => {
    const years: Model.LookupYears = {};
    state.durations.forEach(duration => {
      if (duration.type === 'annual') {
        years[duration.id] = new Duration(duration).iObject<Model.Duration>();
      }
    });
    return years;
  }),
  getYearOptions: fromRoot(state => {
    return state.durations.filter(duration => duration.type === 'annual')
      .map(duration => ({ value: duration.id, label: duration.name }));
  }),
  getLoadStatus: fromRoot(state => state.loadStatus)
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    LookupTables: Query<ILookupTablesState>;
  }

  interface Queries {
    LookupTables: ILookupTablesQueries;
  }
}
