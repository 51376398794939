import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Queries, Actions, Model } from '@app-ngrx-domains';

@Component({
  selector: 'app-funds',
  templateUrl: './funds-page.component.html',
})
export class FundsPageComponent implements OnInit {

  constructor(
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    const headerInfo: Model.HeaderLayout = {
      ...State.Layout.header,
      title: 'Funds',
    }
    this.store.dispatch(Actions.Layout.setHeader(headerInfo));
  }

}
