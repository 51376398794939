<div class="full-page-modal" [class.full-page-modal--small]="small" poFocusTrap>
  <div class="full-page-modal__container">

    <header class="full-page-modal__header">
      <h2 class="full-page-modal__heading" id="qa_full_page_modal_heading">{{heading}}</h2>
      <button
        id="qa_modal_dismiss_button"
        title="Close"
        type="button"
        class="action-button action-button--secondary icon-only"
        (click)="dismissModal('dismiss')"
        [disabled]="buttonCancelDisabled">
        <icon iconId="close"></icon>
      </button>
    </header>

    <ng-content select=".segmented-control"></ng-content>

    <div class="full-page-modal__body" id="qa_full_page_modal_body">
      <ng-content></ng-content>
    </div>

    <footer class="full-page-modal__footer">
      <button
        *ngIf="!!buttonCancel"
        id="qa_modal_cancel_button"
        type="button"
        class="action-button action-button--secondary"
        (click)="cancelModal()"
        [textContent]="buttonCancel"
        [disabled]="buttonCancelDisabled">
      </button>
      <button
        *ngIf="!!buttonAction"
        id="qa_modal_action_button"
        type="submit"
        class="action-button action-button--primary"
        (click)="doAction('action')"
        [textContent]="buttonAction"
        [disabled]="buttonActionDisabled">
        <div class="loader-spinner-inline"></div>
      </button>
    </footer>

  </div>
</div>
