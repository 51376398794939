/** Form Validator for Budget tab
 */

import { AEBG_BUDGET_V1_MAX_YEAR, FUND_TYPES } from '@app-consts';
import { Validate } from '@app/core/utilities';

/** Validate that all form fields meet requirements. If so, return TRUE, else return FALSE.
 * @param b IBudgetState object
 * @returns {boolean}
 */
export function validateStepData(b: any): boolean {

  const validate = () => {
    const isAEBG = (b.project_type === FUND_TYPES.AEBG);

    if (!(b.items && b.items.length > 0)) { return false; }

    // Iterate through all budget items and validate fields
    let totalBudget = 0;
    for (let i = 0; i < b.items.length; i++) {
      const item = b.items[i];
      // make sure all required fields are filled in
      if ([item.title, item.amount, item.institution_id, item.object_code_id].some(i => Validate.isUntouched(i))) {
        return false;
      }

      if (!isAEBG) {
        // description can be blank in aebg.
        if (!(item.description && item.description.length > 0)) { return false; }
      }

      // allow 0 as a valid input on AEBG
      if (!(item.amount > 0 || (isAEBG && item.amount === 0))) {
        return false;
      }

      // make sure that budget quarters are filled in.
      if (isAEBG && b.duration_id <= AEBG_BUDGET_V1_MAX_YEAR) {
        if ([item.quarter_1, item.quarter_2, item.quarter_3, item.quarter_4].some(q => Validate.isUntouched(q))) {
          return false;
        }
        if (!(item.quarter_1 >= 0 && item.quarter_1 <= item.quarter_2)) {
          return false;
        }
        if (!(item.quarter_2 >= item.quarter_1 && item.quarter_2 <= item.quarter_3)) {
          return false;
        }
        if (!(item.quarter_3 >= item.quarter_2 && item.quarter_3 <= item.quarter_4)) {
          return false;
        }
        if (!(item.quarter_4 >= item.quarter_3 && item.quarter_4 <= 100)) {
          return false;
        }
      }

      totalBudget += item.amount;
    }

    if (isAEBG) {
      if (b.totalAvailable !== totalBudget) {
        // total budget must match total available fund.
        return false;
      }

      // make sure all quarters are filled in
      if (b.duration_id > AEBG_BUDGET_V1_MAX_YEAR) {
        const forecast = b.forecast;
        if (!forecast || [forecast.quarter_1, forecast.quarter_2, forecast.quarter_3, forecast.quarter_4].some(q => Validate.isUntouched(q))) {
          return false;
        }
      }
    }

    // At this point, nothing has returned false. Every field is valid!
    return true;
  };

  const validateIPlan = () => {
    if (!(b.items && b.items.length > 0)) { return false; }

    // Iterate through all budget items and validate fields
    for (let i = 0; i < b.items.length; i++) {
      const item = b.items[i];
      if (!(item.fund_id)) { return false; }
      if (!(item.object_code_id && item.object_code_id > 0)) { return false; }
      if (!(item.amount && item.amount > 0)) { return false; }
    }

    return true;
  };

  switch (b.project_type) {
    case FUND_TYPES.AEBG:
    case FUND_TYPES.GP:
      return validate();

    case FUND_TYPES.IPLAN:
      return validateIPlan();

    default:
      return false;
  }
}
