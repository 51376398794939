import { IAllocationsState, State } from '@app-ngrx-domains';
import { isEmpty as _isEmpty} from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { ALLOCATIONS_ACTION_TYPES } from './allocations.action';

/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function AllocationsReducer(state: IAllocationsState = State.Allocations, action: ActionWithPayload<any>): IAllocationsState {
  switch (action.type) {
    case ALLOCATIONS_ACTION_TYPES.LOAD: {
      return {
        ...action.payload,
      };
    }

    case ALLOCATIONS_ACTION_TYPES.UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case ALLOCATIONS_ACTION_TYPES.UPDATE_FIELD_SUCCESS: {
      let retState = state;
      const allocations = action.payload;
      allocations.forEach(update => {
        // update the field for each update in the payload
        const member_allocations = retState.members[update.to_institution_id][update.duration_id].filter(allocation => allocation.id !== update.id);
        member_allocations.push(update);
        retState = {
          ...retState,
          members: {
            ...retState.members,
            [update.to_institution_id]: {
              ...retState.members[update.to_institution_id],
              [update.duration_id]: member_allocations
            }
          }
        };
      });

      return retState;
    }

    case ALLOCATIONS_ACTION_TYPES.ADD_MEMBER: {
      const allocation = action.payload;
      const institution_id = allocation.to_institution_id;
      const duration_id = allocation.duration_id;
      if (state.members[institution_id]) {
        // member is gettng added again.
        return {
          ...state,
          members: {
            ...state.members,
            [institution_id]: {
              ...state.members[institution_id],
              [duration_id]: [allocation],
            },
          },
        };
      } else {
        // new member is getting added.
        return {
          ...state,
          members: {
            ...state.members,
            [institution_id]: {
              [duration_id]: [allocation],
            },
          },
        };
      }
    }

    case ALLOCATIONS_ACTION_TYPES.REMOVE_MEMBER: {
      const institution_id = action.payload.institution_id;
      const duration_id = action.payload.duration_id;
      if (state.members[institution_id] && state.members[institution_id][duration_id]) {
        const member = { ...state.members[institution_id] };
        delete member[duration_id]; // delete the year that's getting removed.
        if (_isEmpty(member)) {
          const members = { ...state.members };
          delete members[institution_id]; // remove the institution as last year got removed.
          return {
            ...state,
            members: members,
          };
        } else {
          return {
            ...state,
            members: {
              ...state.members,
              [institution_id]: member, // keep the institution, minus the year.
            },
          };
        }
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
