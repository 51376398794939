import { Model, State } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.LookupTables = {
  categories: [],
  counties: [],
  countiesCities: [],
  document_types: [],
  funds: [],
  funds_object_codes: [],
  impacted_groups: [],
  institutions: [],
  job_categories: [],
  metric_definitions: [],
  object_codes: [],
  legislation: [],
  perkins_codes: [],
  program_areas: [],
  program_divisions: [],
  roles: [],
  sectors: [],
  soc_codes: [],
  states: [],
  subregions: [],
  success_goals: [],
  recommendations: [],
  top6_codes: [],
  durations: [],
  loadStatus: {},
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface ILookupTablesState {
    categories?: Array<any>;
    counties?: Array<any>;
    countiesCities?: Array<{ id: number, type: string, name: string, parent?: { id: number, type: string, name: string }}>;
    document_types?: Array<any>;
    durations: Array<Model.Duration>;
    funds?: Array<any>;
    funds_object_codes?: Array<any>;
    employers?: Array<any>;
    impacted_groups?: Array<Model.ImpactedGroup>;
    institutions?: Array<any>;
    job_categories?: Array<Model.SelectOption>;
    metric_definitions?: Array<Model.MetricDefinition>;
    object_codes?: Array<Model.ObjectCode>;
    legislation?: Array<Model.Legislation>;
    perkins_codes?: Array<Model.TopCode>;
    program_areas?: Array<any>;
    program_divisions?: Array<{ id: number, name: string }>;
    roles?: Array<Model.RolePermissions>;
    sectors?: Array<any>;
    soc_codes?: Array<Model.TopCode>;
    states?: Array<any>;
    subregions?: Array<any>;
    success_goals: Array<Model.SuccessGoal>;
    recommendations?: Array<any>;
    top6_codes?: Array<Model.TopCode>;
    loadStatus?: any;
  }

  interface State {
    LookupTables: ILookupTablesState;
  }
}
