<div class="toast toast--{{level}}" data-qa="app_alert_toaster" id="qa_alert_toaster" poFocusTrap>

  <div class="toast__icon">
    <icon *ngIf="level !== 'success'" iconId="exclamation"></icon>
    <icon *ngIf="level == 'success'" iconId="checkmark"></icon>
  </div>

  <div class="toast__body">
    <ng-content></ng-content>
  </div>

  <button
    *ngIf="!!buttonDismiss"
    type="button"
    [textContent]="buttonDismiss"
    (click)="closeAlert('dismiss')"
    class="action-button action-button--secondary"
    id="qa_alert_close_button">
  </button>

  <button
    *ngIf="!!buttonAction"
    type="button"
    [textContent]="buttonAction"
    (click)="doAction('action')"
    class="action-button action-button--primary"
    id="qa_alert_action_button">
    <div class="loader-spinner-inline"></div>
  </button>
</div>
