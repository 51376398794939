import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent {
  isSpinning = false;
  spinner_message = 'Loading...';

  @Input()
  public set isBusy(value: boolean) {
    this.isSpinning = value;
  }

  @Input()
  public set message(value: string) {
    this.spinner_message = value;
  }

  @Input() short?: boolean = false;
}
