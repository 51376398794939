import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class CompositeRouteGuard implements CanActivate {

  constructor(
    protected router: Router,
    protected injector: Injector) {
  }

  // Note: This guard evaluates the input guards in the order that they are entered.
  // If authGuard is one of the inputs, it should be the first element so it is
  // evaluated first.
  // Code is an updated version of: https://stackoverflow.com/questions/45618687/angular-2-router-guards-order
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  return this.compositeGuardCheck$(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.compositeGuardCheck$(route, state);
  }

  compositeGuardCheck$(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let compositeCanActivateObservable: Observable<boolean> = of(true);

    const routeGuards = route.data.routeGuards;
    if (routeGuards) {
      for (const guard of routeGuards) {
        const routeGuard = this.injector.get(guard);
        const canActivateObservable = routeGuard.canActivate(route, state);
        compositeCanActivateObservable = compositeCanActivateObservable.pipe(
          mergeMap((bool) => {
              if (!bool) {
                return of(false);
              } else {
                return canActivateObservable as Observable<boolean>;
              }
            }
          ));
      }
    }

    return compositeCanActivateObservable;
  }
}
