import { IFiscalReportsState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { FISCAL_REPORTS_ACTION_TYPES } from './fiscal-reports.action';

export function FiscalReportsReducer(state: IFiscalReportsState = State.FiscalReports, action: ActionWithPayload<any>): IFiscalReportsState {
  switch (action.type) {

    case FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO:
    case FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO_FOR_INSTITUTION: {
      const { _, isRefresh } = action.payload;
      return isRefresh ? state : { ...State.FiscalReports };
    }

    case FISCAL_REPORTS_ACTION_TYPES.GET_REPORTING_INFO_SUCCESS: {
      return {
        ...State.FiscalReports,
        reportingInfo: action.payload,
        reportLoaded: true
      }
    }

    case FISCAL_REPORTS_ACTION_TYPES.UPSERT_FISCAL_REPORT_SUCCESS: {
      const { fiscalReport, taskId } = action.payload;
      const index  = state.reportingInfo.findIndex(report => report.id === taskId);

      if (index < 0) {
        // Report not found
        return state;
      } else {
        return {
          ...state,
          reportingInfo: [
            ...state.reportingInfo.slice(0, index),
            { ...state.reportingInfo[index], fiscal_report: fiscalReport },
            ...state.reportingInfo.slice(index + 1)
          ]
        }
      }
    }

    case FISCAL_REPORTS_ACTION_TYPES.UPDATE_REPORTING_TASK_SUCCESS: {
      const { task, oldId } = action.payload;
      const index = state.reportingInfo.findIndex(report => report.id === oldId);

      if (index < 0) {
        // Report not found
        return state;
      } else {
        return {
          ...state,
          reportingInfo: [
            ...state.reportingInfo.slice(0, index),
            { ...state.reportingInfo[index], ...task },
            ...state.reportingInfo.slice(index + 1)
          ]
        }
      }
    }

    case FISCAL_REPORTS_ACTION_TYPES.RESET: {
      return State.FiscalReports;
    }

    case FISCAL_REPORTS_ACTION_TYPES.LOAD: {
      return {
        ...state,
        items: action.payload,
        proposal_report: undefined, // not using, clear it.
      };
    }

    case FISCAL_REPORTS_ACTION_TYPES.PUSH_STATUS: {
      const updatedArray = state.submitStatus.filter(statusObj => statusObj.label !== action.payload.label);
      updatedArray.push(action.payload);
      return {
        ...state,
        submitStatus: updatedArray
      };
    }

    case FISCAL_REPORTS_ACTION_TYPES.CLEAR_STATUS: {
      return {
        ...state,
        submitStatus: []
      };
    }

    case FISCAL_REPORTS_ACTION_TYPES.SAVE_FISCAL_REPORT_SUCCESS: {
      const item = action.payload;
      const dx = state.items.findIndex(fr => fr.proposal_id === item.proposal_id && fr.institution_id === item.institution_id && fr.duration_id === item.duration_id);
      if (dx >= 0) {
        return {
          ...state,
          items: [
            ...state.items.slice(0, dx),
            {
              ...state.items[dx],
              ...item,
            },
            ...state.items.slice(dx + 1)
          ]
        };
      } else {
        return {
          ...state,
          items: [...state.items, item]
        };
      }
    }

    case FISCAL_REPORTS_ACTION_TYPES.GET_PROPOSAL_SUCCESS: {
      const report = action.payload;
      return {
        ...state,
        proposal_report: report,
      }
    }

    case FISCAL_REPORTS_ACTION_TYPES.SAVE_LINE_PROPOSAL_SUCCESS: {
      const new_item = action.payload;
      const report = { ...state.proposal_report };
      let lines;
      const dx = report.lines.findIndex(line => line.id === new_item.id);
      if (dx >= 0) {
        lines = [
          ...report.lines.slice(0, dx),
          new_item,
          ...report.lines.slice(dx + 1)
        ]
      } else {
        lines = [...report.lines, new_item];
      }
      return {
        ...state,
        proposal_report: { ...report, lines }
      }
    }

    default:
      return state;
  }
}
