import { State, Model } from '@app-ngrx-domains';
import { Profile } from '../models/user';

/**
 * States managed by authorization reducer; initial states
 */
State.Auth = {
  inProgress: false,
  isLoggedIn: false,
  error: null,
  waitingOnUserToLogin: false,
  user: null,
  userMessages: [],
  userError: null,
  isSudoLoggedIn: false,
};

/**
 * Authorization states related type declarations.
 */
declare module '@app-ngrx-domains' {
  export interface IAuthState {
    inProgress: boolean;
    isLoggedIn: boolean;
    error: Object;
    waitingOnUserToLogin: boolean;
    user: Profile;
    userMessages: Array<Model.UserMessage>;
    userError: Object;
    isSudoLoggedIn: boolean;
  }

  interface State {
    Auth: IAuthState;
  }
}
