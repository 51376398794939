import { Component, Input, OnInit } from '@angular/core';
import { ReleaseNote } from '../release-notes.component';

@Component({
  selector: 'po-release-notes-card',
  templateUrl: './release-notes-card.component.html'
})
export class ReleaseNotesCardComponent implements OnInit {

  @Input() release: ReleaseNote;

  constructor() {}

  ngOnInit() {

  }
}
