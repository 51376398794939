import { IProposalReviewsState, Model, Query, Queries, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IProposalReviewsState>('ProposalReviews');

/**
 * Predefined queries to run against root states.
 */
export interface IProposalReviewsQueries {
  getAll: Query<Array<Model.Resource>>;
  getCurrent: Query<Array<Model.Resource>>;
  getTask: Query<Model.Task>;
  getCurrentReviewerId: Query<number>;
  getLoadingStatus: Query<{ is_loading: boolean, error: any }>;
}

Queries.ProposalReviews = {
  getAll: fromRoot(state => state.items),
  getCurrent: fromRoot(state => {
    if (state.current_reviewer_id) {
      return state.items.filter(r => r.user_id === state.current_reviewer_id);
    } else {
      return [];
    }
  }),
  getTask: fromRoot(state => state.task),
  getCurrentReviewerId: fromRoot(state => state.current_reviewer_id),
  getLoadingStatus: fromRoot(state => ({
    is_loading: state.is_loading,
    error: state.error
  })),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    ProposalReviews: Query<IProposalReviewsState>;
  }

  interface Queries {
    ProposalReviews: IProposalReviewsQueries;
  }
}
