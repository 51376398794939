import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, State } from '@app-ngrx-domains';
import * as Sentry from '@sentry/browser';

import { NOVA_SERVICE_USER_AGENT_PREFIX, ROUTER_LINKS } from '../../consts';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {

  pagePath: string;

  constructor(
    route: ActivatedRoute,
    private store: Store<State>) {

    // page we've tried to get to, but failed.
    this.pagePath = `/${route.snapshot.url.slice(0).join('/')}`;
    if (this.pagePath === ROUTER_LINKS.PAGE_NOT_FOUND) {
      this.pagePath = route.snapshot.queryParams['badUrl'];
    }

    // update header.
    this.store.dispatch(Actions.Layout.setHeader({
      ...State.Layout.header,
      title: `Page Not Found`,
    }));

    if (navigator.userAgent.startsWith(NOVA_SERVICE_USER_AGENT_PREFIX)) {
      // Forward error to Sentry.io
      const error = new Error(`PDF printing failed. Path: ${this.pagePath}`);
      Sentry.captureException(error);
    }
  }
}
