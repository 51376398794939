import { ModelBase } from './model.base';
import { Model } from '@app-ngrx-domains';
import { FUND_TYPES, UNRESTRICTED, INSTITUTION_TYPES } from '@app-consts';
import { Institution } from './index';

export interface IUserRoleScope extends Model.PermResourceScope {
  id?: number;
  role_id?: number;
  expire_on?: string;
  role?: Model.Role;
  institution?: Model.Institution;
  institution_name?: string;
  sector_id?: number;
  sector?: Model.Sector;
  proposal?: Model.ProposalItem;
  user?: Model.User;
  designee?: boolean;
}

export interface IGroupedUserRoleScope {
  role_id: number,
  name: string,
  data: Model.RolePermissions,
  roleScopes: Array<Model.UserRoleScope>
}

export class UserRoleScope extends ModelBase implements IUserRoleScope {
  // IResourceScope
  public fund_id: number;
  public institution_id: number;
  public proposal_id: number;
  public user_id: number;
  // IUserRoleScope
  public id: number;
  public role_id: number;
  public expire_on: string;
  public role: Model.Role;
  public institution: Model.Institution;
  public institution_name: string | null;
  public sector_id: number;
  public sector: Model.Sector;
  public proposal: Model.ProposalItem;
  public user: Model.User;
  public designee?: boolean;

  constructor(raw?: any) {
    super();

    if (raw) {
      this.id = raw.id || undefined;
      this.role_id = raw.role_id || undefined;
      this.user_id = raw.user_id || undefined;
      this.fund_id = raw.fund_id || undefined;
      this.sector_id = raw.sector_id || undefined;
      this.sector = raw.sector || undefined;
      this.institution_id = raw.institution_id || undefined;
      this.proposal_id = raw.proposal_id || undefined;
      this.expire_on = raw.expire_on || undefined;
      this.role = raw.role;
      this.institution = raw.institution;
      this.institution_name = raw.institution_name || undefined;
      this.proposal = raw.proposal || undefined;
      this.user = raw.user || undefined;
      this.designee = raw.designee || false;
      if (this.proposal && this.fund_id) {
        this.proposal['project_type'] = this.fund_id;
      }
    }
  }

  /**
   * Strips out extraneous fields from the role scope object.
   * @param rs
   */
  public static stripped(rs: Model.UserRoleScope): any {
    const result: any = {};

    if (rs.id) { result.id = rs.id; }
    if (rs.fund_id) { result.fund_id = rs.fund_id; }
    if (rs.institution_id) { result.institution_id = rs.institution_id; }
    if (rs.sector_id) { result.sector_id = rs.sector_id; }
    if (rs.proposal_id) { result.proposal_id = rs.proposal_id; }
    if (rs.role_id) { result.role_id = rs.role_id; }
    if (rs.user_id) { result.user_id = rs.user_id; }
    if (rs.designee) { result.designee = !!rs.designee; }
    if (rs.expire_on) { result.expire_on = rs.expire_on; }

    return result;
  }

  /**
   * From roles settings/data & the scope, it drive's the institution name.
   * @param roleData
   * @param roleScope
   */
  public static institutionName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    let instName = UNRESTRICTED;
    if (roleData.institution && roleData.institution >= 0) {
      const fund_id = roleScope.fund_id;
      if (fund_id === FUND_TYPES.AEBG) {
        if (roleScope.institution_id && roleScope.institution) {
          instName = Institution.getLongNameOrName(roleScope.institution);
        }
      } else {
        if (roleScope.institution_id && roleScope.institution) {
          instName = roleScope.institution.name;
        }
      }
    }
    return instName;
  }

  /**
   * From roles settings/data & the scope, it drive's the sector name.
   * @param roleData
   * @param roleScope
   */
  public static sectorName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    let sectorName = '';
    if (roleData.sector) {
      if (roleScope.sector_id && roleScope.sector) {
        sectorName = roleScope.sector.name;
      } else {
        sectorName = UNRESTRICTED;
      }
    }
    return sectorName;
  }

  /**
   * From roles settings/data & the scope, it drive's the expiration date.
   * @param roleData
   * @param roleScope
   */
  public static expirationDate(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    return roleScope.expire_on || 'Never';
  }

  /**
   * From the scope, derive the project name.
   * @param roleScope
   */
  public static projectName(roleScope: Model.UserRoleScope): string {
    const fund_id = roleScope.fund_id;
    if (fund_id === FUND_TYPES.AEBG) {
      // for now, distinguish member contacts from consortium contacts by looking at the institution type.
      if (roleScope.proposal_id && roleScope.proposal && roleScope.institution) {
        if (roleScope.institution.type === INSTITUTION_TYPES.CONSORTIUM) {
          return `${roleScope.institution.long_name}`;
        } else {
          return `Member Agency: ${roleScope.institution.name} - ${roleScope.proposal.title}`;
        }
      } else {
        return '?';
      }
    } else {
      return (roleScope.proposal_id && roleScope.proposal) ? roleScope.proposal.title : '?';
    }
  }

}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type UserRoleScope = IUserRoleScope;
    export type GroupedUserRoleScope = IGroupedUserRoleScope;
  }
}
