<div class="columns columns--3">
  <div></div>
  <div class="image-wrapper image-wrapper--center">
    <img alt="NOVA: Plan. Invest. Track." id="qa_login_nova_logo" src="./assets/images/icon-maintenance.svg">
  </div>
  <div></div>
</div>

<div class="align-center margin-bottom">
  <h2 class="margin-top">Down for scheduled maintenance</h2>
  <div class="help-text">
    <p>NOVA is undergoing scheduled maintenance, we will be back soon.</p>
  </div>
</div>



