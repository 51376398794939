import { ICurrentProposalState, Query, Queries, Model, combineRootFactory } from '@app-ngrx-domains';
import { FUND_TYPES } from '@app-consts';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<ICurrentProposalState>('CurrentProposal');

/**
 * Predefined queries to run against root states.
 */
export interface ICurrentProposalQueries {
  get: Query<any>;
  getProposal: Query<Model.ProposalItem>;
  getCAI: Query<Model.CAIProposal>;
  getLVG: Query<Model.LVGProposal>;
  getNEP: Query<Model.NEPProposal>;
  getRSI: Query<Model.RSIProposal>;
  getSWPK: Query<Model.SWPKProposal>;
  getSEP: Query<Model.SEPProposal>;
  getGP: Query<Model.GP2Proposal>;
  getIPlan: Query<Model.IPlanProposal>;
  getId: Query<number>;
  getWorkflowStates: Query<any>;
  getRecommendations: Query<Array<any>>;
  getLeadInstitutionId: Query<number | number>;
  getApproverInstitutionId: Query<number>;
  getInstitutions: Query<Array<any>>;
  getFundId: Query<number>;
  getLoadStatus: Query<{ isLoaded: boolean, isLoading: boolean, error: any }>;
  getTasks: Query<Array<Model.Task>>;
  isAllSector: Query<boolean>;
  isLocalShare: Query<boolean>;
  isRegionalShare: Query<boolean>;
}

Queries.CurrentProposal = {
  get: fromRoot(state => state.data),
  getProposal: fromRoot(state => state.data as Model.ProposalItem),
  getCAI: fromRoot(state => state.data as Model.CAIProposal),
  getLVG: fromRoot(state => state.data as Model.LVGProposal),
  getNEP: fromRoot(state => state.data as Model.NEPProposal),
  getRSI: fromRoot(state => state.data as Model.RSIProposal),
  getSWPK: fromRoot(state => state.data as Model.SWPKProposal),
  getSEP: fromRoot(state => state.data as Model.SEPProposal),
  getIPlan: fromRoot(state => state.data as Model.IPlanProposal),
  getGP: fromRoot(state => state.data as Model.GP2Proposal),
  getId: fromRoot(state => state.data.id),
  getWorkflowStates: fromRoot(state => {
    const proposal = state.data as Model.ProposalItem;
    return {
      id: proposal.id,
      duration_id: proposal.duration_id,
      budget_state: proposal.budget_state,
      contacts_state: proposal.contacts_state,
      description_state: proposal.description_state,
      lmi_state: proposal.lmi_state,
      metrics_state: proposal.metrics_state,
      workplan_state: proposal.workplan_state,
      workplan1_state: proposal.workplan1_state,
      workplan2_state: proposal.workplan2_state,
      workplan3_state: proposal.workplan3_state,
    }
  }),
  getRecommendations: fromRoot(state => (state.data as Model.ProposalItem).recommendations),
  getLeadInstitutionId: fromRoot(state => (state.data && state.data.lead_institution ? state.data.lead_institution.id : null)),
  getApproverInstitutionId: fromRoot(state => (state.data as Model.ProposalItem).approver_institution_id),
  getInstitutions: fromRoot(state => state.data.institutions
    ? state.data.institutions.map(institution => ({ value: institution.id, label: institution.name + (!!institution.is_inactive ? ' (Removed)' : ''), extras: institution}))
    : null),
  getFundId: fromRoot(state => state.data.project_type),
  getLoadStatus: fromRoot(state => ({
    isLoaded: state.is_loaded,
    isLoading: state.is_loading,
    error: state.error,
  })),
  getTasks: fromRoot(state =>
    state.data ? state.data.tasks : []
  ),
  isLocalShare: fromRoot(state => (state.data.project_type === FUND_TYPES.SWP_L)),
  isRegionalShare: fromRoot(state => (state.data.project_type === FUND_TYPES.SWP_R)),
  isAllSector: fromRoot(state => ((state.data as Model.ProposalItem).all_sectors === 0 ? false : true)),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    CurrentProposal: Query<ICurrentProposalState>;
  }

  interface Queries {
    CurrentProposal: ICurrentProposalQueries;
  }
}
