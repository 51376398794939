import { IProposalsState, Query, Queries, Model, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IProposalsState>('Proposals');

/**
 * Predefined queries to run against root states.
 */
export interface IProposalsQueries {
  getAll: Query<Array<any>>;
  getCurrent: Query<any>;
}

Queries.Proposals = {
  getAll: fromRoot(state => state.items),
  getCurrent: fromRoot(state => !!state.current_item_id ? state.items.find(r => r.id === state.current_item_id) : undefined),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Proposals: Query<IProposalsState>;
  }

  interface Queries {
    Proposals: IProposalsQueries;
  }
}
