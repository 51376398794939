import { Model } from '@app-ngrx-domains';

export const PerkinsCertificationCoreIndicatorTargets = [
  { name: '1P1. Post-Program Placement', attributeName: 'faupl_target_1', stateAttributeName: 'core_indicator_1p1', spltAttributeName: 'splt_1p1' },
  { name: '2P1. Earned Recognized Postsecondary Credential', attributeName: 'faupl_target_2', stateAttributeName: 'core_indicator_2p1', spltAttributeName: 'splt_2p1'},
  { name: '3P1. Non-traditional Program Concentration', attributeName: 'faupl_target_3', stateAttributeName: 'core_indicator_3p1', spltAttributeName: 'splt_3p1'}
];

export interface IPerkinsProposal extends Model.ProposalBase {
  // allocations
  allocations: Array<Model.Allocation>;

  // Response
  response_1: string;
  response_2: string;
  response_3: string;
  response_4: string;
  response_5: string;
  response_6: string;
  response_7: string;
  response_8: string;
  response_9: string;
  response_10: string;
  response_11: string;
  response_12: string;
  response_13: string;
  response_14: string;
  response_15: string;
  response_16: string;
  response_17: string;

  // Local Needs Assessment
  needs_1: string;
  needs_2: string;
  needs_3: string;
  needs_4: string;
  needs_5: string;
  needs_6: string;

  // Student Success Metrics
  vision_goals: Array<Model.EAVisionGoal>;

  // CTE-1
  cte_1_certified: boolean;
  cte_1_certified_updated: { updated_by: Model.User, updated_at: string };
  // CTE-3
  cte_3_certified: boolean;
  cte_3_certified_updated: { updated_by: Model.User, updated_at: string };
  // CTE-4
  addresses: Array<Model.EAAddress>;
  unidentified_workplaces: boolean;
  cte_4_certified: boolean;
  cte_4_certified_updated: { updated_by: Model.User, updated_at: string };
  // CTE-6
  faupl_target_1: string;
  faupl_target_2: string;
  faupl_target_3: string;
  target_explaination_1: string;
  target_explaination_2: string;
  target_explaination_3: string;
  cte_6_targets_approved: boolean;
  cte_6_certified: boolean;
  cte_6_certified_updated: { updated_by: Model.User, updated_at: string };
  // CTE-7
  cte_7_certified: boolean;
  cte_7_certified_updated: { updated_by: Model.User, updated_at: string };

  perkins_sub_applications: Array<Model.EAPerkinsSubApplication>
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type PerkinsProposal = IPerkinsProposal;
  }
}
