import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phone' })
export class PhoneTransform implements PipeTransform {

  phone_regex = new RegExp(/^(\d)?(\d{3})?(\d{3})(\d{4})$/);

  transform(unformatted: string): string {
    if (unformatted && unformatted.length > 0) {
      return this.transformForField(unformatted).formatted;
    } else {
      return '';
    }
  }

  transformForField(unformatted: string): { formatted: string, raw: string } {
    const stripped: string = unformatted.toString();
    const raw = stripped.replace(/\D/g, '').slice(0, 11);
    if (raw.length === 0) {
      return { formatted: undefined, raw: null };
    } else if (raw.length < 10) {
      return { formatted: raw, raw };
    } else {
      const [, country, area, prefix, line] = this.phone_regex.exec(raw);
      let formatted = '';
      if (country) {
        formatted += `+${country} `;
      }
      if (area) {
        formatted += `(${area})`;
      }
      if (prefix.length > 0) {
        formatted += ` ${prefix}`;
      }
      if (line.length > 0) {
        formatted += `-${line}`;
      }
      return { formatted, raw };
    }
  }
}
