import { Model } from '@app-ngrx-domains';
import { ModelBase } from './model.base';
import { Program } from './program';
import * as _ from 'lodash';

export interface IMetricItem {
  id: number;
  metric_definition_id: number;
  proposal_id: number;
  year_1: number;
  year_2: number;
  year_3: number;
  year_4: number;
  impacted_group_id: number;
  name: string;
  top6_label: string;
  top6_code: string;
  baseline: number;
  baseline_duration_id: number;
  target: number;
  raw_metric_name: string;
  raw_metric_description: string;
  lmi_program_id: number;
}

export class Metric extends ModelBase implements IMetricItem {
  public id: number;
  public metric_definition_id: number;
  public proposal_id: number;
  public year_1: number;
  public year_2: number;
  public year_3: number;
  public year_4: number;
  public impacted_group_id: number;
  public name: string;
  public top6_label: string;
  public top6_code: string;
  public baseline: number;
  public baseline_duration_id: number;
  public units: string;
  public target: number;
  public raw_metric_name: string;
  public raw_metric_description: string;
  public lmi_program_id: number;
  public lmi_program: Program;
  public metric_definition: any;
  public impacted_group: any;
  public lbAnswer: number;

  constructor(raw?: any) {
    super();

    if (raw) {
      this.id = raw.id;
      this.metric_definition_id = raw.metric_definition_id || 0;
      this.year_1 = raw.year_1;
      this.year_2 = raw.year_2;
      this.year_3 = raw.year_3;
      this.year_4 = raw.year_4;
      this.impacted_group_id = raw.impacted_group_id;
      this.lmi_program_id = raw.lmi_program_id;
      this.lmi_program = raw.lmi_program;
      this.proposal_id = raw.proposal_id;
      this.baseline = raw.baseline;
      this.baseline_duration_id = raw.baseline_duration_id;
      this.units = raw.units;
      this.target = raw.target;
      this.raw_metric_name = raw.raw_metric_name;
      this.raw_metric_description = raw.raw_metric_description;
      this.metric_definition = raw.metric_definition;
      this.impacted_group = raw.impacted_group;
      this.lbAnswer = raw.lbAnswer;
    }
  }

  public serverObject(): IMetricItem {
    return {
      id: this.id || undefined,
      metric_definition_id: this.metric_definition_id || undefined,
      year_1: this.year_1 || undefined,
      year_2: this.year_2 || undefined,
      year_3: this.year_3 || undefined,
      year_4: this.year_4 || undefined,
      name: this.name || undefined,
      top6_label: undefined,
      top6_code: undefined,
      impacted_group_id: this.impacted_group_id || undefined,
      lmi_program_id: this.lmi_program_id || undefined,
      proposal_id: this.proposal_id || undefined,
      baseline: this.baseline || undefined,
      baseline_duration_id: this.baseline_duration_id || undefined,
      target: this.target || undefined,
      raw_metric_name: this.raw_metric_name || undefined,
      raw_metric_description: this.raw_metric_description || undefined,
    };
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type MetricItem = IMetricItem;
  }
}
