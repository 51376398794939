import { Action } from '@ngrx/store';
import { ICfadState, Actions, State, Model } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { Cfad, Task, EnumErrorTypes } from '@app-models';
import { FUND_TYPES, WORKFLOW_TYPES, WORKFLOWS_CONFIG, WORKFLOW_STEPS } from '@app-consts';

/**
 * List of action types
 */
const ACTION_PREFIX = 'CFAD_';
export const CFAD_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  NOOP: `${ACTION_PREFIX}NOOP`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  UPDATE_FIELD: `${ACTION_PREFIX}UPDATE_FIELD`,
  UPDATE_FIELD_SUCCESS: `${ACTION_PREFIX}UPDATE_FIELD_SUCCESS`,
  UPDATE_STATE: `${ACTION_PREFIX}UPDATE_STATE`,
  UPDATE_STATE_SUCCESS: `${ACTION_PREFIX}UPDATE_STATE_SUCCESS`,
  REFRESH: `${ACTION_PREFIX}REFRESH`,
  REFRESH_SUCCESS: `${ACTION_PREFIX}REFRESH_SUCCESS`,
};

/**
 * Action creator class
 */
export class CfadActions {

  /**
   * Hydrates from the raw response data
   * @param {number} program_year
   * @param {any} response
   * @returns {Action}
   */
  load(response: any): ActionWithPayload<any> {
    // get the latest year's cfad.
    const cfad: Model.Cfad = Cfad.latestYearCfad(response.cfads);
    // get last year's cfad.
    const lastYearCfad: Model.Cfad  = Cfad.targetYearCfad(cfad.duration_id - 1, response.cfads);
    // need to explicitly set last year's funding source - for refrencing.
    cfad['last_direct_funding'] = (lastYearCfad) ? lastYearCfad.direct_funding : cfad.last_direct_funding;
    // make latest cfad the current cfad.
    const currentCfad = new Cfad(cfad).iObject<Model.Cfad>();

    // keep around old cfads for reads.
    const oldCfads = [];
    response.cfads.forEach(c => {
      if (c.duration_id < currentCfad.duration_id) {
        oldCfads.push(new Cfad(c).iObject<Model.Cfad>());
      }
    });

    return {
      type: CFAD_ACTION_TYPES.LOAD,
      payload: {
        current: currentCfad,
        old: oldCfads,
      }
    };
  }

  /**
   * Resets/clears data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: CFAD_ACTION_TYPES.LOAD,
      payload: {
        ...State.Cfad,
      },
    };
  }

  /**
   * No-op action.
   * @returns {Action}
   */
  noOp(): Action {
    return {
      type: CFAD_ACTION_TYPES.NOOP,
    };
  }

  refreshCfad(gotoUrl = ''): ActionWithPayload<any> {
    return {
      type: CFAD_ACTION_TYPES.REFRESH,
      payload: { gotoUrl: gotoUrl }
    };
  }

  refreshSuccess(cfad): ActionWithPayload<any> {
    return {
      type: CFAD_ACTION_TYPES.REFRESH_SUCCESS,
      payload: new Cfad(cfad).iObject<Model.Cfad>()
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: CFAD_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  update(req: {name: string, value: any}): ActionWithPayload<any> {
    return {
      type: CFAD_ACTION_TYPES.UPDATE_FIELD,
      payload: req
    };
  }

  updateSuccess(req: {name: string, value: any}): ActionWithPayload<any> {
    return {
      type: CFAD_ACTION_TYPES.UPDATE_FIELD_SUCCESS,
      payload: req
    };
  }

  updateState(changes: any): ActionWithPayload<any> {
    return {
      type: CFAD_ACTION_TYPES.UPDATE_STATE,
      payload: changes
    }
  }

  updateStateSuccess(res: any): ActionWithPayload<any> {
    return {
      type: CFAD_ACTION_TYPES.UPDATE_STATE_SUCCESS,
      payload: new Task(res).iObject<Model.Task>(),
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Cfad = new CfadActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Cfad: CfadActions;
  }
}
