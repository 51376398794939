<div class="workflow-steps__link-indicator"></div>

<div class="workflow-steps__link-content">
  <p class="workflow-steps__step-title">{{step.title}}</p>

  <ng-container *ngIf="step.showStatus" [ngSwitch]="!!step.firstTouch">
    <p *ngSwitchCase="true" class="workflow-steps__step-status-text" data-qa="pristine">
      Not started
    </p>

    <ng-container *ngSwitchCase="false">
      <ng-container [ngSwitch]="!!step.valid">
        <p *ngSwitchCase="false" class="workflow-steps__step-status-text incomplete" [attr.data-qa]="'invalid'">Incomplete</p>
        <p *ngSwitchCase="true" class="workflow-steps__step-status-text completed" [attr.data-qa]="'valid'">{{step.optional ? 'Optional' : step.validLabel || 'Completed' }}</p>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="step.scoreCard && step.scoreCard.collectScore" [ngSwitch]="isNil(step.scoreCard.score)">
    <p *ngSwitchCase="true" class="workflow-steps__step-status-text" data-qa="pristine">
      - / {{step.scoreCard.maxScore}}
    </p>

    <p *ngSwitchCase="false" class="workflow-steps__step-status-text" [ngClass]="step.valid ? 'completed' : 'incomplete'"
      [attr.data-qa]="step.valid ? 'valid' : 'invalid'">
      {{step.scoreCard.score}} / {{step.scoreCard.maxScore}}
    </p>
  </ng-container>
</div>

<ng-container *ngIf="!!step.workflow">
  <icon iconId="arrow"></icon>
</ng-container>
