import { take, skipWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Actions } from '@app-ngrx-domains';
import { LoggerService } from 'ng-logger';
import { CORE_ROUTES, PROGRAM_KEYS, ROUTER_LINKS } from '@app-consts';
import { Utilities } from '@app-models';
import { LOOKUP_TABLES } from '@app/core/state-management/lookup-tables.action';

/**
 * Lookup list based on the program key.
 */
const LOOKUP_LIST: {[key: string]: Array<string> } = {
  [PROGRAM_KEYS.CAEP]: [
    LOOKUP_TABLES.FUNDS,
    LOOKUP_TABLES.PROGRAM_AREAS,
    LOOKUP_TABLES.CAEP_INSTITUTIONS,
    LOOKUP_TABLES.SUCCESS_GOALS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
  ],
  [PROGRAM_KEYS.IPLAN]: [
    LOOKUP_TABLES.CCC_INSTITUTIONS,
  ],
  [PROGRAM_KEYS.CAI]: [
    LOOKUP_TABLES.SECTORS,
    LOOKUP_TABLES.SOC_CODES,
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.COUNTIES_CITIES,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.CCC_INSTITUTIONS,
  ],
  [PROGRAM_KEYS.EWD]: [
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.SECTORS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.SUCCESS_GOALS
  ],
  [PROGRAM_KEYS.GP]: [
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
  ],
  [PROGRAM_KEYS.LVG]: [
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.IMPACTED_GROUPS,
  ],
  [PROGRAM_KEYS.PERKINS]: [
    LOOKUP_TABLES.SETTINGS_INSTITUTIONS,
    LOOKUP_TABLES.PERKINS_CODES,
    LOOKUP_TABLES.LEGISLATION,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.CATEGORIES,
    LOOKUP_TABLES.SUCCESS_GOALS
  ],
  [PROGRAM_KEYS.SEP]: [
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.IMPACTED_GROUPS,
    LOOKUP_TABLES.CATEGORIES,
  ],
  [PROGRAM_KEYS.SWP_K12]: [
    LOOKUP_TABLES.ALL_INSTITUTIONS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.SECTORS,
    LOOKUP_TABLES.IMPACTED_GROUPS,
    LOOKUP_TABLES.SUBREGIONS,
  ],
  [PROGRAM_KEYS.SWP_L]: [
    LOOKUP_TABLES.COUNTIES,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.SECTORS,
    LOOKUP_TABLES.SOC_CODES,
    LOOKUP_TABLES.SUBREGIONS,
    LOOKUP_TABLES.TASKFORCE_RECOMMENDATIONS,
    LOOKUP_TABLES.TOP6_CODES,
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.IMPACTED_GROUPS,
    LOOKUP_TABLES.SUCCESS_GOALS
  ],
  [PROGRAM_KEYS.SWP_R]: [
    LOOKUP_TABLES.COUNTIES,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.SECTORS,
    LOOKUP_TABLES.SOC_CODES,
    LOOKUP_TABLES.SUBREGIONS,
    LOOKUP_TABLES.TASKFORCE_RECOMMENDATIONS,
    LOOKUP_TABLES.TOP6_CODES,
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.IMPACTED_GROUPS,
    LOOKUP_TABLES.SUCCESS_GOALS
  ],
  [PROGRAM_KEYS.NEP]: [
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.IMPACTED_GROUPS,
    LOOKUP_TABLES.SECTORS
  ],
  [PROGRAM_KEYS.RCM]: [
    LOOKUP_TABLES.CCC_INSTITUTIONS,
    LOOKUP_TABLES.SECTORS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.SOC_CODES,
    LOOKUP_TABLES.SUCCESS_GOALS,
    LOOKUP_TABLES.TOP6_CODES,
  ],
  [PROGRAM_KEYS.SMALL_PROGRAMS]: [
    LOOKUP_TABLES.ALL_INSTITUTIONS,
    LOOKUP_TABLES.SECTORS,
    LOOKUP_TABLES.METRICS_DEFINITIONS,
    LOOKUP_TABLES.SUCCESS_GOALS,
  ],
  [CORE_ROUTES.INSTITUTIONS]: [
    LOOKUP_TABLES.ALL_INSTITUTIONS
  ]
};

/** Resolver for pre-loading Lookup tables before progressing to views that need them.
 */
@Injectable()
export class LookupTablesResolve implements Resolve<boolean> {

  constructor(
    private store: Store<State>,
    private router: Router,
    private logger: LoggerService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber: any) => {
      // start busy spinner...
      this.store.dispatch(Actions.Layout.showBusySpinner(true));
      this.logger.debug(`[lookup-tables-resolver][${state.url}] loading`);

      // fetch lookup tables if not already loaded - context it based on the route.

      // base lookup tables
      let waitFor = [
        LOOKUP_TABLES.DOCUMENT_TYPES,
        LOOKUP_TABLES.OBJECT_CODES,
        LOOKUP_TABLES.ROLES,
        LOOKUP_TABLES.DURATIONS
      ];

      // fetch any program specific tables.
      const programKey = Utilities.programKeyFromRoute(state.url, true);
      if (LOOKUP_LIST[programKey]) {
        waitFor = [
          ...waitFor,
          ...LOOKUP_LIST[programKey],
        ];
      }

      // dispatch calls to check/fetch the tables.
      waitFor.forEach(t => {
        switch (t) {
          case LOOKUP_TABLES.CCC_INSTITUTIONS:
            this.store.dispatch(Actions.LookupTables.lookupInstitutions({ ccc: true }));
            break;
          case LOOKUP_TABLES.CAEP_INSTITUTIONS:
            this.store.dispatch(Actions.LookupTables.lookupInstitutions({ caep: true }));
            break;
          case LOOKUP_TABLES.SETTINGS_INSTITUTIONS:
            this.store.dispatch(Actions.LookupTables.lookupInstitutions({ ccc: true, include_settings: true }));
            break;
          case LOOKUP_TABLES.ALL_INSTITUTIONS:
            this.store.dispatch(Actions.LookupTables.lookupInstitutions({ all: true })); 
            break;
          default:
            this.store.dispatch(Actions.LookupTables.doLookup(t));
            break;
        }
      });

      // wait until all the tables are loaded.
      this.store.select(Queries.LookupTables.getLoadStatus).pipe(
        skipWhile(status => !waitFor.every(t => !!status[t + 'IsLoaded'])),
        take(1))
        .subscribe(
          () => {
            this.logger.debug(`[lookup-tables-resolver][${state.url}] loaded`);
            // stop busy spinner...
            this.store.dispatch(Actions.Layout.showBusySpinner(false));
            subscriber.next(true);
            subscriber.complete();
          },
          error => {
            // error thrown while loading.
            this.logger.error(`[lookup-tables-resolver][${state.url}] error=${JSON.stringify(error)}`);
            // stop busy spinner...
            this.store.dispatch(Actions.Layout.showBusySpinner(false));
            this.router.navigate([ROUTER_LINKS.HOME]);
            subscriber.error(false);
            subscriber.complete();
          });
    });
  }
}
