import { ICfadState, Query, Queries, Root, Model, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<ICfadState>('Cfad');

/**
 * Predefined queries to run against root states.
 */
export interface ICfadQueries {
  get: Query<Model.Cfad>;
  getId: Query<number>;
  getClientState: Query<{ [name: string]: any }>;
  getOld: Query<Array<Model.Cfad>>;
  getAll: Query<Array<Model.Cfad>>;
}

Queries.Cfad = {
  get: fromRoot(state => state.current),
  getId: fromRoot(state => state.current.id),
  getClientState: fromRoot(state => state.current.client_state),
  getOld: fromRoot(state => state.old),
  getAll: fromRoot(state => [state.current, ...state.old]),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Cfad: Query<ICfadState>;
  }

  interface Queries {
    Cfad: ICfadQueries;
  }
}
