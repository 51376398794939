import { IAllocationsState, Query, Queries, Model, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IAllocationsState>('Allocations');

/**
 * Predefined queries to run against root states.
 */
export interface IAllocationsQueries {
  getLead: Query<Model.Allocations>;
  getMembers: Query<{ [institution_id: number]: Model.Allocations }>;
  getBudgetMembers: Query<Model.RemainingAllocations>;
  get: Query<IAllocationsState>;
}

Queries.Allocations = {
  getLead: fromRoot(state => state.lead),
  getMembers: fromRoot(state => state.members),
  getBudgetMembers: fromRoot(state => state.budget_members),
  get: fromRoot(state => state),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Allocations: Query<IAllocationsState>;
  }

  interface Queries {
    Allocations: IAllocationsQueries;
  }
}
