import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-simple-pagination',
  templateUrl: './simple-pagination.component.html',
})
export class SimplePaginationComponent {
  // Variables shared with the view
  @Input() itemsName = '';
  @Input() totalResults: Number = 0;
  @Input() startingResult: Number = 0;
  @Input() endingResult: Number = 0;
  @Input() resetScroll = false;
  @Output() paginate: EventEmitter<Number> = new EventEmitter();


  // Private resources attached to `this`
  constructor(
    ) { }

  doResetScroll() {
    const scrollAnchor = document.getElementById('scroll-anchor');
    if (scrollAnchor.scrollIntoView) {
      scrollAnchor.scrollIntoView({ behavior: 'smooth' });
    } else {
      const container = document.querySelector('.content-body__container');
      container.scrollTop = 0;
    }
  }

  emitPaginate(direction: Number) {
    if (this.resetScroll) {
      this.doResetScroll();
    }
    this.paginate.emit(direction);
  }

  onlyOnePage(): boolean {
    return (this.startingResult === 1 && this.endingResult >= this.totalResults);
  }

  disablePrev(): boolean {
    return this.startingResult === 1;
  }

  disableNext(): boolean {
    return this.endingResult >= this.totalResults;
  }

}
