import { environment } from '../environments/environment';

/**
 * This is a simple logger that we can use to remove console logs & errors
 * when running in prod environment.
 */
export class Logger {

  static log(msg: string | object): void {
    if (!environment.production) {
      console.log(msg);
    }
  }

  static error(msg: string): void {
    if (!environment.production) {
      console.error(msg);
    }
  }
}
