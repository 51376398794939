import { IInstitutionState, State, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { INSTITUTION_ACTION_TYPES } from './institution.action';
import { UserRoleScope } from '@app-models';

export function InstitutionReducer(state: IInstitutionState = State.Institution, action: ActionWithPayload<any>): IInstitutionState {
  switch (action.type) {
    case INSTITUTION_ACTION_TYPES.GET:
      return {
        ...State.Institution
      };

    case INSTITUTION_ACTION_TYPES.LOAD:
      // Contacts and Proposals are not eagerly fetched on updates
      const contacts = action.payload.institution.users || state.contacts;
      const proposals = action.payload.institution.proposals || state.institution.proposals;
      return {
        ...State.Institution,
        institution: {
          ...action.payload.institution,
          proposals
        },
        contacts
      };

    case INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES_SUCCESS:
      return {
        ...state,
        contacts: [
          ...state.contacts,
          ...action.payload.role_scopes
        ]
      }

    case INSTITUTION_ACTION_TYPES.REMOVE_ROLE_SCOPE_SUCCESS:
      const deleted_role = action.payload.role_scope;
      const identifier = UserRoleScope.stripped(deleted_role);

      const contacts_state = state.contacts.filter(contact => {
        let match = true;
        Object.keys(identifier).forEach(field => {
          match = match && (identifier[field] === contact[field]);
        });
        return !match;
      });

      return {
        ...state,
        contacts: contacts_state
      }

    case INSTITUTION_ACTION_TYPES.SET_LOGO_URL:
      return {
        ...state,
        institution: {
          ...state.institution,
          logo_url: action.payload.logo_url
        }
      };

    default:
      return state;
  }
};
