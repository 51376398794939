import {Directive, Optional} from '@angular/core';
import {NavGroupComponent, NavItemComponent} from '@app/core/components';

@Directive({
  selector: '[poTreeNode]'
})
export class TreeNodeDirective {

  component;

  constructor(@Optional() navItem: NavItemComponent, @Optional() navGroup: NavGroupComponent) {
    if (navItem && navGroup) {
      this.component = navItem;
    } else {
      if (navItem) {
        this.component = navItem;
      }

      if (navGroup) {
        this.component = navGroup;
      }
    }
  }

}
