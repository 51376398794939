import { Model } from '@app-ngrx-domains';
import { ModelBase } from './model.base';

export interface IEvidenceItem {
  id: number;
  filename: string;
  type: string;
  url: string;
}

export class Evidence extends ModelBase implements IEvidenceItem {
  public id: number;
  public filename: string;
  public type: string;
  public url: string;

  constructor(
    id?: number,
    filename?: string,
    type?: string,
    url?: string,
  ) {
    super();
    this.id = id;
    this.filename = filename;
    this.type = type;
    this.url = url;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type EvidenceItem = IEvidenceItem;
  }
}
