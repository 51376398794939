import { State } from '@app-ngrx-domains';

State.Institution = {
  institution: {
    id: undefined,
  },
  contacts: []
};

declare module '@app-ngrx-domains' {
  export interface IInstitutionState {
    institution: Model.Institution,
    contacts: Array<Model.UserRoleScope>
  }

  interface State {
    Institution: IInstitutionState
  }
}
