<div class="status-card status-card--{{level}}"  [ngClass]="{ 'status-card--small': small }" [attr.aria-expanded]="expanded">
  <div *ngIf="!noIcon" class="status-card__icon">
    <icon [iconId]="iconId"></icon>
  </div>

  <div class="status-card__body">
    <ng-content></ng-content>
  </div>

  <button
    *ngIf="collapsible"
    class="action-button action-button--tertiary status-card__trigger"
    type="button" [title]="expanded ? 'Collapse Card' : 'Expand Card'"
    (click)="toggleExpand()">
    <icon iconId="disclosure"></icon>
  </button>

  <button
    *ngIf="!!dismissLabel"
    type="button"
    [textContent]="dismissLabel"
    (click)="closeAlert()"
    class="action-button action-button--secondary margin-left-sm"
    id="qa_inline_alert_close_button">
  </button>

  <button
    *ngIf="!!actionLabel"
    type="button"
    [textContent]="actionLabel"
    (click)="doAction()"
    class="action-button action-button--secondary margin-left-sm"
    id="qa_inline_alert_action_button">
    <div class="loader-spinner-inline"></div>
  </button>

  <ng-content select="a"></ng-content>
</div>
