import { concat, of, EMPTY } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core'
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Root, State, Actions as DomainActions, Model, Queries } from '@app-ngrx-domains'
import { toPayload } from '@app-libs';
import { ACTION_BUTTONS, WORKFLOW_TYPES } from '@app-consts';
import { WORKFLOW_ACTION_TYPES } from './workflow.action';

/**
 * Injectable Workflow effects class
 */
@Injectable()
export class WorkflowEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>
  ) {
  }

  /**
   * Based on current step, it routes the user to the next step in the workflow.
   */
  @Effect() gotoNext$ = this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.GOTO_NEXT),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workflow)),
    switchMap(data => {
      const [next, workflowState] = [...data];
      const workflowKind = (next.kind === WORKFLOW_TYPES.CURRENT) ? workflowState.current : next.kind;
      const currentStep = next.currentStep;
      const currentWorkflow = workflowState.workflows[workflowKind];

      const currentIndex = currentWorkflow.steps.findIndex((step: Model.WorkflowStep) => (step.route === currentStep));
      if (currentIndex < 0) {
        // couldn't find the match... ignore.
        return EMPTY;
      }

      let nextIndex = currentIndex;
      for (let i = currentIndex + 1; i < currentWorkflow.steps.length; i++) {
        if (!currentWorkflow.steps[i].hide) {
          if (!!currentWorkflow.steps[i].workflow && !!currentWorkflow.steps[i].workflow.section) {
            // go into 1st step of the section if it's not empty.
            if (!!currentWorkflow.steps[i].steps && !!currentWorkflow.steps[i].steps.length) {
              nextIndex = i;
              break;
            }
          } else {
            nextIndex = i;
            break;
          }
        }
      }

      if (nextIndex <= currentIndex) {
        // there is no next step to go to... ignore.
        return EMPTY;
      }

      // get next step's routing info.
      let nextStep = currentWorkflow.steps[nextIndex].route;
      let nextRouterLink = currentWorkflow.steps[nextIndex].routerLink;
      if (!!currentWorkflow.steps[nextIndex].workflow && !!currentWorkflow.steps[nextIndex].workflow.section) {
        // go to section's first element.
        const sectionStep = currentWorkflow.steps[nextIndex].steps[0];
        nextStep = sectionStep.route;
        nextRouterLink = sectionStep.routerLink;
      }

      const nextUrl = !nextRouterLink
        ? `${currentWorkflow.baseLink}/${nextStep}`
        : `${currentWorkflow.baseLink}/${nextRouterLink}`;
      return of(DomainActions.App.go([nextUrl]));
    })
  );

  /**
   * Sets current step of the current workflow and puts up a
   * Next button if requested.
   */
  @Effect() setCurrentStep$ = this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.SET_CURRENT_STEP),
    map(toPayload),
    switchMap(payload => {
      const actions: Action[] = [];
      // set current step.
      actions.push(DomainActions.Workflow.setProps({currentStep: payload.step}));
      // show next button on header.
      if (payload.showNext) {
        actions.push(DomainActions.Layout.appendActions({
          id: ACTION_BUTTONS.NEXT,
          type: 'button',
          title: 'Next',
          route: payload.step,
          class: 'primary',
          footer: true
        }));
      }

      // Dispatch actions. These are executed in order.
      return concat([
        ...actions
      ]);
    })
  );

  /**
   * Shows or hides steps in the workflow.
   */
  @Effect() showSteps$ = this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.SHOW_STEPS),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workflow)),
    switchMap(([req, workflowState]) => {
      const workflowKind = (req.workflowType === WORKFLOW_TYPES.CURRENT) ? workflowState.current : req.workflowType;
      const workflow = workflowState.workflows[workflowKind];

      const steps = [];
      workflow.steps.forEach(s => {
        if (req.exclude.includes(s.route)) {
          if (req.excludeHide.includes(s.route)) {
            // toggle hide this excluded step
            steps.push({
              route: s.route,
              hide: req.show,
            })
          }
        } else {
          steps.push({
            route: s.route,
            hide: !req.show,
          });
        }
      });

      return of(DomainActions.Workflow.updateSteps(steps, req.workflowType));
    })
  );

  @Effect() showSteps_new$ = this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.SHOW_STEPS_new),
    map(toPayload),
    withLatestFrom(this.store.select(Root.Workflow)),
    switchMap(([req, workflowState]) => {
      const { steps, workflowType } = req;
      const workflowKind = (req.workflowType === WORKFLOW_TYPES.CURRENT) ? workflowState.current : workflowType;
      const workflow = workflowState.workflows[workflowKind];

      const updates = workflow.steps.map(step => ({
        route: step.route,
        hide: !steps.includes(step.route)
      }));

      return of (DomainActions.Workflow.updateSteps(updates, workflowType));
  }));

  @Effect() updateParentValidationStatuses$ = this.actions$.pipe(
    ofType(WORKFLOW_ACTION_TYPES.UPDATE_PARENT_VALIDATION_STATUSES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Workflow.get)),
    switchMap(([payload, workflows]) => {
      let childWorkflowName = payload.childWorkflowName;
      if (!childWorkflowName) {
        // bad arg... must have workflow name whose parents must be updated.
        return EMPTY;
      }

      // get child workflow.
      const childWorkflow = workflows[childWorkflowName];
      if (!childWorkflow.parent) {
        // must have a parent.
        return EMPTY;
      }

      // get parent workflow & step that needs to be updated.
      let parentWorkflow = workflows[childWorkflow.parent.name];
      let parentStep = parentWorkflow.steps.find(s => s.route === childWorkflow.parent.route);

      // keep track of status changes so we can batch update the statuses
      const validationStatuses: {[name: string]: Array<Model.WorkflowStep>} = {};
      // add child statuses.
      validationStatuses[childWorkflow.name] = childWorkflow.steps.filter(s => !s.hide && s.showStatus).map(s => ({ route: s.route, valid: s.valid}));
      // then add parents' validation statuses so they can be mutable.
      let parentWorkflowName = parentWorkflow.name;
      while (!!parentWorkflowName) {
        const workflow = workflows[parentWorkflowName];
        // add parent workflow.
        if (!validationStatuses[parentWorkflowName]) {
          validationStatuses[parentWorkflowName] = workflow.steps.filter(s => !s.hide && s.showStatus).map(s => ({ route: s.route, valid: s.valid}));
        }
        parentWorkflowName = workflow.parent ? workflow.parent.name : undefined;
      }

      // update actions to execute serially.
      const actions: Action[] = [];
      do {
        const updateParentOverallStatus = (overallStatus: boolean) => {
          // update redux state.
          if (overallStatus && (!!payload.replace && payload.replace.route === parentStep.route)) {
            // replace valid state with privided label
            this.store.dispatch(DomainActions.Workflow.setValidLabel(parentStep.route, payload.replace.label, parentWorkflow.name));
          } else {
            this.store.dispatch(DomainActions.Workflow.setValidity(parentStep.route, overallStatus, parentWorkflow.name));
          }
          // update cache.
          validationStatuses[parentWorkflow.name] = validationStatuses[parentWorkflow.name].map(s => {
            if (s.route === parentStep.route) {
              return {
                ...s,
                valid: overallStatus,
              }
            } else {
              return s;
            }
          })
        }

        // get over all status of the child workflow.
        const valid = validationStatuses[childWorkflowName].every(s => s.valid);

        // update status of parent workflow step.
        if (!!parentStep.workflow.section) {
          // update status within section.
          const sectionStep = parentStep.steps.find(s => s.workflow && s.workflow.name === childWorkflowName);
          // queue to update redux copy
          this.store.dispatch(DomainActions.Workflow.updateStepsInSection(parentStep.route, [{route: sectionStep.route, valid}], parentWorkflow.name));
          // get section status.
          const sectionValid = parentStep.steps.every(s => {
            if (s.route === sectionStep.route) {
              return valid
            } else {
              return s.valid;
            }
          })

          // update section status.
          updateParentOverallStatus(sectionValid);
        } else {
          updateParentOverallStatus(valid);
        }

        if (!!parentWorkflow.parent) {
          // walk up parent step chains.
          childWorkflowName = parentWorkflow.name;
          parentWorkflow = workflows[parentWorkflow.parent.name];
          parentStep = parentWorkflow.steps.find(s => s.workflow && s.workflow.name === childWorkflowName);
        } else {
          // done
          break;
        }
      } while (true);

      // not dispatching any actions.
      return EMPTY;
    })
  );

}
