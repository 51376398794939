import { ModelBase } from './model.base';
import * as moment from 'moment';
import {IMessageTemplate} from './message-template';
import { Utilities } from './utilities';
import { DATE_TIME_MOMENT_FORMAT_STRINGS } from '@app-consts';

export interface IEventType {
  id?: number;
  name?: string;
}

export interface IEvent {
  id?: number;
  name?: string;
  fund_id?: number;
  event_type_id?: number;
  event_type?: IEventType;
  month?: number;
  day?: number;
  hour?: number;
  minute?: number;
  event_time?: string;
}

export class Event extends ModelBase {

  static convertToEventTime(event: IEvent): string {
    if (!event) {
      return;
    }

    if (!event.month || !event.day) {
      return event.event_time;
    }

    return moment.tz({
      year: moment().year(),
      month: event.month ? event.month - 1 : 0,
      day: event.day || 1,
      hour: event.hour || 0,
      minute: event.minute || 0
    }, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone).utc().toISOString();
  }

  static formatName(event: IEvent): string {
    if (event && event.event_type) {
      return `${event.event_type.name}: ${event.name}`;
    } else if (event) {
      return `${event.name}`;
    } else {
      return '';
    }
  }

  static formatEventDate(event: IEvent): string {
    if (!event || !event.month || !event.day) {
      if (event && event.event_time) {
        return Utilities.convertToNOVADateTime(event.event_time, DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium);
      } else {
        return 'Not set';
      }
    }

    const eventDate = moment(`${event.month}-${event.day}`, 'M-DD');
    const dateFormat = 'MMM Do';
    return eventDate.format(dateFormat);
  }

  static formatEventDateTime(event: IEvent, useFormatString?: string): string {
    const eventTime = Event.convertToEventTime(event);
    if (!eventTime) {
      return 'Not set';
    }

    return Utilities.convertToNOVADateTime(eventTime, useFormatString);
  }

  static formatEventDateTimeLong(event: IEvent): string {
    return Event.formatEventDateTime(event, DATE_TIME_MOMENT_FORMAT_STRINGS.dateTimeLong12HoursTZ);
  }

  static formatDate(message: IMessageTemplate): string {
    return Event.formatEventDate(message.event);
  }

  static formatTriggerDate(message: IMessageTemplate): string {
    const event = message.event;
    if (!event || !event.month || !event.day) {
      return Event.formatTriggerDateTime(message);
    }

    const eventDate = moment(`${event.month}-${event.day}`, 'M-DD');
    const dateFormat = 'MMM Do';

    if (message.offset_type) {
      switch (message.offset_type) {
        case 'on':
          return eventDate.format(dateFormat);
        case 'before':
          return eventDate.subtract(message.date_offset, 'days').format(dateFormat);
        case 'after':
          return eventDate.add(message.date_offset, 'days').format(dateFormat);
        default:
          return eventDate.format(dateFormat);
      }
    } else {
      return eventDate.format(dateFormat);
    }
  }

  static formatTriggerDateTime(message: IMessageTemplate): string {
    const event = message.event;
    if (!event || !event.event_time) {
      return 'Not set';
    }

    const eventDate = moment.tz(event.event_time, DATE_TIME_MOMENT_FORMAT_STRINGS.NOVA_timezone);
    if (message.offset_type) {
      switch (message.offset_type) {
        case 'on':
          return eventDate.format(DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium);
        case 'before':
          return eventDate.subtract(message.date_offset, 'days').format(DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium);
        case 'after':
          return eventDate.add(message.date_offset, 'days').format(DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium);
        default:
          return eventDate.format(DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium);
      }
    } else {
      return eventDate.format(DATE_TIME_MOMENT_FORMAT_STRINGS.dateMedium);
    }
  }

}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type Event = IEvent;
    export type EventType = IEventType;
  }
}
