import { ILookupTablesState, State, Model } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { LOOKUP_TABLES_ACTION_TYPES, LOOKUP_TABLES } from './lookup-tables.action';

/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function LookupTablesReducer(state: ILookupTablesState = State.LookupTables, action: ActionWithPayload<any>): ILookupTablesState {
  switch (action.type) {
    case LOOKUP_TABLES_ACTION_TYPES.LOOKUP_SUCCESS: {
      // DISTRICTS, OBJECT_CODES
      const newState = {...state};
      newState.loadStatus = {...state.loadStatus};

      if (action.payload.lookupType) {
        const key = action.payload.lookupType;
        newState[key] = action.payload.items; // ex: state.districts
        newState.loadStatus[key + 'IsLoaded'] = true; // ex: state.loadStatus.sectorsIsLoaded
      }
      if (action.payload.lookupActionType) {
        const key = action.payload.lookupActionType;
        newState[key] = action.payload.items; // ex: state.lookupDurations
        newState.loadStatus[key] = true; // ex: state.loadStatus.lookupDurations
      }
      return newState;
    }

    case LOOKUP_TABLES_ACTION_TYPES.LOOKUP_INSTITUTIONS_SUCCESS: {
      /*
        The LOOKUP_TABLES.INSTITUTIONS object might contain differnt-but-overlapping sets of records:
        1. The CCC institutions (College, District, Region, State), 2. The CAEP consortia & members, or 3. ALL institutions.
        We treat the loading of these sets separately, because they can be loaded separately from the Service.
        NOTE that there is one array of institutions (state[LOOKUP_TABLES.INSTITUTIONS]) **but MULTIPLE**
          'IsLoaded' status flags (ex: CCC_INSTITUTIONS, CAEP_INSTITUTIONS).
       */
      const newState = {...state};
      newState.loadStatus = {...state.loadStatus};

      // If the last lookup action was a 'force', clear out any existing institution lookup data.
      if (action.payload.lookupPayload.force) {
        newState[LOOKUP_TABLES.INSTITUTIONS] = [];
        newState.loadStatus[LOOKUP_TABLES.CCC_INSTITUTIONS + 'IsLoaded'] = false;
        newState.loadStatus[LOOKUP_TABLES.CAEP_INSTITUTIONS + 'IsLoaded'] = false;
        newState.loadStatus[LOOKUP_TABLES.ALL_INSTITUTIONS + 'IsLoaded'] = false;
      }

      // Get status of what institutions were requested by the lookup action, and whether that data is already loaded.
      const loadCCC: boolean = !!action.payload.lookupPayload.ccc;
      const loadCAEP: boolean = !!action.payload.lookupPayload.caep;
      const loadALL: boolean = !!action.payload.lookupPayload.all;
      const loadSettings: boolean = !!action.payload.lookupPayload.include_settings;
      const isLoadedCCC: boolean = !!newState.loadStatus[LOOKUP_TABLES.CCC_INSTITUTIONS + 'IsLoaded'];
      const isLoadedCAEP: boolean = !!newState.loadStatus[LOOKUP_TABLES.CAEP_INSTITUTIONS + 'IsLoaded'];
      const isLoadedALL: boolean = !!newState.loadStatus[LOOKUP_TABLES.ALL_INSTITUTIONS + 'IsLoaded'];
      const isLoadedSettings: boolean = !!newState.loadStatus[LOOKUP_TABLES.SETTINGS_INSTITUTIONS + 'IsLoaded'];

      // Merge the new institution items with the list of existing items.
      const existingInstitutions = newState[LOOKUP_TABLES.INSTITUTIONS];
      const mergedInstitutions = _.concat(action.payload.items, existingInstitutions); // Order matters: Put newer items first.
      newState[LOOKUP_TABLES.INSTITUTIONS] = _.uniqBy(mergedInstitutions, inst => inst.id); // Uniquify by ID; first item wins.

      // Set the IsLoaded flags accordingly
      const gotALL = loadALL || isLoadedALL;
      newState.loadStatus[LOOKUP_TABLES.ALL_INSTITUTIONS + 'IsLoaded'] = gotALL;
      newState.loadStatus[LOOKUP_TABLES.CCC_INSTITUTIONS + 'IsLoaded'] = loadCCC || isLoadedCCC || gotALL;
      newState.loadStatus[LOOKUP_TABLES.CAEP_INSTITUTIONS + 'IsLoaded'] = loadCAEP || isLoadedCAEP || gotALL;
      newState.loadStatus[LOOKUP_TABLES.SETTINGS_INSTITUTIONS + 'IsLoaded'] = loadSettings || isLoadedSettings

      return newState;
    }

    case LOOKUP_TABLES_ACTION_TYPES.LOOKUP_UPDATE_INFO: {
      let match = false; // Assume the incoming item has no match until we find one
      const { table, id, info } = action.payload;

      const reduced = state[table].map(item => {
        // If we find a matching item in the table, update its info & set the match flag
        if (item.id === id) {
          match = true;
          return {
            ...item,
            ...info,
          }
        } else {
          return item;
        }
      });

      if (match) { // If we updated an existing item, return the updated array
        return {
          ...state,
          [table]: reduced,
        };
      } else { // Else this is a new item, so append it to the list
        return {
          ...state,
          [table]: [...state[table], info ]
        };
      }
    }

    default: {
      return state;
    }

  }
}
