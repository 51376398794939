/** Form Validator for Contacts tab
 */
import { PROJECT_ROLES, FUND_TYPES } from '@app-consts';
import { Model } from '@app-ngrx-domains';
import * as _ from 'lodash';

/** Validate that all form fields meet requirements. If so, return TRUE, else return FALSE.
 * @param contacts IContactsState object
 * @returns {boolean}
 */
export function validateStepData(contacts: any, budget: any, p: any): boolean {

  const validateIPlan = () => {
    // recategorize contacts by contact types.
    const contactByTypes = {
      [PROJECT_ROLES.PROJECT_LEAD.ID]: [],
      [PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID]: [],
      [PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]: [],
      [PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID]: [],
      [PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID]: [],
      [PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID]: [],
      [PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]: [],
    };
    for (let i = 0; i < contacts.items.length; i++) {
      const item = contacts.items[i];
      if (contactByTypes[item.role_id]) {
        contactByTypes[item.role_id].push(item);
      }
    }

    // now loop through to make sure that there is at least one, and if defined, that
    // there is a user defined.
    let valid = true;
    _.forOwn(contactByTypes, (items: Array<Model.UserRoleScope>, role_id) => {
      if (Number(role_id) === PROJECT_ROLES.ALTERNATE_PROJECT_LEAD.ID) {
        // there must be at least one.
        if (items.length < 1) {
          valid = false;
        } else {
          _.forEach(items, item => {
            if (!item.user_id) {
              valid = false;
              return false;
            }
          });
        }
      } else {
        // there could be more than one... just check the first one.
        if (items.length < 1) {
          valid = false;
        } else if (!items[0].user_id) {
          // no user associated with contact.
          valid = false;
        }
      }

      if (!valid) {
        // break from checking.
        return false;
      }
    });

    return valid;
  };

  const validateGP = () => {
    // recategorize contacts by contact types.
    const contactByTypes = {
      [PROJECT_ROLES.PROJECT_LEAD.ID]: [],
      [PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]: [],
      [PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]: [],
    };
    for (let i = 0; i < contacts.items.length; i++) {
      const item = contacts.items[i];
      if (contactByTypes[item.role_id]) {
        contactByTypes[item.role_id].push(item);
      }
    }

    // now loop through and make sure that there is at least one, and the user is defined.
    let valid = true;
    _.forOwn(contactByTypes, (items: Array<Model.UserRoleScope>, role_id) => {
      // there could be more than one... just check the first one.
      if (items.length < 1) {
        valid = false;
      } else if (!items[0].user_id) {
        // no user associated with contact.
        valid = false;
      }

      if (!valid) {
        // break from checking.
        return false;
      }
    });

    return valid;
  };

  const validateLVG = () => {
    const contactByTypes = {
      [PROJECT_ROLES.PROJECT_LEAD.ID]: [],
      [PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]: [],
      [PROJECT_ROLES.CEO.ID]: [],
      [PROJECT_ROLES.TRUSTEES_BOARD_PRESIDENT.ID]: [],
    };
    for (const item of contacts) {
      if (contactByTypes[item.role_id]) {
        contactByTypes[item.role_id].push(item);
      }
    }

    let valid = true;
    _.forOwn(contactByTypes, (items: Array<Model.UserRoleScope>, role_id) => {
      if (items.length < 1) {
        valid = false;
      } else if (!items[0].user_id) {
        valid = false;
      }
      if (!valid) {
        return false;
      }
    });
    return valid;
  };

  const validateSEP = () => {
    // recategorize contacts by contact types.
    const contactByTypes = {
      [PROJECT_ROLES.PROJECT_LEAD.ID]: [],
      [PROJECT_ROLES.CHANCELLOR_PRESIDENT.ID]: [],
      [PROJECT_ROLES.CHIEF_BUSINESS_OFFICER.ID]: [],
      [PROJECT_ROLES.CHIEF_INSTRUCTIONAL_OFFICER.ID]: [],
      [PROJECT_ROLES.CHIEF_STUDENT_SERVICES_OFFICER.ID]: [],
      [PROJECT_ROLES.PRESIDENT_ACADEMIC_SENATE.ID]: [],
    };

    for (let i = 0; i < contacts.items.length; i++) {
      const item = contacts.items[i];
      if (contactByTypes[item.role_id]) {
        contactByTypes[item.role_id].push(item);
      }
    }

    let valid = true;
    _.forOwn(contactByTypes, (items: Array<Model.UserRoleScope>, role_id) => {
      if (items.length < 1) {
        valid = false;
      } else if (!items[0].user_id) {
        valid = false;
      }

      if (!valid) {
        return false;
      }
    });

    return valid;
  };

  switch (p.project_type) {
    case FUND_TYPES.IPLAN:
      return validateIPlan();

    case FUND_TYPES.SEP:
      return validateSEP();

    case FUND_TYPES.GP:
      return validateGP();

    case FUND_TYPES.LVG:
      return validateLVG();


    default:
      return false;
  }

}
