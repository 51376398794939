import { ICfadAmendmentState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { CFAD_AMENDMENT_ACTION_TYPES } from './cfad-amendment.action';
import { TASK_TYPES, FUND_TYPES } from '@app-consts';
import { CfadAmendment } from '@app-models';

/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function CfadAmendmentReducer(state: ICfadAmendmentState = State.CfadAmendment, action: ActionWithPayload<any>): ICfadAmendmentState {
  switch (action.type) {
    case CFAD_AMENDMENT_ACTION_TYPES.LOAD: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CFAD_AMENDMENT_ACTION_TYPES.UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CFAD_AMENDMENT_ACTION_TYPES.UPDATE_TASK_SUCCESS: {
      // amendment year
      const duration_id = action.payload.duration_id;
      // new task belonging to the amendment year.
      const new_task = action.payload.task;
      // client state belonging to the amendment year.
      let new_client_state = state.amendments[duration_id].client_state;
      if (new_task.task_type === TASK_TYPES.CAEP_CFAD_AMENDMENT_SUBMIT) {
        // if submit is getting replaced, then update the client states.
        new_client_state = {
          ...new_client_state,
          ...new_task.client_state,
        };
      }

      // replace old task with new.
      const taskIndex = state.amendments[duration_id].tasks.findIndex(t => t.id === new_task.id);
      // and replace client state if needed.
      const reduced = {
        ...state.amendments[duration_id],
        tasks: [
          ...state.amendments[duration_id].tasks.slice(0, taskIndex),
          new_task,
          ...state.amendments[duration_id].tasks.slice(taskIndex + 1)
        ],
        client_state: new_client_state,
      };

      // now recalculate the state
      reduced['state_id'] = CfadAmendment.getStateFromTasks(reduced.tasks);

      return {
        ...state,
        amendments: {
          ...state.amendments,
          [duration_id]: reduced,
        }
      };
    }

    case CFAD_AMENDMENT_ACTION_TYPES.REFRESH_SUCCESS: {
      // amendment to reduce.
      const amendment = action.payload;
      // amendment year
      const duration_id = amendment.duration_id;

      return {
        ...state,
        amendments: {
          ...state.amendments,
          [duration_id]: amendment,
        }
      };
    }

    default:
      return state;
  }
}
