<fieldset class="fieldset">

  <legend class="legend" *ngIf="headingText" [textContent]="headingText" [class.required]="isRequired()" [class.screen-reader-text]="legendHidden"></legend>

  <ng-content select=".help-text"></ng-content>

  <ng-content></ng-content>

  <show-errors *ngIf="showErrors()" [control]="control" [showUntouched]="errorsOnUntouched" class="error-text"></show-errors>
</fieldset>
