export const OTHER_METRIC_ID = 103;
export const UNUSED_IG_REGXP = /multiple|unknown|masked/i;

export const TOP_CODES_HEALTH = [
  "120100", // Health Occupations, General
  "120200", // Hospital and Health Care Administration
  "120500", // Medical Laboratory Technology
  "120510", // Phlebotomy
  "120600", // Physicians Assistant
  "120800", // Medical Assisting
  "120810", // Clinical Medical Assisting
  "120820", // Administrative Medical Assisting
  "120830", // Health Facility Unit Coordinator
  "120900", // Hospital Central Service Technician
  "121000", // Respiratory Care/Therapy
  "121100", // Polysomnography
  "121200", // Electro-Neurodiagnostic Technology
  "121300", // Cardiovascular Technician
  "121400", // Orthopedic Assistant
  "121500", // Electrocardiography
  "121700", // Surgical Technician
  "121800", // Occupational Therapy Technology
  "121900", // Optical Technology
  "122000", // Speech-Language Pathology and Audiology
  "122100", // Pharmacy Technology
  "122200", // Physical Therapist Assistant
  "122300", // Health Information Technology
  "122310", // Health Information Coding
  "122400", // School Health Clerk
  "122500", // Radiologic Technology
  "122600", // Radiation Therapy Technician
  "122700", // Diagnostic Medical Sonography
  "122800", // Athletic Training and Sports Medicine
  "123000", // Nursing
  // (Asked to remove it on Jan 26, 2021) "123010", // Registered Nursing
  "123020", // Licensed Vocational Nursing
  "123030", // Certified Nurse Assistant
  "123080", // Home Health Aide
  "123900", // Psychiatric Technician
  "124000", // Dental Occupations
  "124010", // Dental Assistant
  "124020", // Dental Hygienist
  "124030", // Dental Laboratory Technician
  "125000", // Emergency Medical Services
  "125100", // Paramedic
  "125500", // Mortuary Science
  "126000", // Health Professions, Transfer Core Curriculum
  "126100", // Community Health Care Worker
  "126200", // Massage Therapy
  "127000", // Kinesiology
  "129900", // Other Health Occupations
];
