import * as _ from 'lodash';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { LaunchboardMetric, EnumErrorTypes } from '@app-models';

/**
 * Metrics Action types
 */
const ACTION_PREFIX = 'METRICS_';
export const METRICS_ACTION_TYPES = {
  LOAD_GP_METRICS: `${ACTION_PREFIX}LOAD_GP_METRICS`,
  LOAD_LVG_METRICS: `${ACTION_PREFIX}LOAD_LVG_METRICS`,
  LOAD_SEP_METRICS: `${ACTION_PREFIX}LOAD_SEP_METRICS`,
  LOAD_SWP_METRICS: `${ACTION_PREFIX}LOAD_SWP_METRICS`,
  LOAD_LB_METRICS: `${ACTION_PREFIX}LOAD_LB_METRICS`,
  LOAD_CAEP_METRICS: `${ACTION_PREFIX}LOAD_CAEP_METRICS`,
  LOAD_METRICS_SUCCESS: `${ACTION_PREFIX}LOAD_METRICS_SUCCESS`,
  RESET: `${ACTION_PREFIX}RESET`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`
};

/**
 * Metrics action class.
 */
export class MetricsActions {

  /** Initiates api call to fetch launchboard data
   * @param {Proposal} proposal
   * @returns {Action}
   */
  loadGP(proposal: Model.ProposalItem): ActionWithPayload<any> {

    return {
      type: METRICS_ACTION_TYPES.LOAD_GP_METRICS,
      payload: proposal
    };
  }

  loadLVG(institution_id: number): ActionWithPayload<any> {
    return {
      type: METRICS_ACTION_TYPES.LOAD_LVG_METRICS,
      payload: institution_id
    };
  }

  loadSEP(institution_id: number): ActionWithPayload<any> {
    return {
      type: METRICS_ACTION_TYPES.LOAD_SEP_METRICS,
      payload: institution_id
    };
  }

  loadSWP(institutionIds: Array<number>, durationIds?: Array<number>, metricDefinitionIds?: Array<number>): ActionWithPayload<any> {
    return {
      type: METRICS_ACTION_TYPES.LOAD_SWP_METRICS,
      payload: { institutionIds, durationIds, metricDefinitionIds }
    }
  }

  loadLB(institutionIds: Array<number>, durationIds?: Array<number>, metricDefinitionIds?: Array<number>): ActionWithPayload<any> {
    return {
      type: METRICS_ACTION_TYPES.LOAD_LB_METRICS,
      payload: { institutionIds, durationIds, metricDefinitionIds }
    }
  }

  loadCAEP(institutionIds: Array<number>, durationIds?: Array<number>, metricDefinitionIds?: Array<number>): ActionWithPayload<any> {
    return {
      type: METRICS_ACTION_TYPES.LOAD_CAEP_METRICS,
      payload: { institutionIds, durationIds, metricDefinitionIds }
    }
  }

  loadSuccess(metricType: string, metrics: Array<any>): ActionWithPayload<any> {
    return {
      type: METRICS_ACTION_TYPES.LOAD_METRICS_SUCCESS,
      payload: { metricType, metrics }
    };
  }

  /**
   * Resets metrics data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: METRICS_ACTION_TYPES.RESET,
      payload: null
    }

  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: METRICS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

};

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Metrics = new MetricsActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Metrics: MetricsActions;
  }
}
