import { Model } from '@app-ngrx-domains';
import { UserRoleScope, Task } from './index';
import { OBSCURED_USER_NAME } from '../consts';

// Interface for contact type objects found on funds table records.
export interface IContactType {
  id: number;
  name: string;
  fund_id?: number;
}

export interface IUser {
  readonly id: number;
  first_name: string;
  last_name: string;
  email_address: string;
  phone: string;
  phone_extension: string;
  title: string;
  prefix: string;
  suffix: string;
  fax: string;
  fax_extension: string;
  address: string;
  city: string;
  zip: string;
  address_2?: string;
  job_category_id?: number;
  institution_id?: number;
  name?: string;
  status?: string;
  last_login?: string;
  employer?: Model.Institution;
  scopes?: Array<Model.UserRoleScope>;
  tasks?: Array<Model.Task>;
  designee?: boolean;
}

export class Profile implements IUser {
  public id: number = undefined;
  public first_name: string = undefined;
  public last_name: string = undefined;
  public email_address: string = undefined;
  public phone: string = undefined;
  public phone_extension: string = undefined;
  public title: string = undefined;
  public prefix: string = undefined;
  public suffix: string = undefined;
  public fax: string = undefined;
  public fax_extension: string = undefined;
  public address: string = undefined;
  public city: string = undefined;
  public zip: string = undefined;
  public address_2: string = undefined;
  public job_category_id: number = undefined;
  public institution_id: number = undefined;
  public status: string = undefined;
  public name: string = undefined; // client user only
  public last_login: string;
  public employer: Model.Institution;
  public scopes: Array<Model.UserRoleScope> = [];
  public tasks: Array<Model.Task> = [];
  public designee?: boolean;

  constructor(raw?: any) {
    if (raw) {
      this.id = raw.id;
      this.first_name = raw.first_name;
      this.last_name = raw.last_name;
      this.email_address = raw.email_address;
      this.phone = raw.phone;
      this.phone_extension = raw.phone_extension;
      this.title = raw.title;
      this.prefix = raw.prefix;
      this.suffix = raw.suffix;
      this.fax = raw.fax;
      this.fax_extension = raw.fax_extension;
      this.address = raw.address;
      this.city = raw.city;
      this.zip = raw.zip;
      this.address_2 = raw.address_2;
      this.job_category_id = raw.job_category_id;
      this.institution_id = raw.institution_id;
      this.status = raw.status;
      this.employer = raw.employer;
      this.scopes = [];
      if (raw.scopes) {
        raw.scopes.forEach(scope => {
          this.scopes.push((new UserRoleScope(scope)).iObject<Model.UserRoleScope>());
        });
      }
      this.tasks = [];
      if (raw.tasks) {
        raw.tasks.forEach(task => {
          this.tasks.push((new Task(task)));
        });
      }
    }
  }

  serverObject(): any {
    const result: any = {};
    if (this.id) { result.id = this.id; }
    if (this.first_name) { result.first_name = this.first_name; }
    if (this.last_name) { result.last_name = this.last_name; }
    if (this.email_address) { result.email_address = this.email_address; }
    if (this.phone || this.phone === '') { result.phone = this.phone; }
    if (this.phone_extension || this.phone_extension === '') { result.phone_extension = this.phone_extension; }
    if (this.title || this.title === '') { result.title = this.title; }
    if (this.prefix || this.prefix === '') { result.prefix = this.prefix; }
    if (this.suffix || this.suffix === '') { result.suffix = this.suffix; }
    if (this.fax || this.fax === '') { result.fax = this.fax; }
    if (this.fax_extension || this.fax_extension === '') { result.fax_extension = this.fax_extension; }
    if (this.address || this.address === '') { result.address = this.address; }
    if (this.city || this.city === '') { result.city = this.city; }
    if (this.zip || this.zip === '') { result.zip = this.zip; }
    if (this.address_2 || this.address_2 === '') { result.address_2 = this.address_2; }
    if (this.job_category_id) { result.job_category_id = this.job_category_id; }
    if (this.institution_id) { result.institution_id = this.institution_id; }
    return result;
  }

  static contactFullName(contact: IUser, obscure = false) {
    if (contact) {
      return obscure
        ? OBSCURED_USER_NAME
        : (contact.prefix ? contact.prefix + ' ' : '') +
          (contact.first_name + ' ' + contact.last_name) +
          (contact.suffix ? ' ' + contact.suffix : '');
    } else {
      return '';
    }
  }

  static contactEmail(contact: IUser, obscure = false) {
    if (contact) {
      return obscure ? '' : contact.email_address;
    } else {
      return '';
    }
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ContactType = IContactType;
    export type User = IUser;
  }
}
