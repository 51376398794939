<div class="card card--no-padding" *ngIf="selectMsgList?.length; else empty">
    <form [formGroup]="form">
      <div class="padding">
        <!-- Select all -->
        <radio-check labelText="Select All">
          <input #selectAllCheckbox inputRef type="checkbox" class="checkbox" formControlName="select_all" id="qa_notifications_all_checkbox"
                 (change)="checkAll()"/>
        </radio-check>

        <button id="qa_delete_checked_notifications_button" [disabled]="!enableDeleteAllButton()" class="action-button action-button--primary icon--left"
                (click)="dismissCheckedMessages()">
          <icon iconId="delete"></icon>
          Delete Checked Notifications
        </button>
      </div>

      <po-action-list-item *ngFor="let msg of selectMsgList; let i = index"
        [isEmail]="isMessageEmail(msg)"
        [itemTitle]="formatContentTitle(msg)"
        [itemBody]="formatContentBody(msg)"
        [secondaryText]="formatDate(msg)"
        [deleteButtonText]="'Dismiss Notification'"
        [truncateBody]="true"
        [showIndicator]="true"
        [itemViewed]="msg.read"
        [clickable]="true"
        (deleteEvent)="dismissMessage(msg)">

        <radio-check [labelText]="msg.selectName" [labelHidden]="true">
          <input inputRef #checkbox (change)="checkMessage(checkbox.checked, msg)" type="checkbox" class="action-list-item__checkbox" formControlName="{{msg.selectName}}" id="qa_notifications_checkbox{{i}}"/>
        </radio-check>
      </po-action-list-item>
    </form>
</div>

<ng-template #empty>
  <div class="card">
    <p *ngIf="!selectMsgList">Loading notifications...</p>
    <div id="qa_no_notifications_text" *ngIf="selectMsgList?.length === 0" class="empty-state">You don't have any notifications.</div>
  </div>
</ng-template>
