import { IWorkplanState, Model, Query, Queries, Root, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IWorkplanState>('Workplan');

/**
 * Predefined queries to run against root states.
 */
export interface IWorkplanQueries {
  getItems: Query<Array<Model.WorkplanItem>>;
  getProposalId: Query<number>;
}

Queries.Workplan = {
  getItems: fromRoot(state => state.items),
  getProposalId: fromRoot(state => state.proposal_id),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    Workplan: Query<IWorkplanState>;
  }

  interface Queries {
    Workplan: IWorkplanQueries;
  }
}
