<div class="card">
  <div class="profile-card__info">
    <!-- Title -->
    <div class="card-title">
      <h4 id="{{formatDomQaIdFromRoleName()}}" class="header-title h4">
        {{userRole.name}}
        <span *ngIf="roleAliases">({{expandAliases()}})</span>
      </h4>
      <p class="form-help__text">{{userRole.data.description}}</p>
    </div>

    <!-- Roles -->
    <table class="po-table">
      <thead>
      <tr class=" align-headings--left">
        <th scope="col">Fund</th>
        <th scope="col">Institution</th>
        <th scope="col" *ngIf="showSectorColumn">Sector</th>
        <th scope="col" class="col-10-rem">Expiration Date</th>
        <th scope="col" class="col-4-rem" *ngIf="canEditRole">&nbsp;</th>
      </tr>
      </thead>
      <tbody id="qa_profile_roles_table{{count}}">
      <tr *ngFor="let roleScope of userRole.roleScopes; index as i">
        <td>{{ fundName(userRole.data, roleScope) }}</td>
        <td>{{ institutionName(userRole.data, roleScope) }}</td>
        <td *ngIf="showSectorColumn">{{ sectorName(userRole.data, roleScope) }}</td>
        <td>{{ expirationDate(userRole.data, roleScope) }}</td>
        <td class="align-center" *ngIf="canEditRole">
          <button id="qa_delete_role{{count}}_button{{i}}" class="action-button action-button--small action-button--tertiary icon-only" data-qa="delete-icon" (click)="removeRoleScope(roleScope)">
            <icon iconId="delete" class="icon--delete"></icon>
          </button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Permissions -->
    <div>
      <h5 class="h6">Permissions</h5>
      <ng-container [ngSwitch]="userRole.data.permissions.length > 0">
        <ul class="unordered-list" *ngSwitchCase="true">
          <li *ngFor="let permission of userRole.data.permissions; index as i" id="qa_table{{count}}_permissions{{i}}">
            <strong>{{permission.name}}</strong>: {{permission.actionsJoined}}
          </li>
        </ul>

        <p class="empty-state" *ngSwitchDefault>
          None have been assigned.
        </p>
      </ng-container>
    </div>
  </div>
</div>
