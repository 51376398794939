import { ISystemState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { SYSTEM_ACTION_TYPES } from './system.actions';

export function SystemReducer(state: ISystemState = State.System, action: ActionWithPayload<any>): ISystemState {
  switch (action.type) {
    case SYSTEM_ACTION_TYPES.SET_MAINTENANCE:
      return {
        ...state,
        maintenanceStatus: action.payload.maintenanceStatus
      };
    default:
      return state;
  }
}
