
import { withLatestFrom } from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Model, State, Queries } from '@app-ngrx-domains';
import { environment } from '../../../../environments/environment'
import { ApiService, TitleService } from '../../services';
import { Utilities, ResourceNote, Resource, IResourceNote } from '../../models';
import { ROUTER_LINKS, TIMEOUT_DELAYS } from '../../consts';

@Component({
  selector: 'content-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() printMode = false;

  public headerInfo: Model.HeaderLayout = { ...State.Layout.header };
  public headerMessage: Model.StatusMessage;
  public isBookmarkable: boolean;
  public activeBookmark: boolean;
  public logo = environment.logos.header;
  private bookmark: Model.ResourceNote;

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
    public router: Router,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.store.select(Queries.Layout.getHeader).pipe(
      withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)))
      .subscribe(([header, userId]) => {
        this.headerInfo = header;
        this.updateBrowserTitle();
        this.bookmark = undefined;
        this.activeBookmark = false;
        this.isBookmarkable = !!this.headerInfo.resource;
        if (this.isBookmarkable && userId) {
          this.initializeBookmarkData(userId);
        }
      },
      this.handleError);

    this.store.select(Queries.Layout.getHeaderMessage)
      .subscribe((message: Model.StatusMessage) => {
      this.headerMessage = message;
      if (message.type === 'fail' || message.type === 'success') {
        this.clearHeaderMessage();
      }
    });
  }

  get headerString() {
    let str = '';
    if (this.headerInfo.pre_title) {
      str += ` ${this.headerInfo.pre_title}`;
    }
    if (this.headerInfo.title) {
      str += ` ${this.headerInfo.title}`;
    }
    if (!this.headerInfo.title && !this.headerInfo.pre_title) {
      str += ' Invest & Plan for Student Success';
    }
    return str;
  }

  updateBrowserTitle() {
    let str = 'NOVA: ';
    str += this.headerString;
    this.titleService.setTitle(str);
  }

  showHeaderLink() {
    const routes = ['/help', '/passwordReset', '/forgotPassword', '/sign-up', ROUTER_LINKS.MAINTENANCE];
    return routes.reduce((show, route) => show || this.router.url.startsWith(route), false);
  }

  showHeaderMessage() {
    return this.headerMessage.type && this.headerMessage.type.length;
  }

  private clearHeaderMessage() {
    setTimeout(() => {
      this.store.dispatch(Actions.Layout.updateHeaderMessage());
    }, TIMEOUT_DELAYS.MEDIUM);
  }

  addBookmark() {
    const payload = {
      ...this.headerInfo.resource,
      note: { deleted: false, type: 'bookmark' }
    };

    const callback = (response: ResourceNote) => {
      this.bookmark = response;
      this.activeBookmark = true;
    };

    this.apiService.upsertResourceNote(payload)
      .subscribe(
        callback,
        this.handleError);
  }

  handleError = Utilities.setCallbackError(this.store, this.constructor.name);

  initializeBookmarkData(currentUser: number = null) {
    const note_info = this.headerInfo.resource;
    delete note_info.id;

    if (note_info) {
      this.apiService.getBookmarks(note_info)
        .subscribe((response: Array<IResourceNote>) => {
          const bookmark = response.find(r => Resource.compareResponse(note_info, r));
          if (bookmark) {
            this.activeBookmark = !bookmark.deleted;
            this.bookmark = bookmark;
          }
        }, this.handleError)
    }
  }

  removeBookmark() {
    const payload = {
        ...this.headerInfo.resource,
      note: { deleted: true, type: 'bookmark' },
    }
    this.apiService.upsertResourceNote(payload)
      .subscribe(() => {
          this.activeBookmark = false;
        },
        this.handleError
      );
  }
}
