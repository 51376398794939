import { ICfadState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { CFAD_ACTION_TYPES } from './cfad.action';

/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function CfadReducer(state: ICfadState = State.Cfad, action: ActionWithPayload<any>): ICfadState {
  switch (action.type) {
    case CFAD_ACTION_TYPES.LOAD: {
      return {
        ...action.payload,
      };
    }

    case CFAD_ACTION_TYPES.REFRESH_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload,
        }
      };
    }

    case CFAD_ACTION_TYPES.UPDATE_FIELD_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          [action.payload.name]: action.payload.value,
        }
      };
    }

    case CFAD_ACTION_TYPES.UPDATE_STATE_SUCCESS: {
      const taskIndex = _.findIndex(state.current.tasks, (t => t.id === action.payload.id));
      let client_state = state.current.client_state;

      const task = state.current.tasks[taskIndex];
      if (task.task_type === 'aebg_cfad_submit') {
        // rehydrate client states.
        client_state = {
          ...client_state,
          ...action.payload.client_state,
        };
      }

      return {
        ...state,
        current: {
          ...state.current,
          tasks: [
            ...state.current.tasks.slice(0, taskIndex),
            action.payload,
            ...state.current.tasks.slice(taskIndex + 1)
          ],
          client_state: client_state,
        }
      };
    }

    default:
      return state;
  }
}
