import { Injectable } from '@angular/core';
import { State, Actions as SystemActions} from '@app-ngrx-domains';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '../services';
import { SYSTEM_ACTION_TYPES } from './system.actions';


@Injectable()
export class SystemEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private apiService: ApiService
  ) {}

  @Effect() refreshMaintenance$ = this.actions$
    .pipe(
      ofType(SYSTEM_ACTION_TYPES.REFRESH_MAINTENANCE),
      switchMap(() => this.apiService.getMaintenanceStatus()),
      map(maintenanceStatus => SystemActions.System.setMaintenance(maintenanceStatus))
    )
}
