import { ModelBase } from './model.base';

export interface IPermResourceScope {
  fund_id?: any;
  institution_id?: any;
  proposal_id?: any;
  user_id?: any;
}

export interface IPermResourceItem extends IPermResourceScope {
  area: string;
  action: string;
}

export interface IPermResourceResponseItem extends IPermResourceScope {
  area: string;
  action: string;
  allowed: boolean;
  resource_id: string;
}

export class PermissionResource extends ModelBase implements IPermResourceResponseItem {
  public area: string;
  public action: string;
  public fund_id: number;
  public institution_id: number;
  public proposal_id: number;
  public user_id: number;
  public allowed: boolean;
  public resource_id: string;

  constructor(raw?: any) {
    super();

    if (raw) {
      this.area = raw.area;
      this.action = raw.action;
      this.allowed = raw.allowed;
      this.resource_id = raw.resource_id;
      this.fund_id = raw.type;
      this.institution_id = raw.type;
      this.proposal_id = raw.type;
      this.user_id = raw.user_id;
    }
  }

  static completeObject(resource: IPermResourceItem) {
    resource.fund_id = resource.fund_id || null;
    resource.institution_id = resource.institution_id || null;
    resource.proposal_id = resource.proposal_id || null;
    resource.user_id = resource.user_id || null;
    return resource;
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type PermResourceScope = IPermResourceScope;
    export type PermResourceItem = IPermResourceItem;
    export type PermResourceResponseItem = IPermResourceResponseItem;
  }
}
