/** Useful details on writing custom validators here:
 * https://blog.thoughtram.io/angular/2016/03/14/custom-validators-in-angular-2.html
 */
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';
import { Utilities } from '../models/index';

export class ValidatorsEx {

  static date(c: FormControl) {
    const DATE_REGEXP = /[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/;

    return DATE_REGEXP.test(c.value) ? null : {
      date: {
        message: 'Invalid date'
      }
    };
  }

  static nonZero(c: FormControl) {
    return c.value && (Math.abs(c.value) > 0) ? null : {
      nonZero: {
        message: 'Value can\'t be 0'
      }
    };
  }

  static sumsToAmount(amount: number) {
    const result = (group: FormGroup) => {
      const sum = Object.values(group.controls).reduce((total, control) => total + (Number(control.value) || 0), 0);
      if (sum !== amount) {
        return { validationError: `Amounts must sum to ${amount}` };
      } else {
        return null;
      }
    }
    return result;
  }

  static positiveIfDefined(c: FormControl) {
    if (c.value && c.value < 0) {
      return { validationError: 'Value cannot be negative.' };
    }
  }

  static requiredSelection(c: FormControl) {
    const value = c.value;
    const valid = Array.isArray(value)
      ? !!(value && value.length)
      : !!value;
    if (!valid) {
      return {
        requiredCheck: {
          message: 'You must make a selection'
        }
      }
    }
  }

  static requiredCheck(c: FormControl) {
    return c.value && (c.value !== 0) ? null : {
      requiredCheck: {
        message: 'You must check this box'
      }
    };
  }

  static requiredCheckGroup(g: FormGroup) {
    const valid = Object.values(g.controls).some(control => {
      return control && !!control.value;
    });

    return valid ? null
    : { requiredCheck: { message: 'You must check at least one box' } };
  }

  static requireOne(numeric = false) {
    const result = (g: FormGroup) => {
      const reduced = Object.keys(g.controls).map(key => g.get(key).value).reduce((acc, curr) => acc || curr);
      const value = numeric ? Number(reduced) : reduced;
      return value ? null : { requireOne: { message: 'At least one field is required' }};
    }
    return result;
  }

  static requireAmount(formGroup: FormGroup) {
    if (Object.values(formGroup.value).every(value => !value)) {
      return { validationError: 'Amount cannot be $0' };
    }
  }

  static EMAIL_REGEXP() {
    // The following regexp is from https://www.w3resource.com/javascript/form/email-validation.php
    const result = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return result;
  }

  static emailString(s: string) {
    return (s && ValidatorsEx.EMAIL_REGEXP().test(s));
  }

  static email(c: FormControl) {
    if (c.value && !ValidatorsEx.EMAIL_REGEXP().test(c.value)) {
      return {
        email: {
          message: 'Not a valid email'
        }
      };
    }

    return null;
  }

  /**
   * Checks validity of one or more emails, separated by commas.
   * @param {FormControl} c
   * @returns
   * @memberof ValidatorsEx
   */
  static emails(c: FormControl) {
    const emails = c.value;
    let valid = emails && emails.length && (emails.length > 0);
    if (valid) {
      valid = emails.every(emails.split(/, */).map((email) => ValidatorsEx.emailString(email)));
    }

    return valid ? null : {
      emails: {
        message: 'All emails are not valid'
      }
    };
  }

  static programKey(c: FormControl) {
    if (!/^[a-zA-Z0-9_-]*$/.test(c.value)) {
      return { validationError: 'This value must only contain alphanumeric characters, underscores, and dashes' };
    }
  }

  /* Validator for password field.
    - Field is required
    - Password must be at least 6 characters
  */
  static password(c: FormControl) {
    const password = c.value;
    if (!password) {
      return { required: true };
    } else if (password.length < 6) {
      return { minlength: { requiredLength: 6 }};
    } else {
      return null;
    }
  }

  /* Validator for a password & password confirmation set.
    - The values must match
  */
  static passwords(passwordKey: string, confirmPasswordKey: string) {
    const result = (group: FormGroup): { [key: string]: any } => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: {
            message: 'Passwords don\'t match'
          }
        };
      }

      return null;
    };
    return result;
  }

  static alphaChars(allowSpaces = false) {
    const result = (c: FormControl): { [key: string]: any } => {
      const ALPHA_REGEXP = allowSpaces ? /^[a-zA-Z ]+$/ : /^[a-zA-Z]+$/;
      if (c.value && !ALPHA_REGEXP.test(c.value)) {
        return {
          alphaChars: {
            message: `Value must contain only alphabet characters${allowSpaces ? ' and spaces' : ''}`
          }
        }
      }
    }
    return result;
  }

  static noSpaces(c: FormControl) {
    if (c.value && /\s/.test(c.value)) {
      return { validationError: 'Short name cannot contain spaces' };
    }
  }

  static url(c: FormControl) {
    const URL_REGEXP = /^((?:http|ftp)s?:\/\/)?(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::\d+)?(?:\/?|[\/?]\S+)$/i;

    if (c.value && !URL_REGEXP.test(c.value)) {
      return {
        url: {
          message: 'Not a valid URL'
        }
      };
    }

    return null;
  }

  static zipCode(c: FormControl) {
    const ZIPCODE_REGEXP =  /(^\d{5}$)|(^\d{5}-\d{4}$)/;

    if (c.value && !ZIPCODE_REGEXP.test(c.value)) {
      return {
        zipCode: {
          message: 'Not a valid Zip Code'
        }
      };
    }

    return null;
  }

  static emailTo(group: FormGroup): ValidationErrors | null {
    const funds = group.get('fund_ids');
    const employers = group.get('employers');
    const roles = group.get('role_ids');

    return funds.value.length || employers.value.length || roles.value.length ?
      null :
      {
        emailTo: {
          message: 'Select at least one fund, employer, or role'
        }
      };
  }

  static eventDate(group: FormGroup): ValidationErrors | null {
    const offset_type = group.get('offset_type');
    const date_offset = group.get('date_offset');

    if (offset_type.value === 'on') {
      return null;
    } {
      return !(date_offset.value && offset_type.value) ?
        {
          eventDate: {
            message: 'Select a number of days and when'
          }
        } :
        null;
    }
  }

  static matDatepickerMin(minDateString: string) {
    const result = (group: FormGroup): { [key: string]: any } => {
      const control: any = group.get('date');
      const date = moment.utc(control.value);
      const minDate = moment.utc(minDateString);
      if (minDate.isAfter(date)) {
        return {
          matDatepickerMin: {
            min: minDateString
          }
        }
      }
      return null;
    };
    return result;
  }

  static minDate(minDateString: string) {
    const result = (c: FormControl): ValidationErrors | null => {
      const date = moment.utc(c.value);
      const minDate = moment.utc(minDateString);
      if (minDate.isAfter(date)) {
        return {
          matDatepickerMin: { min: minDateString }
        }
      }
    }
    return result;
  }

  static phoneNumber(c: FormControl): ValidationErrors | null {
    const phone_regex = new RegExp(/^((\+?\d ?)?\(?\d{3}\)?)? ?(\d{3})-?(\d{4})$/);
    if (c.value && !phone_regex.test(c.value)) {
      return {
        phoneNumber: {
          message: 'Invalid number format'
        }
      };
    }
    return null;
  }

  static employerIdNumber(c: FormControl): ValidationErrors | null {
    const ein_regex = new RegExp(/^[0-9]{2}-[0-9]{7}$/);
    if (c.value && !ein_regex.test(c.value)) {
      return {
        employerIdNumber: {
          message: 'Invalid Employer Identification Number(EIN).'
        }
      };
    }
    return null;
  }

  static extension(c: FormControl): ValidationErrors | null {
    const result = /^\d*$/.test(c.value) ? null : {
      extension: {
        message: 'Extension must be number'
      }
    };
    return result;
  }

  static beforeDate(c: FormControl): ValidationErrors | null {
    const today = new Date();
    const value = new Date(c.value);
    return value <= today ? null : {
      dateBefore: {
        message: 'The date must be today or before today'
      }
    };
  }

  static decimalNumber(c: FormControl): ValidationErrors | null {
    const decimal_regex = new RegExp(/^[0-9]+(.[0-9]{0,2})?$/);
    if (c.value && !decimal_regex.test(c.value)) {
      return {
        decimalNumber: {
          message: 'Invalid number format'
        }
      };
    }
    return null;
  }

  static textCharMinimum(minLength: number, isOptional?: boolean) {
    const result = (c: FormControl): ValidationErrors | null => {
      const text = c.value;
      if (isOptional && (!text || !text.length)) {
        return null;
      } else if (!text || (text.length < minLength)) {
        return {
          minlength: {
            requiredLength: minLength
          }
        };
      }
    }
    return result;
  }

  static htmlCharMinimum(minLength: number, isOptional?: boolean) {
    const result = (c: FormControl): ValidationErrors | null => {
      const text = Utilities.stripTags(c.value);
      if (isOptional && !text.length) {
        return null;
      } else if (text.length < minLength) {
        return {
          minlength: {
            requiredLength: minLength
          }
        };
      }
    }
    return result;
  }

  static htmlCharMaximum(maxLength: number) {
    const result = (c: FormControl): ValidationErrors | null => {
      const text = Utilities.stripTags(c.value);
      if (text.length > maxLength) {
        return {
          maxlength: {
            requiredLength: maxLength
          }
        };
      }
    }
    return result;
  }

  static endDateValidator(startDateKey: string, endDateKey: string, minDurationYear = 0) {
    const result = (group: FormGroup): { [key: string]: any } => {
      const startDate = moment.utc(group.controls[startDateKey].value).add(minDurationYear, 'years');
      const endDate = moment.utc(group.controls[endDateKey].value);
      if (startDate.isAfter(endDate)) {
        return {
          validationError: (minDurationYear >= 1)
            ? `The end date must be minimum of ${minDurationYear} ${minDurationYear === 1 ? 'year' : 'years' } after the start date.`
            : 'The end date cannot be before the start date.'
          };
      }
      return null;
    };
    return result;
  }

  static conditionalValidators(conditionChecker: Function, validators: Array<Function>) {
    const result = (control: FormControl) => {
      if (conditionChecker && conditionChecker()) {
        for (const validator of validators) {
          const invalid = validator(control);
          if (invalid) {
            return invalid;
          }
        }
      }
    }
    return result;
  }
}
