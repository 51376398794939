import * as _ from 'lodash';
import { FUND_TYPES, WORKPLAN_TYPES } from '@app-consts';

/** Form Validator for Workplan3 tab
 */
export function validateStepData(w: any): boolean {

  const validateGP = () => {
    for (let i = 0; i < w.items.length; i++) {
      const item = w.items[i];
    }

    return true;
  }

  switch (w.proposal.project_type) {
    case FUND_TYPES.GP:
      return validateGP();

    default:
      return false;
  }
}
