export const APPLICATION_FUNDING_MINIMUM = 1000;

export interface IProposalFundingClientState {
  task_id?: number;
  ada_total: number;
  requested_amount: number;
  score: number;
  rank: number;
  offered_amount: number;
  accepted_amount: number;
}

export interface IProposalFundingInfo {
  proposal_id: number;
  title: string;
  status: string;
  status_display?: string;
  lead_institution: any;
  client_state: IProposalFundingClientState;
  error?: string;
  eligible_for_tiebreaker?: boolean;
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ProposalFundingClientState = IProposalFundingClientState;
    export type ProposalFundingInfo = IProposalFundingInfo;
  }
}
