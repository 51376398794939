import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Model, Queries, Actions } from '@app-ngrx-domains';
import { forOwn, sortBy, groupBy, find, uniq, isEmpty, isNumber as _isNumber } from 'lodash';
import { ApiService } from './api.service';
import { ProgramService } from './program.service';
import { AppUtils } from '../utilities';
import {
  DURATION_TYPES, FUND_TYPES, INSTITUTION_TYPES, LEA_TYPES, METRIC_GROUPS, PROGRAM_KEYS, PROJECT_ROLES,
  PROTECTED_ROLE_IDS, SYSTEM_ROLES, TASK_TYPES, UNRESTRICTED, UNUSED_IG_REGXP
} from '../consts';
import { FILTER_ALL_MATCH_OPTION, Fund, Proposal,  PROPOSAL_TYPE_NAMES } from '../models';
import * as moment from 'moment';
import { LOOKUP_TABLES } from '../state-management/lookup-tables.action';

/**
 * This is a lookup service, that holds onto core level lookup tables as snapshots that
 * can be accessed within the system, and provides functions to access them.
 * NOTE: LookupService doesn't execute redux actions to fetch lookup data; somebody else (ex: LookupTablesResolver)
 * still has to initiate the lookups from the service.
 */
@Injectable()
export class LookupService {

  private snapshots: {
    durations?: Array<Model.Duration>,
    durationsGrouped?: { [year: number]: Array<Model.Duration> },
    years?: Model.LookupYears,
    institutions?: Array<any>,
    institution_regions?: { [institution_id: number]: any },
    regions?: Array<Model.Institution>,
    roles?: Array<Model.RolePermissions>,
    metric_definitions?: { raw: Array<any>, uniques: any, other: any },
    impacted_groups?: { raw: Array<any>, groups: any, lvg: any },
  } = {};

  _recentInstitutionLookup: Array<Model.Institution> = [];

  constructor(
    private store: Store<State>,
    private apiService: ApiService,
    private programService: ProgramService,
  ) {
    // listen for roles and snapshot its content.
    this.store.select(Queries.LookupTables.getRoles).subscribe(roles => {
      // keep the list sorted by long_name.
      this.snapshots.roles = roles.filter(r => r.long_name); // filter out ones with no names.
      this.snapshots.roles = sortBy(this.snapshots.roles, ['long_name']);
    });
    this.store.dispatch(Actions.LookupTables.doLookup(LOOKUP_TABLES.ROLES));

    // listen for institutions and snapshot its content.
    this.store.select(Queries.LookupTables.getInstitutionsRaw).subscribe(institutions => {
      this.snapshots.institutions = institutions;
      // build out institutional regions.
      this.snapshots.institution_regions = {};
      this.snapshots.regions = [];
      institutions.forEach(inst => {
        if (inst.type === INSTITUTION_TYPES.RC) {

          this.snapshots.regions.push(inst);

          this.snapshots.institution_regions[inst.id] = {
            ...inst,
            collegeIds: this.regionColleges(institutions, inst.id, true).map(college => (college.id)),
          };
        }
      });
    });
    // Request fundamental institutions (State of California & CCCCO Chancellor's Office) to seed
    this.apiService.listInstitutions({ type: INSTITUTION_TYPES.STATE }).subscribe(res => {
      const stateOfCalifornia = res ? res[0] : { id: 252, type: INSTITUTION_TYPES.STATE }; // We could set null, but seeding 252 is a safe bet.
      this.store.dispatch(Actions.LookupTables.updateLookup(LOOKUP_TABLES.INSTITUTIONS, stateOfCalifornia.id, stateOfCalifornia));
    });
    this.apiService.listInstitutions({ type: INSTITUTION_TYPES.EMPLOYER, name: 'Chancellor\'s Office' }).subscribe(res => {
      if (res && res[0]) {
        const co = res[0];
        this.store.dispatch(Actions.LookupTables.updateLookup(LOOKUP_TABLES.INSTITUTIONS, co.id, co));
      }
    });

    // listen for fund years and snapshot its content.
    // NOTE: The getYears query maps years to their duration objects (ex: { 2019: {...}, 2020: {...}, ... },
    //       which is why we don't just filter this.durations for the year durations.
    this.store.select(Queries.LookupTables.getYears).subscribe(years => {
      this.snapshots.years = years;
    });

    // listen for durations and snapshot its content.
    this.store.select(Queries.LookupTables.getDurations).subscribe(durations => {
      this.snapshots.durations = durations;
      this.snapshots.durationsGrouped = this.groupDurationsByYearByType(durations);
      // hydrate years.
      this.snapshots.years = {};
      durations.forEach(duration => {
        if (duration.type === DURATION_TYPES.YEAR) {
          this.snapshots.years[duration.id] = duration;
        }
      });
    });

    this.store.select(Queries.LookupTables.getMetricsDefinitions).subscribe(defs => {
      this.snapshots.metric_definitions = { uniques: {}, raw: [], other: null };
      this.snapshots.metric_definitions.raw = defs;
      (defs || []).forEach(def => {
        if (def.uniq) {
          this.snapshots.metric_definitions.uniques[def.uniq] = this.snapshots.metric_definitions.uniques[def.uniq] ?
            this.snapshots.metric_definitions.uniques[def.uniq].concat(def) : [def];
        } else if (def.name === 'Other') {
          this.snapshots.metric_definitions['other'] = def;
        }
      });
    });

    this.store.select(Queries.LookupTables.getImpactedGroups).subscribe(groups => {
      const tmp: any = {};
      tmp.raw = groups;
      tmp.groups = {};
      tmp.lvg = {};
      groups.forEach(group => {
        if (group.group === METRIC_GROUPS.LVG) {
          if (!tmp.lvg[group.type]) {
            tmp.lvg[group.type] = [];
          }
          if (!UNUSED_IG_REGXP.test(group.name)) {
            tmp.lvg[group.type].push(group);
          }
        } else {
          if (!tmp.groups[group.type]) {
            tmp.groups[group.type] = [];
          }
          tmp.groups[group.type].push(group);
        }
      });
      this.snapshots.impacted_groups = tmp;
    })
  }

  /************************************************************
   * Funding/Program Years lookup table helper functions
   ************************************************************/

  /**
   * Returns fund years' lookup table.
   */
  get years(): Model.LookupYears {
    try {
      return this.snapshots.years;
    } catch (e) {
      return {};
    }
  }

  public getYearSelectOptions(): Array<Model.SelectOption> {
    return Object.values(this.years).map(year => ({ value: year.id, label: year.name }));
  }

  public getMappedYears(baseYearDurationId: number, span?: number) {
    if (!baseYearDurationId) {
      // pick current fiscal year
      baseYearDurationId = this.getCurrentFiscalYear().year;
    }
    let maxYearDurationId: number;
    if (span !== undefined) {
      maxYearDurationId = baseYearDurationId + span;
    }

    return Object.values(this.years)
      .filter(year => year.id >= baseYearDurationId && (!maxYearDurationId || year.id <= maxYearDurationId))
      .map(year => ({ value: year.id, label: year.name }));
  }

  /**
   * Returns all defined durations known to NOVA.
   */
  get durations(): Array<Model.Duration> {
    return this.snapshots.durations;
  }

  /**
   * Returns duration id of given year and quarter.
   * @param year
   * @param quarter
   */
  quarterDurationId(year: number, quarter: number): number|null {
    try {
      return this.snapshots.durationsGrouped[year][DURATION_TYPES.QUARTER][quarter].id;
    } catch (e) {
      return null;
    }
  }

  /**
   * Returns year's duration object.
   */
  getYear(yearDurationId: number): Model.Duration {
    try {
      return this.snapshots.durationsGrouped[yearDurationId][DURATION_TYPES.YEAR];
    } catch (e) {
      return undefined;
    }
  }

  /**
   * Converts a duration_id to its year string. Ex:  2018 => '2017-18'
   */
  getYearName(yearDurationId: number): string {
    try {
      return this.snapshots.durationsGrouped[yearDurationId][DURATION_TYPES.YEAR].name;
    } catch (e) {
      return 'Undefined year';
    }
  }

  /**
   * Convert a duration id to the associated year string: 16 => 2017 => '2016-2017'
   */
  getYearNameByDuration(durationId: number): string {
    const duration = this.snapshots.durations.find(dur => dur.id === durationId);
    if (duration && duration.year) {
      return this.getYearName(duration.year)
    } else {
      return '';
    }
  }

  getYearByDuration(durationId: number): number {
    const duration = this.snapshots.durations.find(dur => dur.id === durationId);
    if (duration && duration.year) {
      return duration.year;
    } else {
      return null;
    }
  }

  /**
   * Returns durations by type.
   * @param type
   */
  getDurationsByType(type: string) {
    try {
      return this.snapshots.durations.filter(d => d.type === type);
    } catch (e) {
      return [];
    }
  }

  /**
   * Returns durations groups by year then by type. Structure looks like:
   * durations[2019].annual == { id: 2019, type: 'annual', name: '2018-2019', ... }
   * durations[2019].quarter[1] == { id: 2019001, type: 'quarter', name: 'Quarter 1', ... }
   * durations[2019].generic[66] == { id: 201900066, type: 'generic', name: 'Period 66', ... }
   */
  groupDurationsByYearByType(allDurations: Array<Model.Duration>) {
    const durationsByYear = groupBy(allDurations, 'year'); // Output ex: { 2017: [...], 2018: [...], ... }
    const durationsByYearByType = {};
    const groupQuartersByYear = (year: number) => {
      const quarters = {};
      [1, 2, 3, 4].forEach(
        q => quarters[q] = allDurations.find(d => d.type === DURATION_TYPES.QUARTER && d.year === year && d.quarter === q) || {}
      );
      return quarters;
    };

    // Iterate over every year, and group durations by type
    Object.keys(durationsByYear).forEach((yearStr: string) => {
      const year = parseInt(yearStr, 10);
      durationsByYearByType[year] = {
        [DURATION_TYPES.YEAR]: durationsByYear[yearStr].find(d => d.type === DURATION_TYPES.YEAR),
        [DURATION_TYPES.QUARTER]: groupQuartersByYear(year),
        [DURATION_TYPES.PERIOD]: durationsByYear[yearStr].filter(d => d.type === DURATION_TYPES.PERIOD), // NOTE: This assumes generic periods, if any exist, are zero-indexed and the 0th element is defined.
      };
    });
    return durationsByYearByType;
  }

  /**
   * Returns current fiscal year.
   */
  getCurrentFiscalYear(): Model.Duration {
    const now = moment();
    const currentFiscalYear = this.snapshots.durations.find(d => d.type === DURATION_TYPES.YEAR && now.isBetween(d.start_date, d.end_date, 'day', '[]'));
    return currentFiscalYear;
  }

  /**
   * Returns fiscal year the date falls within.
   */
  getFiscalYear(date: string): Model.Duration {
    const check = moment.utc(date);
    const fiscalYear = this.snapshots.durations.find(d => d.type === DURATION_TYPES.YEAR && check.isBetween(d.start_date, d.end_date, 'day', '[]'));
    return fiscalYear;
  }

  /**
   * Returns allocation years associated with the program by looking at the state allocations submit tasks.
   */
  getProgramAllocationYearOptions(fund_id: number, base_year: number, includeAll = false): Observable<Array<Model.SelectOption>> {
    return this.apiService.listTasks({ fund_id, task_type: TASK_TYPES.STATE_ALLOCATIONS_SUBMIT }, true).pipe(
      map((tasks: Array<Model.Task>) => {
        let yearOptions: Array<Model.SelectOption> = includeAll ? [FILTER_ALL_MATCH_OPTION] : [];
        if (!!tasks) {
          tasks.sort((a, b) => a.duration_id > b.duration_id ? -1 : 1); // sort the task in descending order.
          tasks.shift(); // pop latest, as it's set for next year
          tasks.forEach(t => {
            yearOptions.push({
              value: t.duration_id,
              label: this.getYearName(t.duration_id),
            });
          });
          const earliestTaskYear = tasks[tasks.length - 1].duration_id;
          if (base_year && earliestTaskYear > base_year) {
            // year needs to go before allocation tasks were available
            for (let year = earliestTaskYear - 1; year >= base_year; year--) {
              yearOptions.push({
                value: year,
                label: this.getYearName(year),
              })
            }
          }
        }
        return yearOptions;
      })
    );
  }
  /************************************************************
   * Institutions lookup table helper functions
   ************************************************************/

  /**
   * Returns institution object from the snapshot.
   * @param {number} institutionId
   * @returns {*}
   */
  getInstitution(institutionId: number): any {
    if (institutionId) {
      return this.snapshots.institutions.find((i) => { return i.id === institutionId; });
    }
  }

  /**
   * Returns institution's name from the snapshot.
   * @param {number} institutionId
   * @returns {*}
   */
  getInstitutionName(institutionId: number): string {
    const inst = this.getInstitution(institutionId);
    return (inst) ? inst.name : '';
  }

  getRegions(): Array<Model.Institution> {
    return this.snapshots.regions;
  }

  getRegionOptions(): Array<Model.SelectOption> {
    return this.snapshots.regions
      .map(r => ({ value: r.id, label: r.name, extras: r}))
      .sort((a, b) => a.label > b.label ? 1: -1);
  }

  getDistricts(): Array<Model.Institution> {
    return this.snapshots.institutions.filter(inst => inst.type === INSTITUTION_TYPES.CCD);
  }

  getInstitutionHierarchy(institutionId: number): Model.InstitutionHierarchy {
    const institution = this.snapshots.institutions.find(inst => inst.id === institutionId);
    if (institution) {
      return institution.hierarchy;
    }
  }

  /**
   * Returns regions and the colleges & districts belonging to each region.
   * @readonly
   * @type {*}
   */
  getInstitutionRegions(): { [institution_id: number]: any } {
    return this.snapshots.institution_regions;
  }

  /**
   * Returns district's region.
   * @param districtId
   */
  getDistrictRegion(districtId: number): Model.Institution {
    const inst = this.getInstitution(districtId);
    return inst && inst.parent && inst.parent.length ? inst.parent[0] : undefined;
  }

  /**
   * Returns region's child institutions.
   * @param region
   * @param includeTypes
   */
  getRegionChildInstitutions(region: any, includeTypes: Array<string>): Array<Model.SelectOption> {
    const institutions: Array<Model.SelectOption> = [];

    if (includeTypes.includes('Region')) {
      // add region to the list
      institutions.push({
        value: region.id,
        label: `${region.name} Region`
      })
    }

    region.collegeIds.forEach(institution_id => {
      const institution = this.getInstitution(institution_id);
      if (includeTypes.includes(institution.type)) {
        institutions.push({
          value: institution.id,
          label: institution.name,
        })
      }
    });
    institutions.sort((a, b) => a.label > b.label ? 1 : -1);

    return institutions;
  }

  /**
   * Returns list of colleges (and optionally districts) belonging to a given region.
   *
   * @param {number} region_institution_id
   * @param {boolean} [includeDistricts=false]
   * @returns {Array<any>}
   */
  private regionColleges(institutions: Array<any>, region_institution_id: number, includeDistricts: boolean = false): Array<any> {
    // get all the districts belonging to a region.
    const districts = institutions.filter(inst => {
      if (!inst.parent || isEmpty(inst.parent)) {
        return false;
      }
      return (inst.type === INSTITUTION_TYPES.CCD && inst.parent[0].id === region_institution_id);
    });

    // now get colleges belonging to each district.
    let colleges = [];
    districts.forEach(inst => {
      colleges = [...colleges, ...this.districtColleges(institutions, inst.id), ...this.districtSchools(institutions, inst.id)];
    });

    if (includeDistricts) {
      colleges = [...districts, ...colleges];
    }

    return colleges;
  }

  /**
   * Returns a list of institutions formatted for po-select options list.
   * @param filter String value to limit the lookup. We require a 2-character input minimum to do the query.
   * @param limit Number of results to return
   * @param useInstitutionName Optional boolean to return institution name from formatInstitution
   */
  public formattedInstitutionList$(filter: Object, useInstitutionName?: boolean): Observable<Array<Model.SelectOption>> {
    if (filter['match_strings'] && filter['match_strings'].length >= 2) {
      return this.apiService.listInstitutions({
        ...filter,
        include_parent: false,
        include_hierarchy: false
      }).pipe(
        map((res: Array<Model.Institution>) => {
          this._recentInstitutionLookup = res;
          return res
            .map((i: Model.Institution) => useInstitutionName ? AppUtils.formatInstitution(i, true) : AppUtils.formatInstitution(i))
        })
      );
    } else {
      return of([]);
    }
  }

  public getRecentInstitutionLookup(institutionId: number) {
    return this._recentInstitutionLookup.find(i => i.id === institutionId);
  }

  /**
   * Returns list of colleges belonging to a given district.
   *
   * @param {number} district_institution_id
   * @returns {Array<any>}
   */
  public districtColleges(institutions: Array<any>, district_institution_id: number): Array<any> {
    const colleges = institutions.filter(inst => {
      if (!inst.parent || isEmpty(inst.parent)) {
        return false;
      }
      return (inst.type === INSTITUTION_TYPES.COLLEGE && inst.parent[0].id === district_institution_id);
    });
    return colleges;
  }

  public getDistrictColleges(district_institution_id: number): Array<Model.Institution> {
    return this.snapshots.institutions.filter(inst => {
      if (!inst.parent || isEmpty(inst.parent)) {
        return false;
      }
      return (inst.type === INSTITUTION_TYPES.COLLEGE && inst.parent[0].id === district_institution_id);
    });
  }
  
  public districtSchools(institutions: Array<any>, district_institution_id: number): Array<any> {
    const schools = institutions.filter(inst => {
      if (!inst.parent || isEmpty(inst.parent)) {
        return false;
      }
      return (LEA_TYPES.includes(inst.type) && inst.parent[0].id === district_institution_id);
    });
    return schools;
  }

  public get getStateInstitution(): Model.SelectOption {
    const match = this.snapshots.institutions.find(inst => inst.type === INSTITUTION_TYPES.STATE);
    if (match) {
      return {
        value: match.id,
        label: match.name,
      }
    }
  }

  public get getCCCCOInstitution(): Model.SelectOption {
    const match = this.snapshots.institutions.find(inst => inst.type === INSTITUTION_TYPES.EMPLOYER && inst.name === `Chancellor's Office`);
    if (match) {
      return {
        value: match.id,
        label: match.name,
      }
    }
  }

  /************************************************************
   * Contacts lookup table helper functions
   ************************************************************/

  /**
   * Returns a list of users formatted for po-select options list.
   * @param filter String value to limit the lookup. We require a 2-character input minimum to do the query.
   * @param limit Number of results to return
   */
  public formattedContactList$(filter: string, limit: number): Observable<Array<Model.SelectOption>> {
    if (filter && filter.length >= 2) {
      return this.apiService.listProfiles({match_strings: filter, limit}).pipe(
        map((res: {count: number, users: Array<Model.User>}) => {
          return res.users
            .filter(contact => contact.status !== 'deleted')
            .map((contact: Model.User) =>
              AppUtils.formatContact(contact)
            )
        })
      );
    } else {
      return of([]);
    }
  }

  /************************************************************
   * Roles lookup table helper functions
   ************************************************************/
  public get roles(): Array<Model.RolePermissions> {
    return this.snapshots.roles;
  }

  public get systemRoles(): Array<Model.RolePermissions> {
    return this.snapshots.roles.filter(r => r.proposal !== 1);
  }

  public getRole(roleId: number): Model.RolePermissions {
    return this.snapshots.roles.find(r => r.id === roleId);
  }

  public rolesByAreaAction(area: string, actionName: string): Array<Model.RolePermissions> {
    return this.snapshots.roles.filter(r => {
      return r.permissions.some(p => {
        return p.name === area && p.actions.some(a => a.name === actionName);
      })
    })
  }

  public get rolesSelectOptions(): Array<Model.SelectOption> {
    const selectOptions = [];

    const roles = this.snapshots.roles;
    roles.forEach(role => {
      selectOptions.push({
        label: role.long_name,
        value: role.id
      });
    });

    return selectOptions;
  }

  public get systemRolesSelectOptions(): Array<Model.SelectOption> {
    const selectOptions = [];

    const roles = this.snapshots.roles;
    roles.forEach(role => {
      if (role.proposal !== 1) {
        selectOptions.push({
          label: role.long_name,
          value: role.id
        });
      }
    });

    return selectOptions;
  }

  public get roleAddSelectOptions(): Array<Model.SelectOption> {
    const selectOptions = [];

    const roles = this.snapshots.roles;
    roles.forEach(role => {
      // filter out roles that cannot be assigned in admin console.
      if (!(role.id === SYSTEM_ROLES.SYSTEM_ADMIN.ID || role.proposal === 1)) {
        const names = uniq([role.long_name, ...this.getRoleAliases(role.id)]);
        selectOptions.push({
          label: names.join(' / '),
          value: role.id
        });
      }
    });

    return selectOptions;
  }

  public getRoleLongName(roleId: number): string {
    if (roleId) {
      return this.snapshots.roles.find(r => r.id === roleId).long_name;
    } else {
      return 'Unknown Role';
    }
  }

  /** Returns role name (potentially aliased) for a given fund.
   * The fund identifier could be a map to FUND_TYPES or FUND_KEYS.
   * @param {number} roleId
   * @param {number|string} fundIdOrKey
   * @returns {string}
   */
  public getRoleNameForFund(roleId: number, fundIdOrKey: number | string): string {
    let role = find(PROJECT_ROLES, r => r.ID === roleId);
    if (!role) {
      role = find(SYSTEM_ROLES, r => r.ID === roleId);
    }

    if (role && role.ALIASES && role.ALIASES[fundIdOrKey]) {
      return role.ALIASES[fundIdOrKey];
    } else {
      return this.getRoleLongName(roleId);
    }
  }

  public getRoleAliases(roleId: number): Array<string> {
    let role = find(PROJECT_ROLES, r => r.ID === roleId);
    if (!role) {
      role = find(SYSTEM_ROLES, r => r.ID === roleId);
    }

    return role && role.ALIASES
      ? uniq(Object.values(role.ALIASES))
      : [];
  }

  public getGroupedUserRoleScopes(userRoleScopes: Array<Model.UserRoleScope>) {
    let grpUserSysRoles: Array<Model.GroupedUserRoleScope> = [];
    let grpUserProjRoles: Array<Model.GroupedUserRoleScope> = [];

    if (userRoleScopes && userRoleScopes.length > 0) {
      // organize scopes by roles.
      const groups = {};
      userRoleScopes.forEach(r => {
        if (groups[r.role_id]) {
          groups[r.role_id].push(r);
        } else {
          groups[r.role_id] = [r];
        }
      });

      forOwn(groups, (roleScopes, idString) => {
        const role_id = roleScopes[0].role_id;
        const data = this.getRole(role_id);
        const role = {
          role_id: role_id,
          data: data,
          name: data.long_name,
          roleScopes: roleScopes,
        };
        (data.proposal === 1) ? grpUserProjRoles.push(role) : grpUserSysRoles.push(role);
      });

      grpUserSysRoles = sortBy(grpUserSysRoles, ['name']);
      grpUserProjRoles = sortBy(grpUserProjRoles, ['name']);
    }

    return {
      sysRoles: grpUserSysRoles,
      projRoles: grpUserProjRoles,
    }
  }

  /***
   * Returns true if inquired role is a protected one.
   */
  public isProtected(role_id: number) {
    return PROTECTED_ROLE_IDS.includes(role_id);
  }

  /************************************************************
   * Message lookup helper functions
   ************************************************************/
  public get dateOffsets(): Array<Model.SelectOption> {
    const list = [];
    for (let day = 1; day <= 15; day++) {
      list.push({
        value: day,
        label: `${day}`,
      })
    }
    return list;
  }

  public get offsetTypes(): Array<Model.SelectOption> {
    return  [
      {value: 'before', label: 'Before event'},
      {value: 'after', label: 'After event'},
      {value: 'on', label: 'On day of event'},
    ];
  }

  public eventTypes$(fundType: number): Observable<Array<Model.SelectOption>> {
    return this.apiService.getEvents(fundType).pipe(
      map(events => {
        return events
          .map(e => ({ // return in select option format
            value: e.id,
            label: `${e.event_type.name}: ${e.name}`
          }))
          .sort((a, b) => a.label.localeCompare(b.label)); // sort by label.
      }));
  }

  /************************************************************
   * Metrics lookup helper functions
   ************************************************************/
  public metricUniqToId(uniqs: string, group: string) {
    const defList = this.snapshots.metric_definitions.uniques[uniqs];

    if (!defList) {
      return null;
    } else {
      return defList.find(def => def.group === group)?.id;
    }
  }

  public metricSymbol(symbol: string): string {
    switch (symbol) {
      default:
      case 'number':
        return '#';
      case 'percent':
        return '%';
      case 'dollars':
        return '$ per year';
    }
  }

  public metricDefByUniq(uniqs: string, group: string) {
    const defList = this.snapshots.metric_definitions.uniques[uniqs];
    let def;

    if (!defList) {
      return null;
    } else {
      def = defList.find(d => d.group === group);
      if (!def) {
        return null;
      }
    }
    const symbol = this.metricSymbol(def.units);

    return {
      ...def,
      symbol
    }
  }

  public lvgMetricsDefinitions(): Array<any> {
    return this.snapshots.metric_definitions.raw.filter(def => def.group === PROGRAM_KEYS.LVG);
  }

  public lvgMetricDefinition(id: number): any {
    return this.snapshots.metric_definitions.raw.find(def => def.id === id);
  }

  public metricIsOther(id: number): boolean {
    return this.snapshots.metric_definitions.other.id === id;
  }

  public getImpactedGroupsList() {
    return this.snapshots.impacted_groups.raw;
  }

  public getImpactedGroups() {
    return this.snapshots.impacted_groups.groups;
  }

  public lvgImpactedGroups() {
    return this.snapshots.impacted_groups.lvg;
  }

  public impactedGroupById(id: number) {
    return this.snapshots.impacted_groups.raw.find(group => id === group.id)
  }

/************************************************************
 * Role Scope Related Helper Functions
 ************************************************************/

  /**
   * From roles settings/data & the scope, it derive's the fund name.
   * @param roleData
   * @param roleScope
   */
  public getRoleScopeFundName(roleData: Model.RolePermissions, roleScope: Model.UserRoleScope): string {
    let fundName = UNRESTRICTED;
    if (roleData.fund && roleData.fund >= 0) {
      if (roleScope.fund_id) {
        const program = this.programService.getProgramById(roleScope.fund_id);
        fundName = Fund.getShortestName(program);
      }
    }
    return fundName;
  }

  public getRoleScopeProjectLink(roleScope: Model.UserRoleScope): string {
    let projectLink = '';
    const program = this.programService.getProgramById(roleScope.fund_id);

    if (program && program.key === PROGRAM_KEYS.CAEP) {
      if (roleScope.proposal_id && roleScope.proposal && roleScope.institution) {
        if (roleScope.institution.type === INSTITUTION_TYPES.CONSORTIUM) {
          projectLink = `/${PROGRAM_KEYS.CAEP}/consortia/${roleScope.proposal.id}/${roleScope.institution.id}`;
        } else {
          projectLink = `/${PROGRAM_KEYS.CAEP}/members/${roleScope.proposal.id}/${roleScope.institution.id}`;
        }
      }
    } else if (program && program.parent_key === PROGRAM_KEYS.PERKINS) {
      if (roleScope.proposal_id) {
        projectLink = `/${PROGRAM_KEYS.PERKINS}/${program.key}/applications/${roleScope.proposal_id}`;
      }
    } else {
      if (roleScope.proposal_id && roleScope.proposal) {
        let key = program ? program.parent_key || program.key : undefined;
        if ([PROGRAM_KEYS.GP_2].includes(program.key)) {
          key = program.key;
        }
        projectLink = Proposal.routerLink(roleScope.proposal, undefined, key);
      }
    }

    return projectLink;
  }

  public getRoleScopeProjectType(roleScope: Model.UserRoleScope): string {

    const getCustomProjectType = (): string => {
      const program = this.programService.getProgramById(roleScope.fund_id);
      if (program) {
        if ([PROGRAM_KEYS.SWP_K12_PC, PROGRAM_KEYS.SWP_K12_TAP].includes(program.key)) {
          return 'Reporting';
        }
      }
      return 'Project';
    }

    return roleScope.proposal && roleScope.proposal.type
      ? PROPOSAL_TYPE_NAMES[roleScope.proposal.type]
      : getCustomProjectType();
  }

  /**
   * Returns observable that will return users belonging to given role.
   * @param scope
   */
  public getProgramUsersByRole$(
    scope: { roleId: number, parentKey?: string, institutionId?: number, sectorId?: number }
  ): Observable<Array<Model.User>> {
    const userFilter = {
      role_ids: [scope.roleId],
    }
    if (!!scope.parentKey) {
      const parent = this.programService.getParentProgramByKey(scope.parentKey);
      userFilter['fund_ids'] = [parent.id];
    }
    if (!!scope.institutionId) {
      userFilter['institution_ids'] = [scope.institutionId];
    }
    if (!!scope.sectorId) {
      userFilter['sector_ids'] = [scope.sectorId];
    }
    return this.apiService.listProfiles(userFilter).pipe(
      map(profile => {
        return profile && profile.count > 0 ? profile.users : [];
      }));
  }

}
