
import {skipWhile, debounceTime, take} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Actions, Queries, Model } from '@app-ngrx-domains';
import { LoggerService } from 'ng-logger';
import { PROGRAM_KEYS, ROUTER_LINKS, TASK_TYPES } from '@app-consts';
import { ProposalBase as Proposal } from '@app-models';
import { LookupService } from '@app-services';

/* Resolver to fetch all reviews belonging to project. */
@Injectable()
export class ProjectReviewsResolver implements Resolve<boolean> {

  constructor(
    private store: Store<State>,
    private router: Router,
    private logger: LoggerService,
    private lookupService: LookupService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {

      // proposal should has been loaded by now; get the params needed to fetch the reviews.
      this.store.select(Queries.CurrentProposal.get)
        .pipe(take(1))
        .subscribe((proposal: Model.ProposalBase) => {

          // build out the filter for fetch.
          const filter = {
            proposal_id: proposal.id,
          }

          if (route.params['userId']) {
            filter['user_id'] = Number(route.params['userId']);
          }

          const programKey = Proposal.getProgramKey(proposal);
          const fundKey = proposal.funds[0].key;

          if (programKey === PROGRAM_KEYS.SWP_K12 && fundKey === PROGRAM_KEYS.SWP_K12_v1) {
            // K12v1 is the only one that doesn't use the shared RFA task
            filter['task_type'] = TASK_TYPES.SWPK_REVIEW_SUBMIT;
          } else {
            filter['task_type'] = TASK_TYPES.RFA_REVIEW_APPLICATION;
            filter['fund_id'] = proposal.fund_ids[0];
            filter['duration_id'] = proposal.duration_id;
          }

          // fetch reviews.
          this.logger.debug(`[project-reviews-resolver][${state.url}] loading filter=${JSON.stringify(filter)}`);
          this.store.dispatch(Actions.ProposalReviews.get(filter));

          this.store.select(Queries.ProposalReviews.getLoadingStatus).pipe(
              debounceTime(50),
              skipWhile(status => status.is_loading),
              take(1)
            )
            .subscribe(
              status => {
                if (status.error) {
                  this.logger.error(`[project-reviews-resolver][${state.url}] failed to load=${JSON.stringify(status.error)}`);
                  this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
                  subscriber.error(false);
                  subscriber.complete();
                } else {
                  this.logger.debug(`[project-reviews-resolver][${state.url}] loaded`);
                  subscriber.next(true);
                  subscriber.complete();
                }
              },
              error => {
                // error thrown while loading proposal preview.
                this.logger.error(`[project-reviews-resolver][${state.url}] error=${JSON.stringify(error)}`);
                // route user back to application dashboard.
                this.router.navigate([`/${programKey}/applications`]);
                subscriber.error(false);
                subscriber.complete();
              }
            );
        });
    });
  };
}
