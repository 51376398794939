import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.CurrentProposal = {
  data: {
    id: null,
    deleted: false,
    project_type: null,
    fund_ids: [],
    funds: [],
    title: '',
    description: '',
    created_at: null,
    updated_at: null,
    creator_id: 0,
    lead_institution: null,
    institutions: [],
    duration_id: 0,
    tasks: [],
  },
  is_loaded: false,
  is_loading: true,
  error: null,
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {

  /**
   * Interface definition of current proposal's states that this reducer manages.
   */
  export interface ICurrentProposalState {
    data: any;
    is_loaded: boolean;
    is_loading: boolean;
    error: any;
  }

  interface State {
    CurrentProposal: ICurrentProposalState;
  }
}
