import * as _ from 'lodash';
import { Action } from '@ngrx/store';
import { Actions, Model, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { Proposal, Budget, Allocation, EnumErrorTypes } from '@app-models';
import { FUND_TYPES } from '@app-consts';

/**
 * Budget Action types
 */
const ACTION_PREFIX = 'BUDGET_';
export const BUDGET_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  LOAD_ALLOCATIONS: `${ACTION_PREFIX}LOAD_ALLOCATIONS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  NOOP: `${ACTION_PREFIX}NOOP`,
  ADD_ITEM: `${ACTION_PREFIX}ADD_ITEM`,
  ADD_ITEM_SUCCESS: `${ACTION_PREFIX}ADD_ITEM_SUCCESS`,
  DELETE_ITEM: `${ACTION_PREFIX}DELETE_ITEM`,
  DELETE_ITEM_SUCCESS: `${ACTION_PREFIX}DELETE_ITEM_SUCCESS`,
  UPDATE_ITEM: `${ACTION_PREFIX}UPDATE_ITEM`,
  UPDATE_ITEM_SUCCESS: `${ACTION_PREFIX}UPDATE_ITEM_SUCCESS`,
  CANCEL_BUDGET: `${ACTION_PREFIX}CANCEL_BUDGET`,
  SAVE_BUDGET: `${ACTION_PREFIX}SAVE_BUDGET`,
  SAVE_BUDGET_SUCCESS: `${ACTION_PREFIX}SAVE_BUDGET_SUCCESS`,
};

/**
 * Budget action class.
 */
export class BudgetActions {

  /**
   * Loads budget from the proposal
   *
   * @param {any} proposal
   * @returns {Action}
   */
  load(proposal: any): ActionWithPayload<any> {
    const state = {
      proposal_id: proposal.id,
      items: [],
      raw_items: [],
      lastUsedNewId: 0,
    };

    const items: Array<Model.BudgetItem> = [];
    if (proposal.budget_items && proposal.budget_items.length > 0) {
      proposal.budget_items.forEach((item) => {
        state.items.push(new Budget(item).iObject<Model.BudgetItem>());
      });

      // make copy of the list in case we have to cancel the changes.
      state.raw_items = state.items.slice();
    }

    return {
      type: BUDGET_ACTION_TYPES.LOAD,
      payload: state,
    };
  }

  /**
   * Loads allocations made to the proposal's lead institution.
   *
   * @param {any} institutionAllocations
   * @returns {Action}
   */
  loadAllocations(institutionAllocations: Array<any>): ActionWithPayload<any> {
    const allocations = {};

    if (institutionAllocations) {
      institutionAllocations.forEach(institutionAllocation => {
        if (!allocations[institutionAllocation.duration_id]) {
          allocations[institutionAllocation.duration_id] = [];
        }
        allocations[institutionAllocation.duration_id].push(new Allocation(institutionAllocation).iObject<Model.Allocation>());
      });
    }

    return {
      type: BUDGET_ACTION_TYPES.LOAD_ALLOCATIONS,
      payload: allocations,
    };
  }

  /**
   * Resets budget
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    const state = {
      items: [],
      raw_items: [],
      lastUsedNewId: 0,
      allocations: {},
    };

    return {
      type: BUDGET_ACTION_TYPES.LOAD,
      payload: state
    }
  }

  /**
   * Adds a new budget item.
   *
   * @param {Model.BudgetItem} budget to add
   * @returns {Action}
   */
  addBudgetItem(budget: Model.BudgetItem): ActionWithPayload<any> {
    return {
      type: BUDGET_ACTION_TYPES.ADD_ITEM,
      payload: budget,
    };
  }

  addBudgetItemSuccess(budget: Model.BudgetItem): ActionWithPayload<any> {
    return {
      type: BUDGET_ACTION_TYPES.ADD_ITEM_SUCCESS,
      payload: budget,
    };
  }

  /**
   * Updates field(s) in budget item.
   *
   * @param {number} id
   * @param {any} fields
   * @returns {Action}
   */
  updateBudgetItem(budget: Model.BudgetItem): ActionWithPayload<any> {
    return {
      type: BUDGET_ACTION_TYPES.UPDATE_ITEM,
      payload: budget
    };
  }

  updateBudgetItemSuccess(budget: Model.BudgetItem): ActionWithPayload<any> {
    return {
      type: BUDGET_ACTION_TYPES.UPDATE_ITEM_SUCCESS,
      payload: budget
    };
  }

  /**
   * Deletes budget item.
   *
   * @param {number} itemId
   * @returns {Action}
   */
  deleteBudgetItem(itemId: number): ActionWithPayload<any> {
    return {
      type: BUDGET_ACTION_TYPES.DELETE_ITEM,
      payload: itemId
    };
  }

  deleteBudgetItemSuccess(itemId: number): ActionWithPayload<any> {
    return {
      type: BUDGET_ACTION_TYPES.DELETE_ITEM_SUCCESS,
      payload: itemId
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: BUDGET_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  /**
   * This action results in no-op.
   * @returns {Action}
   */
  noOp(): Action {
    return {
      type: BUDGET_ACTION_TYPES.NOOP,
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Budget = new BudgetActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    Budget: BudgetActions;
  }
}
