// Barrel all within this directory
export * from './admin-lookup-tables.resolver';
export * from './auth.guard';
export * from './can-deactivate.guard';
export * from './fund.guard';
export * from './health-check.guard';

// List available core guards & resolvers.
import { AdminLookupTablesResolver } from './admin-lookup-tables.resolver';
import { AuthGuard } from './auth.guard';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { FundGuard } from './fund.guard';
import { HealthCheckGuard } from './health-check.guard';

export const CoreGuards = [
  AdminLookupTablesResolver,
  AuthGuard,
  CanDeactivateGuard,
  FundGuard,
  HealthCheckGuard,
];
