import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import {DATE_TIME_MOMENT_FORMAT_STRINGS} from '@app-consts';

@Component({
  selector: 'show-errors',
  templateUrl: './show-errors.component.html'
})

export class ShowErrorsComponent {

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  @Input()
  private showUntouched = false;

  shouldShowErrors(): boolean {
    return this.control && this.control.errors && (this.control.dirty || this.control.touched || this.showUntouched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, messageParams: any) {

    const errorMessages = {
      'required': () => 'This field is required',
      'minlength': (params) => 'The minimum number of characters is ' + params.requiredLength,
      'maxlength': (params) => 'The maximum allowed number of characters is ' + params.requiredLength,
      'pattern': () => 'This value is not valid',
      'date': (params) => params.message,
      'dateBefore': (params) => params.message,
      'dateTime': (params) => params.message,
      'nonZero': (params) => params.message,
      'min': (params) => 'The minimum value is ' + params.min,
      'max': (params) => 'The maximum value is ' + params.max,
      'matDatepickerMin': (params) => 'The minimum date is ' + moment(params.min).format(DATE_TIME_MOMENT_FORMAT_STRINGS.date),
      'matDatepickerMax': (params) => 'The maximum date is ' + moment(params.max).format(DATE_TIME_MOMENT_FORMAT_STRINGS.date),
      'emailString': (params) => params.message,
      'email': (params) => params.message,
      'emailTo': (params) => params.message,
      'emails': (params) => params.message,
      'eventTo': (params) => params.message,
      'eventDate': (params) => params.message,
      'mismatchedPasswords': (params) => params.message,
      'tooFewActivities': (params) => 'This goal requires ' + params + ' activities to be filled out',
      'url': (params) => params.message,
      'phoneNumber': (params) => params.message,
      'employerIdNumber': (params) => params.message,
      'extension': (params) => params.message,
      'requiredCheck': (params) => params.message,
      'requireOne': (params) => params.message,
      'zipCode': (params) => params.message,
      'yearNotSelected': (params) => params.message,
      'validationError': (message) => message // To be used as a generic validation error
    };

    if (errorMessages[type]) {
      return errorMessages[type](messageParams);
    }
  }

}
