import { IAuthState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { AUTH_ACTION_TYPES } from './auth.action';
import { includes } from 'lodash';


export function AuthReducer(state: IAuthState = State.Auth, action: ActionWithPayload<any>): IAuthState {

  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN: {
      return {
        ...state,
        inProgress: true,
        isLoggedIn: false,
        error: null,
        user: null,
        userMessages: [],
        userError: null,
      };
    }

    case AUTH_ACTION_TYPES.LOGIN_SUCCESS: {
      return {
        ...state,
        inProgress: false,
        isLoggedIn: true,
        waitingOnUserToLogin: false,
        user: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.LOGIN_FAILED: {
      return {
        ...state,
        inProgress: false,
        error: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.REFRESH_FAILED: {
      return {
        ...state,
        error: action.payload,
      };
    }


    case AUTH_ACTION_TYPES.LOGIN_WAITING_ON_USER: {
      return {
        ...state,
        waitingOnUserToLogin: action.payload,
      };
    }

    case AUTH_ACTION_TYPES.LOGOUT_SUCCESS:
    case AUTH_ACTION_TYPES.LOGIN_CHECK_FAILED:  {
      return {
        ...State.Auth
      };
    }

    case AUTH_ACTION_TYPES.UPDATE_USER: {
      return {
        ...state,
        inProgress: true,
        userError: null
      };
    }

    case AUTH_ACTION_TYPES.CRUD_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        inProgress: false,
        userError: null
      };
    }

    case AUTH_ACTION_TYPES.SET_SUDO_LOGIN: {
      return {
        ...state,
        isSudoLoggedIn: action.payload
      };
    }

    case AUTH_ACTION_TYPES.REFRESH_USER_MESSAGES_SUCCESS: {
      return {
        ...state,
        userMessages: action.payload
      };
    }

    case AUTH_ACTION_TYPES.MARK_USER_MESSAGES_SUCCESS: {
      const reduced = [];
      if (action.payload.all) {
        state.userMessages.forEach(m => {
          if (action.payload.markType === 'read') {
            reduced.push({
              ...m,
              read: true,
            })
          }
        });
      } else {
        state.userMessages.forEach(m => {
          if (includes(action.payload.ids, m.id)) {
            if (action.payload.markType === 'read') {
              reduced.push({
                ...m,
                read: true,
              })
            }
          } else {
            reduced.push({
              ...m,
            })
          }
        });
      }

      return {
        ...state,
        userMessages: reduced
      };
    }

    default:
      return state;
  }
}
