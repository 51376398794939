import { throwError as observableThrowError, Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';

import { environment } from '../../../environments/environment';

/**
 * Http interceptor that would intercept outgoing request, and
 * attaches auth token to the header.
 * @export
 * @class TokenInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  /**
   * Intercepts http request and attaches it an auth token.
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //API Gateway key header
    const apiKey = environment.apiKey;
    const token = localStorage.getItem('auth_token');

    if (req.url.startsWith(environment.apiHostUrl)) {
      if (apiKey) {
        req = req.clone({
          headers: req.headers.append('x-api-key', apiKey)
        })
      }

      if (token) {
        req = req.clone({
          headers: req.headers.append('x-nova-auth-token', token)
        })
      }
    }

    return next.handle(req).pipe(catchError((error, caught) => {
      // return the error to the method that called it
      return observableThrowError(error);
    })) as Observable<HttpEvent<any>>;
    // get the token.
  }
}

/**
 * Http interceptor that would intercept outgoing request, and
 * attaches Sentry transaction ID to the header.
 * Also intercept incoming response, and
 * set Sentry context if service version header is present.
 * @export
 * @class TokenInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class SentryInterceptor implements HttpInterceptor {

  /**
   * Intercepts http request and attaches it an auth token.
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // attach transaction id to header
    if (!req.url.startsWith(environment.helpdeskApiUrl)) {
      const transactionId = uuid();
      Sentry.configureScope(scope => {
        scope.setTag('transaction_id', transactionId);
      });
      const newReq = req.clone({headers: req.headers.set('x-sentry-transaction-id', transactionId)});

      return next.handle(newReq).pipe(
        map(event => {
          if (event instanceof HttpResponse) {
            const serviceVersion = event.headers.get('x-nova-version');
            if (serviceVersion) {
              Sentry.configureScope(scope => {
                scope.setExtra('Service Version', serviceVersion);
              });
            }
          }
          return event;
        }),
        catchError(resp => {
          if (resp instanceof HttpErrorResponse) {
            const message = `${req.method} request failed - (${resp.status}, ${req.url})`;
            const error = new Error(message);
            error.name = 'HttpErrorResponse';

            Sentry.withScope(scope => {
              Sentry.setExtra('Method', req.method);
              Sentry.setExtra('Status', resp.status);
              Sentry.setExtra('StatusText', resp.statusText);
              Sentry.setExtra('Body', resp.error);
              Sentry.setExtra('Message', resp.message);
              Sentry.captureException(error);
            });
          }

          return observableThrowError(resp); // return the error to the method that called it
        }),
      );
    }  else {
      return next.handle(req).pipe(catchError((error, caught) => {
        // return the error to the method that called it
        return observableThrowError(error);
      })) as any;
    }
  }
}
