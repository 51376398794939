import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AnalyticsService, EVENT_ACTION, EVENT_CATEGORY, LookupService } from '@app-services';
import { Proposal, Filter, FilterType, Utilities } from '@app-models';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { chain } from 'lodash';

/*
 * The project filter is a component which takes an array of objects (rows), and and array of filters.
 * the filters input should contain field, name, and type.
 * Optional fields include: labelField & valueField, includeRegions, and defaultOption (see Filter model file for description)
 * Each filter will become a filter input. When the formValue is updated, the formValue[filter.field] will contain
 * the value to filter by. Each type has a different comparison mechanism in updateFilteredProposals().
 * In order for the filtering to work, every filter.field must be a valid property of each row.
 */

@Component({
  selector: 'project-filter',
  templateUrl: './project-filter.component.html'
})
export class ProjectFilterComponent implements OnDestroy {
  @Input() projectLongName: string;
  @Input() filters: Filter[] = [];
  @Input() analyticsFundName: string;
  @Input() enableSaveFilters: boolean = true;
  @Input() filterOnChange: boolean = true;
  @Input() set rows(proposals: any[]) {
    if (proposals) {
      this.proposalList = proposals;
      this.filters.forEach(filter => {
        if (filter.field) {
          this.updateFilterValues(filter);
        }
      });

      if (this.filterForm) {
        this.updateFilteredProposals();
      } else {
        this.initializeForm();
      }
    }
  }

  @Output() filterUpdate = new EventEmitter();
  @Output() filterChange = new EventEmitter();

  filterForm: FormGroup;
  filteredProposals: Proposal[];
  filterOptions = {};
  saveFilterForm: FormGroup;
  filterKey: string;
  showClearFilters: boolean;
  private savedFilters: { filters?: { [filterName: string]: any }, optedOut?: boolean } = {};
  private proposalList = [];
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private lookupService: LookupService,
    private analyticsService: AnalyticsService,
    private fb: FormBuilder) {}

  initializeForm() {
    this.initSavedFilters();
    const formFields = {};

    this.filters.forEach((filter: Filter) => {
      switch (filter.type) {
        case FilterType.Multi:
          formFields[filter.field] = [filter.defaultOption ? [filter.defaultOption.value] : []];
          break;
        case FilterType.Single:
          formFields[filter.field] = [filter.defaultOption ? filter.defaultOption.value : undefined];
          break;
        case FilterType.Text:
        case FilterType.Id:
          formFields[filter.field] = [undefined];
          break;
        case FilterType.TypeAhead:
          formFields[filter.field] = [[]];
          break;
        default:
          return console.error(`Missing or unknown filter type: ${filter.type} on filter ${filter.field}`);
      }

      this.updateFilterValues(filter);
    });

    const savedFilters = this.fetchSavedFilters(formFields);
    this.filterForm = this.fb.group(savedFilters);

    if (this.filterOnChange) {
      this.updateFilteredProposals();
    } else if (this.showClearFilters) {
      // If we have saved filters, use them right away
      this.applyFilters();
    }

    this.filterForm.valueChanges.pipe(
      debounceTime(250),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      if (this.filterOnChange) {
        this.updateFilteredProposals();
        this.showClearFilters = Object.keys(this.filterForm.controls).some(controlName => {
          const value = this.filterForm.get(controlName).value;
          if (value) {
            return Array.isArray(value) ? !!value.length : true;
          }
        });
        this.setSavedFilters();
      }
    });
  }

  fetchSavedFilters(formFields) {
    if (this.savedFilters && this.savedFilters.filters) {
      const savedFilters = this.savedFilters.filters;

      Object.keys(formFields).forEach(key => {
        if (key in savedFilters) {
          let savedFilterValue = savedFilters[key];

          const filter = this.filters.find(f => f.field === key);
          if (filter) {
            const filterType = filter.type;
            const options = (this.filterOptions[key] || []).map(option => option.value);
            if (filterType === FilterType.Single) {
              if (options.includes(savedFilterValue)) {
                this.showClearFilters = true;
                formFields[key] = [savedFilterValue];
              }
            } else if (filterType === FilterType.Multi) {
              if (Array.isArray(savedFilterValue)) { // Saved filter value must be an array for multi types
                savedFilterValue = savedFilterValue.filter(value => options.includes(value));
                if (savedFilterValue.length) {
                  this.showClearFilters = true;
                  formFields[key] = [savedFilterValue];
                }
              }
            } else if ([FilterType.Text, FilterType.Id].includes(filterType)) {
              // All values for text inputs are valid
              this.showClearFilters = true;
              formFields[key] = [savedFilterValue];
            } else if (filterType === FilterType.TypeAhead) {
              if (Array.isArray(savedFilterValue) && savedFilterValue.length) { // Saved filter must be an array
                savedFilterValue.forEach(saved => {
                  if (!this.filterOptions[key].find(option => option.value === saved.value)) {
                    this.filterOptions[key].push(saved);
                  }
                });
                this.showClearFilters = true;
                formFields[key] = [savedFilterValue];
              }
            }
          }
        }
      });
    }
    return formFields;
  }

  getTemplateForFilter(filter: Filter): string {
    switch (filter.type) {
      case FilterType.Text:
      case FilterType.Id:
        return 'text';
      case FilterType.Single:
      case FilterType.Multi:
        return 'select';
      case FilterType.TypeAhead:
        return 'search';
      default:
        return undefined;
    }
  }

  isMultiSelect(filterType: number) {
    return filterType === FilterType.Multi;
  }

  updateFilterValues(filter: Filter) {
    if ([FilterType.Text, FilterType.Id].includes(filter.type)) {
      return; // No options for text filters
    }

    if (filter.type === FilterType.TypeAhead) {
      this.filterOptions[filter.field] = [];
      return;
    }

    if (filter.selectOptions) {
      this.filterOptions[filter.field] = filter.selectOptions;
    } else {
      const optionsList = [];
      if (this.proposalList.length) {
        this.proposalList.forEach(proposal => {
          const value = proposal[filter.field];
          if (value && Array.isArray(value)) {
            optionsList.push(...value);
          } else if (value && !optionsList.includes(value)) {
            optionsList.push(value);
          }
        });
      }

      this.filterOptions[filter.field] = this.uniqAndSort(optionsList, filter.valueField, filter.labelField).map(option => {
        if (filter.valueField && filter.labelField) {
          return { value: option[filter.valueField], label: option[filter.labelField] };
        } else { // Assumes option is a string/number
          return { value: option, label: option };
        }
      });

      if (!!filter.sortDescending) {
        this.filterOptions[filter.field].sort((a, b) => a.label > b.label ? -1 : 1);
      }
    }

    if (filter.defaultOption) {
      this.filterOptions[filter.field] = this.filterOptions[filter.field].filter(option => option.value !== filter.defaultOption.value);
      this.filterOptions[filter.field].unshift(filter.defaultOption);
    }

    if (filter.includeRegions) {
      const regionOptions = [];
      const regions = this.lookupService.getInstitutionRegions();
      Object.values(regions).forEach(region => {
        regionOptions.push({
          value: region.id,
          label: `Region: ${region.name}`,
          collegeIds: region.collegeIds
        });
      });

      this.filterOptions[filter.field].unshift(...this.uniqAndSort(regionOptions, 'value'));
    }
  }

  uniqAndSort(values: Array<any>, valueField?: string, labelField?: string) {
    return chain(values).uniqBy(valueField).sortBy(labelField).value();
  }

  updateFilteredProposals() {

    if (!this.filterOnChange || !this.filterForm) {
      return;
    }

    const filtersState = this.getFilterValues();

    // Start with the full proposalList and filter out items from there
    this.filteredProposals = this.proposalList.filter(p => {
      let pass = true; // Presume that each item passes the filter test

      pass = this.filters.every((filter: Filter) => {
        let filterValue = filtersState[filter.field];
        let isArray = Array.isArray(filterValue);
        const proposalField = p[filter.field];

        if (!filterValue || (isArray && !filterValue.length)) {
          return true;
        }

        if (filter.includeRegions) {
          const filterOptions = this.filterOptions[filter.field];
          if (!isArray) { // Regional options include all collegeIds
            filterValue = [filterValue];
            isArray = true;
          }
          const regionOptions = filterOptions.filter(option => option.collegeIds && filterValue.includes(option.value));

          if (regionOptions.length && p['region_id']) { // Special filter for K12/SWP-v2 "regional" proposals
            return regionOptions.some(option => option.value === p['region_id']);
          }

          // Add collegeIds to filterValue
          const collegeIds = regionOptions.reduce((ids, region) => {
            return [...ids, ...region.collegeIds];
          }, []);
          filterValue = isArray ? [...filterValue, ...collegeIds] : [filterValue, ...collegeIds];
        }

        if (Array.isArray(proposalField)) {
          // Look for filterValue within proposalValues
          const proposalValues = proposalField.map(value => filter.valueField ? value[filter.valueField] : value);
          if (filter.type === FilterType.Text) {
            const matchValue = filterValue.toLowerCase();
            return proposalValues.some(value => value.toLowerCase().indexOf(matchValue) >= 0);
          } else if (isArray) {
            return proposalValues.some(value => filterValue.includes(value));
          } else {
            return proposalValues.includes(filterValue);
          }
        } else {
          // Match against single value
          const proposalValue = filter.valueField ? proposalField[filter.valueField] : proposalField;
          if (filter.type === FilterType.Text) {
            return proposalValue.toLowerCase().indexOf(filterValue.toLowerCase()) >= 0;
          } else if (filter.type === FilterType.Id) {
            const proposalValueString = proposalValue.toString();
            return proposalValueString.startsWith(filterValue);
          } else if (isArray) {
            return filterValue.includes(proposalValue);
          } else {
            return filterValue === proposalValue
          }
        }
      });

      return pass;
    });

    if (this.filterUpdate) {
      this.filterUpdate.emit(this.filteredProposals);
    }
    if (this.filterChange) {
      this.filterChange.emit(filtersState);
    }
  }

  clearFilters() {
    Object.keys(this.filterForm.controls).forEach(control => {
      const value = this.filterForm.get(control).value;
      const emptyValue = Array.isArray(value) ? [] : undefined;
      this.filterForm.get(control).setValue(emptyValue);
    });
  }

  applyFilters() {
    this.showClearFilters = Object.keys(this.filterForm.controls).some(controlName => {
      const value = this.filterForm.get(controlName).value;
      if (value) {
        return Array.isArray(value) ? !!value.length : true;
      }
    });
    this.setSavedFilters();

    const filtersState = this.getFilterValues();
    this.filterChange.emit(filtersState);
  }

  getFilterValues() {
    const filtersState = this.filterForm.value;
    this.filters.forEach(filter => {
      if (filter.type === FilterType.TypeAhead) {
        // Map type-ahead values from object to values only
        filtersState[filter.field] = filtersState[filter.field].map(selected => selected.value);
      }
    });
    return filtersState;
  }

  initSavedFilters() {
    if (this.enableSaveFilters) {
      this.saveFilterForm = this.fb.group({ optedIn: [true] });

      this.filterKey = `savedFilters${this.router.url}`;
      const savedFilters = localStorage.getItem(this.filterKey);
      if (savedFilters) {
        try {
          this.savedFilters = JSON.parse(savedFilters)
          if (this.savedFilters.optedOut) {
            this.saveFilterForm.get('optedIn').setValue(false);
          }
        } catch (err) {
          console.error('Couldn\'t parse saved filters');
        }
      }
    }
  }

  setSavedFilters() {
    if (this.enableSaveFilters) {
      const savedFilters = {};
      if (!this.savedFilters.optedOut) {
        Object.keys(this.filterForm.controls).forEach(controlName => {
          const value = this.filterForm.get(controlName).value;
          if (!Utilities.isNil(value)) {
            savedFilters[controlName] = value;
          }
        });
      }

      this.savedFilters.filters = { ...savedFilters };
      localStorage.setItem(this.filterKey, JSON.stringify(this.savedFilters));
    }
  }

  toggleOptIn() {
    const optedIn = this.saveFilterForm.get('optedIn').value;
    this.savedFilters.optedOut = !optedIn;
    this.setSavedFilters();
  }

  updateResultsList(filter: Filter, filterStr: string) {
    const filters = { ...(filter.typeAheadFilter || {}), match_strings: filterStr, limit: 10 };
    this.lookupService.formattedInstitutionList$(filters).subscribe(res => {
      this.filterOptions[filter.field] = res;
    });
  }

  logFilter(filterName: string) {
    this.logEvent(EVENT_CATEGORY.table, EVENT_ACTION.filter, filterName.toLowerCase());
  }

  private logEvent(category: string, action: string, label: string) {
    const fullAction = `${action}-proposals-${this.analyticsFundName}`;
    this.analyticsService.logEvent(category, fullAction, label);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
