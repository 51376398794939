import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'po-action-list-item',
  templateUrl: './action-list-item.component.html',
})
export class ActionListItemComponent implements OnInit {
  @Input() isEmail = true;
  @Input() itemTitle;
  @Input() itemBody;
  @Input() secondaryText;
  @Input() itemViewed = false;
  @Input() showIndicator = false;
  @Input() truncateBody = false;
  @Input() editButtonText;
  @Input() deleteButtonText;
  @Input() clickable = false;

  itemBody_unformatted: string;
  showFullMessage: boolean;

  @Output() deleteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editEvent: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.itemBody = this.itemBody.replace(/<style>[^>]+<\/style>/g, '');
    this.itemBody_unformatted = this.itemBody.replace(/<[^>]+>/g, '');
  }

  deleteClicked() {
    this.deleteEvent.emit();
  }

  editClicked() {
    this.editEvent.emit();
  }

  toggleShowFullMessage() {
    this.showFullMessage = !this.showFullMessage;
  }

}
