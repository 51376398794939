/**
 * This is the global reducers module that keeps track of dynamically loaded ngrx-domains, which consists of:
 * --> Actions
 * --> Reducers
 * --> States
 * --> Models (optional)
 * --> Queries (optional)
 */
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { State, getReducers } from '@app-ngrx-domains';

/**
 * Token used to inject domain reducers; this is required to get AOT compilation to work.
 */
export const DOMAIN_REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('Domain Registered Reducers');

/**
 * Returns all the domain reducers.
 */
export function domainReducers() {
  return {
    ...getReducers(),
  };
}
