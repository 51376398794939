
import {take, skipWhile, withLatestFrom, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from 'ng-logger';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Model, Queries, Actions } from '@app-ngrx-domains';
import { ProgramService } from '@app-services';
import { ROUTER_LINKS, PROGRAM_KEYS } from '@app-consts';
import { Utilities } from '@app-models';

@Injectable()
export class FundGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private logger: LoggerService,
    private programService: ProgramService,
    private store: Store<State>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoaded(route, state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isLoaded(route, state);
  }

  isLoaded(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable((subscriber) => {
      this.logger.debug(`[fund-guard][${state.url}] checking...`);

      // // get parent key from route
      const parentKey =  Utilities.programKeyFromRoute(state.url);
      this.store.dispatch(Actions.Layout.showBusySpinner(true));
      this.programService.isLoaded().pipe(
        skipWhile((loaded) => !loaded),
        take(1)
      ).subscribe(() => {
        this.store.dispatch(Actions.Layout.showBusySpinner(false));

        const parentProgram = this.programService.getParentProgramByKey(parentKey);
        if (!parentProgram) {
          subscriber.next(false);
          subscriber.complete();
          return;
        }
        let fundId = parentProgram.id;
        if (route.params['fundId']) {
          // this route is a child route. reset fund id as child id.
          fundId = Number(route.params['fundId']);
          if (isNaN(fundId)) {
            // redirect user to page-not-found page.
            this.logger.info(`[fund-guard][${state.url}] bad url - redirecting to page-not-found`);
            this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
            subscriber.next(false);
            subscriber.complete();
            return;
          }
        }

        // start busy spinner...
        this.store.dispatch(Actions.Layout.showBusySpinner(true));

        // fetch fund.
        this.store.dispatch(Actions.Fund.get(fundId));

        this.store.select(Queries.Fund.getLoadingStatus).pipe(
          withLatestFrom(this.store.select(Queries.Fund.get)),
          filter(([status, fund]) => !status.is_loading && !!(fund && fund.id)),
          take(1)
        ).subscribe(data => {
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          const [status, fund] = data;
          const rootFund = this.programService.getRootFund(fund);

          if (fund.deleted) {
            this.logger.error(`[fund-guard][${state.url}] failed to load, '${fund.name}' fund has been deleted`);
            this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
            subscriber.error(false);
            subscriber.complete();
            return;
          }

          if (status.error) {
            this.logger.error(`[fund-guard][${state.url}] failed to load=${JSON.stringify(status.error)}`);
            this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
            subscriber.error(false);
            subscriber.complete();
            return;
          }

          if ((fund.key === PROGRAM_KEYS.GRANTS && rootFund.key !== parentKey) && (fund.parent_key && fund.parent_key !== parentKey)) {
            this.logger.error(`[fund-guard][${state.url}] failed to load, wrong parent=${fund.parent_key}`);
            this.router.navigate([ROUTER_LINKS.PAGE_NOT_FOUND], { queryParams: { badUrl: state.url } });
            subscriber.error(false);
            subscriber.complete();
            return;
          }

          this.logger.debug(`[fund-guard][${state.url}] loaded`);
          subscriber.next(true);
          subscriber.complete();
        },
        error => {
          this.store.dispatch(Actions.Layout.showBusySpinner(false));
          // error thrown while loading proposal preview.
          this.logger.error(`[fund-guard][${state.url}] error=${JSON.stringify(error)}`);
          // route user back to fund settings dashboard.
          this.router.navigate([`/${parentKey}/settings`]);
          subscriber.error(false);
          subscriber.complete();
        });
      });
    });
  }
}
