import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
} from '@angular/router';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries } from '@app-ngrx-domains';

@Injectable()
export class LmiCrossSectorGuard implements CanActivate {

  constructor(
    private router: Router,
    private store: Store<State>,
  ) { }

  canActivate(): Observable<boolean> {
    return this.canEditLmi();
  }

  canEditLmi(): Observable<boolean> {
    return new Observable((subscriber) => {
      this.store.select(Queries.CurrentProposal.isAllSector).subscribe((all_sector) => {
        if (all_sector) {
          subscriber.next(false);
          subscriber.complete();
        }
      });
      subscriber.next(true);
      subscriber.complete();
    });
  }
}
