import { State } from '@app-ngrx-domains';

State.System = {
  maintenanceStatus: {
    show_maintenance: false
  }
}

declare module '@app-ngrx-domains' {
  export interface ISystemState {
    maintenanceStatus: Model.Maintenance
  }

  interface State {
    System: ISystemState
  }
}
