import { Action } from '@ngrx/store';
import { Actions, Model } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';

// Layout action types
const ACTION_PREFIX = 'LAYOUT_';
export const LAYOUT_ACTION_TYPES = {
  CLEAR_HEADER: `${ACTION_PREFIX}CLEAR_HEADER`,
  CLEAR_HEADER_STATUS: `${ACTION_PREFIX}CLEAR_HEADER_STATUS`,
  CLEAR_HEADER_RESOURCE: `${ACTION_PREFIX}CLEAR_HEADER_RESOURCE`,
  EMIT_ACTION: `${ACTION_PREFIX}EMIT_ACTION`,
  SET_ACTIONS: `${ACTION_PREFIX}SET_ACTIONS`,
  UPDATE_ACTIONS: `${ACTION_PREFIX}UPDATE_ACTIONS`,
  APPEND_ACTIONS: `${ACTION_PREFIX}APPEND_ACTIONS`,
  SET_BOOKMARK: `${ACTION_PREFIX}SET_BOOKMARK`,
  SET_HEADER: `${ACTION_PREFIX}SET_HEADER`,
  SET_HEADER_STATUS: `${ACTION_PREFIX}SET_HEADER_STATUS`,
  SET_HEADER_TITLES: `${ACTION_PREFIX}SET_HEADER_TITLES`,
  SET_PAGE: `${ACTION_PREFIX}SET_PAGE`,
  SHOW_BUSY_SPINNER: `${ACTION_PREFIX}SHOW_BUSY_SPINNER`,
  UPDATE_HEADER_MESSAGE: `${ACTION_PREFIX}UPDATE_HEADER_MESSAGE`,
  UPDATE_NAV: `${ACTION_PREFIX}UPDATE_NAV`,
  SHOW_LOGO: `${ACTION_PREFIX}SHOW_LOGO`,
};

// Layout action class.
export class LayoutActions {

  clearHeader(): Action {
    return {
      type: LAYOUT_ACTION_TYPES.CLEAR_HEADER,
    };
  }

  clearHeaderStatus(): Action {
    return {
      type: LAYOUT_ACTION_TYPES.CLEAR_HEADER_STATUS,
    };
  }

  clearHeaderResource(): Action {
    return {
      type: LAYOUT_ACTION_TYPES.CLEAR_HEADER_RESOURCE,
    }
  }

  clearPageType(): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_PAGE,
      payload: { type: '' },
    };
  }

  emitAction(action: Model.HeaderAction): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.EMIT_ACTION,
      payload: { name: action.route, id: action.id }
    };
  }

  setActions(actions: Array<Model.HeaderAction>): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_ACTIONS,
      payload: actions
    };
  }

  updateActions(actions: Array<Model.HeaderAction> | Model.HeaderAction): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.UPDATE_ACTIONS,
      payload: actions
    };
  }

  appendActions(actions: Array<Model.HeaderAction> | Model.HeaderAction): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.APPEND_ACTIONS,
      payload: actions
    };
  }

  setBookmark(show: boolean): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_BOOKMARK,
      payload: show
    };
  }

  setHeader(header: Model.HeaderLayout): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_HEADER,
      payload: header,
    };
  }

  setHeaderStatus(status: { text: string, type: string }): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_HEADER_STATUS,
      payload: status,
    };
  }

  setHeaderTitles(titles: any): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_HEADER_TITLES,
      payload: titles,
    };
  }

  updateHeader(updates: any): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_HEADER_TITLES,
      payload: updates,
    };
  }

  setBreadcrumbs(breadcrumbs?: Array<Model.Breadcrumb>): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_HEADER_TITLES,
      payload: { breadcrumbs },
    };
  }

  setPage(page: Model.PageLayout): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_PAGE,
      payload: page,
    };
  }

  setPageType(type: string): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SET_PAGE,
      payload: { type: type },
    };
  }

  showBusySpinner(show: boolean): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SHOW_BUSY_SPINNER,
      payload: show,
    };
  }

  updateHeaderMessage(type?: string, message?: string): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.UPDATE_HEADER_MESSAGE,
      payload: { type, message },
    };
  }

  showNavBar(show: boolean): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.UPDATE_NAV,
      payload: { show: show },
    };
  }

  showFooterLogo(show: boolean): ActionWithPayload<any> {
    return {
      type: LAYOUT_ACTION_TYPES.SHOW_LOGO,
      payload: { show: show },
    };
  }
}


/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.Layout = new LayoutActions();

// Adds actions definitions to ngrx-domains table
declare module '@app-ngrx-domains' {
  interface Actions {
    Layout: LayoutActions;
  }
}
