import { IMetricsExState, Model, Query, Queries, Root, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IMetricsExState>('MetricsEx');

/**
 * Predefined queries to run against root states.
 */
export interface IMetricsExQueries {
  get: Query<Array<Model.MetricItem>>;
}

Queries.MetricsEx = {
  get: fromRoot(state => state),
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    MetricsEx: Query<IMetricsExState>;
  }

  interface Queries {
    MetricsEx: IMetricsExQueries;
  }
}
