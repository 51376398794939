import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CHAR_LIMITS } from '@app-consts';
import { EnumErrorTypes } from '@app-models';
import { Actions, Model, State } from '@app-ngrx-domains';
import { LookupService, ProgramService } from '@app-services';
import { ValidatorsEx } from '@app-utilities';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-access-request-form',
  templateUrl: './access-request-form.component.html'
})

export class AccessRequestFormComponent implements OnInit {
  @Input() editingRequest: Model.AccessRequest;
  @Output() requestEditedEvent: EventEmitter<Model.AccessRequest> = new EventEmitter;
  @Output() requestSubmittedEvent: EventEmitter<Model.AccessRequest> = new EventEmitter;

  ACTIVITIES: string[] = ['Application — Create', 'Application — Edit', 'Application — Submit', 'Fiscal Report — Edit',
                          'Fiscal Report — Submit', 'Project — Create', 'Project — Edit', 'Project — Submit'];
  ACTIVITIES_READ_ONLY_TEXT: string = 'Read only access';
  DONT_KNOW_TEXT: string = 'I don\'t know';
  PROGRAMS: string[];
  PROGRAMS_ALL_TEXT: string = 'All';

  employerForm: FormGroup;
  form: FormGroup;
  formattedInstitutionsLookup: Array<Model.SelectOption> = [];
  isValidInputGroups: boolean;
  shouldShowEmployer: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private lookupService: LookupService,
    private programService: ProgramService,
    private store: Store<State>
  ) {}

  ngOnInit() {
    const activityControls: any = {
      dontKnow: [this.editingRequest ? this.editingRequest.activitiesDontKnow : false],
      readOnly: [this.editingRequest ? this.editingRequest.activitiesReadOnly : false]
    };
    const programControls: any = {
      all: [this.editingRequest ? this.editingRequest.programsAll : false],
      dontKnow: [this.editingRequest ? this.editingRequest.programsDontKnow : false]
    };

    this.PROGRAMS = this.programService.getProgramOptions().map(p => p.label);
    this.ACTIVITIES.forEach((a: string , i: number): void => {
      activityControls['a' + i] = [this.editingRequest ? this.editingRequest.activities.includes(a) : false];
    });
    this.PROGRAMS.forEach((p: string, i: number): void => {
      programControls['p' + i] = [this.editingRequest ? this.editingRequest.programs.includes(p) : false];
    });
    this.form = this.formBuilder.group({
      activities: this.formBuilder.group(activityControls),
      additionalInfo: [this.editingRequest ? this.editingRequest.additionalInfo : ''],
      programs: this.formBuilder.group(programControls)
    });
    this.isValidInputGroups = this.editingRequest !== undefined;

    this.employerForm = this.formBuilder.group({
      employer: [this.editingRequest && this.editingRequest.employer ? this.editingRequest.employer : undefined,
                [Validators.required, ValidatorsEx.requiredSelection]],
      newEmployer: this.formBuilder.group({
        addressLine1: [this.editingRequest && this.editingRequest.newEmployer ? this.editingRequest.newEmployer.addressLine1 : '',
                      [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)]],
        addressLine2: [this.editingRequest && this.editingRequest.newEmployer ? this.editingRequest.newEmployer.addressLine2 : ''],
        city: [this.editingRequest && this.editingRequest.newEmployer ? this.editingRequest.newEmployer.city : '',
              [Validators.required, Validators.minLength(2)]],
        name: [this.editingRequest && this.editingRequest.newEmployer ? this.editingRequest.newEmployer.name : '',
               [Validators.required, Validators.minLength(CHAR_LIMITS.NARRATIVE_MIN)]],
        zip: [this.editingRequest && this.editingRequest.newEmployer ? this.editingRequest.newEmployer.zip : '',
             [Validators.required, ValidatorsEx.zipCode]]
      })
    });
    this.toggleEmployer(!this.editingRequest || this.editingRequest.employer !== undefined);
  }

  get currentRequest(): Model.AccessRequest {
    return {
      activities: this.ACTIVITIES.filter((a: string, i: number): string[] => this.form.get('activities.a' + i).value),
      activitiesDontKnow: this.form.get('activities.dontKnow').value,
      activitiesReadOnly: this.form.get('activities.readOnly').value,
      additionalInfo: this.form.get('additionalInfo').value,
      employer: this.employerForm.get('employer').value ? this.employerForm.get('employer').value : undefined,
      newEmployer: this.employerForm.get('newEmployer').value || undefined,
      programs: this.PROGRAMS.filter((p: string, i: number): string[] => this.form.get('programs.p' + i).value),
      programsAll: this.form.get('programs.all').value,
      programsDontKnow: this.form.get('programs.dontKnow').value
    };
  }

  get formIdSuffix(): string {
    return this.editingRequest ? 'Editing' : '';
  }

  get isEachFormValid(): boolean {
    return this.employerForm.valid && this.form.valid && this.isValidInputGroups;
  }

  checkAllPrograms(): void {
    const isProgramsAllChecked: boolean = this.form.get('programs.all').value;
    this.PROGRAMS.forEach((p: string, i: number): void => this.form.get('programs.p' + i).setValue(isProgramsAllChecked));
    this.validateInputGroups();
  }

  checkNone(controlName: string): void {
    if (controlName === 'programsDontKnow') {
      this.uncheck('programs.all');
      this.PROGRAMS.forEach((p: string , i: number): void => this.uncheck('programs.p' + i));
    } else {
      if (controlName === 'activitiesDontKnow') {
        this.uncheck('activities.readOnly');
      } else {
        this.uncheck('activities.dontKnow');
      }
      this.ACTIVITIES.forEach((a: string, i: number): void => this.uncheck('activities.a' + i));
    }
    this.validateInputGroups();
  }

  checkSingle(controlGroup: string): void {
    if (controlGroup === 'programs') {
      this.uncheck('programs.all');
      this.uncheck('programs.dontKnow');
    } else {
      this.uncheck('activities.dontKnow');
      this.uncheck('activities.readOnly');
    }
    this.validateInputGroups();
  }

  emitEditedRequest(request: Model.AccessRequest): void {
    this.requestEditedEvent.emit(request);
  }

  setEmployerValidation(): void {
    this.employerForm.controls.employer.enable();
    this.employerForm.controls.newEmployer.disable();
    this.shouldShowEmployer = true;
  }

  submitForm(): void {
    this.requestSubmittedEvent.emit(this.currentRequest);
    this.employerForm.reset();
    this.form.reset();
    this.isValidInputGroups = false;
    this.setEmployerValidation();
  }

  toggleEmployer(isNewEmployerSection: boolean): void {
    // disable employer validation depending on which employer form is displayed
    if (isNewEmployerSection) {
      this.employerForm.controls.newEmployer.reset();
      this.setEmployerValidation();
    } else {
      this.employerForm.controls.employer.reset();
      this.employerForm.controls.employer.disable();
      this.employerForm.controls.newEmployer.enable();
      this.shouldShowEmployer = false;
    }
  }

  uncheck(controlName: string): void {
    if (this.form.get(controlName).value) {
      this.form.get(controlName).setValue(false);
    }
  }

  updateInstitutionsList(filterStr: string, limit: number = 30): void {
    const filters = {
      match_strings: filterStr,
      limit,
    }

    this.lookupService.formattedInstitutionList$(filters).subscribe(
      (res: any): void => this.formattedInstitutionsLookup = res,
      (raw: Error): void => this.store.dispatch(
        Actions.App.setError({
          location: this.constructor.name,
          raw,
          show: false,
          type: EnumErrorTypes.api
        })
      )
    );
  }

  validateInputGroups(): void {
    // set isValidInputGroups to true if at least 1 program and activity are selected
    const activityValues: boolean[] = Object.values(this.form.get('activities').value);
    const programValues: boolean[] = Object.values(this.form.get('programs').value);

    this.isValidInputGroups = activityValues.reduce((acc: boolean, val: boolean): boolean =>  acc || val) &&
                            programValues.reduce((acc: boolean, val: boolean): boolean =>  acc || val);
  }
}
