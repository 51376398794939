import * as _ from 'lodash';
import { FUND_TYPES, WORKPLAN_TYPES, CHAR_LIMITS } from '@app-consts';
import { WorkplanItem } from '@app-models';

/** Form Validator for Workplan2 tab
 */
export function validateStepData(w: any): boolean {

  const validateIPlan = () => {

    if (!(w.proposal.rationale_5 && w.proposal.rationale_5.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false
    }

    if (!(w.proposal.rationale_6 && w.proposal.rationale_6.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false
    }

    if (!(w.proposal.rationale_7 && w.proposal.rationale_7.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false
    }

    if (!(w.proposal.rationale_8 && w.proposal.rationale_8.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false
    }

    if (!(w.proposal.rationale_9 && w.proposal.rationale_9.length >= CHAR_LIMITS.NARRATIVE_MIN)) {
      return false
    }

    return true;
  }

  const validateGP = () => {
    for (let i = 0; i < w.items.length; i++) {
      const item = w.items[i];

      if (item.workplan_type.startsWith(WORKPLAN_TYPES.GP_IMPLEMENTATION) && !item.workplan_type.endsWith(WORKPLAN_TYPES.GP_ASSESSMENT)) {
        if (!WorkplanItem.isValidGPItem(item)) {
          return false;
        }
      }
    }

    return true;
  }

  switch (w.proposal.project_type) {
    case FUND_TYPES.IPLAN:
      return validateIPlan();

    case FUND_TYPES.GP:
      return validateGP();

    default:
      return false;
  }
}
