import { IProposalReviewsState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { PROPOSAL_REVIEWS_ACTION_TYPES } from './reviews.action';

export function ProposalReviewsReducer(state: IProposalReviewsState = State.ProposalReviews, action: ActionWithPayload<any>): IProposalReviewsState {
  switch (action.type) {

    case PROPOSAL_REVIEWS_ACTION_TYPES.GET: {
      return {
        ...State.ProposalReviews,
        is_loading: true,
      };
    }

    case PROPOSAL_REVIEWS_ACTION_TYPES.GET_SUCCESS: {
      return action.payload;
    }

    case PROPOSAL_REVIEWS_ACTION_TYPES.GET_FAIL: {
      return {
        ...State.ProposalReviews,
        is_loading: false,
        error: action.payload
      };
    }

    case PROPOSAL_REVIEWS_ACTION_TYPES.CREATE_TEMP: {
      const items = action.payload;
      return {
        ...state,
        items: [...state.items, ...items],
      };
    }

    case PROPOSAL_REVIEWS_ACTION_TYPES.UPSERT_SUCCESS: {
      const item = action.payload;
      const dx = state.items.findIndex(note => note.area_name === item.area_name
        && note.fund_id === item.fund_id
        && note.proposal_id === item.proposal_id
        && note.user_id === item.user_id);
      if (dx >= 0) {
        const old = state.items[dx];
        return {
          ...state,
          items: [
            ...state.items.slice(0, dx),
            {
              ...old,
              ...item,
              note: { ...old.note, ...item.note }
            },
            ...state.items.slice(dx + 1)
          ]
        };
      } else {
        return state;
      }
    }

    case PROPOSAL_REVIEWS_ACTION_TYPES.COMPLETE_TASK_SUCCESS: {
      return {
        ...state,
        task: action.payload
      };
    }

    default:
      return state;
  }
}
