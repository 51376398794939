import { IWorkplanState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { WORKPLAN_ACTION_TYPES } from './workplan.action';

export function WorkplanReducer(state: IWorkplanState = State.Workplan, action: ActionWithPayload<any>): IWorkplanState {
  switch (action.type) {
    case WORKPLAN_ACTION_TYPES.LOAD_ITEMS: {
      return {
        ...state,
        proposal_id: action.payload.proposal_id,
        items: action.payload.items
      };
    }

    case WORKPLAN_ACTION_TYPES.ADD_ITEM_SUCCESS: {
      // append new item at end of array.
      return {
        ...state,
        items: [...state.items, action.payload]
      };
    }

    case WORKPLAN_ACTION_TYPES.DELETE_ITEM_SUCCESS: {
      // remove item from the list
      const reducedItems = state.items.filter((item, index) => { return item.id !== action.payload });
      return {
        ...state,
        items: reducedItems
      };
    }

    case WORKPLAN_ACTION_TYPES.UPDATE_ITEM_SUCCESS: {
      // update the item
      const reducedItems = state.items.map((item, index) => {
        if (item.id === action.payload.id) {
          return {
            ...action.payload
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: reducedItems
      }
    }

    case WORKPLAN_ACTION_TYPES.ADD_ITEM_COLLEGE_SUCCESS: {
      // add the college to workplan item.
      const reducedItems = state.items.map((item, index) => {
        if (item.id === action.payload.itemId) {
          return {
            ...item,
            lead_institution: (action.payload.is_lead ? action.payload.institution : item.lead_institution),
            institution_ids: [...item.institution_ids, action.payload.institution_id],
            institutions: [...item.institutions, action.payload.institution],
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: reducedItems
      }
    }

    case WORKPLAN_ACTION_TYPES.REMOVE_ITEM_COLLEGE_SUCCESS: {
      // remove college from workplan item.
      const reducedItems = state.items.map((item, index) => {
        if (item.id === action.payload.itemId) {
          return {
            ...item,
            institution_ids: item.institution_ids.filter((institution_id) => { return institution_id !== action.payload.institution_id }),
            institutions: item.institutions.filter((inst) => { return inst.id !== action.payload.institution_id }),
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: reducedItems
      }
    }

    case WORKPLAN_ACTION_TYPES.ADD_ITEM_STRATEGY_SUCCESS: {
      const reducedItems = state.items.map((item, index) => {
        if (item.id === action.payload.itemId) {
          return {
            ...item,
            strategies: [...item.strategies, action.payload.strategy],
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: reducedItems
      }
    }

    case WORKPLAN_ACTION_TYPES.REMOVE_ITEM_STRATEGY_SUCCESS: {
      const reducedItems = state.items.map((item, index) => {
        if (item.id === action.payload.itemId) {
          return {
            ...item,
            strategies: item.strategies.filter((strategy) => { return strategy.id !== action.payload.strategy_id; }),
          };
        } else {
          return item;
        }
      });

      return {
        ...state,
        items: reducedItems
      };
    }

    default:
      return state;
  }
}
