import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State, Queries, Model } from '@app-ngrx-domains';
import { FUND_SETTINGS, PROGRAM_KEYS, FUND_TYPES } from '@app-consts';
import { Utilities, ProgramSettings, Fund } from '../models';
import { cloneDeep, groupBy, sortBy } from 'lodash';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class ProgramService {

  private _programSettings = {};
  private _programs: Array<Model.Fund>;
  private smallPrograms$ = new BehaviorSubject<Array<Model.Fund & { isActive?: boolean, isCompetitive?: boolean, settings_access?: boolean, review_access?: boolean, offer_access?: boolean }>>(undefined);
  private programsByKey: { [programKey: string]: Array<Model.Fund> };
  private isLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: ApiService,
    private store: Store<State>,
  ) {
    this.refreshFunds();
  }

  public async refreshSmallPrograms() {
    this.smallPrograms$.next(undefined);
    const smallPrograms = await this.apiService.getSmallPrograms()
      .pipe(
        take(1),
        map((res) => res.map(program => {
          program.isActive = Fund.programIsActive(program);
          program.isCompetitive = this.getChildProgramsByParentKey(program.key).find(grant => grant.program_settings.is_rfa);
          return program;
        }))
      ).toPromise();
    this.smallPrograms$.next(smallPrograms);
  }

  public async refreshFunds() {
    this.isLoaded$.next(false);

    const programs = await this.apiService.getv2Funds()
      .pipe(take(1))
      .toPromise();
    await this.refreshPrograms(programs);
    this.isLoaded$.next(true);

    this.store.select(Queries.Fund.get)
      .pipe(filter(f => !!(f && f.id)))
      .subscribe(fund => {
        const clonedFund = cloneDeep(fund);
        if (this._programs.find(p => p.id === clonedFund.id)) {
          this._programs = this._programs.map(program => (program.id === clonedFund.id) ? clonedFund : program);
        } else {
          this._programs.push(clonedFund);
        }
      });
  }


  private async refreshPrograms(programs) {
    programs = programs.filter(p => p.key); // Filter out programs that are not part of a workflow
    this._programs = programs;
    await this.refreshSmallPrograms();
    this.programsByKey = groupBy(programs, (value) => value.parent_key === PROGRAM_KEYS.SMALL_PROGRAMS ? value.key : value.parent_key || value.key);
    Object.keys(this.programsByKey).forEach(programKey => {
      const program = this.getParentProgramByKey(programKey);
      if (program) {
        // If the program doesn't have settings coming from the service, use the old settings defined in core/consts
        let settings = program.program_settings && program.program_settings.id ? program.program_settings : FUND_SETTINGS[program.id];
        if (program.key === PROGRAM_KEYS.IPLAN) {
          settings = FUND_SETTINGS[FUND_TYPES.IPLAN]; // There isn't an 'IPlan' fund, only children so we have to do this.
        }
        this._programSettings[programKey] = { name: program.name, ...settings };
      }
    });
  }

  isLoaded(): Observable<boolean> {
    return this.isLoaded$.asObservable();
  }

  get smallPrograms(): Observable<Array<Model.Fund>> {
    return this.smallPrograms$.asObservable();
  }

  get programs(): Array<Model.Fund> {
    return this._programs;
  }

  get parentPrograms(): Array<Model.Fund> {
    return this._programs.filter(p => !p.parent_key);
  }

  get programSettings(): { [programKey: string]: Model.EAProgramSettings } {
    return this._programSettings;
  }

  // Takes a known programKey, assumes a parent program
  getParentProgramByKey(programKey: string): Model.Fund {
    if (!!this.programsByKey[programKey]) {
      const programs = this.programsByKey[programKey];
      return programs ? programs.find(p => p.parent_key === null || p.parent_key === PROGRAM_KEYS.SMALL_PROGRAMS) : undefined;
    }
  }

  // Used to lookup program information for a given key
  getProgramByKey(programKey: string): Model.Fund {
    return this._programs.find(p => p.key === programKey);
  }

  // Used to lookup program information for a given id
  getProgramById(programId: number) {
    return this._programs.find(p => p.id === programId);
  }

  getParentProgramById(programId: number) {
    let program = this._programs.find(p => p.id === programId);
    if (program?.parent_key && program.parent_key !== PROGRAM_KEYS.SMALL_PROGRAMS) {
      program = this.getParentProgramByKey(program.parent_key);
    }
    return program;
  }

  getProgramParentKeyById(programId: number) {
    const p = this.getProgramById(programId);
    return p ? p.parent_key || p.key : undefined;
  }

  getChildProgramsByParentKey(programKey: string, childKey?: string): Array<Model.Fund> {
    return this._programs.filter(p => p.parent_key === programKey && (childKey ? p.key === childKey : true));
  }

  getRCContributorByParentKey(parentKey: string): Model.Fund {
    return this._programs.find(p => p.parent_key === parentKey && p.key === PROGRAM_KEYS.RCM_C);
  }

  getProgramDurationId(program: Model.Fund): number {
    if (program && program.program_settings.base_duration_id) {
      return program.program_settings.base_duration_id;
    }
  }

  replaceProgram(program: Model.Fund) {
    this._programs = this.programs.map(p => p.id === program.id ? program : p);
  }

  deleteProgramById(programId: number) {
    this._programs = this.programs.filter(p => p.id !== programId);
  }

  getFiscalReportSettings(programKey: string): Array<Model.EAReportingPeriod> {
    const programSettings = this._programSettings[programKey];
    return programSettings ? programSettings.reporting_periods : [];
  }

  getProgramSurveys(programKey: string) {
    const program = this.getParentProgramByKey(programKey);
    return program ? program.survey_templates : [];
  }

  /** Utility Methods **/

  /**
   * Returns an array of multi-select options for parent programs
   * @param collection - return 'values' as arrays of all parent programIds for each key (Useful for IPlan)
   *  When collection is true: [ { value: [4, 5 ,6], label: 'IPlan' }]
   *  When collection is false: [ { value: 4, label: 'IPlan' }]
   */
  getProgramOptions(collection?: boolean, short_name?: boolean) {
    const options = [];
    Object.entries(this.programsByKey).forEach(([key, programs]) => {
      const program = this.getParentProgramByKey(key);
      if (!program) {
        return;
      }
      const name = short_name ? Fund.getShortestName(program) : program.name;
      if (collection) {
        const programIds = programs.filter(p => !p.parent_key || p.parent_key === PROGRAM_KEYS.SMALL_PROGRAMS).map(p => p.id);
        options.push({ value: programIds, label: name });
      } else {
        options.push({ value: program.id, label: name });
      }
    });

    return sortBy(options, 'label');
  }

  /**
   * Returns parent program from the route.
   * @param route
   */
  getParentProgramFromRoute(route: string): Model.Fund {
    // get the module name from the route as parent program key.
    const programKey = Utilities.programKeyFromRoute(route);
    return this.getParentProgramByKey(programKey);
  }

  /**
   * Returns the parent program id, or program ids of children from the route.
   * @param route
   */
  getProgramIdsFromRoute(route: string): Array<number> {
    const program = this.getParentProgramFromRoute(route);
    if (!!program) {
      switch (program.key) {
        case PROGRAM_KEYS.CAI: {
          const childrenV1 = this.getChildProgramsByParentKey(program.key, PROGRAM_KEYS.RFA);
          const childrenV2 = this.getChildProgramsByParentKey(program.key, PROGRAM_KEYS.RFA_v2);
          return [...childrenV1, ...childrenV2].map(child => child.id);
        }
        default: {
          if (program.is_small_program) {
            return this.getChildProgramsByParentKey(program.key, PROGRAM_KEYS.RFA).map(child => child.id);
          }
          return [program.id];
        }
      }
    } else {
      return [];
    }
  }

  /**
   * Returns the guidance for a workflow step if it exists.
   * @param programId
   * @param proposal_type
   * @param workflow_step
   */
  getWorkflowStepGuidance(programId: number, proposal_type: string, workflow_step: string): string {
    let guidance: string;
    const program = this.getProgramById(programId);
    const guidanceSettings = program.program_settings.guidances.find(g => g.proposal_type === proposal_type && g.workflow_step === workflow_step);
    if (guidanceSettings) {
      guidance = guidanceSettings.description;
    }

    return guidance;
  }

  /**
   * Returns guidance text.
   * @param workflowFilter
   * @param fieldName
   */
  getWorkflowStepFieldGuidanceText(workflowFilter: Model.GuidanceWorkflowFilter, fieldName: string): string {
    // get program
    const program = this.getProgramById(workflowFilter.programId);

    // return guidance text
    return ProgramSettings.getGuidanceText(program.program_settings.guidances, workflowFilter, fieldName);
  }

  /**
   * Returns participating institution types that are set up for given program.
   * If it's a child program (like RFA), then it gets its list from the budget
   * match requirements, if one's been setup.
   * @param programId
   */
  getParticipatingInstitutionTypes(programId: number, checkMatchRequirements = true): Array<string> {
    const institutionTypes = [];

    const program = this.getProgramById(programId);
    if (program.parent_key) {
      // see if there are budget match requirements.
      if (checkMatchRequirements && program.program_settings.budget_match_requirements.length) {
        program.program_settings.budget_match_requirements.forEach((b: Model.EABudgetMatchRequirement) => {
          if (!Utilities.isNil(b.match_percent) && b.match_percent >= 0) {
            institutionTypes.push(b.institution_type);
          }
        });
      } else {
        if (program.program_settings.participating_institution_types.length) {
          program.program_settings.participating_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
        } else {
          // return list set up for parent.
          const parentProgram = this.getParentProgramByKey(program.parent_key);
          if (!!parentProgram.program_settings && parentProgram.program_settings.participating_institution_types) {
            parentProgram.program_settings.participating_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
          }
        }
      }
    } else {
      // return the list set up for program.
      program.program_settings.participating_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
    }

    return institutionTypes;
  }

  /**
   * Returns partner institution types from the parent program.
   * @param programId
   */
  getPartnerInstitutionTypes(programId: number): Array<string> {
    const institutionTypes = [];

    const program = this.getProgramById(programId);
    if (program.program_settings.partner_institution_types.length) {
      program.program_settings.partner_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
    } else {
      const parentKey = this.getProgramParentKeyById(programId);
      const parentProgram = this.getParentProgramByKey(parentKey);
      parentProgram.program_settings.partner_institution_types.forEach((t: Model.AttributeValue) => institutionTypes.push(t.value));
    }

    return institutionTypes;
  }

  /**
   * Recurses & finds the root fund.
   * @param program
   */
  getRootFund(program: Model.Fund): Model.Fund {
    if (program.parent_key && program.parent_key !== PROGRAM_KEYS.SMALL_PROGRAMS) {
      const parentProgram = this.programs.find(p => program.parent_key === p.key);

      return this.getRootFund(parentProgram);
    } else {
      return program;
    }
  }

  getBaseLink(programKey: string, suffix: string = ''): string {
    return `${this.isSmallProgram(programKey) ? `/${PROGRAM_KEYS.SMALL_PROGRAMS}` : ''}/${programKey}${suffix}`;
  }

  isSmallProgram(programKey) {
    const program = this.getProgramByKey(programKey);
    return program && program.is_small_program;
  }
}
