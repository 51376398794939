export const TASK_TYPES = {
    // System Tasks
    DEFINE_CHILD_FUND: 'define_child_fund',
    INVITE_NEW_USER: 'invite_new_user',
    STATE_ALLOCATIONS_SUBMIT: 'state_allocations_submit',
    SUB_ALLOCATIONS_SUBMIT: 'sub_allocations_submit',
    SUB_ALLOCATION_AMENDMENTS_SUBMIT: 'sub_allocation_amendments_submit',

    // Program Tasks
    CAEP_CFAD_SUBMIT: 'aebg_cfad_submit',
    CAEP_CFAD_APPROVE: 'aebg_cfad_member_approve',
    CAEP_CFAD_AMENDMENT_SUBMIT: 'aebg_cfad_amendment_submit',
    CAEP_CFAD_AMENDMENT_APPROVE: 'aebg_cfad_amendment_member_approve',
    CAEP_ANNUAL_PLAN_SUBMIT: 'aebg_annual_plan_submit',
    CAEP_ANNUAL_PLAN_APPROVE: 'aebg_annual_plan_approve',
    CAEP_ANNUAL_PLAN_AMENDMENT_SUBMIT: 'aebg_annual_plan_amendment_submit',
    CAEP_ANNUAL_PLAN_AMENDMENT_APPROVE: 'aebg_annual_plan_amendment_approve',
    CAEP_MEMBER_PLAN_SUBMIT: 'aebg_memberplan_submit',
    CAEP_MEMBER_PLAN_APPROVE: 'aebg_memberplan_approve',
    CAEP_FISCAL_REPORT_APPROVE: 'aebg_fiscal_report_approve',
    CAEP_PROGRAM_AREA_APPROVE: 'aebg_program_area_approve',
    CAEP_THREE_YEAR_PLAN_SUBMIT: 'aebg_three_year_plan_submit',
    CAEP_THREE_YEAR_PLAN_APPROVE: 'aebg_three_year_plan_approve',

    FISCAL_AGENT_ALLOCATION_SUBMIT: 'fiscal_agent_allocation_submit',

    GENERIC_SIGNUP: 'generic_signup',

    GP_CONTACT_SIGNUP: 'gp_contact_signup',
    GP_PROPOSAL_SUBMIT: 'gp_proposal_submit',
    GP_PROPOSAL_APPROVE: 'gp_proposal_approve',
    GP_CYCLE_CLOSE: 'gp_cycle_close',

    IPLAN_PROPOSAL_SUBMIT: 'iplan_proposal_submit',
    IPLAN_PROPOSAL_CLOSE: 'iplan_proposal_close',

    LVG_CONTACT_SIGNUP: 'lvg_contact_signup',
    LVG_PROPOSAL_SUBMIT: 'lvg_proposal_submit',
    LVG_PROPOSAL_APPROVE: 'lvg_proposal_approve',

    NEP_CONTACT_SIGNUP: 'nep_contact_signup',
    NEP_PROPOSAL_SUBMIT: 'nep_proposal_submit',
    NEP_PROPOSAL_APPROVE: 'nep_proposal_approve',

    PERKINS_CONTACT_SIGNUP: 'perkins_contact_signup',
    PERKINS_HEADCOUNT_SUBMIT: 'perkins_headcount_submit',
    PERKINS_HEADCOUNT_CERTIFY: 'perkins_headcount_certify',
    PERKINS_SUB_APPLICATION_SUBMIT: 'perkins_sub_application_submit',
    PERKINS_APPLICATION_SUBMIT: 'perkins_application_submit',
    PERKINS_APPLICATION_PRE_APPROVE: 'perkins_application_pre_approve',
    PERKINS_APPLICATION_APPROVE: 'perkins_application_approve',

    GRANT_ALLOCATION_SUBMIT: 'grant_allocation_submit',
    GRANT_AMENDMENT_SUBMIT: 'grant_amendment_submit',

    RFA_APPLICATION_WINDOW: 'rfa_application_window',
    RFA_APPLICATION_SUBMIT: 'rfa_application_submit',
    RFA_REVIEW_WINDOW: 'rfa_review_window',
    RFA_REVIEW_APPLICATION: 'rfa_review_application',
    RFA_FUNDING_OFFER: 'rfa_funding_offer',
    RFA_FUNDING_OFFER_BATCH: 'rfa_funding_offer_batch',
    RFA_FUNDING_TIEBREAKER: 'rfa_funding_tiebreaker',
    RFA_FUNDING_ACCEPT: 'rfa_funding_accept',
    RFA_PLAN_SUBMIT: 'rfa_plan_submit',
    RFA_PLAN_APPROVE: 'rfa_plan_approve',
    RFA_PLAN_CLOSE_SUBMIT: 'rfa_plan_close_submit',
    RFA_PLAN_CLOSE_APPROVE: 'rfa_plan_close_approve',
    RFA_PLAN_RENEW: 'rfa_plan_renew',

    RSI_REPORT_SUBMIT: 'rsi_report_submit',
    RSI_REPORT_APPROVE: 'rsi_report_approve',
    RSI_WORKFLOW_SUBMIT: 'rsi_workflow_submit',

    SEP_CONTACT_SIGNUP: 'sep_contact_signup',
    SEP_PROPOSAL_SUBMIT: 'sep_proposal_submit',
    SEP_PROPOSAL_APPROVE: 'sep_proposal_approve',
    SEP_MID_ALLOCATION_REPORT_SUBMIT: 'sep_mid_allocation_report_submit',
    SEP_MID_ALLOCATION_REPORT_APPROVE: 'sep_mid_allocation_report_approve',
    SEP_ALLOCATION_END_REPORT_SUBMIT: 'sep_allocation_end_report_submit',
    SEP_ALLOCATION_END_REPORT_APPROVE: 'sep_allocation_end_report_approve',
    SEP_DISTRICT_ALLOCATION_END_SUBMIT: 'seap_term_end_submit',
    SEP_DISTRICT_ALLOCATION_END_APPROVE: 'seap_term_end_approve',

    SP_SETUP: 'setup_small_program',

    SWP_PROPOSAL_SUBMIT: 'swp_proposal_submit',
    SWP_PROPOSAL_APPROVE: 'swp_proposal_approve',
    SWP_PROPOSAL_APPROVE_V2: 'swp_v2_proposal_approve',
    SWP_PROPOSAL_CLOSE: 'swp_proposal_close',

    SWPK_LOI_SUBMIT: 'swpk_loi_submit',
    SWPK_APPLICATION_SUBMIT: 'swpk_application_submit',
    SWPK_REGION_REVIEW: 'swpk_region_review',
    SWPK_REGION_FUNDING: 'swpk_region_funding',
    SWPK_REVIEW_SUBMIT: 'swpk_application_review_submit',
    SWPK_FUNDING_SUBMIT: 'swpk_application_funding_submit',
    SWPK_FUNDING_APPROVE: 'swpk_application_funding_approve',
    SWPK_PLAN_SUBMIT: 'swpk_plan_submit',
    SWPK_PLAN_APPROVE: 'swpk_plan_approve',
    SWPK_PLAN_CLOSE_SUBMIT: 'swpk_plan_close_submit',
    SWPK_PLAN_CLOSE_APPROVE: 'swpk_plan_close_approve',

    FISCAL_REPORT_SUBMIT: 'fiscal_report_submit',
    FISCAL_REPORT_APPROVE: 'fiscal_report_approve',

    ANNUAL_PROJECT_ALLOCATION: 'annual_project_allocation'
};
