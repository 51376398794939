import { Model } from '@app-ngrx-domains';
import { ModelBase } from './model.base';
import { WORKFLOW_STEPS } from '../consts';
import { Duration, Proposal, Utilities } from './'
import { ENTITY_CHILD_ROUTES, FUND_SETTINGS, PROGRAM_KEYS, ROUTER_LINKS, AREAS } from '../consts';
import { ResourceNote } from './resource-note';
import { ResourceState } from './resource-state';
import { ProgramService } from '../services';
import { ProposalBase, PROPOSAL_TYPES } from './proposal-base';


export const AREA_NAMES = {
    [WORKFLOW_STEPS.ACTIVITIES]: WORKFLOW_STEPS.ACTIVITIES,
    [WORKFLOW_STEPS.ALIGNMENT]: WORKFLOW_STEPS.ALIGNMENT,
    [WORKFLOW_STEPS.AGENCIES]: WORKFLOW_STEPS.AGENCIES,
    [WORKFLOW_STEPS.BUDGET]: WORKFLOW_STEPS.BUDGET,
    [WORKFLOW_STEPS.CONTACTS]: WORKFLOW_STEPS.CONTACTS,
    [WORKFLOW_STEPS.CAPABILITY]: WORKFLOW_STEPS.CAPABILITY,
    [WORKFLOW_STEPS.DETAILS]: WORKFLOW_STEPS.DETAILS,
    [WORKFLOW_STEPS.DESCRIPTION]: WORKFLOW_STEPS.DESCRIPTION,
    [WORKFLOW_STEPS.DISSEMINATION]: WORKFLOW_STEPS.DISSEMINATION,
    [WORKFLOW_STEPS.DOCUMENTS]: WORKFLOW_STEPS.DOCUMENTS,
    [WORKFLOW_STEPS.FEASIBILITY]: WORKFLOW_STEPS.FEASIBILITY,
    [WORKFLOW_STEPS.MANAGEMENT]: WORKFLOW_STEPS.MANAGEMENT,
    [WORKFLOW_STEPS.METRICS]: WORKFLOW_STEPS.METRICS,
    [WORKFLOW_STEPS.NEEDS]: WORKFLOW_STEPS.NEEDS,
    [WORKFLOW_STEPS.OBJECTIVES]: WORKFLOW_STEPS.OBJECTIVES,
    [WORKFLOW_STEPS.OUTCOMES]: WORKFLOW_STEPS.OUTCOMES,
    [WORKFLOW_STEPS.PARTNERS]: WORKFLOW_STEPS.PARTNERS,
    [WORKFLOW_STEPS.PATHWAY]: WORKFLOW_STEPS.PATHWAY,
    [WORKFLOW_STEPS.PROBLEM_STATEMENT]: WORKFLOW_STEPS.PROBLEM_STATEMENT,
    [WORKFLOW_STEPS.QUALIFICATIONS]: WORKFLOW_STEPS.QUALIFICATIONS,
    [WORKFLOW_STEPS.RESPONSE]: WORKFLOW_STEPS.RESPONSE,
    [WORKFLOW_STEPS.RESPONSE_TO_PROBLEM]: WORKFLOW_STEPS.RESPONSE_TO_PROBLEM,
    [WORKFLOW_STEPS.SECTORS]: WORKFLOW_STEPS.SECTORS,
    [WORKFLOW_STEPS.SOW]: WORKFLOW_STEPS.SOW,
    [WORKFLOW_STEPS.TARGET]: WORKFLOW_STEPS.TARGET,
    [WORKFLOW_STEPS.WORKPLAN]: WORKFLOW_STEPS.WORKPLAN,
    ALLOCATION_BUDGETED: 'allocation-budgeted',
    ANALYTICS: 'analytics',
    ASSESSMENT: 'assessment',
    BUDGET_FOLLOWUP: 'budget-followup',
    CONSORTIUM: 'consortium',
    FIFO: 'fifo',
    MEMBER: 'member',
    PROGRAM: 'program',
    ROLLUP: 'rollup',
    SUMMARY: 'summary',
    THREE_YEAR_PLAN: 'three-year-plan',
};

export const FUND_TITLES = {
    [PROGRAM_KEYS.SWP_K12]: {
        [AREAS.PROJECT]: 'Letter of Intent',
        [AREAS.LOI]: 'Letter of Intent',
    },
    [PROGRAM_KEYS.CAEP]: {
        [AREAS.ANNUAL_PLAN]: 'Annual Plan',
        [AREAS.BUDGET]: 'Member Budget & Workplan',
        [AREAS.CFAD]: 'Consortium Fiscal Administration Declaration',
        [AREAS.FISCAL_REPORT]: 'Consortium Fiscal Reporting',
        [AREAS.PROGRAM_AREA_REPORT]: 'Program Area Reporting',
        [AREA_NAMES.CONSORTIUM]: 'Institution Homepage',
        [AREA_NAMES.THREE_YEAR_PLAN]: 'Three-Year Plan',
        [AREA_NAMES.MEMBER]: 'Member Dashboard',
        [AREA_NAMES.ANALYTICS]: 'Monitor Reports',
        [AREA_NAMES.ROLLUP]: 'Member Budget & Workplan Rollup',
        [AREA_NAMES.FIFO]: 'FIFO Report Dashboard',
        [AREA_NAMES.ALLOCATION_BUDGETED]: 'Allocations vs Budgets Report',
    },
    [PROGRAM_KEYS.LVG]: {
        [AREAS.PROJECT]: 'Goals',
    },
    [PROGRAM_KEYS.SEP]: {
        [AREAS.PROJECT]: 'Plans',
        [AREAS.MID_ALLOCATION_REPORT]: 'Annual Report',
        [AREAS.ALLOCATION_END_REPORT]: 'Term-End Expenditure Report'
    },
    [PROGRAM_KEYS.NEP]: {
        [AREAS.PROJECT]: 'Plans',
        [AREAS.FISCAL_REPORT]: 'Fiscal Reporting'
    },
    [PROGRAM_KEYS.PERKINS]: {
        [AREAS.HEADCOUNT_CERTIFICATION]: 'Headcount Certification'
    }
};

export const SHARED_TITLES = {
    [AREA_NAMES.ANALYTICS]: 'Analytics',
    [AREAS.APPLICATION]: 'Applications',
    [AREAS.DELEGATE]: 'Review Details',
    [AREAS.FISCAL_REPORT]: 'Fiscal Reporting',
    [AREA_NAMES.FIFO]: 'FIFO Report Dashboard',
    [AREA_NAMES.ALLOCATION_BUDGETED]: 'Allocations vs Budgets Report',
    [AREAS.FUND_SETTINGS]: 'Fund Settings',
    [AREAS.PROJECT]: 'Project',
    [AREAS.PLAN]: 'Plan',
    [AREAS.REVIEW]: 'Review',
}

export interface IResourceScore {
    resource?: IResource,
    resources?: Array<IResource>,
    scorer?: Model.User,
    scorer_index?: number,
    total?: number,
    excluded?: boolean,
}

export interface IResourceLink {
    route: string,
    queryParams?: {[key: string]: string|number}
}

export class ResourceLink {
    public route = '';
    public queryParams?: {[key: string]: string|number} = {};

    constructor(raw?: IResourceLink) {
        if (raw) {
            this.route = raw.route;
            this.queryParams = raw.queryParams;
        }
    }
}

export interface IResourceTitle {
    title: string,
    area: string,
}

export class ResourceTitle implements IResourceTitle {
    public title = 'N/A';
    public area = 'N/A/';

    constructor(raw?: IResourceTitle) {
        if (raw) {
            this.title = raw.title;
            this.area = raw.area;
        }
    }
}


export interface IResource {
    id?: number;
    user_id?: number;
    fund_id?: number;
    proposal_id?: number;
    proposal?: Proposal;
    institution_id?: number;
    area_name?: string;
    duration_id?: number;
    duration?: Model.Duration;
    note?: Model.ResourceNote;
    state?: Model.ResourceState;
}

export class Resource extends ModelBase implements IResource {
    public id?: number;
    public user_id?: number;
    public fund_id?: number;
    public proposal_id?: number;
    public proposal?: Proposal;
    public institution_id?: number;
    public institution_name?: string;
    public area_name?: string;
    public duration_id?: number;
    public duration?: Duration;
    public note?: ResourceNote;
    public state?: ResourceState;

    constructor(raw: IResource) {
        super();
        this.id = raw.id;
        this.user_id = raw.user_id;
        this.fund_id = raw.fund_id;
        this.proposal_id = raw.proposal_id;
        this.proposal = raw.proposal;
        this.institution_id = raw.institution_id;
        this.area_name = raw.area_name;
        this.duration_id = raw.duration_id;
        this.duration = new Duration(raw.duration);
        this.note = new ResourceNote(raw.note);
        this.state = new ResourceState(raw.state);
    }

    /**********************
     * NAVIGATION FUNCTIONS
     *************/

    /**
     * Generate a route for a resource note.
     * @param {Resource} resource - the resource we want to route to.
     * @param {Fund} program Used to get key for route segments.
     * @returns {...ResourceLink}
     */
    static resourceLink(resource: IResource, program: Model.Fund): ResourceLink {
        if (resource.note.parent_id) {
            return { route: '' };
        }
        if (resource.proposal && resource.proposal.type === PROPOSAL_TYPES.FISCAL_AGENT) {
            return Resource.fiscalAgentRoute(resource);
        } else if (resource.fund_id) {
            return Resource.routeByFund(resource, program);
        } else if (resource.proposal) {
            return {
                route: Proposal.routerLink(resource.proposal)
            }
        }
    }

    private static fiscalAgentRoute(note: IResource): ResourceLink {
        if (note.area_name === AREAS.FISCAL_REPORT) {
            return { route: `${ROUTER_LINKS.FISCAL_AGENTS}/fiscal-reports/${note.proposal_id}` };
        } else {
            return { route: ROUTER_LINKS.FISCAL_AGENTS };
        }
    }

    /**
     * Generate a route for a resource by checking the note.fund_id
     * @param {Resource} note - The resource you wish to route to
     * @returns {ResourceLink}
     */
    private static routeByFund(resource: IResource, program: Model.Fund): ResourceLink {
        const programKey = program ? program.parent_key || program.key : undefined;
        switch (programKey) {
            case PROGRAM_KEYS.CAEP:
                return Resource.AEBGRoute(resource);
            case PROGRAM_KEYS.IPLAN:
            case PROGRAM_KEYS.SWP_L:
            case PROGRAM_KEYS.SWP_R:
                return Resource.simpleRoute(resource, programKey);
            case PROGRAM_KEYS.GP:
                if (resource.area_name === AREAS.FISCAL_REPORT) {
                  return Resource.simpleRoute(resource, programKey, undefined, true);
                } else {
                  return Resource.gpRoute(resource, programKey, program.key);
                }
            case PROGRAM_KEYS.LVG:
                return Resource.simpleRoute(resource, programKey, 'goals');
            case PROGRAM_KEYS.SEP:
              return Resource.SEPRoute(resource)
            case PROGRAM_KEYS.NEP:
                if (resource.area_name === AREAS.FISCAL_REPORT) {
                    return Resource.simpleRoute(resource, programKey, undefined, true);
                } else {
                    return Resource.simpleRoute(resource, programKey, 'plans');
                }
            case PROGRAM_KEYS.SWP_K12:
            case PROGRAM_KEYS.CAI:
            case PROGRAM_KEYS.EWD:
                return Resource.RFARoute(resource, programKey);
            case PROGRAM_KEYS.PERKINS:
                return Resource.perkinsRoute(resource, programKey);
            default:
                if (program && program.is_small_program) {
                    const sp = PROGRAM_KEYS.SMALL_PROGRAMS;
                    const spKey = `${sp}/${programKey === sp ? program.key : programKey}`;
                    if (program.program_settings.is_rfa) {
                        return Resource.RFARoute(resource, spKey);
                    }
                    return Resource.simpleRoute(resource, spKey);
                } else if (resource.proposal_id) {
                    return { route: Proposal.routerLink({ ...resource.proposal, project_type: resource.fund_id }) };
                } else if (FUND_SETTINGS[resource.fund_id]) {
                    return { route: FUND_SETTINGS[resource.fund_id].programKey };
                }
        }
    }

    private static SEPRoute(resource: IResource) {
      const { proposal } = resource;

      if (proposal) {
        if (proposal.type === PROPOSAL_TYPES.DISTRICT_REPORTING) {
          return { route: `/${ROUTER_LINKS.SEP_REPORTING}/districts/allocation-end/${resource.proposal_id}`};
        } else if (resource.area_name === AREAS.MID_ALLOCATION_REPORT || resource.area_name === AREAS.ALLOCATION_END_REPORT) {
          const baseLink = `/${ROUTER_LINKS.SEP_REPORTING}/colleges`;
          const reportLink = resource.area_name === AREAS.MID_ALLOCATION_REPORT
            ? 'mid-allocation'
            : 'allocation-end';

          return { route: `/${baseLink}/${reportLink}/${resource.proposal_id}`};
        } else {
          return { route: `/${ROUTER_LINKS.SEP_PLANS}/${resource.proposal_id}`};
        }
      } else {
        return { route: `/${ROUTER_LINKS.SEP_PLANS}` }
      }
    }

    /**
     * Generate a resource route for AEBG
     * @param {Resource} note - The resource you wish to route to
     * @returns {ResourceLink}
     */
    private static AEBGRoute(note: IResource): ResourceLink {
        if (note.proposal_id && note.institution_id) {
            if (Resource.AEBGInstIsLead(note)) { // not lead inst
                return Resource.AEBGConsortiaRoute(note);
            } else {
                return Resource.AEBGMemberRoute(note);
            }
        } else if (note.area_name === AREAS.FUND_SETTINGS) {
            return { route: '/' + ROUTER_LINKS.AEBG + '/settings' };
        } else if (note.area_name === AREA_NAMES.ANALYTICS) {
            return { route: '/' + ROUTER_LINKS.AEBG + '/monitor-summary' };
        } else if (note.area_name === AREAS.FISCAL_REPORT) {
            const route = '/' + ROUTER_LINKS.AEBG + '/fiscal-reports';
            if (note.proposal_id) {
                return { route: route + '/' + note.proposal_id };
            } else {
                return { route };
            }
        } else if (note.area_name === AREA_NAMES.FIFO) {
            return { route: '/' + ROUTER_LINKS.AEBG + '/fiscal-reports/fifo' };
        } else {
            return { route: '/' + ROUTER_LINKS.AEBG_CONSORTIA };
        }
    }

    /**
     * Generate AEBG routes beginning with the consortia baselink
     * @param {Resource} note - The resource you wish to route to
     * @returns {ResourceLink}
     */
    private static AEBGConsortiaRoute(note: IResource): ResourceLink {
        const consortium_link = `/${ROUTER_LINKS.AEBG_CONSORTIA}/${note.proposal_id}/${note.institution_id}`;
        const resourceLink = new ResourceLink();

        switch (note.area_name) {
            case AREAS.CFAD:
                if (note.duration_id) {
                    resourceLink.route = consortium_link + `/${note.duration.year}/cfad-amendment`;
                } else {
                    resourceLink.route = consortium_link + `/${ENTITY_CHILD_ROUTES.CFAD}`;
                }
                break;
            case AREAS.ANNUAL_PLAN:
                resourceLink.route = consortium_link + `/${ENTITY_CHILD_ROUTES.ANNUAL_PLAN}`;
                break;
            case AREA_NAMES.THREE_YEAR_PLAN:
                resourceLink.route = consortium_link + `/${ENTITY_CHILD_ROUTES.THREE_YEAR_PLAN}`;
                break;
            case AREAS.FISCAL_REPORT:
                resourceLink.route = `${ROUTER_LINKS.AEBG}/${ENTITY_CHILD_ROUTES.FISCAL_REPORTS}/proposals/${note.proposal_id}/${note.institution_id}`;
                if (note.duration_id) {
                    resourceLink.queryParams.year = note.duration.year;
                }
                break;
            case AREAS.PROGRAM_AREA_REPORT:
                resourceLink.route = `${ROUTER_LINKS.AEBG}/${ENTITY_CHILD_ROUTES.PROGRAM_AREA_REPORTS}/${note.proposal_id}/${note.institution_id}`;
                if (note.duration_id) {
                    resourceLink.queryParams.year = note.duration.year;
                }
                break;
            case AREA_NAMES.ROLLUP:
                if (note.duration_id) {
                    resourceLink.route = consortium_link + `/${note.duration.year}/rollup`;
                } else {
                    resourceLink.route = consortium_link;
                }
                break;
            case undefined:
            case null:
            default:
                resourceLink.route = consortium_link;
                break;
        }
        return resourceLink;
    }

    /**
     * Return AEBG routes beginning with member agency baselink
     * @param {Resource} note - The resource you wish to route to
     * @returns {ResourceLink}
     */
    private static AEBGMemberRoute(note: IResource): ResourceLink {
        const member_link = `/${ROUTER_LINKS.AEBG_MEMBERS}/${note.proposal_id}/${note.institution_id}`;
        const resourceLink = new ResourceLink();

        switch (note.area_name) {
            case AREAS.BUDGET:
                resourceLink.route = member_link + (note.duration_id ? `/${note.duration.year}/project` : '');
                break;
            case AREAS.PROGRAM_AREA_REPORT:
                resourceLink.route = `/${ROUTER_LINKS.AEBG}/${ENTITY_CHILD_ROUTES.PROGRAM_AREA_REPORTS}/${note.proposal_id}/${note.institution_id}`;
                if (note.duration_id) {
                    resourceLink.route += '/' + note.duration.year;
                }
                break;
            case undefined:
            case null:
            default:
                resourceLink.route = member_link;
                break;
        }
        return resourceLink;
    }

    private static gpRoute(note: IResource, programKey: string, subKey: string): ResourceLink {
        const resourceLink = new ResourceLink();
        const base_link = `${programKey}`;

        switch (note.area_name) {
            case AREA_NAMES.ANALYTICS:
                resourceLink.route = `${base_link}/analytics`;
                break;
            case AREAS.FUND_SETTINGS:
                resourceLink.route = `${base_link}/settings`;
                break;
            default:
            case AREAS.PROJECT:
                if (note.proposal_id) {
                    resourceLink.route = `${base_link}/proposals/${subKey}/${note.proposal_id}`
                } else {
                    resourceLink.route = `${base_link}/proposals`
                }
                break;
        }
        return resourceLink;
    }

    private static perkinsRoute(note: IResource, programKey: string): ResourceLink {
        if (note.area_name === AREAS.HEADCOUNT_CERTIFICATION) {
            // at this time, only 1c has headcount. any headcount routes go to perkins/headcount/perkins-1c/
            const p1cKey = PROGRAM_KEYS.PERKINS_1C;
            const resourceLink = new ResourceLink();
            if (note.duration_id && note.institution_id) {
                resourceLink.route = `${programKey}/${p1cKey}/headcount/${note.duration_id}/${note.institution_id}`;
            } else {
                resourceLink.route = `${programKey}/${p1cKey}/headcount`;
            }
            return resourceLink;
        } else {
            return Resource.RFARoute(note, programKey);
        }
    }


    /**
     * simpleRoute works for the basic funds, SWP R/L, IPlan, GP
     * SimpleRoute is for funds that don't have fiscal reporting (IPlan, GP)
     * @param {Resource} note
     * @returns {ResourceLink}
     */
    private static simpleRoute(note: IResource, programKey: string, proposalSegment = 'proposals', noSegment?: boolean): ResourceLink {
        const base_link = programKey;
        const resourceLink = new ResourceLink();
        switch (note.area_name) {
            case AREAS.FISCAL_REPORT:
                const FRRoute = `${base_link}/fiscal-reports`;
                if (note.proposal_id) {
                    const segment = noSegment ? '' : `/${proposalSegment}`;
                    resourceLink.route = `${FRRoute}${segment}/${note.proposal_id}`;
                    if (note.duration_id) {
                        resourceLink.queryParams = { year: note.duration.year };
                    }
                } else {
                    resourceLink.route = FRRoute;
                }
                break;
            case AREAS.FUND_SETTINGS:
                resourceLink.route = base_link + `/settings`;
                break;
            case AREA_NAMES.ANALYTICS:
                resourceLink.route = base_link + `/analytics`;
                break;
            case AREA_NAMES.FIFO:
                resourceLink.route = `/${programKey}/fiscal-reports/${[AREA_NAMES.FIFO]}`;
                break;
            case AREA_NAMES.ALLOCATION_BUDGETED:
                resourceLink.route = `/${programKey}/fiscal-reports/${[AREA_NAMES.ALLOCATION_BUDGETED]}`;
                break;
            case AREAS.PROJECT:
            default:
                if (note.proposal_id) {
                   resourceLink.route = Proposal.routerLink({ ...note.proposal, project_type: note.fund_id });
                } else {
                    resourceLink.route = base_link + `/${proposalSegment}`;
                }
        }
        return resourceLink;
    }

    /**
     * Generate RFA resource route.
     * @param {Resource} resource - The resource you wish to route to
     * @returns {string}
     */
    private static RFARoute(resource: IResource, programKey?: string): ResourceLink {
        if (!programKey) {
            programKey = Proposal.getProgramKey(resource.proposal);
        }

        let base_link: string;
        const extras = [];
        const resourceLink = new ResourceLink();
        const versionString = () => {
            if (resource.proposal && programKey === PROGRAM_KEYS.SWP_K12 && [AREAS.APPLICATION, AREAS.PLAN].includes(resource.area_name)) {
                return `/${ProposalBase.version(resource.proposal)}`;
            } else {
                return '';
            }
        }
        const subFundString = () => {
            if (resource.proposal && programKey === PROGRAM_KEYS.PERKINS &&
                [AREAS.APPLICATION, AREAS.PLAN, AREAS.FISCAL_REPORT,AREAS.REVIEW, AREAS.OFFER, AREAS.DELEGATE].includes(resource.area_name)) {
                return `/${resource.proposal.funds[0].key}`;
            } else {
                return '';
            }
        }

        switch (resource.area_name) {
            case AREAS.REVIEW:
                base_link = `${programKey}${subFundString()}/reviews`;
                extras.push(resource.note.creator_id);
                break;
            case AREAS.DELEGATE:
                base_link = `${programKey}${subFundString()}/reviews`;
                break;
            case AREAS.FUND_SETTINGS:
                base_link = `${programKey}/settings`;
                break;
            case AREAS.PLAN:
                base_link = `${programKey}${subFundString()}/plans`;
                break;
            case AREAS.LOI:
                base_link = `${programKey}/lois`;
                break;
            case AREAS.FISCAL_REPORT:
                base_link = `${programKey}${subFundString()}/fiscal-reports`;
                break;
            case AREAS.APPLICATION:
            default:
                base_link = `${programKey}${subFundString()}/applications`;
                break;
        }
        if (resource.proposal_id) {
            resourceLink.route = `${base_link}/${resource.proposal_id}`;
            if (extras.length > 0) {
                resourceLink.route += '/' + extras.join('/');
            }
            resourceLink.route += versionString();
        } else {
            resourceLink.route = base_link;
        }

        return resourceLink;
    }

    static resourceTitle(note: IResource, programService: ProgramService): ResourceTitle {
        const title = new ResourceTitle();
        const programKey = programService.getProgramParentKeyById(note.fund_id);
        // try to get a fund-specific area name
        let spec_title = undefined;
        if (FUND_TITLES[programKey]) {
            spec_title = FUND_TITLES[programKey][note.area_name];
        }
        if (spec_title) {
            title.area = spec_title;
        } else {
            title.area = SHARED_TITLES[note.area_name];
        }
        if (note.proposal_id) {
            if (note.area_name === AREAS.FISCAL_REPORT && programKey === PROGRAM_KEYS.GP){
              title.title = Proposal.fiscalReportTitle(note.proposal, programKey);
            } else {
              title.title = note.proposal.title;
            }
        } else {
            title.title = title.area;
        }
        return title;
    }

    private static AEBGInstIsLead(note: IResource) {
        return (note.proposal && note.proposal.lead_institution.id === note.institution_id);
    }

    /**
     * compareResponse() is used to find a resourceNote which matches the one found in the headerState. Some resource_notes
     * are identical except one is missing a field. If you're trying to find one specifically that loosely matches several,
     * use this functions.
     * @param local
     * @param response
     * @returns {boolean}
     *
     * What was the use case for a loose match? Why do we not want to strictly match on all set fields?
     */
    static compareResponse(local: IResource, response: IResource): boolean {
        const compare_fields = [ 'fund_id', 'proposal_id', 'institution_id', 'area_name', 'duration_id' ];
        response = this.resourcePayload(response);
        // console.log(local, response);
        return compare_fields.every(key => {
            return local[key] === response[key];
        })
    }

    /**
     * Builds object with resource's required fields.
     * @param bookmark
     */
    static resourcePayload(bookmark: Model.Resource) {
        const payload = {};
        ['fund_id', 'proposal_id', 'institution_id', 'area_name', 'duration_id'].forEach(key => {
            if (bookmark[key]) {
                payload[key] = bookmark[key];
            }
        });
        return payload;
    }

    /****************
     * Review Functions
     */

    static getTotalScore(notes: Array<IResource>): number {
        const isNull = notes.every(n => Utilities.isNil(n.note.score) && n.area_name !== AREA_NAMES.BUDGET_FOLLOWUP);
        return (isNull)
            ? null
            : notes.reduce((sum, n) => (!isNaN(n.note.score) && n.area_name !== AREA_NAMES.BUDGET_FOLLOWUP) ? sum + n.note.score : sum, 0);
    }

    static getTotalScoreByUserId(notes: Array<IResource>, userId: number): number {
        const totalScore = notes.filter(n => n.user_id === userId)
            .reduce((sum, n) => !isNaN(n.note.score) ? sum + n.note.score : sum, 0);
        return totalScore;
    }

    static getAverageScore(scoreNotes: Array<IResourceScore>): number {
        const notes = scoreNotes.filter(sn => sn.resource && !sn.excluded).map(n => n.resource);
        if (notes && notes.length) {
            const totalScore = this.getTotalScore(notes);
            const avg = totalScore / notes.length;
            return avg;
        }
    }

    static getTotalSubScore(notes: Array<IResource>, areaName: string): number {
        const areaNamePrefix = `${areaName}--`;
        const subScoreNotes = notes.filter(n => n.area_name.startsWith(areaNamePrefix));
        const isNull = subScoreNotes.length ? subScoreNotes.every(n => Utilities.isNil(n.note.score)) : true;
        return (isNull)
            ? null
            : subScoreNotes.reduce((sum, n) => (!isNaN(n.note.score) && n.area_name.startsWith(areaNamePrefix)) ? sum + n.note.score : sum, 0);
    }
}

declare module '@app-ngrx-domains' {
    export namespace Model {
        export type Resource = IResource;
        export type ResourceScore = IResourceScore;
    }
}
