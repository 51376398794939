<div class="type-ahead__results-container" *ngIf="showResultsList()">
  <ul #resultsScroller class="type-ahead__results-list">
    <ng-container *ngFor="let option of options; let index = index; trackBy:trackById">
      <results-list-item #item class="type-ahead__results-list-item-host"
                         *ngIf="foundInSearch(option)"
                         (mousedown)="optionSelectedClick(item)"
                         (mouseover)="handleMouseOver(item)"
                         [searchValue]="input.control.value"
                         [option]="option"
                         [index]="index">
      </results-list-item>
    </ng-container>
 </ul>
</div>
