import { Model } from '@app-ngrx-domains';
import { ProposalBase } from './proposal-base';
import { Task } from './task';
import { TASK_TYPES } from '../consts';

export interface ILVGProposal extends Model.ProposalBase {
  impacted_groups: Array<Model.AttributeValue>;
  submit_task: Model.Task;
  approve_tasks: Array<Model.Task>;
}

export class LVGProposal extends ProposalBase implements ILVGProposal {
  public impacted_groups: Array<Model.AttributeValue>;
  public submit_task: Model.Task;
  public approve_tasks: Array<Model.Task>;

  constructor(raw?: any) {
    super(raw);

    if (!raw) {
      // nothing to hydrate
      return;
    }

    this.impacted_groups = raw.impacted_groups;
    this.submit_task = raw.submit_task;
    this.approve_tasks = raw.approve_tasks;
  }

  /**
   * Computes lvg state for LVG proposals, based on the state of tasks.
   * @param code {string}
   * @param p
   */
  static lvgStateId(p: LVGProposal | Model.LVGProposal): number {
    let submit_task: Model.Task;
    let approve_tasks: Array<Model.Task>;

    if (p.tasks && p.tasks.length > 0) {
      // get the submit & approve tasks from the tasks list for now
      submit_task = p.tasks.find(task => task.task_type === TASK_TYPES.LVG_PROPOSAL_SUBMIT);
      approve_tasks = p.tasks.filter(task => task.task_type === TASK_TYPES.LVG_PROPOSAL_APPROVE);
    } else {
      submit_task = p.submit_task;
      approve_tasks = p.approve_tasks;
    }

    return Task.getStateFromTasksWMultipleApprovers(submit_task, approve_tasks);
  }
}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type LVGProposal = ILVGProposal;
 }
}
