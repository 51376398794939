import { Pipe, PipeTransform, SecurityContext } from '@angular/core';

@Pipe({
  name: 'replaceNewline'
})
export class ReplaceNewlinePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.replace(/\r\n|\r|\n/g, '<br>');
  }

}
