import { IProposalReportsState, Model, Query, Queries, Root, combineRootFactory } from '@app-ngrx-domains';

/**
 * Get the root states.
 */
const fromRoot = combineRootFactory<IProposalReportsState>('ProposalReports');

/**
 * Predefined queries to run against root states.
 */
export interface IProposalReportsQueries {
  getProgramAreaReportLoadStatus: Query<boolean>;
  getProgramAreaReports: Query<Model.ProgramAreaReport[]>;
  getSurveys: Query<Model.LegacyProposalReport[]>;
}

Queries.ProposalReports = {
  getProgramAreaReportLoadStatus: fromRoot(state => state.program_area_reports_loaded),
  getProgramAreaReports: fromRoot(state => state.program_area_reports),
  getSurveys: fromRoot(state => state.surveys)
};

/**
 * Add queries to ngrx domains type declarations.
 */
declare module '@app-ngrx-domains' {
  interface Root {
    ProposalReports: Query<IProposalReportsState>;
  }

  interface Queries {
    ProposalReports: IProposalReportsQueries;
  }
}
