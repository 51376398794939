import { forkJoin, of, Observable } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { State, Queries, Model, Actions as DomainActions } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { INSTITUTION_ACTION_TYPES } from './institution.action';
import { EnumErrorTypes, UserRoleScope } from '@app-models';
import { ROUTER_LINKS } from '@app-consts';
import { isNil } from 'lodash';

@Injectable()
  export class InstitutionEffects {

    constructor(
      private actions$: Actions,
      private apiService: ApiService,
      private router: Router,
      private store: Store<State>
    ) { }

    private errorPayload(error, location, show) {
      return DomainActions.App.setError({
        type: EnumErrorTypes.api,
        raw: error,
        location,
        show
      });
    }

    @Effect() get$: Observable<Action> = this.actions$.pipe(
      ofType(INSTITUTION_ACTION_TYPES.GET),
      map(toPayload),
      mergeMap((payload) => {
        // Fetch institution by id
        const institution_id = payload.institution_id;

        return this.apiService.getInstitutionById(institution_id).pipe(mergeMap((response: Model.Institution) => {
          return of(DomainActions.Institution.load(response));
        }),
        catchError(error => {
          this.router.navigate([ROUTER_LINKS.INSTITUTIONS]);
          return of(this.errorPayload(error, INSTITUTION_ACTION_TYPES.GET, false));
        }));
      })
    );

    @Effect() update$: Observable<Action> = this.actions$.pipe(ofType(INSTITUTION_ACTION_TYPES.UPDATE),
      map(toPayload),
      withLatestFrom(this.store.select(Queries.Institution.getInstitutionId)),
      mergeMap(([payload , institution_id]) => {
        const updates = Object.entries(payload.updates).reduce((obj, entry) => {
          const [key, value] = entry;
          obj[key] = isNil(value) ? undefined : value;
          return obj;
        }, {});

        return this.apiService.updateInstitution({ id: institution_id, ...updates }).pipe(mergeMap((response: Model.Institution) => {
          return of(DomainActions.Institution.load(response));
        }),
        catchError(error => {
          return of(this.errorPayload(error, INSTITUTION_ACTION_TYPES.UPDATE, true));
        }));
      })
    );

    @Effect() addRoleScopes$: Observable<Action> = this.actions$.pipe(
      ofType(INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES),
      map(toPayload),
      mergeMap((payload) => {
        const role_scopes = payload.role_scopes.map(role_scope => UserRoleScope.stripped(role_scope));
        return forkJoin(role_scopes.map(role_scope => {
          return forkJoin([
            this.apiService.addUserRoleScope(role_scope, payload.notify_on_assignment),
            this.apiService.getProfileById(role_scope.user_id)]).pipe(map(([role, user]) => ({ ...role, user })))
          })).pipe(
            mergeMap(res => of(DomainActions.Institution.addRoleScopesSuccess(res))),
            catchError(err => {
              if (err.status === 409) {
                return of(DomainActions.App.showAlert(`
                  <strong>Couldn't Add Duplicate Role</strong>
                  This user already has already been assigned this role.`, true));
              } else  {
                return of(this.errorPayload(err, INSTITUTION_ACTION_TYPES.ADD_ROLE_SCOPES, true));
              }
            }));
        })
    );

    @Effect() removeRoleScope$: Observable<Action> = this.actions$.pipe(
      ofType(INSTITUTION_ACTION_TYPES.REMOVE_ROLE_SCOPE),
      map(toPayload),
      mergeMap((payload) => {
        const role_scope = UserRoleScope.stripped(payload.role_scope);
        return this.apiService.removeUserRoleScope(role_scope).pipe(mergeMap(() => {
          return of(DomainActions.Institution.removeRoleScopeSuccess(payload.role_scope));
        }),
        catchError(error => of(this.errorPayload(error, INSTITUTION_ACTION_TYPES.UPDATE, true))));
      })
    );
  }
