import { IStrategiesState, State } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { STRATEGIES_ACTION_TYPES } from './strategies.action';

export function StrategiesReducer(state: IStrategiesState = State.Strategies, action: ActionWithPayload<any>): IStrategiesState {
  switch (action.type) {

    case STRATEGIES_ACTION_TYPES.LOAD: {
      return action.payload;
    }

    case STRATEGIES_ACTION_TYPES.CREATE_STRATEGY: {
      const item = action.payload;
      return [...state, item];
    }

    case STRATEGIES_ACTION_TYPES.UPDATE_STRATEGY_SUCCESS: {
      const item = action.payload.item;
      const dx = state.findIndex(strategy => strategy.id === item.id);
      if (dx >= 0) {
        return [
          ...state.slice(0, dx),
          {
            ...state[dx],
            ...item,
            id: action.payload.newId, // update the id in case it got replaced with real id
          },
          ...state.slice(dx + 1)
        ];
      } else {
        return state;
      }
    }

    case STRATEGIES_ACTION_TYPES.DELETE_STRATEGY_SUCCESS: {
      const item = action.payload;
      const dx = state.findIndex(strategy => strategy.id === item.id);
      if (dx >= 0) {
        return [
          ...state.slice(0, dx),
          ...state.slice(dx + 1)
        ];
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
