<div class="status-message" *ngIf="showMessage" [@fadeInOut] [ngClass]="{'status-message--align-left': alignLeft}">
  <ng-container [ngSwitch]="messageType">
    <ng-container *ngSwitchCase="'loading'" [ngTemplateOutlet]="loadingTemplate"></ng-container>
    <ng-container *ngSwitchCase="'success'" [ngTemplateOutlet]="successTemplate"></ng-container>
    <ng-container *ngSwitchCase="'fail'" [ngTemplateOutlet]="failTemplate"></ng-container>
  </ng-container>
</div>


<ng-template #loadingTemplate>
  <p class="status-message__message">
    <span class="loader-spinner-inline"></span>
    {{statusMessage.message}}
  </p>
</ng-template>

<ng-template #successTemplate>
  <icon iconId="checkmark" class="icon--block icon--right icon--positive margin-right-xxs"></icon>
  <p class="status-message__message no-margin">
    {{statusMessage.message}}
  </p>
</ng-template>

<ng-template #failTemplate>
  <icon iconId="alert" class="icon--block icon--right icon--negative margin-right-xxs"></icon>
  <p class="status-message__message__fail no-margin">
    {{statusMessage.message}}
  </p>
</ng-template>
