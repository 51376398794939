import { IAnnualPlansState, State } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload } from '@app-libs';
import { ANNUAL_PLANS_ACTION_TYPES } from './annual-plans.action';

/**
 * According to the action type, this reducer unpacks the payload, figures out the new (immutable) state,
 * then returns it to the central store.
 */
export function AnnualPlansReducer(state: IAnnualPlansState = State.AnnualPlans, action: ActionWithPayload<any>): IAnnualPlansState {
  switch (action.type) {
    case ANNUAL_PLANS_ACTION_TYPES.LOAD: {
      return action.payload;
    }

    case ANNUAL_PLANS_ACTION_TYPES.REFRESH_SUCCESS:
    case ANNUAL_PLANS_ACTION_TYPES.UPDATE_SUCCESS:  {
      const dx = _.findIndex(state, { id: action.payload.id });
      if (dx >= 0) {
        return [
          ...state.slice(0, dx),
          {
            ...state[dx],
            ...action.payload,
          },
          ...state.slice(dx + 1)
        ]
      } else {
        return state;
      }
    }

    case ANNUAL_PLANS_ACTION_TYPES.UPDATE_STATE_SUCCESS: {
      const dx = _.findIndex(state, { id: action.payload.id });
      if (dx >= 0) {
        let client_state = state[dx].client_state;
        const taskIndex = _.findIndex(state[dx].tasks, (t => t.id === action.payload.task.id));
        const task = state[dx].tasks[taskIndex];
        if (task.task_type === 'aebg_annual_plan_submit') {
          // rehydrate client states.
          client_state = {
            ...client_state,
            ...action.payload.task.client_state,
          }
        }

        return [
          ...state.slice(0, dx),
          {
            ...state[dx],
            tasks: [
              ...state[dx].tasks.slice(0, taskIndex),
              action.payload.task,
              ...state[dx].tasks.slice(taskIndex + 1)
            ],
            client_state: client_state,
          },
          ...state.slice(dx + 1)
        ]
      } else {
        return state;
      }
    }

    case ANNUAL_PLANS_ACTION_TYPES.CREATE_STRATEGY: {
      const item = action.payload;
      const dx = _.findIndex(state, { id: item.annual_plan_id });
      if (dx >= 0) {
        const strategies = state[dx].strategies[item.objective] || [];
        const reduced = [...strategies, item];
        return [
          ...state.slice(0, dx),
          {
            ...state[dx],
            strategies: {
              ...state[dx].strategies,
              [item.objective]: reduced,
            },
          },
          ...state.slice(dx + 1)
        ]
      } else {
        return state;
      }
    }

    case ANNUAL_PLANS_ACTION_TYPES.UPDATE_STRATEGY_SUCCESS: {
      const item = action.payload.item;
      const dx = _.findIndex(state, { id: item.annual_plan_id });
      if (dx >= 0) {
        const strategies = state[dx].strategies[item.objective] || [];
        const itemDx = _.findIndex(strategies, { id: item.id });
        const reduced = [
          ...strategies.slice(0, itemDx),
          {
            ...strategies[itemDx],
            ...item,
            id: action.payload.newId, // update the id in case it got replaced with real id
          },
          ...strategies.slice(itemDx + 1),
        ]
        return [
          ...state.slice(0, dx),
          {
            ...state[dx],
            strategies: {
              ...state[dx].strategies,
              [item.objective]: reduced,
            },
          },
          ...state.slice(dx + 1)
        ]
      } else {
        return state;
      }
    }

    case ANNUAL_PLANS_ACTION_TYPES.DELETE_STRATEGY_SUCCESS: {
      const item = action.payload;
      const dx = _.findIndex(state, { id: item.annual_plan_id });
      if (dx >= 0) {
        const strategies = state[dx].strategies[item.objective] || [];
        const itemDx = _.findIndex(strategies, { id: item.id });
        const reduced = [
          ...strategies.slice(0, itemDx),
          ...strategies.slice(itemDx + 1),
        ]
        return [
          ...state.slice(0, dx),
          {
            ...state[dx],
            strategies: {
              ...state[dx].strategies,
              [item.objective]: reduced,
            },
          },
          ...state.slice(dx + 1)
        ]
      } else {
        return state;
      }
    }

    case ANNUAL_PLANS_ACTION_TYPES.UPDATE_AMENDMENT_TASK_SUCCESS: {
      const current_state = _.cloneDeep(state) || [];
      const updated_task = action.payload;
      const annual_plan = current_state.find(plan => plan.id === updated_task.annual_plan_id);

      if (annual_plan) {
        let annual_plan_tasks = annual_plan.tasks || [];
        annual_plan_tasks = annual_plan_tasks.filter(task => task.id !== updated_task.id);
        annual_plan_tasks.push(updated_task);
        annual_plan.tasks = annual_plan_tasks;

        const annual_plans = current_state.filter(plan => plan.id !== annual_plan.id);
        annual_plans.push(annual_plan);

        return annual_plans;
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
