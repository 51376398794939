import { Action } from '@ngrx/store';
import { IAnnualPlansState, Actions, State, Model } from '@app-ngrx-domains';
import * as _ from 'lodash';
import { ActionWithPayload, NewTempId } from '@app-libs';
import { AnnualPlan, Cfad, Task, EnumErrorTypes } from '@app-models';

/**
 * List of action types
 */
const ACTION_PREFIX = 'ANNUAL_PLANS_';
export const ANNUAL_PLANS_ACTION_TYPES = {
  LOAD: `${ACTION_PREFIX}LOAD`,
  NOOP: `${ACTION_PREFIX}NOOP`,
  REFRESH: `${ACTION_PREFIX}REFRESH`,
  REFRESH_SUCCESS: `${ACTION_PREFIX}REFRESH_SUCCESS`,
  SERVICE_FAIL: `${ACTION_PREFIX}SERVICE_FAIL`,
  UPDATE: `${ACTION_PREFIX}UPDATE`,
  UPDATE_SUCCESS: `${ACTION_PREFIX}UPDATE_SUCCESS`,
  UPDATE_STATE: `${ACTION_PREFIX}UPDATE_STATE`,
  UPDATE_STATE_SUCCESS: `${ACTION_PREFIX}UPDATE_STATE_SUCCESS`,
  CREATE_STRATEGY: `${ACTION_PREFIX}CREATE_STRATEGY`,
  UPDATE_STRATEGY: `${ACTION_PREFIX}UPDATE_STRATEGY`,
  UPDATE_STRATEGY_SUCCESS: `${ACTION_PREFIX}UPDATE_STRATEGY_SUCCESS`,
  DELETE_STRATEGY: `${ACTION_PREFIX}DELETE_STRATEGY`,
  DELETE_STRATEGY_SUCCESS: `${ACTION_PREFIX}DELETE_STRATEGY_SUCCESS`,
  // Amendment Actions
  CREATE_AMENDMENT_STRATEGY: `${ACTION_PREFIX}CREATE_AMENDMENT_STRATEGY`,
  UPDATE_AMENDMENT_STRATEGY: `${ACTION_PREFIX}UPDATE_AMENDMENT_STRATEGY`,
  DELETE_AMENDMENT_STRATEGY: `${ACTION_PREFIX}DELETE_AMENDMENT_STRATEGY`,
  AMEND_APPROVED_STRATEGY: `${ACTION_PREFIX}AMEND_APPROVED_STRATEGY`,
  UPDATE_AMENDMENT_VALUE: `${ACTION_PREFIX}UPDATE_AMENDMENT_VALUE`,
  REVERT_AMENDMENT_VALUE: `${ACTION_PREFIX}REVERT_AMENDMENT_VALUE`,
  UPDATE_AMENDMENT_TASK_SUCCESS: `${ACTION_PREFIX}UPDATE_AMENDMENT_TASK_SUCCESS`
};

/**
 * Action creator class
 */
export class AnnualPlansActions {

  /**
   * Hydrates from the raw response data
   * @param {any} response
   * @returns {Action}
   */
  load(response: any): ActionWithPayload<any> {
    const state = [];

    // read annual plans.
    response.annual_plans.forEach(ap => {
      state.push(new AnnualPlan(ap).iObject<Model.AnnualPlan>());
    });

    return {
      type: ANNUAL_PLANS_ACTION_TYPES.LOAD,
      payload: state
    };
  }

  /**
   * No-op action.
   * @returns {Action}
   */
  noOp(): Action {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.NOOP,
    };
  }

  /**
   * Refresh annual plan of given id.
   * @param {number} id
   * @param {string} gotoUrl
   * @returns {ActionWithPayload<any>}
   */
  refresh(id: number, gotoUrl = ''): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.REFRESH,
      payload: { id: id, gotoUrl: gotoUrl },
    };
  }

  /**
   * Successfully fetched annual plan of given id.
   * @param {number} id
   * @returns {ActionWithPayload<any>}
   */
  refreshSuccess(res: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.REFRESH_SUCCESS,
      payload: new AnnualPlan(res).iObject<Model.AnnualPlan>()
    }
  }

  /**
   * Resets/clears data
   * @returns {Action}
   */
  reset(): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.LOAD,
      payload: State.AnnualPlans,
    };
  }

  /**
   * Error occurred while executing service api.
   * @param {any} error
   * @returns {Action}
   */
  serviceFail(error: any): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: ANNUAL_PLANS_ACTION_TYPES.SERVICE_FAIL,
      show: true,
      raw: error,
    });
  }

  update(ap: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE,
      payload: ap
    };
  }

  updateSuccess(ap: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_SUCCESS,
      payload: ap
    };
  }

  updateState(id: number, changes: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_STATE,
      payload: { id: id, changes: changes }
    };
  }

  updateStateSuccess(id: number, res: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_STATE_SUCCESS,
      payload: { id: id, task: new Task(res).iObject<Model.Task>() },
    };
  }

  createStrategy(item: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.CREATE_STRATEGY,
      payload: {
        ...item,
        id: NewTempId.id(), // assign auto generated id.
      }
    };
  }

  updateStrategy(item: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_STRATEGY,
      payload: item
    };
  }

  updateStrategySuccess(item: any, res: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_STRATEGY_SUCCESS,
      payload: {
        item: item,
        newId: res.id,
      }
    };
  }

  deleteStrategy(item: any): ActionWithPayload<any> {
    if (NewTempId.isTemp(item.id)) {
      // we're dealing with a temp record, no need to call the service.
      return {
        type: ANNUAL_PLANS_ACTION_TYPES.DELETE_STRATEGY_SUCCESS,
        payload: item
      }
    } else {
      return {
        type: ANNUAL_PLANS_ACTION_TYPES.DELETE_STRATEGY,
        payload: item
      };
    }
  }

  deleteStrategySuccess(item: any): ActionWithPayload<any> {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.DELETE_STRATEGY_SUCCESS,
      payload: item
    };
  }

  // AMENDMENT ACTIONS
  createAmendmentStrategy(amendmentTask: Model.Task, objective: string) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.CREATE_AMENDMENT_STRATEGY,
      payload: { amendmentTask, objective }
    };
  }

  updateAmendmentStrategy(amendmentTask: Model.Task, objective: string, strategy: { id: number, description: string }) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_AMENDMENT_STRATEGY,
      payload: { amendmentTask, objective, strategy, amendment_type: 'additions' }
    };
  }

  deleteAmendmentStrategy(amendmentTask: Model.Task, objective: string, strategy_id: number) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.DELETE_AMENDMENT_STRATEGY,
      payload: { amendmentTask, objective, strategy_id, amendment_type: 'additions' }
    };
  }

  reviseApprovedStrategy(amendmentTask: Model.Task, objective: string, strategy: { id: number, description: string }) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_AMENDMENT_STRATEGY,
      payload: { amendmentTask, objective, strategy, amendment_type: 'revisions' }
    };
  }

  revertRevisedStrategy(amendmentTask: Model.Task, objective: string, strategy_id: number) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.DELETE_AMENDMENT_STRATEGY,
      payload: { amendmentTask, objective, strategy_id, amendment_type: 'revisions' }
    };
  }

  updateAmendmentValue(amendmentTask: Model.Task, key: string, value: string) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_AMENDMENT_VALUE,
      payload: { amendmentTask, key, value }
    };
  }

  revertAmendmentValue(amendmentTask: Model.Task, key: string) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.REVERT_AMENDMENT_VALUE,
      payload: { amendmentTask, key }
    };
  }

  updateAmendmentTaskSuccess(amendmentTask: Model.Task) {
    return {
      type: ANNUAL_PLANS_ACTION_TYPES.UPDATE_AMENDMENT_TASK_SUCCESS,
      payload: amendmentTask
    };
  }
}

/**
 * Instantiate the class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.AnnualPlans = new AnnualPlansActions();

/**
 * Adds actions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    AnnualPlans: AnnualPlansActions;
  }
}
