export const ANNUAL_PLAN_OBJECTIVES = {
  ACCELERATION: 'acceleration',
  DEVELOPMENT: 'pd',
  GAPS: 'gaps',
  REGIONAL: 'regional',
  RESOURCES: 'resources',
  TRANSITIONS: 'transitions',
};

export const OBJECTIVES_CONFIG = [
  {
    key: 'gaps',
    title: 'Gaps in Service',
  },
  {
    key: 'transitions',
    title: 'Seamless Transitions',
  },
  {
    key: 'acceleration',
    title: 'Student Acceleration',
  },
  {
    key: 'pd',
    title: 'Shared Professional Development',
  },
  {
    key: 'resources',
    title: 'Leveraging Resources',
  },
];
