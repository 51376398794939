import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, State, Model } from '@app-ngrx-domains';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html'
})

export class ActionBarComponent {

  @Input() set actions(newActions: Array<Model.HeaderAction>) {
    this._actions = newActions;
  };

  get actions() {
    return this._actions || [];
  }

  private _actions: Array<Model.HeaderAction>;

  baseClass: string = 'action-button action-button--small action-button--primary';

  constructor(
    private store: Store<State>
  ) {}

  emitAction(action: Model.HeaderAction) {
    this.store.dispatch(Actions.Layout.emitAction(action));
  }
}
