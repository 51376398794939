import { Model } from '@app-ngrx-domains';
import { pick } from 'lodash';

export interface IProposalInstitution {
  id: number;
  institution_id: number;
  proposal_id: number;
  state_id: number;
  is_lead: boolean;
  is_employer: boolean;
  is_inactive: boolean;
}

export class ProposalInstitution {
  static iObject(pi: any): IProposalInstitution {
    return pick(pi, [
      'id',
      'institution_id',
      'proposal_id',
      'state_id',
      'is_lead',
      'is_employer',
      'is_inactive',
    ]);
  }

  /**
   * Returns institution name from proposal institutions.
   * @param proposal
   * @param institutionId
   */
  static getInstitutionName(proposal: Model.ProposalBase, institutionId: number): string {
    const found = (proposal.institutions || []).find(inst => inst.id === institutionId);
    if (found)  {
      return found.name;
    }
  }

  /**
   * Returns institution type from proposal institutions.
   * @param proposal
   * @param institutionId
   */
  static getInstitutionType(proposal: Model.ProposalBase, institutionId: number): string {
    const found = (proposal.institutions || []).find(inst => inst.id === institutionId);
    if (found)  {
      return found.type;
    }
  }
}

declare module '@app-ngrx-domains' {
  export namespace Model {
    export type ProposalInstitution = IProposalInstitution;
  }
}
