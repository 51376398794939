import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emphasize' })
export class EmphasizePipe implements PipeTransform {
  transform(fullText: string, emphasisText: string): any {
    // find only word characters
    const initialRegexp = /(\w+)/g;
    const wordMatches = emphasisText.match(initialRegexp);
    if (!!wordMatches) {
      const newTextString = wordMatches.join('|');
      const regexp = new RegExp(newTextString, 'gi');

      return fullText.replace(regexp, (text) => {
        return `<em>${text}</em>`;
      });
    } else {
      return fullText;
    }
  }
}
