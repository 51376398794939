import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'icon',
  templateUrl: './icon.component.html'
})
export class IconComponent {

  @Input() iconId: string;

  @HostBinding('class.icon')
  get hostClass() {
    return true;
  };

  constructor() { }

  public get iconPath(): string {
    return `assets/images/icons/svg-defs.svg#icon-${this.iconId}`;
  }

}
