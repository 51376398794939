import { Action } from '@ngrx/store';
import { Actions, Model, Queries } from '@app-ngrx-domains';
import { ActionWithPayload } from '@app-libs';
import { RolePermissions, EnumErrorTypes } from '../models';
import { INSTITUTION_TYPES } from '../consts/index';

/**
 * List of action types for Lookup Tables store
 */
const ACTION_PREFIX = 'LOOKUP_TABLES_';
export const LOOKUP_TABLES_ACTION_TYPES = {
  DO_LOOKUP: `${ACTION_PREFIX}DO_LOOKUP`,
  LOOKUP_SUCCESS: `${ACTION_PREFIX}LOOKUP_SUCCESS`,
  LOOKUP_INSTITUTIONS_SUCCESS: `${ACTION_PREFIX}LOOKUP_INSTITUTION_SUCCESS`,
  LOOKUP_HAS_DATA: `${ACTION_PREFIX}LOOKUP_HAS_DATA`,
  LOOKUP_UPDATE_INFO: `${ACTION_PREFIX}LOOKUP_UPDATE_INFO`,

  // Institution Actions
  LOOKUP_INSTITUTIONS_LIST: `${ACTION_PREFIX}LOOKUP_INSTITUTION_LIST`
};

// NOTE 1: Some of the LOOKUP_TABLES strings are passed to the API as keys.
// Do not change them without following the trail through the API.
// NOTE 2: If you add to this list, make sure you update LookupTablesResolve, which iterates over this list.
export const LOOKUP_TABLES = {
  CATEGORIES: 'categories',
  COUNTIES: 'counties',
  COUNTIES_CITIES: 'countiesCities',
  DOCUMENT_TYPES: 'document_types',
  DURATIONS: 'durations',
  FUNDS: 'funds',
  FUNDS_OBJECT_CODES: 'funds_object_codes',
  IMPACTED_GROUPS: 'impacted_groups',
  INSTITUTIONS: 'institutions', // the institutions state object name; there is no corresponding IsLoaded flag
  CCC_INSTITUTIONS: 'ccc_institutions', // Used for IsLoaded state only, not a state object
  CAEP_INSTITUTIONS: 'caep_institutions', // Used for IsLoaded state only, not a state object
  ALL_INSTITUTIONS: 'all_institutions', // Used for IsLoaded state only, not a state object
  SETTINGS_INSTITUTIONS: 'settings_institutions',
  JOB_CATEGORIES: 'job_categories',
  METRICS_DEFINITIONS: 'metric_definitions',
  OBJECT_CODES: 'object_codes',
  LEGISLATION: 'legislation',
  PERKINS_CODES: 'perkins_codes',
  PROGRAM_AREAS: 'program_areas',
  PROGRAM_DIVISIONS: 'program_divisions',
  ROLES: 'roles',
  SECTORS: 'sectors',
  SOC_CODES: 'soc_codes',
  SUBREGIONS: 'subregions',
  SUCCESS_GOALS: 'success_goals',
  TASKFORCE_RECOMMENDATIONS: 'recommendations',
  TOP6_CODES: 'top6_codes',
};

// TODO: I think this can just be an array of the tables now
export const GENERIC_LOOKUPS = {
  [LOOKUP_TABLES.CATEGORIES]: { name: 'categories' },
  [LOOKUP_TABLES.COUNTIES]: { name: 'counties' },
  [LOOKUP_TABLES.COUNTIES_CITIES]: { name: 'countiesCities' },
  [LOOKUP_TABLES.DOCUMENT_TYPES]: { name: 'document_types' },
  [LOOKUP_TABLES.DURATIONS]: { name: 'durations' },
  [LOOKUP_TABLES.FUNDS]: { name: 'funds' },
  [LOOKUP_TABLES.IMPACTED_GROUPS]: { name: 'impacted_groups' },
  [LOOKUP_TABLES.JOB_CATEGORIES]: { name: 'job_categories' },
  [LOOKUP_TABLES.METRICS_DEFINITIONS]: { name: 'metric_definitions' },
  [LOOKUP_TABLES.OBJECT_CODES]: { name: 'object_codes' },
  [LOOKUP_TABLES.LEGISLATION]: { name: 'legislation' },
  [LOOKUP_TABLES.PERKINS_CODES]: { name: 'perkins_codes' },
  [LOOKUP_TABLES.PROGRAM_AREAS]: { name: 'program_areas' },
  [LOOKUP_TABLES.PROGRAM_DIVISIONS]: { name: 'program_divisions' },
  [LOOKUP_TABLES.ROLES]: { name: 'roles' },
  [LOOKUP_TABLES.SECTORS]: { name: 'sectors' },
  [LOOKUP_TABLES.SOC_CODES]: { name: 'soc_codes' },
  [LOOKUP_TABLES.SUBREGIONS]: { name: 'subregions' },
  [LOOKUP_TABLES.SUCCESS_GOALS]: { name: 'success_goals' },
  [LOOKUP_TABLES.TASKFORCE_RECOMMENDATIONS]: { name: 'recommendations' },
  [LOOKUP_TABLES.TOP6_CODES]: { name: 'top6_codes' },
};

/**
 * Lookup Tables action creator class
 */
export class LookupTablesActions {

  doLookup(tableName: string): ActionWithPayload<any> {
    const lookupConfig = Object.values(GENERIC_LOOKUPS).find(lookup => lookup.name === tableName);
    if (!lookupConfig) {
      return this.lookupFail(tableName, `Couldn't get lookup information for "${tableName}"`);
    }
    return { type: LOOKUP_TABLES_ACTION_TYPES.DO_LOOKUP, payload: lookupConfig };
  }

  lookupSuccess(items, lookupType): ActionWithPayload<any> {
    // perform any transformations on the incoming items as needed.
    switch (lookupType) {
      case LOOKUP_TABLES.JOB_CATEGORIES: {
        items = this.cccMenuOptionsMapping(items);
        break;
      }

      case LOOKUP_TABLES.ROLES: {
        items = items.map(item => {
          return new RolePermissions(item).iObject<Model.RolePermissions>();
        });
        break;
      }
    }

    return {
      type: LOOKUP_TABLES_ACTION_TYPES.LOOKUP_SUCCESS,
      payload: {
        items,
        lookupType,
      },
    };
  }

  lookupInstitutionsSuccess(items, lookupPayload = {}): ActionWithPayload<any> {
    // just keep region as parent of districts (i.e., strip off counties).
    items = items.map(item => {
      if (item.type === INSTITUTION_TYPES.CCD) {
        return {
          ...item,
          parent: item.parent.filter(parent => {
            if ((parent.type === INSTITUTION_TYPES.RC)) {
              return parent;
            }
          })
        };
      } else {
        return item;
      }
    });

    return {
      type: LOOKUP_TABLES_ACTION_TYPES.LOOKUP_INSTITUTIONS_SUCCESS,
      payload: {
        items,
        lookupPayload
      },
    };
  }

  cccMenuOptionsMapping = function (items: Array<any>) {
    return items.map(i => ({
      value: i.id,
      label: i.name ? i.name : i.value,
    }));
  };

  lookupFail(actionType, error): ActionWithPayload<any> {
    return Actions.App.setError({
      type: EnumErrorTypes.api,
      location: actionType,
      show: true,
      raw: error,
    });
  }

  /** lookupHasData is a no op, since data exists already. No reducer catches this action.
   */
  lookupHasData(): Action {
    return {
      type: LOOKUP_TABLES_ACTION_TYPES.LOOKUP_HAS_DATA,
    };
  }

  /** Looks up institutions and populates lookup tables.
   * NOTE: Two main sets of institutions get stored in the institutions lookup table:
   *   1. native CCC institution types (college, district, region & state)
   *   2. CAEP consortia & member agencies - All institutions involved in CAEP
   *   Other use cases that need info on arbitrary institutions (businesses, etc.) must do their own lookups.
   * @param {{force: boolean; ccc: boolean; caep: boolean}} options
   *   force - Reload from API, even if institutions lookups have already been populated
   *   ccc - Load the CCC institutions (i.e., colleges, CCDs, regions)
   *   caep - Load CAEP member institutions; if ccc false, exclude the CCC institutions, if true, include them.
   *   all - Load ALL institutions from the service. NOT RECOMMENDED for most use cases.
   * @return {ActionWithPayload<any>}
   */
  lookupInstitutions(options?: { force?: boolean, ccc?: boolean, caep?: boolean, all?: boolean, include_settings?: boolean }): ActionWithPayload<any> {
    return {
      type: LOOKUP_TABLES_ACTION_TYPES.LOOKUP_INSTITUTIONS_LIST,
      payload: options || {}
    };
  }

  /**
   * Updates or adds an entry in the specified lookup table.
   * @param {string} table
   * @param {number} id
   * @param {*} info - to update
   * @returns {Action}
   */

  updateLookup(table: string, id: number, info: any): ActionWithPayload<any> {
    return {
      type: LOOKUP_TABLES_ACTION_TYPES.LOOKUP_UPDATE_INFO,
      payload: { table, id, info },
    };
  }
}

/**
 * Instantiate the actions class as a singleton object; this gets created the first time
 * it's loaded.
 */
Actions.LookupTables = new LookupTablesActions();

/**
 * Adds actions definitions to ngrx-domains table
 */
declare module '@app-ngrx-domains' {
  interface Actions {
    LookupTables: LookupTablesActions;
  }
}
