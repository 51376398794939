import { concat, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { State, Queries, Actions as DomainActions } from '@app-ngrx-domains';
import * as Sentry from '@sentry/browser';

import { ROUTER_LINKS } from '../consts';
import { toPayload } from '@app-libs';
import { ApiService } from '../services/api.service';
import { AUTH_ACTION_TYPES } from './auth.action';
import { AppUtils } from '../utilities';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<State>
  ) { }

  /**
   * Listens for login action, then executes the related actions.
   */
  @Effect() login$ = this.actions$.pipe( // listen for the login action
    ofType(AUTH_ACTION_TYPES.LOGIN),
    map(toPayload),
    switchMap(payload => {
      // ask backend to authenticate user
      const queryParams = this.route.snapshot.queryParams;

      if (queryParams && queryParams.session) {
        const session_token = atob(decodeURI(queryParams.session))
        localStorage.setItem('auth_token', session_token)
      }

      if (!(payload.email && payload.password) && !localStorage.getItem('auth_token')) {
        return of(DomainActions.Auth.loginFail(new HttpErrorResponse({
          statusText: 'No credentials provided',
          error: { message: 'No credentials provided' }
        })));
      }

      return this.apiService.doLogin(payload.email, payload.password).pipe(
        mergeMap(user => {
          // Set user info with Sentry
          Sentry.configureScope((scope) => {
            scope.setUser({ id: user.id, email: user.email_address });
          });

          const observables = [];

          const decoded = AppUtils.jwtDecodeToken(user.session_token);
          if (decoded && decoded.type === 'sudo-session') {
            observables.push(DomainActions.Auth.setSudoLogin(true));
          }

          return concat([
            ...observables,
            DomainActions.Auth.loginSuccess(user),
            DomainActions.Auth.refreshUserMessages(),
          ]);
        }),
        // If request fails, dispatch failed action
        catchError(error => of(DomainActions.Auth.loginFail(error))
      ))
    })
  );

  /**
   * Listens for logout action
   */
  @Effect() logout$ = this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.LOGOUT),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Auth.isSudoLoggedIn)),
    mergeMap(([payload, isSudo]) => {
      const auth_token = localStorage.getItem('suspended_token');
      if (isSudo && auth_token) {
        // Reinstate the suspended_token
        this.store.dispatch(DomainActions.Auth.setSudoLogin(false));
        localStorage.setItem('auth_token', auth_token);
        localStorage.removeItem('suspended_token');
        this.router.navigate([ROUTER_LINKS.ADMIN]);
        return of(DomainActions.Auth.login());
      } else {
        // remove both auth tokens from storage.
        localStorage.removeItem('auth_token');
        localStorage.removeItem('suspended_token');
        this.router.navigate(payload.routerCommands, payload.routerOptions);
        return of(DomainActions.Auth.logoutSuccess());
      }
    }),
    catchError(error => of(DomainActions.Auth.loginFail(error)))
  );

  /**
   * Listens for refresh action
   */
  @Effect() refresh$ = this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.REFRESH),
    switchMap(() =>
      this.apiService.doLogin().pipe(
        mergeMap((response: any) =>
          concat([
            DomainActions.Auth.refreshSuccess(response),
            DomainActions.Auth.refreshUserMessages(),
          ])),
          catchError(error => of(DomainActions.Auth.refreshFail(error))
        ))
    )
  );

  /**
   * Listens for user update action.
   */
  @Effect() updateUser$ = this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.UPDATE_USER),
    map(toPayload),
    mergeMap(data => {
      const profile = data.profile;
      const loginAfter = data.loginAfter;
      return this.apiService.updateProfile(profile.serverObject()).pipe(
        // send out success action
        map((response) => (loginAfter) ? DomainActions.Auth.loginOnDemand(response) : DomainActions.Auth.crudUserSuccess(response)),
        // unhandled exception occurred.
        catchError((error) => of(DomainActions.Auth.serviceFail(error))));
    })
  );

  /**
   * Fetch user notifications/emails.
   */
  @Effect() refreshUserMessages$ = this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.REFRESH_USER_MESSAGES),
    withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)),
    switchMap(data => {
      const [__, userId] = data;
      if (userId) {
        return this.apiService.getUserMessages(userId, 'current', 50).pipe(
          // send out success action
          map((response) => DomainActions.Auth.refreshUserMessagesSuccess(response)),
          // unhandled exception occurred.
          catchError((error) => of(DomainActions.Auth.serviceFail(error))));
      }
    })
  );

  /**
   * Listens for user update action.
   */
  @Effect() markUserMessages$ = this.actions$.pipe(
    ofType(AUTH_ACTION_TYPES.MARK_USER_MESSAGES),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.Auth.getCurrentUserId)),
    mergeMap(([mark, userId]) => {
      return this.apiService.markUserMessages(userId, mark.all, mark.markType, mark.ids).pipe(
        // send out success action
        map((response) => DomainActions.Auth.markUserMessagesSuccess(mark, response)),
        // unhandled exception occurred.
        catchError((error) => of(DomainActions.Auth.serviceFail(error))));
    })
  );
}
