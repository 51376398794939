import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State, Queries, Model } from '@app-ngrx-domains';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AppUtils } from '@app-utilities';

@Component({
  selector: 'app-nav-workflow',
  templateUrl: './nav-workflow.component.html'
})
export class NavWorkflowComponent implements OnInit, OnDestroy {
  workflow: Model.Workflow;
  destroy$: Subject<boolean> = new Subject();

  constructor(
    private store: Store<State>
  ) {}

  ngOnInit() {
    this.store.select(Queries.Workflow.getCurrent).pipe(
      takeUntil(this.destroy$)
    ).subscribe(workflow => {
      this.workflow = workflow;
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  stepRouterLink(step, baseLink) {
    if (baseLink) {
      return AppUtils.isNil(step.routerLink) ? `${baseLink}/${step.route}` : `${baseLink}/${step.routerLink}`;
    } else {
      return '';
    }
  }

  trackByName(index: number, step: any): number {
    return step.route;
  }
}
