import { Model } from '@app-ngrx-domains';
import { ModelBase } from './model.base';
import { Program } from './program';
import { Evidence } from './evidence';
import { Metric } from './metric';
import * as _ from 'lodash';

/**
 * Lmi item state data model
 */
export interface ILmiItem {
  id: number;
  name: string;
  proposal_id: number;
  lmi_demand: Array<any>;
  soc_codes: Array<any>;
  has_occupational_projections: boolean;
  other_supply: number;
  hide: boolean;
  region_institution_id: number;
  region: any;
  subregions: Array<any>;
  counties: Array<any>;
  programs: Array<Model.ProgramItem>;
  evidences: Array<Model.EvidenceItem>;
}

export class Lmi extends ModelBase {
  public id: number;
  public name: string;
  public proposal_id: number;
  public lmi_demand: Array<any>;
  public soc_codes: Array<any>;
  public has_occupational_projections: boolean;
  public other_supply: number;
  public hide: boolean;
  public programs: Array<Program> = [];
  public evidences: Array<Evidence> = [];
  public region_institution_id: number;
  public region: any;
  public subregions: Array<any> = [];
  public counties: Array<any> = [];

  constructor(
    id?: number,
    name?: string,
    proposal_id?: number,
    has_occupational_projections?: boolean,
    other_supply?: number,
    lmi_demand?: Array<any>,
    soc_codes?: Array<any>,
    hide?: boolean
  ) {
    super();
    this.id = id;
    this.name = name;
    this.proposal_id = proposal_id;
    this.has_occupational_projections = has_occupational_projections;
    this.other_supply = other_supply;
    this.lmi_demand = lmi_demand || [];
    this.soc_codes = soc_codes || [];
    this.hide = hide || false;
  }

  static transformRaw(rawLmis: Array<any>): Array<Lmi> {
    let lmis;
    if (rawLmis && rawLmis.length > 0) {
      lmis = rawLmis.map((lmi, index) => {
        const hideLmi = (index + 1 === rawLmis.length) ? false : true; // Hide all but the most recent one
        const new_lmi = new Lmi(lmi.id,
          lmi.name,
          lmi.proposal_id,
          lmi.has_occupational_projections,
          lmi.other_supply,
          lmi.lmi_demand,
          lmi.soc_codes,
          hideLmi);
        new_lmi.region_institution_id = lmi.region_institution_id;
        new_lmi.region = lmi.region;
        new_lmi.subregions = lmi.subregions;
        new_lmi.counties = lmi.counties;
        if (lmi.lmi_evidences) {
          new_lmi.evidences = lmi.lmi_evidences.map((e) => new Evidence(e.id, e.filename, e.type, e.url));
        }
        if (lmi.lmi_programs) {
          new_lmi.programs = lmi.lmi_programs.map((p) => {
            const program = new Program(p);
            program.metrics = p.metrics.map((m) => new Metric(m));
            return program;
          });
        }
        return new_lmi;
      });
    }

    return lmis;
  }

  /**
   * Returns demand supply total.
   * @static
   * @param {any} lmi
   * @returns
   */
  static demandSupplySummary(lmi: any) {
    const summary = {
      totalDemand: 0,
      totalSupply: 0,
      demandMinusSupply: 0,
      success: false,
      message: ''
    };

    if (lmi.programs) {
      lmi.programs.forEach((program) => {
        summary.totalSupply += Number(program.supply || 0) || 0;
      });
    }
    summary.totalSupply += Number(lmi.other_supply || 0) || 0;

    if (lmi.lmi_demand) {
      lmi.lmi_demand.forEach((demand) => {
        summary.totalDemand += Number(demand.demand || 0) || 0;
      });
    }

    summary.demandMinusSupply = summary.totalDemand - summary.totalSupply;
    if (summary.demandMinusSupply > 0) {
      summary.message = 'Demand Exceeded: Eligible for Funding';
    }
    if (summary.demandMinusSupply < 0) {
      summary.message = 'Not Enough Demand: Not Eligible for Funding';
    }

    return summary;
  }

}

/**
 * Adds models definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export namespace Model {
    export type LmiItem = ILmiItem;
  }
}
