import { State, Model } from '@app-ngrx-domains';

/**
 * States managed by reducer; used also as initial states
 */
State.Cfad = {
  current: {
    id: null,
    institution_id: null,
    direct_funding: false,
    rationale: '',
    fiscal_approach: '',
    justification: '',
    tasks: [],
    client_state: {},
  },
  old: [],
};

/**
 * Adds states definitions to ngrx-domains table.
 */
declare module '@app-ngrx-domains' {
  export interface ICfadState {
    current: Model.Cfad;
    old: Array<Model.Cfad>;
  }

  interface State {
    Cfad: ICfadState;
  }
}
