import {AfterContentInit, OnDestroy, Component, Input, HostBinding, ChangeDetectorRef} from '@angular/core';
import {AbstractControlDirective} from '@angular/forms';
import { Logger } from '../../../app-logger';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'input-group',
  templateUrl: './input-group.component.html'
})
export class InputGroupComponent implements AfterContentInit, OnDestroy {

  @HostBinding('attr.data-invalid') invalidState = false;
  @Input() headingText: string;
  @Input() errorsOnUntouched = false;
  @Input() legendHidden = false;
  @Input() required = false;
  @Input() control: AbstractControlDirective;

  invalid: boolean = false;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterContentInit() {
    if (!this.headingText) {
      Logger.error('Legend text required on input group component:');
      Logger.log(this.control);
    }

    if (this.control) {
      this.checkValidation();
      this.control.statusChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.checkValidation();
      });
    }
  }

  isRequired() {
    return this.required;
  }

  showErrors() {
    return this.control && this.required;
  }

  private checkValidation() {
    // SetTimeout to avoid expressionChangedAfterChecked error
    setTimeout(() => {
      this.invalidState = !!this.control.errors;
      this.cdr.markForCheck();
    }, 0);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
