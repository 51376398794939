import { of, combineLatest, concat, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { State, Queries, Actions as DomainActions, Model } from '@app-ngrx-domains';
import { toPayload } from '@app-libs';
import { ApiService } from '@app-services';
import { PROPOSAL_REVIEWS_ACTION_TYPES } from './reviews.action';
import { maxBy as _maxBy } from 'lodash';

/**
 * Injectable effects class
 */
@Injectable()
export class ProposalReviewsEffects {

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<State>
  ) {
  }

  /**
   * Fetches all reviews belonging to the proposal plus the review task.
   */
  @Effect() getReviews = this.actions$.pipe(
    ofType(PROPOSAL_REVIEWS_ACTION_TYPES.GET),
    map(toPayload),
    switchMap(filter => {
      const reviewTasks$ = this.apiService.listTasks(filter, true);
      const proposalReviews$ = this.apiService.getProposalReviews(filter.proposal_id);

      return combineLatest([reviewTasks$, proposalReviews$]).pipe(
        mergeMap(([tasks, reviews]) => {
          const proposalSurvey: Model.ProposalSurvey = reviews.proposal_review_survey;
          const resourceReviews: Model.Resource[] = reviews.resource_reviews;

          return concat([
            DomainActions.Proposals.load([proposalSurvey], proposalSurvey.id),
            DomainActions.ProposalReviews.getSuccess(filter, { task: _maxBy(tasks, 'id'), comments: resourceReviews })
          ]);
        }),
        catchError((error) => of(DomainActions.ProposalReviews.getFail(error)))
      );
    })
  );


  /**
   * Upserts proposal review.
   */
  @Effect() upsertReview$ = this.actions$.pipe(
    ofType(PROPOSAL_REVIEWS_ACTION_TYPES.UPSERT),
    map(toPayload),
    mergeMap(note => {
      // upserts review
      return this.apiService.upsertResourceNote(note).pipe(
        map(() => DomainActions.ProposalReviews.upsertSuccess(note)),
        catchError((error) => of(DomainActions.ProposalReviews.serviceFail(error)))
      );
    })
  );

  @Effect() completeTask$: Observable<Action> = this.actions$.pipe(
    ofType(PROPOSAL_REVIEWS_ACTION_TYPES.COMPLETE_TASK),
    map(toPayload),
    withLatestFrom(this.store.select(Queries.ProposalReviews.getTask)),
    mergeMap(([review_url, task]) => {
      this.store.dispatch(DomainActions.Layout.showBusySpinner(true));
      const payload = {
        task_type: task.task_type,
        proposal_id: task.proposal_id,
        user_id: task.user_id,
        review_url
      };
      if (task['duration_id']) {
        payload['duration_id'] = task.duration_id;
      }
      if (task['fund_id']) {
        payload['fund_id'] = task.fund_id;
      }

      return this.apiService.completeTask(payload).pipe(
        switchMap(completedTask => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.ProposalReviews.completeTaskSuccess(completedTask));
        }),
        catchError(error => {
          this.store.dispatch(DomainActions.Layout.showBusySpinner(false));
          return of(DomainActions.ProposalReviews.serviceFail(error));
        })
      );
    })
  );
}
