<!-- Roles -->
<div class="card">
  <ng-container [ngSwitch]="canEditRole">
    <div class="card-title" *ngSwitchCase="true">
      <div class="columns columns--2">
        <h2 class="h3">System Roles</h2>
        <div class="text-right">
          <div class="inline-button-group__container">
            <button type="button" class="inline-button action-button action-button--secondary"
                    (click)="transferRoles()">
              Transfer Roles
            </button>

            <button type="button" class="inline-button action-button action-button--secondary icon--left"
                    (click)="addRoles()">
              <icon iconId="plus"></icon>
              Add Role
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card-title" *ngSwitchDefault>
      <div class="columns columns--2">
        <h2 class="h3">Roles</h2>
        <div class="text-right">
          <p><a class="text-link" routerLink="/request-access">Need to be assigned a role?</a></p>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="grpUserSysRoles?.length>0">
    <div *ngFor="let role of grpUserSysRoles; let i=index; trackBy: trackById">
      <app-user-role-card
        [count]="i+1"
        [canEditRole]="canEditRole"
        [userRole]="role"
        (removeClicked)="removeRoleScope($event)">
      </app-user-role-card>
    </div>
  </div>

  <div *ngIf="grpUserSysRoles?.length===0" class="empty-state">
    No system roles have been assigned to the user.
  </div>
</div>

<!-- Associated Project Roles -->
<div class="card" *ngIf="grpUserProjRoles?.length>0">
  <div class="card-title">
    <h2 class="h3">Associated Project Roles</h2>
  </div>

  <div *ngFor="let role of grpUserProjRoles; let i=index; trackBy: trackById">
    <app-user-role-project-card
      [count]="i+1"
      [canEditRole]="canEditRole"
      [userRole]="role"
      (removeClicked)="removeRoleScope($event)">
    </app-user-role-project-card>
  </div>
</div>
